import { Container, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Outlet } from 'react-router-dom';
import { headerStyles, simpleContainerPaperStyle } from '../../styles/themeStylesSx';
//import useLocales from '../hooks/useLocales';

export default function ReportsContainer() {

  return (
    <Container>
      <Box sx={{pt:5}}>
        <Typography sx={ headerStyles }>{'Raportit'}</Typography>

      </Box>
      <Paper sx={ simpleContainerPaperStyle }>
          <Outlet />
      </Paper>
    </Container>
  );
}