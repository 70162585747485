/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch  } from 'react-redux';
import {  Box, Button, FormControl, InputLabel, Select, MenuItem, Typography, TextField } from '@mui/material';
import { RootState } from '../../store/ConfigureStore';
import { subHeaderStyles } from '../../styles/themeStylesSx';
import calendarServices from '../../services/calendarServices';
import {editFilterData} from '../../actions/filters';

type aclEditMembersType = {
  calendarId: any,
  id: any;
  open?: any,
  close: () => void,
  permission: any;
  calendarName: string
};
type GroupStateType = {
  open?: any;
  member?: any;
  group?: any;
  openDelete? : any;
  members? : any,
  addUser? : any;
  close: boolean;
  data?: any;
  newRole?: any;
  translatedRole?: string;
  updateOk?: boolean;
};

const AclEditCalendarMember =  (props: aclEditMembersType ) => {
  const close = props.close
   const authorit: any = useSelector((state: RootState) => state.auth);
  const filters: any = useSelector((state: RootState) => state.filters);
  const [state, setState] = useState<GroupStateType>({ open: false, openDelete: false, addUser: false, members: [], member: '', group: '', close: false, newRole: '', translatedRole: '' , updateOk: false});
  const dispatch = useDispatch();

  const token: any = `bearer ${authorit.idToken}`;
  useEffect( () => {
    if (state.close){
      setState({...state, close: false})
      close()
    }    
  }, []);
  
  useEffect(() => {
    if(state.updateOk ){
      setState({...state, close: false})
      close()
    }
  },[state.updateOk])
  

  useEffect(() => {
    
    const translatedPermissionValues =['writer','reader','owner','freeBusyReader','none'];
    const workRole= translatedPermissionValues[['Tee muutoksia','Näytä tiedot','Tee muutoksia ja hallitse', 'Näytä vain vapaat/varatut','Ei valtuuksia'  ].indexOf( props.permission.role)];    
    setState({...state, newRole : workRole, translatedRole: props.permission.role });    
  }, []);

  if (!filters.drive) return (<div> <br />. Ei vaadittuja oikeuksia</div>);

  const handleChange = (e: any) => {
    const work = e.target.value;
    
    const translatedPermissionValues = ['Tee muutoksia','Näytä tiedot','Tee muutoksia ja hallitse', 'Näytä vain vapaat/varatut','Ei valtuuksia'  ]
    const workRole = translatedPermissionValues[['writer','reader','owner','freeBusyReader','none'].indexOf(work)];

    setState({...state, newRole: work, translatedRole: workRole, data:{role: work, type: props.permission.scope.type, value: props.permission.scope.value}})
  }
  const submit = async () => {
    if (window.confirm('Oletko varma: Muutat ' + props.id  )) {
      try{
        await calendarServices
        .aclUpdateCalendar(  props.calendarId, props.id, state.data, token );
        //dispatch(editFilterData({}));
        setState({...state, close: true});
        setState({ ...state, updateOk: true});
        dispatch(editFilterData({somethchanged : Math.random()}));
       
        }catch(error) {
          console.log('errori')
          setState({ ...state, updateOk: false});
          dispatch(editFilterData({error: true, errorMessage: ' Roolin päivitysvihr AclEditFilterData'}));
      }    
    }
  } 

  return (
    <Box>        
      {props.calendarId && props.id &&
       <Typography sx={ subHeaderStyles }>{`Muutetaan Kalenterin permission ${props.id} kalenterista ${props.calendarName}`}</Typography>
      }
      <Box>
        <Box>
          <TextField
            sx={{ mt: 1, pl:1 }}
            label={"Valtuutettu"}
          
            rows={1}
            variant="outlined"
            fullWidth
            value={ props.id}
          //onChange={(e: any) => handleChange('newLinePm', e.target.value)}
          />

        <TextField
          sx={{ mt: 1, pl:1 }}
          label={"Kalenteri"}
        
          rows={1}
          variant="outlined"
          fullWidth
          value={ props.calendarId}
        //onChange={(e: any) => handleChange('newLinePm', e.target.value)}
        />
         <TextField
          sx={{ mt: 1, pl:1 }}
          label={"Rooli"}
        
          rows={1}
          variant="outlined"
          fullWidth
          value={props.permission.role}
        //onChange={(e: any) => handleChange('newLinePm', e.target.value)}
        />
        <FormControl  fullWidth = {true}>
          <InputLabel>Valitse Rooli</InputLabel>
          <Select
            labelId="selectRole"
            value={state.newRole}
            name="rolechange"
            onChange={handleChange}
          >
            <MenuItem value="writer">Tee muutoksia</MenuItem>
            <MenuItem value="reader">Näytä tiedot</MenuItem>
            <MenuItem value="owner"> Tee muutoksia ja hallitse</MenuItem>
            <MenuItem value = "freeBusyReader"> Näytä vain vapaat/varatut</MenuItem>
            <MenuItem value = "none"> Ei valtuuksia</MenuItem>
            <MenuItem value = " "> </MenuItem>
          </Select>

        </FormControl>

        </Box>
      </Box>
      <Box>
        <Button sx= {{p: 2 }  } variant='contained' color="primary"
          onClick={() => {submit()}}>Päivitä rooli</Button>                 
      </Box>
    </Box>
  )
}
export default AclEditCalendarMember