import React, { useEffect, useState } from 'react';
import { Button, Container, Link, Paper, Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ArrowBackRounded } from '@mui/icons-material';
import { backButtonStyle,  headerStyles,subHeaderStyles, simpleContainerPaperStyle,  tabsStyle, tabStyle } from '../../styles/themeStylesSx';
import { Outlet, Link as RouterLink, useNavigate, useLocation, Location } from 'react-router-dom';

export default function ServicesContainer() {
  //const { t } = useLocales();
  const location: Location = useLocation();
  //const params = useParams();
  const navigate = useNavigate();

  const [value, setValue] = useState('seuraohjelma');


  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(`/seuraohjelma/${newValue}`);
    setValue(newValue);
  };

  useEffect(() => {
    const parts = location.pathname.split('/');
    const last = parts[parts.length - 1];
    if (last !== value) {
      setValue(last);
    };
  }, [location, value]);

  const windowWidth = window ? window.innerWidth : 1000;
  const [width, setWidth] = useState(windowWidth);

  useEffect(() => {
    setWidth(windowWidth);
  }, [windowWidth]);

  return (
    <Container>
      <Link sx={ backButtonStyle } component={RouterLink} to='/'>
        <Button sx={{ color: 'secondary.main', fontWeight: 600 }} startIcon={<ArrowBackRounded />}>{'Back'}</Button>
      </Link>
      <Box>
        <Typography sx={ headerStyles }>{'Seuraohjelma'}</Typography>
      </Box>
      
      <Tabs
        orientation={ width < 1000 ? 'vertical' : 'horizontal' }
        value={value === '' ? 'seuraohjelma' : value}
        onChange={handleChange}
        TabIndicatorProps={{
          style: { display: 'none' }
        }}
        sx={tabsStyle}
        aria-label="serviceprogram tabs"
      >
        <Tab
          disableRipple
          disableFocusRipple
          sx={ tabStyle }
          value="seuraohjelma"
          label={'Seuraohjelma'}
        />
        <Tab
          value="lisaarivi"
          label={'Lisää rivi'}
          sx={ tabStyle }
        />
        <Tab
          value="analyysit"
          label={'Tilakalenterivertailu'}
          sx={ tabStyle }
        />
   
        
      </Tabs>
      <Paper sx={ simpleContainerPaperStyle }>
          <Outlet />
      </Paper>
    </Container>
  );
}