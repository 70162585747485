/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */

import React, { useState, useEffect } from 'react' ;
import { useSelector , useDispatch} from 'react-redux'
import TransferUsers from '../TransferUsers';
import { editFilterData } from '../../../actions/filters';
import {RootState} from '../../../store/ConfigureStore';
import { Box, Button,  Grid, TextField, CircularProgress } from '@mui/material';
import isEmail from 'email-validator'

type AddUserType = {
    member?: any,
    group?: any,
    open?: boolean,
    openDelete: any,
    members?: any,
    close: () => void,
  };

const AddUserToGroup = (props: AddUserType) => {
  const dispatch = useDispatch();
  const filters: any = useSelector((state: RootState)=> state.filters);
  const users: any = useSelector((state: RootState) => state.users);
  const groups: any = useSelector((state: RootState) => state.groups);
  const [ loading, toggleLoading ] = useState(false);
  const [arrays, setArrays] = useState<any>([]);
  const [arraysCivil, setArraysCivil] = useState<any>([]);
  const [members, setMembers] = useState<any>([]);
  const [selected, setSelected] = useState<any>([]);
  const close = props.close;  
  const [email, setEmail] = useState('');

  const [state, setState] = useState<any>({
    kind: '',
    etag: '',
    id: 0,
    email:  '',
    role: '',
    type: 'any',
    status: '',
    käyttäjä: [],
    openAdd: false,
    userId: 0,
    groupId: '',
    käyttäjäCivil:[],

  })
  useEffect (() => {   
    if (filters.loading) {
     <CircularProgress/>
    }
    
  },[members])  

  useEffect (() => {
    if (props.group) {
      toggleLoading(true);
      const selectedGroup =groups.find((gro: any ) => gro.id === props.group.id);
      setMembers( selectedGroup.members.map((member: any) => {return member.email}));
      setSelected(selectedGroup.members);
      toggleLoading(false);
      // membersFunc(token,groupActuel.id);
      setState({...state, groupId: props.group.id});
    }
  },[props.group, filters.haveToReadDrive]);

  if (loading ) return   <CircularProgress />

  const alreadyInGroup = (member1: any) => { 
    return  (props.group.members.some((member: any) =>  {
     return member.email === member1
    }));
  }
 
  const handleChange = async (name: any, value: string) => { 
    setState({ ...state, [name]: value });    
    const memberArray =  users.filter((user: any) => user.primaryEmail
      .toLowerCase()
      .includes(value.toLowerCase())   
    )
    const memberArray1 =   memberArray.filter((member: any) => { 
      if (!alreadyInGroup(member.primaryEmail)) return member
    })
    
    setArrays(memberArray1);
  } 

  const handleChangeCivil = async (value: string) => {     
    setEmail(value); 
  }   
  const clearSearch = async () => {     
    setState({...state, käyttäjä:[]});
    setArraysCivil([]);
   
  } 

  const AddCivilEmailToList = () => {
    let workArray:any = [...arraysCivil];
    const work1 = ({primaryEmail: email})
    //workArray.push(work1)
   if (workArray.findIndex((ind: any) => ind.primaryEmail === work1.primaryEmail) !== -1 ){   
      dispatch(editFilterData( {error: true, errorMessage: 'Käyttäjätunnus on jo listalla'}));
      workArray = []
   }
   else {
      if (!isEmail.validate(email)) {
        dispatch(editFilterData( {error: true, errorMessage: 'email-tunnus virheellinen'}))
      } else {      
        let workArray = [] ;
          const work = ({
            primaryEmail: email.toLowerCase()
          })
          workArray = [...arraysCivil];
          workArray.push(work);
          setArrays(workArray);
          setArraysCivil(workArray);
          setEmail('');
          workArray=[];
        } 
      }
    }

  return( 
    <Box>
      <Box>
        {arraysCivil.length  === 0 &&
          <TextField
            sx={{ ml: 1, p:1 }}
            label={'Kirjoita lisättävän käytttäjän nimeä'}      
            rows={1}
            variant="outlined"
            fullWidth
            value={ state.käyttäjä}
            onChange={(e: any) => handleChange('käyttäjä', e.target.value)}
          />
        }
        {(!props.group.email.includes('drive') && state.käyttäjä.length === 0) &&
          <Box>            
            <TextField
              sx={{ ml: 1, p:1, width : 250
              }}
              label={'Anna henkilön siviilisähköposti'}
            
              rows={1}
              variant="outlined"
              fullWidth
              value={ email}
          
              onChange={(e: any) => handleChangeCivil(e.target.value)}
            />       
            <Button variant='contained' color = 'primary' sx= {{m:2}}
            onClick = {() => {AddCivilEmailToList()}}> Lisää email
            </Button>
          </Box>
    }
       <Button variant='contained' color = 'primary' sx= {{m:2}}
            onClick = {() => {clearSearch()}}> Tyhjennä haut
            </Button>
      <Box>
        <Grid item xs ={2}>
          { TransferUsers(arrays, setMembers, members, state.groupId, selected, close ) } ;
        </Grid>  
        {/* <Grid>
          {members.map((me: any) => {
            
            return ( 
              me + '/'
            )
          }
          
          )}
        </Grid> */}
      </Box>
    </Box>
  </Box>
  )
}

export default AddUserToGroup;