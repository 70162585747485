import React from 'react';
import { Button, Drawer } from "@mui/material";
import { Box } from '@mui/system';
import { KeyboardArrowLeftRounded } from '@mui/icons-material';
//import useLocales from '../../hooks/useLocales';

export interface SidePanelProps {
  children: React.ReactNode,
  open: boolean,
  noBack?: boolean,
  close: (event: React.MouseEvent<HTMLButtonElement>) => void,
}

export default function SidePanel(props: SidePanelProps) {
  const children = props.children;
  //const { t } = useLocales();
  return (
    <Drawer
      open={props.open}
      onClose={props.close}
      variant="temporary"
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
      anchor="right"
      sx={{
        width: {
          xs: '75%',
          md: '80%',
          lg: '40%',
          xl: '30%'
        },
        '& .MuiDrawer-paper': {
          pr: 4,
          width: {
            xs: '55%',
            md: '600%',
            lg: '50%',
            xl: '40%'
          },
          boxSizing: 'border-box',
        },
      }}
    >
      <Box sx={{
        p: 2,
        width: '100%',
      }}>
        <Button sx={{ mb: 3, mt: 2, textTransform: 'none', color: 'secondary.main', fontWeight: 600 }} onClick={ props.close } startIcon={<KeyboardArrowLeftRounded />}>{'Back'}</Button>
        {children}
      </Box>
    </Drawer>
  );
}