/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */

import React, { useState, useEffect } from 'react' ;
import { useSelector , useDispatch} from 'react-redux';
import { subHeaderStyles } from '../../styles/themeStylesSx';
import moment from 'moment';
import userServices from '../../services/userServices'
import {addUsers} from '../../actions/users';
import { editFilterData } from '../../actions/filters';
import {RootState} from '../../store/ConfigureStore';
import { Box, Button, TextField, Typography, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import {AccountLineType} from './../../types/accountLine';
import MouseOverPopover from '../../utilities/MouseOverPopover';

const isEmail = (email: any) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

type AddUserComponentType = {
  close: () => void,
};
let popOverText = '';

const AddServiceLine = (props: AddUserComponentType) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filters: any = useSelector((state: RootState)=> state.filters);
  const users: any = useSelector((state: RootState)=> state.users);
  const auth: any = useSelector((state: RootState) => state.auth);
  const [ loading, toggleLoading ] = useState(false);
  const token = `bearer ${auth.idToken}`;
  const [state, setState] = useState<AccountLineType>({
    creationDate: moment().format('D.M.YYYY'),
    lastLoginDate:'',
    id:'',
    primaryEmail: `.helsinginrauhanyhdistys.fi`,
    addUser: false,
    deleteUser: false,
    openDrive: false,
    openGroup: false,
    familyName: '',
    givenName: '',
    passWord: '',
    customerId: '',
    selectGroups: false,
    workFamilyName: '',
    workGivenName: '',
  });
   const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    popOverText =`Tilin nimeksi etunimi.Sukunimi@helsinginrauhanyhdistys.fi. Salasana ekalla kerralla Ryky2017, Valisesi lisäämäsi käyttäjän riviltä ryhmät - nappi ja lisää tili ryhmään`;
  };
 
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
 

  useEffect (() => {
    if (filters.loading) {
     <CircularProgress/>
    }   
    toggleLoading(false);     
  },[]);  
    
  if (loading ) return   <CircularProgress />

  const submit = async () => {
    let ok = true;
    if (!state.familyName || !state.givenName  ) {
      ok = false;
      dispatch(editFilterData({error: true, errorMessage: `Anna etu- ja sukunimi`}))
    }

    if (!isEmail(state.primaryEmail)) {
      ok = false;
      dispatch(editFilterData({error: true, errorMessage: `Email virheellinen`}))
    }

    if (users.findIndex((email: any) => { 
      return email.primaryEmail === state.primaryEmail

    }) !== -1) {
      ok = false;
      dispatch(editFilterData({error: true, errorMessage: `tili: ${state.primaryEmail} exists`}))

    } 
    if (ok) {
      const accountToInsert=({
        creationDate: state.creationDate,
        lastLoginDate: state.lastLoginDate,
        primaryEmail: state.primaryEmail,
        givenName: state.givenName,
        familyName: state.familyName,
        customerId: 'C0243pof9',
        passWord: 'Ryky2017',
        changePasswordAtNextLogin: true,
      });
      try {
        const user: any = await userServices
        .insertAccount(token, accountToInsert);
        if (user.Error) {
          dispatch(editFilterData({error: true, errorMessage: `Smth went wrong? Code ${user.Error}`}))
        } else {
          dispatch(addUsers({
          creationDate: moment(user?.data.creationTime).format('D.M.YYYY'),
          lastLoginDate: moment('1970-01-01').format('D.M.YYYY'),
          primaryEmail:user?.data.primaryEmail,
          id: user?.data.id    
          }))
          dispatch(editFilterData({error: true, errorMessage: `tili: ${user?.data.primaryEmail} lisätty`}))
        }

      
      }catch(error) {
        console.error('Error addinf userAccount in AddUserComponent', error)
      }
    }    
  }   

  const handleChange = (name: string, value: any | string | Date | null | undefined ) => {
    let work : string = '';
    work = value?.toLowerCase();
    let work1 = '';
    if (name === 'givenName') {
      work1 = work.replace(/ä/g,'a').replace(/ö/g,'o').replace(/å/g,'a');     
      setState({ ...state, [name]: value, workGivenName: work1, primaryEmail: `${work1}.${state.workFamilyName}@helsinginrauhanyhdistys.fi`.toLowerCase() })
    }
    if (name === 'familyName') {
      work1 = work.replace(/ä/g,'a').replace(/ö/g,'o').replace(/å/g,'a'); 
      setState({ ...state, [name]: value, workFamilyName: work1, primaryEmail: `${state.workGivenName}.${work1}@helsinginrauhanyhdistys.fi`.toLowerCase() })
    }
    if (name === 'primaryEmail') {
      dispatch(editFilterData({error: true, errorMessage: 'Not allowed'}));
    }
    
  }
  const paluu = async () => {

    navigate("/user");
  }

  return( 
    <Box>  
      <MouseOverPopover popOverText = { popOverText } anchor = { anchorEl }/>
      <Box>
        <Typography 
          aria-owns = { open ? 'mouse-over-popover1' : undefined}
          aria-haspopup = "true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        
        sx={ subHeaderStyles }>{`Lisää tili`}</Typography>
      </Box>        
      <Box  sx={{ p:1}}>
        <TextField 
          sx={{ mt: 1, pl:1 }}
          label={'Etunimi'}
        
          rows={1}
          variant="outlined"
          fullWidth
          value={ state.givenName}
          onChange={(e: any) => handleChange('givenName', e.target.value)}
        /> 

        <TextField
          sx={{ mt: 1, pl:1 }}
          label={'Sukunimi'}
        
          rows={1}
          variant="outlined"
          fullWidth
          value={ state.familyName}
          onChange={(e: any) => handleChange('familyName', e.target.value)}
        /> 

        <TextField
          sx={{ mt: 1, pl:1 }}
          label={'Email'}
        
          rows={1}
          variant="outlined"
          fullWidth
          value={ state.primaryEmail}
          // onChange={(e: any) => handleChange('primaryEmail', e.target.value)}
        />
        <Box>
        
          <Box>
            <Button color="primary" variant="contained" sx={{ ml: 1 }}
              onClick= {submit}>
              {('Submit')}
            </Button>   
            <Button color="primary" variant="contained" sx={{ ml: 1 }}
              onClick= {paluu}>
              {('Paluu')}
            </Button>       
          </Box>          
       
        </Box>
      </Box>
    </Box>
  )
}

export default AddServiceLine;