/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */

import selectServices from '../../selectors/services';
import submitGetCalendarIdArr from '../calendars/submitGetCalendarIdArr'
import submitGenCalUpd from '../calendars/submitGenCalUpd'
import database from '../../firebase/firebase';
import {setCalendarIds  } from '../../actions/filters';
import { editFilterData } from '../../actions/filters';

export const submitHryCals = async ( uid: any, hryTilat: any,
  kahvio: any, yläkerranKokousHuone: any, keittiö: any,
  arkisto: any, autoTalli: any, kerhoHuone: any,  dispatch: any, token: any, servYear: any ,filters: any) => {

  const selectRows = async (services: any, filters: any) => {
    const eventsToCalendarRanged = await selectServices(services,filters);
    return eventsToCalendarRanged
  }

  const updateEventsToCalendar = async (eventsToCalendar: any) => {
    const eventsToCalendarRange = await selectRows(eventsToCalendar, filters);
    
    //for (const event of eventsToCalendar) {
    await eventsToCalendarRange.reduce(async(earlier: any, current: any) => {
      await earlier
      // eslint-disable-next-line array-callback-return
      const calendarId = calendarIdArray.filter((id: any) => {
        if (current.Location.toLowerCase() === id.calendar.toLowerCase()) return id} )
        const deleteOnly: string = 'no';
      await submitGenCalUpd(calendarId[0], token,current, deleteOnly,  dispatch, editFilterData );
      await new Promise( resolve => setTimeout(resolve, 1000));
    }, Promise.resolve([]));
  }

  const retr1 = async (calendarDataArray: any) => {
    const calendarDataArra: any = calendarDataArray.map((row: any) => {
      if (caleid.findIndex((ind: any) => ind.toLowerCase() === row.calendar.toLowerCase()) !== -1)
        row.update = true
      else row.update = false
      return row
    });
    dispatch(setCalendarIds(calendarDataArra))
    return calendarDataArra
  }
  const retr = async (auth: any) => {
    const calendarDataArray1 = await submitGetCalendarIdArr(token)
    const calendars = await retr1 (calendarDataArray1)
    return calendars
  }


  const getSheet = async () => {
    const returnable = await retr (token)
    return returnable
  }

  let caleid: any = []
  if (hryTilat) caleid.push('hry tilat');
  if (kahvio) caleid.push('kahvio');
  if (yläkerranKokousHuone) caleid.push('yläkerran kokoushuone');
  if (keittiö) caleid.push('keittiö');
  if (autoTalli) caleid.push('autotalli');
  if (kerhoHuone) caleid.push('kerhohuone');
  if (arkisto) caleid.push('arkisto');
  
  const calendarIdArray = await getSheet();
  //dispatch(emptyDrives())
  let serviceList: any = []
  await database.ref(`users/${uid}/serviceYear/${servYear}/serviceProgram`)
    .once('value')
    .then((snapshot: any) => {
      snapshot.forEach((childSnapshot: any) => {
        serviceList.push({
          serviceId:childSnapshot.key,
          ...childSnapshot.val()
        })
      })
      // eslint-disable-next-line array-callback-return
      const eventsToCalendar = serviceList.filter((service: any) => {
    
        if (caleid.indexOf(service.Location.toLowerCase()) !== -1 && service.strike.toLowerCase() === 'n')
          return service})
    updateEventsToCalendar(eventsToCalendar)
  })
}