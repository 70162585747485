/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
import programService from '../../services/programService'
import 'core-js/stable'

const updateSpeakerColor = async (token: any,id: any, sheetId: any, subSheet: any, strikeThrough: any ,columnStart: any, columnEnd: any) => {
  await programService
    .updateColor(token,id,sheetId, subSheet,strikeThrough,columnStart, columnEnd)
    .then(initialLines => {
    })
    .catch(error => {
      console.log('erreoddd',error)

    })
}

export default updateSpeakerColor;