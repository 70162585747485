
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react'
import moment from 'moment';
import {   useDispatch, useSelector  } from 'react-redux'
// eslint-disable-next-line no-unused-vars
import SpeakerDatesForm from './SpeakerDatesForm'
import { setTypesToState, setDaysToState, speakerEdited} from '../../actions/filters';
import { CircularProgress } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import {RootState} from '../../store/ConfigureStore';
type PickUpDaysComponentType = {
  speaker?: any,
  filters?: any[],
  close: () => void,
};

const PickUpDays = (props: PickUpDaysComponentType)=> {
  const close = props.close;
  const [ loading, toggleLoading ] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [closeSidePanel, setCloseSidePanel] = useState(false)

  // const authorit: any = useSelector((state: RootState) => state.auth);
  const speakersState = useSelector((state: RootState) => state.speakers)
  const filters: any =useSelector((state: RootState) => state.filters)
  // const servYear= filters.serviceYear;
  // const token: any = `bearer ${authorit.idToken}`
  const dispatch: void = useDispatch()
  const selectedSpeaker: any = props.speaker ? props.speaker : '';

  // const closeSide = () => {
  //   setCloseSidePanel(true)
  // }
  useEffect(() => {

    if (filters.speakerEdited) {
      close()
    }


  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filters.speakerEdited])

  useEffect(() => {

    const disp = async (dispatch: any) => {
        await dispatch(speakerEdited(false))
      }

      disp(dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filters.speakerEdited])

  useEffect(() => {
    if (props.speaker) {
      let dayRangeArray: any [] = [];

      const createDayRangeArray = async (dayRangeArray: any,speaker: any,speakers: any, dispatch: any) => {

        const array = dayRangeArray;
        if (dayRangeArray.length === 0) {
          if (speaker.datesArrayFinal.length > 0) {
            speaker.datesArrayFinal.forEach((da: any) => {
              let del = false;
              if (moment(da.apvm).isSame(moment('31.12.2040','DD.MM.YYYY'))) del = true

              array.push({id:uuidv4() ,startDate:da.apvm, endDate:da.ppvm, deleted:del,speakerId:speaker.linenumber})

            })
            await dispatch(setDaysToState(array));
            await dispatch(speakerEdited(false));
          }
        }
      }

      createDayRangeArray(dayRangeArray,selectedSpeaker, speakersState,dispatch)

      let typesArray: any [] = [];
      const createTypeArray = async (typeArray: any, speaker: any,speakers: any,dispatch: any) => {
        if (typesArray.length === 0) {
          if (speaker.typesArray.length > 0 ) {
            typeArray = speaker.typesArray.map((type: any) =>type )
          }
          await dispatch(setTypesToState(typeArray));
          toggleLoading(false);
        }
      }
      // const disp = async (dispatch: any) => {
      //   await dispatch(speakerEdited(false))
      // }

      toggleLoading(true)
      createTypeArray(typesArray, selectedSpeaker,speakersState,dispatch)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.speaker, props.filters])
  if (loading)  return <div><CircularProgress /> <br />still loading... wait</div>


  if (!filters.freetime) return (<div> <br />. Ei vaadittuja oikeuksia PickUpDays</div>)
  return (
    <div>
      {props.speaker &&
      <SpeakerDatesForm
        speaker = {selectedSpeaker}
        filters = { filters}
      />
      }
    </div>
  )
}
export default  PickUpDays
