import * as React from 'react';
import  {  useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { editFilterData } from '../../actions/filters';
import Stack from '@mui/material/Stack';
import {RootState} from '../../store/ConfigureStore';
import 'moment/locale/fi';

export default function ResponsiveDateTimePickers(Alkupäivä: any) {
  const filters: any = useSelector((state: RootState)=> state.filters);
    const dispatch = useDispatch();
    const headerText: any = Object.keys(Alkupäivä);

    useEffect(() => {
      moment.updateLocale('fi', { week: { dow: 1 } })
  }, [])
    
  let momentti = moment();
  if (filters.newLineStartDate !== '') {
    momentti = moment(filters.newLineStartDate+ 'T' + filters.newLineStartTime,'D.M.YYYYTHH.mm');
    if (headerText[0] !== 'Alkupäivä'){
      momentti = moment(filters.newLineEndDate+ 'T' + filters.newLineEndTime,'D.M.YYYYTHH.mm');
    }
  };
  let value = momentti;
  const handleChange =(newValue: any) => {
    value = newValue;
    let startDate = '';
    let endDate = '';
    let startTime = '';
    let endTime = '';

    if (headerText[0] === 'Alkupäivä') {
      startDate =  moment(newValue).format('D.M.YYYY');
      startTime = moment(newValue).format('HH.mm');
      endDate = moment(newValue).format('D.M.YYYY');
      endTime = moment(newValue).add(1, 'hour').format('HH.mm');    
    }
    else {
      startDate =  filters.newLineStartDate;
      startTime = filters.newLineStartTime;
      endDate = moment(newValue).format('D.M.YYYY');
      endTime = moment(newValue).format('HH.mm');   
    }
    
    dispatch(editFilterData({ 
      newLineStartDate: startDate, 
      newLineStartTime: startTime, 
      newLineVko: moment(newValue).isoWeek(),
    
      newLineVkonpäivä: moment(newValue).format('dd'),
      newLineEndDate: endDate,
      newLineEndTime: endTime,
      startDate : moment(newValue).subtract(1,'day'), 
      endDate: moment(newValue).add(1, 'day'), 
      text: '' 
    })) 
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Stack spacing={3}>
        <DateTimePicker
          label={headerText}
          renderInput={(params) => <TextField {...params} />}
          value={value}
          
          ampm={false}
          minutesStep = {15}
          // minDateTime= {moment()}
          closeOnSelect={true}
          onChange={(newValue) => {
            handleChange(newValue);
          }}
        />
        
      </Stack>
    </LocalizationProvider>
  );
}