import React, { useState, useEffect } from 'react' ;
import {  svgDefaultProps } from "@nivo/bar";
import {useSelector, useDispatch } from 'react-redux';
import {RootState} from '../../store/ConfigureStore';
import moment from 'moment';
import { Button, Paper, Table, TableContainer, TableRow, TableHead, TableCell, TableBody, Box } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import { Navigate } from 'react-router-dom';
import { colors } from '../../styles/themeStylesSx';
import SidePanel from '../sidepanel/SidePanel';
import ListSpeakerDays from '../loadSpeakers/ListSpeakerDays';
import ListSpeakersPage from '../speakers/ListSpeakersPage';
import MoveTurns from './MoveTurns';
import { editFilterData } from '../../actions/filters';

type ServiceStateType = {
  speaker?: any;
  openStrike?: boolean;
  openSpeaker?: boolean;
  serviceId?: number;
  add?: boolean;
  linenumber?: number;
  id?: any;
  openLeave?: boolean;
  forceName?: boolean;
  mouseMove?: boolean,
};
const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  sticky: {
    position: "sticky",
    left: 0,
    background: "white",
    boxShadow: "5px 2px 5px grey",
    borderRight: "2px solid black"
  }
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const nameArray: any = ['kesk','su16','su18', 'la18', 'ke19', 'alue', 'lait', 'kirk', 'last', 'tall', 'raam', 'ahve', 'useita'];
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const layers: any = [...svgDefaultProps.layers];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles: any = {
  fontFamily: "sans-serif",
  textAlign: "left"
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const axisBottom: any = {
  tickSize: 5,
  tickPadding: 5,
  tickRotation: -30,
  legend: "Year/Month",
  legendPosition: "middle",
  legendOffset: 32
};


// eslint-disable-next-line @typescript-eslint/no-unused-vars
const theme: any = {
  background: "#D5D2AF",
  axis: {
    fontSize: "14px",
    tickColor: "#0D1E0A",
    ticks: {
      line: {
        stroke: "#555555"
      },
      text: {
        fill: "#081006"
      }
    },
    legend: {
      text: {
        fill: "#aaaaaa"
      }
    }
  },
  grid: {
    line: {
      stroke: "#555555"
    }
  }
};
const colorCodes: any = [
  '#800000',
  '#A52A2A',
  '#008000',
  // '#FFA500',
  '#00FF00',
  // '#FF0000',
  // '#0000FF',
  '#7FFFD4',
  '#00FFFF',
  '#A52A2A', 
  '#00008B',
  '#C0C0C0',
  '#FF00FF',
  '#FFC0CB',
  '#808000',
  '#008000',
  '#000000',
  '#808080',
  '#FFFFFF',
  '#800080',
  '#FFC0CB',
  '#00008B',
];
let headerWeeks:any =[];

const CreateGraphicsOfTimes: any = (props: any) => {
  const dispatch = useDispatch();
  const [headerWeek, setHeaderWeek] = useState([]);
  const [leaveWeek, setLeaveWeek] = useState<any[]>([]);
  const classes = useStyles();
  const [state, setState] = useState<ServiceStateType>({speaker: '', openSpeaker: false, openLeave: false, mouseMove: false });
  const speakers: any = useSelector((state: RootState) => state.speakers);
  const serviceArray: any = useSelector((state: RootState) => state.services);
  const filters: any = useSelector((state: RootState) => state.filters);
  const [speakerArray, setSpeakerArray] = useState<any>([]);
  const [toPrin, setToPrin] = useState<any>([]);
  const [serviceMissing, setServiceMissing] = useState<boolean>(false);
  const [yearMonthDayWeek, setYearMonthDayWeek] = useState<any>([]);
  const [dayArrayState, setDayArrayState] = useState<any>([]); 
  const [clickMove, setClickMove] =useState<any>({count: 0, from: [], to: []}); 

  useEffect (() => { 
    let startDate: any = moment('01.01.' + filters.serviceYear,'DD.MM.YYYY').format('DD.MM.YYYY');
    let startWeek: any = moment(startDate,'DD.MM.YYYY').format('ww');
    let work = ({
       weekDay : startDate,
      weekNumber: startWeek
    })
    let workArray = [];
    workArray.push (work);
    setYearMonthDayWeek(workArray);
    const workNumber = Number(filters.serviceYear) + 1
    while (moment(startDate, 'DD.MM.YYYY').unix() < moment('01.01.' + workNumber, 'DD.MM.YYYY').unix()) {
     startDate = moment(startDate,'DD.MM.YYYY').add(1,'day').format('DD.MM.YYYY') 
     startWeek = moment(startDate,'DD.MM.YYYY').format('ww')
     work = ({
      weekDay : startDate,
      weekNumber: startWeek
     })
     workArray.push(work);
     setYearMonthDayWeek(workArray);
    }
  },[filters.somethchanged])

  useEffect(() => {
    headerWeeks = [];
    for (var i: number = 0; i < 54; i++) {
      if (i === Number(53) ) {
        const work = moment('31.12.' + filters.serviceYear, 'DD.MM.YYYY').format('DD.MM.YYYY');
        const workWeek = Number(moment(work,'DD.MM.YYYY').format('ww'));
        if (workWeek === Number(52)) {
          headerWeeks.push(Number(1)); 
        };
        if (workWeek === Number(1)) {
          headerWeeks.push(Number(1)); 
        }
        const work1 = moment('06.01.' + Number(filters.serviceYear), 'DD.MM.YYYY').format('DD.MM.YYYY');
        const work2 = moment(work1,'DD.MM.YYY').add(1,'y')
        let workWeek1 = Number(moment(work2,'DD.MM.YYYY').format('ww'));
        if (workWeek1 === Number(2)) {
            headerWeeks.push(workWeek1); 
        }      
      }
      else {
        headerWeeks.push(i);
      }      
    };
    setHeaderWeek(headerWeeks)
  },[]);

  useEffect(() => {
    const linex = filters.sheetYears.find((line: any) => {
      return  line[0] === 'maxlines'+filters.serviceYear;
    });
    let lineCount = 0;
    if (typeof(linex === 'undefined')) {
      lineCount = 800
    }
    else
      {
      lineCount = parseInt(linex[1]);   
      }
    lineCount = lineCount-1;
    if  (serviceArray.length === 0 || serviceArray.length < lineCount) {
      window.alert('Anna raportointivuosi, Palaa sitten Raportteihin:')
      
      setServiceMissing(true)
    }
    else
    {
      setServiceMissing(false);
      const speArr = speakers.map((spe:any)=> {return spe.speaker});
      moment.updateLocale('en', {
        week: {
          dow : 1, // Monday is the first day of the week.
        }
      });
      const speakerList: any = serviceArray
      .filter((filtered: any) => (['1','2','3','4','5','6','7','8','9','0','10','11'].indexOf(filtered.type) !== -1))
      .filter((filtered: any) => filtered.strike === 'n')
      .filter((filtered: any) => (speArr.indexOf(filtered.sspeaker) !== -1))
      .sort(( a: any,b: any ) => {
        var x = a.sspeaker
        var y = b.sspeaker
        if (x < y) {return -1}
        if (x > y) {return 1}
        else return 0
      })
      .reduce ((speakerLines: any, line: any) => {
        const speakerLinesx = speakerLines;
        speakerLinesx[line.sspeaker] = speakerLinesx[line.sspeaker] || [];
        const apvm = moment(line.StartDate, 'D.M.YYYY').unix();
        let ind: any = 0
        if (moment(line.StartDate,'D.M.YYYY').year() > moment(filters.serviceYear,'YYYY' ).year()) {
          const lineWeek = Number(moment(line.StartDate,'D.M.YYYY').format('ww'));
          const work = moment('31.12.' + filters.serviceYear, 'DD.MM.YYYY').format('DD.MM.YYYY');
          let newYear = Number(moment(work,'DD.MM.YYYY').format('ww'));
          if (newYear !== 1)  {
            if (lineWeek !== newYear ) {
              ind = newYear + lineWeek;
            } 
            else {
              const work = moment('31.12.' + filters.serviceYear,'DD.MM.YYYY').format('DD.MM.YYYY');
              const work1 = moment(work,'DD.MM.YYYY').subtract(7,'d');
              const week52or53 = Number(moment(work1,'DD.MM.YYYY').format('ww'));
              ind = week52or53 + lineWeek;
            }
          }
          else {
            const work = moment('31.12.' + filters.serviceYear,'DD.MM.YYYY').format('DD.MM.YYYY');
            const work1 = moment(work,'DD.MM.YYYY').subtract(7,'d');
            const week52or53 = Number(moment(work1,'DD.MM.YYYY').format('ww'));
            ind = week52or53 + lineWeek;
          }
          if (speakerLinesx[line.sspeaker][ind] ) {
            speakerLinesx[line.sspeaker][ind] = '12';           
          }
          else {          
            speakerLinesx[line.sspeaker][ind] = line.type;
          }
        }
        else{
          const ppvm = moment((`5.1.${filters.serviceYear}`),'D.M.YYYY').unix()
          // eslint-disable-next-line eqeqeq
          if (line.Vko == '52' && apvm < ppvm) {
            if (speakerLinesx[line.sspeaker][0] ) {
              speakerLinesx[line.sspeaker][0] = '12';
            }
            else {          
            speakerLinesx[line.sspeaker][0] = line.type
            }
          } else {  
            if (speakerLinesx[line.sspeaker][Number(moment(line.StartDate,'D.M.YYYY').format('ww'))] ) {
              speakerLinesx[line.sspeaker][Number(moment(line.StartDate,'D.M.YYYY').format('ww'))] = '12';           
            }
            else {          
              speakerLinesx[line.sspeaker][Number(moment(line.StartDate,'D.M.YYYY').format('ww'))] = line.type;
            }
          } 
        }   
        return speakerLinesx;
      }, {});
      const speakerArra: any = Object.keys(speakerList);
      setSpeakerArray(speakerArra);
      const toPrint: any = speakerArra.reduce((speakerLines: any, speaker: any) => {
        speakerLines[speaker] = speakerLines[speaker] || [];
        speakerLines[speaker] = [...speakerList[speaker]]
        return speakerLines;
      }, {});
      setToPrin(toPrint);    
    }
    let array: any = []
    speakers.map((spe: any) => {      
      let dayArray: any = []
      spe.datesArrayFinal.forEach((dat: any) => {
        const difference = moment(dat.ppvm,'D.M.YYYY').diff(moment(dat.apvm,'D.M.YYYY'), 'days');
        dayArray.push(moment(dat.apvm,'D.M.YYYY').format('D.M.YYYY'));
        let workDifference = difference;
        let workDay: any = moment(dat.apvm,'D.M.YYYY');
        if (difference > 0 ){
          while (workDifference > 0){
            workDay = moment(workDay,'D.M.YYYY').add(1,'d').format('D.M.YYYY');
            dayArray.push(workDay);
            workDifference= workDifference -1;
          }
        }
      })
      
      const returnable = ({
      speaker: spe.speaker,
      dayArray: dayArray,
      })
    
      array.push(returnable);
      return array;
    });
        
    array.sort(( a: any,b: any ) => {
      var x = a.speaker
      var y = b.speaker
      if (x < y) {return -1}
      if (x > y) {return 1}
      else return 0
    });
    setDayArrayState(array);
    const leaveArray = array.reduce((previous: any, current: any) => {
      const leaveArrayx = previous;
      leaveArrayx[current.speaker] = leaveArrayx[current.speaker] || [];
      current.dayArray.forEach((day: any) => {
        const apvm = moment(day, 'D.M.YYYY').unix();
        let ind: any = 0

        if (moment(day,'D.M.YYYY').year() > moment(filters.serviceYear,'YYYY' ).year()) {
          const lineWeek = Number(moment(day,'D.M.YYYY').format('ww'));
          const work = moment('31.12.' + filters.serviceYear, 'DD.MM.YYYY').format('DD.MM.YYYY');
          let newYear = Number(moment(work,'DD.MM.YYYY').format('ww'));
          if (newYear !== 1)  {
            if (lineWeek !== newYear ) {
              ind = newYear + lineWeek;
            } 
            else {
              const work = moment('31.12.' + filters.serviceYear,'DD.MM.YYYY').format('DD.MM.YYYY');
              const work1 = moment(work,'DD.MM.YYY').subtract(7,'d');
              const week52or53 = Number(moment(work1,'DD.MM.YYYY').format('ww'));
              ind = week52or53 + lineWeek;
            }
          }
          else {
            const work = moment('31.12.' + filters.serviceYear,'DD.MM.YYYY').format('DD.MM.YYYY');
            const work1 = moment(work,'DD.MM.YYY').subtract(7,'d');
            const week52or53 = Number(moment(work1,'DD.MM.YYYY').format('ww'));
            ind = week52or53 + lineWeek;
          }
        
            leaveArrayx[current.speaker][ind] = '20';

        }
        else{
          const ppvm = moment((`5.1.${filters.serviceYear}`),'D.M.YYYY').unix()
          // eslint-disable-next-line eqeqeq
          if ( Number(moment(day,'D.M.YYYY').format('ww')) === 52 && apvm < ppvm) {
            if (leaveArrayx[current.speaker][0] ) {
              leaveArrayx[current.speaker][0] = '20';
            }
            else {          
              leaveArrayx[current.speaker][0] = '20';
            }
          } else {  
                  
            leaveArrayx[current.speaker][Number(moment(day,'D.M.YYYY').format('ww'))] = '20';
          }
        }           
      })

      return leaveArrayx;
    },{});
    

    const speakerLeaveArra: any = Object.keys(leaveArray);
    const leavesToPrint: any = speakerLeaveArra.reduce((speakerLines: any, speaker: any) => {
      speakerLines[speaker] = speakerLines[speaker] || [];
      speakerLines[speaker] = [...leaveArray[speaker]]
      return speakerLines;
    }, {});

    setLeaveWeek(leavesToPrint)
  },[filters.somethchanged]);

  if (serviceMissing) {
    return <Navigate to="/info" replace={true} />
  }

  const findDay = (ii: any, speaker: any) => {
    if (ii > 52) ii = 52;
    const spe = dayArrayState.find((dd: any) => { 
      return speaker === dd.speaker
    });

  let workArray : any = [];
  spe.dayArray.map((sp: any) => {
    let startWeek: any = moment(sp,'D.M.YYYY').format('ww');
    const work = ({
      speaker: speaker,
      leaveDay: sp,
      week: startWeek
    })
    workArray.push(work);
  })
  const returnable = workArray.filter((wo: any) => {
  return wo.week == ii 
  })

  const xx =  returnable.map((re: any) => { 
    return moment(re.leaveDay, 'D.M.YYYY').format('dd')
  });

  if (xx.findIndex((ind: any ) => ind === 'Mo') !== -1  &&
  xx.findIndex((ind: any ) => ind === 'Tu') !== -1  &&
  xx.findIndex((ind: any ) => ind === 'We') !== -1  &&
  xx.findIndex((ind: any ) => ind === 'Th') !== -1  &&
  xx.findIndex((ind: any ) => ind === 'Fr') !== -1  &&
  xx.findIndex((ind: any ) => ind === 'Sa') !== -1  &&
  xx.findIndex((ind: any ) => ind === 'Su') !== -1 
  ){
    return 'mo-su' 
  }
    else {
      if ( xx.findIndex((ind: any ) => ind === 'We') !== -1  &&
      xx.findIndex((ind: any ) => ind === 'Th') !== -1  &&
      xx.findIndex((ind: any ) => ind === 'Fr') !== -1  &&
      xx.findIndex((ind: any ) => ind === 'Sa') !== -1  &&
      xx.findIndex((ind: any ) => ind === 'Su') !== -1 
      ) {
        return 'we-su'
        }  
        else {
        if (xx.findIndex((ind: any ) => ind === 'Mo') !== -1  &&
        xx.findIndex((ind: any ) => ind === 'Tu') !== -1  &&
        xx.findIndex((ind: any ) => ind === 'We') !== -1  &&
        xx.findIndex((ind: any ) => ind === 'Th') !== -1  &&
        xx.findIndex((ind: any ) => ind === 'Fr') !== -1  
          ) {
            return 'Mo-Fr'
          } 
        else {
        return xx.toString(); 
        }
      } 
    }
  }

  const lineNumber: any = (speaker: string) => {
    if (speaker !== '') {
      const line =  speakers.find((sp: any) => {
        return sp.speaker === speaker;      
      });  
      return line.linenumber; 
    }  
  }
  const LineClick = (speaker: any, index: any) => {
    if (filters.speakerSubDayCol1 !== 'loaded') {
      setState({ speaker: speaker , openSpeaker: false})
      if (clickMove.count === 0 ){
        setClickMove({count: clickMove.count+1, from: [speaker, index]})
      } else 
      if (clickMove.count === 1 ) {
        setClickMove({...clickMove, count:clickMove.count + 1, to: [speaker,index]})
        setState({...state, mouseMove: true})
      }
    }else {
      dispatch(editFilterData( { error: true, errorMessage: 'Seuraohjelma ladattu, käytä seuraohjelmaa puhujamuutoksiin' }));
    }
  }
  
  const closes = () => {
    setState({ openLeave: false, speaker: '', mouseMove: false });
    setClickMove({count: 0, from: [], to: []})
  }
  return (
    
    <Box sx= {{ pt:8}}>
       <SidePanel
        open={ state.mouseMove === true }
        close={ () => closes()}
        >
        <Box sx={{}}>
          {state.mouseMove && 
             <MoveTurns speaker ={ speakers.find((row: any) => row.linenumber === lineNumber(state.speaker) )} 
             currentYear = {moment(filters.serviceYear,'YYYY').format('YYYY') }  
             moves={clickMove}
             close ={ () => closes()} />
      
          //   setClickMove({...clickMove, count: 0, to: [], from:[]})
          // //   <switchSpeakers  close={() => setState({ speaker: state.speaker, mouseMove: false })} speaker={state.speaker}  />
          }
        </Box>
      </SidePanel>
      <SidePanel
        open={ state.openSpeaker === true }
        close={ () => setState({ speaker: '', openSpeaker: false })}
        >
        <Box sx={{}}>
          {state.openSpeaker && 
            <ListSpeakerDays close={() => setState({ speaker: state.speaker, openSpeaker: false })} speaker={state.speaker}  />
          }
        </Box>
      </SidePanel>
      <SidePanel
        open={ state.openLeave === true }
        close={ () => setState({ openLeave: false, speaker : '' })}
      >
        {state.openLeave &&
          <ListSpeakersPage speaker ={ speakers.find((row: any) => row.linenumber === lineNumber(state.speaker) )} currentYear = {moment(filters.serviceYear,'YYYY').format('YYYY') }  close ={ () => setState({ openLeave: false, speaker: '' })} />
        }
      </SidePanel>
      <Paper sx={{ width: '100%' }}> 
        <TableContainer style={{ border: "1px solid black" }} sx={{ maxHeight: 800 , width:'100%'}}>
          <Table stickyHeader aria-label="sticky table"
          className={classes.table}
          size= 'small'
          //  aria-label="simple table"
          //style={{ tableLayout: "fixed" }}        
          >
            <TableHead>
              <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main', pb:2}}>          
              <TableCell className={classes.sticky}></TableCell>
                {headerWeek.map((w: any, i:any) => {
                  return (
                    <TableCell  key={`${i}_cell1`}>{w}</TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
        
              {speakerArray.map((speaker: any, i: any) => {                
                const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
                return (          
                  <TableBody key = {`${i}${speaker}_spee`}>
                  <TableRow sx={{pb : 2, backgroundColor: backgroundColor, borderBottom: 'thin solid'}} key={`${i}${speaker} _row1150`}>                   
                    <TableCell 
                      className={classes.sticky}
                      component="th"
                      scope="row"
                      sx={{ backgroundColor: backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15 , borderBottomRightRadius: 15, 
                      borderTopRightRadius: 15 }} key={`${i}${speaker}_turns`}
                    >
                      <Button variant = 'text' size='small' sx={{p: 0, textTransform:'capitalize'}} onClick={() => setState({ speaker: speaker , openSpeaker: true})} >
                        {speaker }
                      </Button>
                    </TableCell>
             
                    {toPrin[speaker].map((spelist: any, ii: any) => {           
                      const backgroundColor = colorCodes[spelist];          
                      return (
                        <TableCell sx={{ backgroundColor: backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15 , borderBottomRightRadius: 15, borderTopRightRadius: 15 ,
                          fontWeight: 'light' }} key={`${ii}${speaker}_rowx11`}>  
                          {!spelist && clickMove.count === 1 && clickMove.from[0] === speaker && clickMove.from[1] === ii &&
                            <Button variant = 'contained' size='small' color="error"  sx={{ p: 0, color: 'black', width:'5px', textTransform:'lowercase', flexFlow: 'wrap row'}} onClick={() => LineClick(speaker, ii)}>                                           
                            {'**'}
                            </Button>
                          }
                          {!spelist && !(clickMove.count === 1 && clickMove.from[0] === speaker && clickMove.from[1] === ii) &&
                            <Button variant = 'text' size='small'  sx={{ p: 0, color: 'black', width:'5px', textTransform:'lowercase', flexFlow: 'wrap row'}} onClick={() => LineClick(speaker, ii)}>                                           
                              {'**'}
                            </Button>                    
                          }                        
                          {(clickMove.count === 1 && clickMove.from[0] === speaker && clickMove.from[1] === ii) ? 
                            <Button variant = 'contained' size='small' color="error" sx={{ p: 0, color: 'black', width:'5px', textTransform:'lowercase', flexFlow: 'wrap row'}} onClick={() => LineClick(speaker, ii)}>                                           
                                {nameArray[spelist]}
                            </Button>
                            :
                            <Button variant = 'text' size='small' sx={{ p: 0, color: 'black', width:'5px', textTransform:'lowercase', flexFlow: 'wrap row'}} onClick={() => LineClick(speaker, ii)}>   
                                          
                              {nameArray[spelist]}
                            </Button>
                          }
                        </TableCell>
                      )
                    })}
                  </TableRow>  
                  {props.leaves &&
                    <TableRow sx={{pb : 2, backgroundColor: backgroundColor, borderBottom: 'thin solid'}} key={`${i}${speaker}_roww1509`}>                   
                      <TableCell 
                        className={classes.sticky}
                        component="th"
                        scope="row"
                        sx={{ backgroundColor: backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15 , borderBottomRightRadius: 15, 
                        borderTopRightRadius: 15 }} key={`${i}_rowwx13`}
                      >
                        <Button variant = 'text' size='small' sx={{p: 0, textTransform:'capitalize'}} onClick={() => setState({ speaker: speaker , openLeave: true})} >
                        
                        Vapaat
                        </Button>
                      </TableCell>
                    
              
                      {leaveWeek[speaker].map((spelist: any, ii: any) => {
                        const backgroundColor = colorCodes[spelist];
            
                        return (
                        
                          <TableCell 
                            component="th"
                            scope="row"
                            sx={{ backgroundColor: backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15 , borderBottomRightRadius: 15, borderTopRightRadius: 15 ,
                              fontWeight: 'light', }} key={`${ii}${speaker}_rowwx12`}
                            >    
    
                            <Button variant = 'text' size='small' sx={{ p: 0, color: 'black', width:'5px', textTransform:'lowercase', flexFlow: 'wrap row'}} onClick={() => setState({ speaker: speaker , openLeave: true})}>   
                              {spelist == 20 ? findDay(ii, speaker)  : ''}  
                              </Button> 
                          </TableCell>
                        )
                      })}
                    </TableRow> 
                  }
                </TableBody>
              )                
            })
            }         
          </Table>
        </TableContainer>        
      </Paper> 
    </Box>
  );
}
export default CreateGraphicsOfTimes;


