/* eslint-disable no-unused-vars */
// eslint-disable-next-line linebreak-style
/* eslint-disable semi */
/* eslint-disable linebreak-style */

import React from 'react'
import { connect } from 'react-redux'
import {  TextField, Box } from '@mui/material';
import { setTextFilter } from '../../actions/filters';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
// const useStyles: any = makeStyles((theme: any) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
// }));
// const inputStyle = {
//   marginRight: '8px',
//   marginTop: '1px',
// }


class SpeakerListFilters extends React.Component <any> {
  state: any = {
    calendarFocused:null,
    selspeaker : '',
    speaker:this.props.filters.text,
    saveSpeaker: this.props.filters.text,
    checked: false,
    checkedQuest: false
  }

  onChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(() => ({ [name]: value }));
  }
  render () {
    return (
      <Box>
        <Box>
          { (this.props.filters.admin) ?
            <TextField
              sx={{ mt: 1 , width: 150 }}
              label="Puhuja"
              autoFocus
              name="speaker"
              value={this.props.speaker}
              onChange={(e) => {
                this.props.dispatch(setTextFilter(e.target.value))
              }}
            />
            :
            <TextField
              sx={{ mt: 1 , width: 150 }}
              label="Puhuja"
              autoFocus
              name="speaker"
              value={this.props.filters.text}
              // onChange={(e) => {
              //   this.props.dispatch(setTextFilter(e.target.value))
              // }}
            />
          }
          <TextField
            sx={{ mt: 1 , width: 150 }}
            label="Vuosi"
            autoFocus
            name="serviceYear"
            value={this.props.filters.serviceYearSpeakers}
            // onChange={(e) => {
            //   this.props.dispatch(setTextFilter(e.target.value))
            //}}
          />
        </Box>
      </Box>
    )
  }
}
const mapStateToProps = (state: any) => {
  return {
    filters: state.filters,
    speakers: state.speakers,
    auth:state.auth
  }
}
export default connect(mapStateToProps) (SpeakerListFilters)