/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react'
import 'core-js/stable';
import {   useDispatch, useSelector  } from 'react-redux';
import programService from '../../services/programService';
import {  editFilterData } from '../../actions/filters';
import {RootState} from '../../store/ConfigureStore';
import { Box, Button, FormControl, Select, InputLabel, MenuItem, TextField, CircularProgress } from '@mui/material';
import moment from 'moment';
import database from '../../firebase/firebase';
import { ReadServiceProgram } from '../genUtilities/ReadServiceProgram';
import { Capitalize } from '../genUtilities/Capitalize';


type AddSingleLineType = {
  filters: any,
  close: () => void,
  listing: () => void,
  listCale: () => void,
};


const AddSingleLine = (props: AddSingleLineType) => {
  // const [ speakers, setSpeakers ] = useState([]);
  const close = props.close;
  const listing = props.listing;
  const listCale = props.listCale;
  const [ loading, toggleLoading ] = useState(false);
  //const service: any = props.service;
  const auth: any = useSelector((state: RootState) => state.auth);
  const token = `bearer ${auth.idToken}`;
  const filters: any = props.filters;
  const [toimikunnat, setToimikunnat] = useState([]);
  const [serviceSheet, setServiceSheet] = useState('')
  const [returnPressed, setReturnPressed] = useState(false);
  const [paluuPressed, setPaluuPresswed] = useState(false);
  const dispatch = useDispatch();
  
  useEffect (() => {
    if (returnPressed ) { 
      listing();
      listCale();
      close();      
    }  else {
      if (paluuPressed) {
        close();
        listCale();
      }   
    }
  }, [filters.somethChanged])

  useEffect(() => {
    const findToimikunta = async (id: any) => {
      const toimikunta = filters.sheetYears.find((tmk: any) => tmk[0] === `TMK-nimet${filters.serviceYear}` );
      const service = filters.sheetYears.find((tmk: any) => tmk[0] == filters.serviceYear );
      if (!service) {
        dispatch(editFilterData({error: true, errorMesssage: 'VAstuutoimikunta puuttuu, lisää se sanomalle'}))
      }
      setServiceSheet(service);
      const initialRows = await programService
      .getAll(token, ' ', toimikunta[1],toimikunta[2]);
      let toimikunnatArray: any = '';
      setToimikunnat(initialRows);   
      toggleLoading(false); 
      return toimikunnatArray;
    }
    toggleLoading(true);
    findToimikunta(filters.serviceYear);

  }, [props.filters.serviceYear]);

  if (loading) return <div> ...loading <CircularProgress/> </div>

  const readFirebase = async () => {
    let serviceList: any [] = []
    await database.ref(`users/${auth.uid}/serviceYear/${filters.serviceYear}/serviceProgram`)
      .once('value')
      .then((snapshot: any) => {
        snapshot.forEach((childSnapshot: any) => {
          serviceList.push({
            serviceId:childSnapshot.key,
            ...childSnapshot.val()
          })
        })
      })
      .catch( (error: any) => {
        console.error('Error fetching serviceProgram in CheckCals', error);
      })
   
    return serviceList;
  }
  
  const toimikuntaChange = (e: any) => {
    dispatch(editFilterData({newLineToimikunta: e}))
  }

  const createServiceLine = async () => {

    const createSingleLine = async() =>  {
      // let processing = true;
      if ( filters.newLineSubject !== '')  {
        dispatch(editFilterData({ haveToRead: true }));
        const range = `${serviceSheet[5]}!${'A'}${lineNumber+1}:${'AB'}${lineNumber+1}`
        const reply = await programService
        .insertNewLine(token, serviceSheet[1], serviceSheet[5], lineNumber, lineNumber+1, param, range);
       
        reply.split(' ').map((text:any) => {
          return text === 'Error' && dispatch(editFilterData({error: true, errorMessage: `Seuraohjelman kirjoituksessa  joku virhe @{linenumber}` }))
        });
       
      }  
      setReturnPressed(true);
      setPaluuPresswed(false);
      await ReadServiceProgram(dispatch, filters, token, auth, filters.serviceYear);
      // processing = false;
      // if (processing) return <div>  Waiting for lineInsert in AddSingleLine CircularProgress</div>
    }

    if (!filters.newLineToimikunta || filters.newLineToimikunta === ' ' || filters.newLineToimikunta === 'Toimikunta') {
      dispatch(editFilterData({ error: true, errorMessage: `Anna vatuutoimikunta` }));
    }
  
    const param = [[ filters.newLineType,
      filters.newLineExcluded, 
      filters.newLineVko, 
      Capitalize(filters.newLineVkonpäivä),
      filters.newLineStartDate,
      filters.newLineStartTime,
      filters.newLineEndDate,
      filters.newLineEndTime,
      filters.newLinePm, 
      filters.newLineSubject,
      filters.newLineSelite,
      filters.newLineLocation,
      filters.newLineToimikunta,
      ' ',
      filters.newLinePuhuja,
      filters.newLineSeuraisäntä,
      filters.newLineSäestäjä,
      filters.newLineEsilaulaja,
      filters.newLineValvoja,
      filters.newLineTulkki,
      ' ',
      ' ',
      filters.newLineÄänentoisto,    
      ' ',
      ' ',
      ' ',
      ' ',
      auth.email
      ]];
      const serviceLines: any = await readFirebase();
      const lineNumber = serviceLines.findIndex((se: any) => {
        return moment(se.StartDate,'D.M.YYYY').unix() ===  moment(filters.newLineStartDate,'D.M.YYYY').unix();
      });

      if (lineNumber === -1){
        dispatch(editFilterData({ error: true, errorMessage: `Päivämäärä puuttuu seuraohjelmasta korjaa Drivestä lisäämällä rivi jokaiselle päivälle ${filters.newLineStartDate}` }));
      } 
      else{
        await createSingleLine();   
        dispatch(editFilterData({somethChanged:  Math.random()}));   
        setReturnPressed(true);
        setPaluuPresswed(false);     
      }  
    
    }

  const clo = () => {  
    dispatch(editFilterData({somethChanged:  Math.random()}));   
    setReturnPressed(false);
    setPaluuPresswed(true);
  }

if (toimikunnat.length === 0) {
  return <div> ...loading <CircularProgress/> </div>
}

  return (
    <Box
    sx = {{}}>      
      <TextField
        sx={{ p:1 }}
        label={'Hry:n tila'}      
        rows={1}
        variant="outlined"
        fullWidth
        value={ filters.newLineLocation }        
      />
      <TextField
        sx={{ p:1 }}
        label={'Viikko'}      
        rows={1}
        variant="outlined"
        fullWidth
        value={ moment(filters.newLineStartDate, "D.M.YYYY").isoWeek()}        
      />
        <TextField
        sx={{ p:1 }}
        label={'Viikonpäivä'}      
        rows={1}
        variant="outlined"
        fullWidth
        value={  Capitalize(moment(filters.newLineStartDate, 'D.M.YYYY').format('dd'))}        
      />
       <TextField
        sx={{ p:1 }}
        label={'Apvm'}      
        rows={1}
        variant="outlined"
        fullWidth
        value={ filters.newLineStartDate }        
      />
       <TextField
        sx={{ p:1 }}
        label={'Klo'}      
        rows={1}
        variant="outlined"
        fullWidth
        value={ filters.newLineStartTime }        
      />
       <TextField
        sx={{ p:1 }}
        label={'Ppvm'}      
        rows={1}
        variant="outlined"
        fullWidth
        value={ filters.newLineEndDate }        
      />
       <TextField
        sx={{ p:1 }}
        label={'Klo'}      
        rows={1}
        variant="outlined"
        fullWidth
        value={ filters.newLineEndTime }        
      />
       <TextField
        sx={{ p:1 }}
        label={'Tilaisuus'}      
        rows={1}
        variant="outlined"
        fullWidth
        value={ filters.newLineSubject }        
      />
      
      <FormControl sx={{ p:1}}>
        <InputLabel> Toimikunta</InputLabel>
        <Select
          sx={{ minWidth: '100px'}}
          labelId="selectToimikunta"
          value = { filters.newLineToimikunta }
          name="toimikunta"
          onChange={(e: any) => {
            toimikuntaChange( e.target.value)
          }}
          >      
          {toimikunnat.length > 0 &&
          toimikunnat.map( (tmk: any, i: number) =>
            <MenuItem
              key={i}
              value={tmk[0]}
            >
              { tmk[0] }
            </MenuItem>
          )}          
        </Select>
      </FormControl>
      <Box sx = {{p: 1}}>
          <Button color="primary" variant="contained" sx={{ ml: 1 }}
      onClick= {createServiceLine}>
            {('Submit')}
          </Button>   
      <Button color="primary" variant="contained" sx={{p:1 }}
        onClick= {clo}>
              {('Paluu')}
            </Button> 
        </Box>
    

    </Box>


  )
}

export default  AddSingleLine;
