import React, { useState } from 'react';
import {  Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { ArticleRounded, AssessmentRounded, ConstructionRounded, FolderRounded, DirectionsCarRounded, HomeRounded, PersonRounded, QuestionMarkRounded,
  ReceiptRounded, ScheduleSendRounded, SchemaRounded, EventRepeatRounded, EventAvailableRounded, AddBusinessRounded, MailRounded
} from '@mui/icons-material';

import MenuIcon from '@mui/icons-material/Menu';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// import { useAppSelector } from '../../hooks';
// import { ServiceBookType } from '../../types/serviceBook';

import { Box } from '@mui/system';
import Logo from '../../images/hrx.jpg';
import { colors } from '../../styles/themeStylesSx';
import CheckAuthorities from '../authority/CheckAuthorities';
import { useSelector  } from 'react-redux';
import {RootState} from '../../store/ConfigureStore';

export type NavigationItemType = {
  label: string,
  icon?: string,
  path: string,
  subLinks?: NavigationItemType[]
}

const items: NavigationItemType[] = [
  // {
  //   label: 'Aloitus',
  //   path: '/info',
  //   icon: 'flag'
  // },

  {
    label: 'Seuraohjelma',
    path: '/seuraohjelma',
    icon: 'schema',
  },
  {
    label: 'Kalenteri',
    icon: 'calendar',
    path: '/calendar',
  },
  {
    label: 'Puhujien vapaat',
    path: '/puhujat',
    icon: 'schelude',
  },
  {
    label: 'Puhujien lataus ohjelmaan',
    icon: 'servcre',
    path: '/servcrea',
    },
    {
      label: 'Kansiot ja ryhmät',
      path: '/drive/drive',
      icon: 'drive',
    },
    {
      label: 'Käyttäjät',
      path: '/user',
      icon: 'user',
    },
    {
      label: 'Gmail-viestit',
      path: '/gmail/gmail',
      icon: 'dashboard',
    },
  {
    label: 'Raportit',
    icon: 'reports',
    path: '/reports',
  },
  {
    label: 'Hallinta',
    icon: 'admin',
    path: '/admin',
  },

];

const getIcon = (icon?: string) => {
  switch (icon) {
  case 'home':
  case 'drive':
    return <FolderRounded />;
  case 'user':
    return <PersonRounded />;
  case 'schelude':
    return <ScheduleSendRounded />;
  case 'receipts':
    return <ReceiptRounded />;
  case 'agreements':
    return <ArticleRounded />;
  case 'reports':
    return <AssessmentRounded />;
  case 'dashboard':
    return <MailRounded />;
  case 'gmail':
    return <DirectionsCarRounded />;
  case 'calupd':
    return <EventAvailableRounded />;
  case 'calendar':
    return <EventAvailableRounded />;
  case 'other':
    return <PersonRounded />;
  case 'schema':
    return <SchemaRounded/>;
  case 'calcheck':
    return <EventRepeatRounded />;
  case 'servcre':
      return <AddBusinessRounded />;
    case 'admin':
      return <ConstructionRounded />;
  default:
    return <QuestionMarkRounded />;
  }
};

export const drawerWidth = 300;

export default function SideNavigation() {
  const filters: any = useSelector((state: RootState) => state.filters);
  const auth: any = useSelector((state: RootState) => state.auth);
  //const { t } = useLocales();

  CheckAuthorities();
  // const { details: customer } = useAppSelector(store => store.customer);
  // const { profile } = useAppSelector(store => store.profile);
  const [showSidebar, toggleShowSidebar] = useState(false);
  const location = useLocation();
  const renderList = () => {
    return (
      <>
        <Box sx={{ display: 'flex', alignItems: 'center', p: 0 }}>
          <Box sx={{ width: '240px' }}>
            <img width="100%" height="100%" src={Logo} alt="KodinKanta" />
          </Box>
          <IconButton
            size="medium"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ ml: 'auto', display: { xs: 'block', md: 'none' } }}
            onClick={ () => toggleShowSidebar(false) }
          >
            X
          </IconButton>
        </Box>
        <List>
          {
          items.map((item: NavigationItemType) => {
            const pathSplit = location.pathname.split('/').filter(r => r !== '');
            const itemSplit = item.path.split('/').filter(r => r !== '');
            let isTopSelected = (item.path === '/' && pathSplit.length === 0) ? location.pathname === '/' : pathSplit[0] === itemSplit[0];
            // if (item.label === 'Service books' && pathSplit.length > 1) {
            //   isTopSelected = false;
            // }
            const topBackgroundColor = isTopSelected ? colors.highlightItem : '#fff';
            // const expandBooks = false;
            return (
              <React.Fragment key={item.label}>
                { !filters.loading && auth.uid &&
              
                  <ListItemButton
                    sx={{
                      m: 0,
                      pt: 2,
                      pb: 2,
                      backgroundColor: topBackgroundColor,
                      color: isTopSelected ? '#fff' : 'secondary.main',
                      '&:hover': { backgroundColor: colors.navigationSelectedList, color: 'secondary.main' },
                    }}
                    component={RouterLink}
                    to={item.path}
                    onClick={ () => toggleShowSidebar(false)}
                  >
                  <ListItemIcon
                    sx={{
                      fontSize: 20,
                    }}
                  >{ getIcon(item.icon) }</ListItemIcon>
                  <ListItemText
                    sx={{ my: 0 }}
                    primary={item.label}
                    primaryTypographyProps={{
                      fontSize: 20,
                      fontWeight: 'bold',
                      letterSpacing: 0,
                    }}
                  />
                  </ListItemButton>
                }
              </React.Fragment>
            );
          })
          }
        </List>
      </>
    );
  };

  return (
    <>
   
      <Box
        sx={{
          display: { xs: 'block', lg: 'none' },
        }}>
          <Box
            sx={{ zIndex: 1200, ml: 2, position: 'fixed', top: '5px', left: '10px' }}
            onClick={ () => toggleShowSidebar(true) }
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={ () => toggleShowSidebar(true) }
            >
              {auth.uid  && 
              <MenuIcon />
}
            </IconButton>
          </Box>
        <Drawer
          sx={{
            zIndex: 1300,
            '& .MuiDrawer-paper': {
              width: {
                sm: drawerWidth,
                xs: drawerWidth - 20,
              },
              boxSizing: 'border-box',
              // backgroundColor: '#96CBA0',
            },
          }}
          variant="temporary"
          anchor="left"
          open={showSidebar}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          onClose={() => toggleShowSidebar(false)}
        >
          {renderList()}
        </Drawer>
      </Box>
      <Box sx={{
          display: { xs: 'none', lg: 'block' },
          width: drawerWidth
        }}
      >
        <Drawer
          variant="permanent"
          anchor="left"
          sx={{
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              // backgroundColor: '#96CBA0',
            },
            width: drawerWidth,
          }}
        >
          {renderList()}
        </Drawer>
      </Box>

    </>
  );
}