/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import SubmitPermissionUpdateOfDrive from './SubmitPermissionUpdateOfDrive';
import {haveToReadDrive, editFilterData } from '../../actions/filters';
import { FormControl, Select, InputLabel, Box, MenuItem, Typography, CircularProgress } from '@mui/material';
import {RootState} from '../../store/ConfigureStore';
import { subHeaderStyles } from '../../styles/themeStylesSx';
import { lineStyles } from '../../styles/themeStylesSx';
import groupServices from '../../services/groupServices';
import QueryGroupsSimple from '../users/QueryGroupsSimple';

type UpdatePermPropsType = {
  driveId?: any;
  permission?: any;
  openDelete?: boolean;
  close:() => void;
  permissions: any[];
}

type StateType = {
  driveId?: any;
  permission?: any;
  openGroups?: boolean;
  workDrive?: any;
}
const UpdatePermissions =  (props: UpdatePermPropsType) => {
  const close = props.close;
  const drives = useSelector((state: RootState) => state.drives);
  const users = useSelector((state: RootState) => state.users);
  const authorit: any = useSelector((state: RootState) => state.auth);
  const filters = useSelector((state: RootState) => state.filters);
  const dispatch = useDispatch();
  const authh: any = useSelector((state: RootState) => state.auth);
  const [ loadingPerm, toggleLoadingPerm ] = useState(false);
  const [role,setRole] = useState(' ');
  const [displayName,setDisplayName] = useState(' ');
  const token = `bearer ${authh.idToken}`;
  const [loading, toggleLoading] = useState(false);
  const [state, setState] = useState<StateType>({permission: props.permission, driveId: props.driveId, openGroups: false, 
    workDrive: drives.find((dri: any)=> dri.driveId === props.driveId)});
  const [foundGroupsState, setFoundGroupsState] = useState<any>([]);
  let groupWork: any = [];
  let workPermission: any = '';

  if (props.permission ) {
    workPermission = props.permission
  }
  else workPermission = state.permission

  useEffect(() => {
    
    const groupQuery = async (permission: any, email: any) => {
      const authUserId = users.find((usr: any) => {
        let permissionTxt = email;
        permissionTxt = permissionTxt.replace('@hry.fi','@helsinginrauhanyhdistys.fi')
        return permissionTxt === usr.primaryEmail
      });

      try {
        let permissionTxt = permission.emailAddress;
        permissionTxt= permissionTxt.replace('@hry.fi','@helsinginrauhanyhdistys.fi')
        const isInGroup = await groupServices
        .membersHasMember(token, permissionTxt, authUserId.id);
        return isInGroup;

      }catch( error) {
        console.error('Error fetching drivelist', error);
        dispatch (editFilterData({ error: true, errorMessage : 'Error in updatePermissions proc' + error}));
        return error;
      }
    }

    const groupsQuery = async (permissions: any, email: any) => {
      const foundGroups = await permissions.reduce(async(current:any , data: any) => {
        // await current;
        if (data.permissionType === 'group'){
          const returnedValue = await groupQuery(data, email);
          if (returnedValue) {
            groupWork.push(data);
            setFoundGroupsState(groupWork);
          }
        }
      },{});

      toggleLoadingPerm(false)
      return foundGroups;
      
    };

    if (!workPermission) {
      close();
    }
    else
    if (drives.length === 0 ) {

      close();
    }
    if (filters.driveLineEdited) close();

    if (workPermission) {
      setRole(workPermission.permissionRole)
      setDisplayName(workPermission.displayName);
      setState({...state, permission: workPermission, driveId: props.driveId});
      if (workPermission.permissionType === 'user') {
        toggleLoadingPerm(true);
        groupsQuery(props.permissions, workPermission.emailAddress);
      }
    }
  }, [state.driveId, filters.driveLineEdited, workPermission, props.permission ])


   if (loadingPerm) return <CircularProgress/>
  if (!filters.drive) return (<div> <br />. Ei vaadittuja oikeuksia</div>);
  if (filters.driveLineEdited) close();



  const updatePermission = async (role: any) => {

    const updatePerm = async (permission: any) => {
      await  SubmitPermissionUpdateOfDrive (
        token,
        state.driveId,
        permission,
        authorit.uid,
        role,
        dispatch);
        toggleLoadingPerm(false);
      }

      toggleLoadingPerm(true);
      await updatePerm(state.permission);

      if (loadingPerm) return (<div><CircularProgress/>..permission loading</div>)
      dispatch(haveToReadDrive(true))
      close()
    }

  return (
    <Box>
      {workPermission &&
        <Box>
          <Typography sx={ subHeaderStyles }>{` ${displayName} `}</Typography>
          <Typography sx={ lineStyles }>{`Rooli ${role}`}</Typography>
          <Box>
            <FormControl>
              <InputLabel id="selectSort">Muuta Rooli  </InputLabel>
              <Select
                style={{ minWidth: '250px'}}
                labelId="selectRole"
                value = {role}
                onChange={(e: any) => {
                  // eslint-disable-next-line no-lone-blocks
                  {setRole(e.target.value)}
                  updatePermission(e.target.value)
                }}
              >
                <MenuItem value="organizer">   Ylläpitäjä    </MenuItem>
                <MenuItem value="fileOrganizer">   Sisällönhallinta </MenuItem>
                <MenuItem value="writer"> Tekijä</MenuItem>
                <MenuItem value="reader">   Katsoja   </MenuItem>
                <MenuItem value=" ">   empty   </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      } 
      <Typography sx={ lineStyles }>{` ${displayName} on seuraavissa driven ${state.workDrive.driveName} ryhmissä: `}</Typography>
      {foundGroupsState.map((fo:any) => {
        return fo.emailAddress
      })}
       {/* <QueryGroupsSimple primaryEmail = {workPermission.emailAddress } close ={ () => setState({ openGroups: false })} /> */}
    </Box>
  )
}

export default (UpdatePermissions)
