/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
import React, { useState, useEffect }  from 'react'
import programServices from '../../services/programService'
import {startAddSheet} from '../../actions/filters'
import {startRemoveSheet} from '../../actions/filters'
// import database from '../firebase/firebase';

const submitCreateSheets = async(token,uid, dispatch) => {
  dispatch(startRemoveSheet())
  const sheetResponse = await programServices
    .createdrivesheet(token,'ohjesivu')
    .catch( error => {
      console.error('Error fetching permissions', error)
    })
  dispatch(startAddSheet({
    spreadsheetId : sheetResponse.spreadsheetId,
    propertiesSpreadSheet : sheetResponse.properties.title,
    gsheetSheetId : sheetResponse.sheets[0].properties.sheetId

  }))
  return sheetResponse
}
export default submitCreateSheets