import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

export default function MouseOverPopover(props: any) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>();
  //setAnchorEl( props.anchorEl);
  const open = Boolean(props.anchor);
const popOverText = props.popOverText
const handlePopoverClose = () => {
  setAnchorEl(null);
};

  return (
   
  
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={props.anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{popOverText}</Typography>
      </Popover>
    
  );
}