import React,{useEffect, useState }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { haveToRead } from '../../actions/filters'
import { loadOnce} from './../../actions/services';

import { Box, Button, CircularProgress, Snackbar, Table, TableCell, TableBody, TableRow, TableHead, Typography } from '@mui/material';
import {RootState} from '../../store/ConfigureStore';
import {subHeaderStyles } from '../../styles/themeStylesSx';
import {editFilterData } from '../../actions/filters';
import servicesForGmail from '../../selectors/servicesForGmail';
import database from './../../firebase/firebase';
import { useNavigate } from "react-router-dom";
import GmailFilter1 from '../gmail/GmailFilter1';
import submitGetSheetId from './../service/submitGetSheetId';

import { colors } from '../../styles/themeStylesSx';
import programService from '../../services/programService';
import gmailService from '../../services/gmailServices';


export const GmailStart = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch()
  const authorit: any = useSelector((state: RootState) => state.auth);
  const services = useSelector((state: RootState) => state.services);
  const filters = useSelector((state: RootState) => state.filters);
  const  token: any = `bearer ${authorit.idToken}`;
  const [send, setSend] = useState(false);
  const [loading, toggleLoading] = useState(false);
  const [collect, setCollect] = useState({ collectMails: false, haveToFindRecievers: false, collected: false, sent: false} )

  //let componentMounted = true;
  const [lines, setLines] = useState([]);
  useEffect(() => {
    setCollect({ collectMails: false, haveToFindRecievers: !collect.haveToFindRecievers, collected: false, sent: false} );
  },[ filters.gmailEndDate, filters.gmailStartDate, JSON.stringify(filters.taskArray.selections)  ]);

  useEffect( () => {
     const createAllLines = async (services: any) => {
      const linesToReturn =  await doRenderLoop(services);
      return linesToReturn;
    }
    const doRenderLoop = async(services: any) => {
      const mails: any = [];
      const linesToRender = filters.taskArray.selections.reduce(async (results: any, data: any) => {
        await results;
        if (data !== '' ) {
          const index = filters.taskArray.tasks.findIndex((task: any) => {
            return task === data
          })
          const workSheetIdParm = filters.taskArray.stateKeys[index];
          const workColumnNames = filters.taskArray.columnNames[index];
          const servicesForGmailBoss = await getServiceLines(filters.serviceYear, workSheetIdParm);
          const mailsToSend = await findRecievers(servicesForGmailBoss, workColumnNames, services);
          mails.push(...mailsToSend);
        };
        return mails;
      }, Promise.resolve([]));
      return linesToRender
    }
    const getServiceLines = async (year: any, sheetIdParam: any) => {
      let initialServiceHosts: any = [];
      const search = sheetIdParam + year;
      const doDispatch = true;
      const speakerData = await submitGetSheetId(token,search, dispatch, doDispatch, filters );
      const sheetId = speakerData?.sheetId;
      const range = speakerData?.range;
      const initialServiceLines = await programService
        .getAll(token,year,sheetId,range)
        if (Array.isArray(initialServiceHosts)) {
          initialServiceHosts = initialServiceLines
        }
        else {
          initialServiceHosts = [];

          dispatch(editFilterData({error: true, errorMessage: initialServiceLines}))

        }
      return initialServiceHosts;
    }
    const findRecievers = async(servicesForSmbd: any, header: string, services: any) => {
      const serviceHostsToMail = await servicesForSmbd.reduce((results: any, data: any) => {
        const result =  results;
        const serviceDays = services.filter((serv: any) => {
          return data[0] === serv[header];
        });
        let subjAndDays = [];
        if (serviceDays.length > 0) {
          subjAndDays = serviceDays.reduce((sresults: any, sdata: any)  => {
            const sresult = sresults;
            sresult[sdata[header]] = sresult[sdata[header]] || [];
            sresult[sdata[header]].push({
              subject : sdata.subject,
              day : sdata.StartDate,
              time: sdata.StartTime
            })
            return sresult;
          },{});
        }
        result[data[0]] = result[data[0]] || []
        result[data[0]].push({header: header, mail: data[2], name: data[0], phone: data.length > 1 && data[1], subjAndDays : subjAndDays[data[0]]
        })
        return result;
      }, {})
      const objKeys = Object.keys(serviceHostsToMail)
      let mailData: any = [];
      let work: any[] = [];
      objKeys.forEach((obj: any)=>   {
        if (serviceHostsToMail[obj][0].subjAndDays !== undefined) {
          if (serviceHostsToMail[obj][0].subjAndDays.length > 0) {
            mailData = ({
              name: serviceHostsToMail[obj][0].name,
              mail: serviceHostsToMail[obj][0].mail,
              header: serviceHostsToMail[obj][0].header,
              content: serviceHostsToMail[obj][0].subjAndDays,
            })
          }
          work.push(mailData);
        }
      });
      return work;
    }
    const doDispatch = async(services: any) => {
      dispatch(loadOnce(services));
    }
    const readFirebase =async () => {
      let work: any = [];
      const serviceList: any = [];
      await database.ref(`users/${authorit.uid}/serviceYear/${filters.serviceYear}/serviceProgram`)
      .once('value')
      .then((snapshot: any) => {
        snapshot.forEach((childSnapshot: any) => {
          serviceList.push({
            serviceId:childSnapshot.key,
            ...childSnapshot.val()
          })
        });
      })
      const services = await servicesForGmail(serviceList, filters);
      await doDispatch(services);
      const lines = await createAllLines(services);
      setLines(lines);
      dispatch(haveToRead(true));
      toggleLoading(false);
    }
  if (services.length === 0) {
    navigate("/seuraohjelma")
  }
  else
    {
    if (collect.collectMails) {
      toggleLoading(true);
      readFirebase();
      }
    }
  }, [ collect.haveToFindRecievers ]);

  if (loading) return <CircularProgress/>
  if (!filters.admin) return <div> Ei tarvittavia oikeuksia</div>

  const callSendMessage = async ( host: any) => {
    let content: any = [];
    const from = 'matti.friman@helsinginrauhanyhdistys.fi';
    const subject = 'Sinulla lähiaikona seuroissa työvuoro';
    const line = host.content.map((lin: any) => {
      return ' ' + lin.subject + ' ' + lin.day + ' klo: ' + lin.time + ' '   }
      );
    content.push(line);
    const contentText  = `Jt ${host.name} . Kiitos että  olet ottanut tehtävän vastaan. Sinulla on työvuoro Helsingin Rauhanyhdistyksellä:  `
    const prosa = 'Seuraisäntä: Saavu hyvissä ajoin paikalle. Erityisesti jos seuroissa palvelee vieras puhuja. Äänitarkkailija: Saavu vähintäään 20 minuuttia ennen ilaisuuden alkua. Varmista että tekniikka toimii, buuttaaa modeemi yläkerran toimistossa,mikäli verkko ei ole päällä. Netti tulee avata n 10 minuuttia ennen tilaisuuden alkua. Säestäjä ja esilaulaja:  Pari laulua ennen tilaisuutta ovat bonusta. '
    const toSend = contentText + content + prosa;
    await gmailService
    .sendMessage(token, from, host.email, subject, toSend)
    setCollect({collectMails: false, haveToFindRecievers: !collect.haveToFindRecievers, collected: true, sent: true});
  };

  const sendMessageToRecievers = async () => {
    if (window.confirm(' Olet lähettämässä muistutussähköposteja')) {
      setSend(true);
      for (const one of lines) {
        await callSendMessage(one);
      }
      setSend(false);
    }
  }

  const handleClose: any = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }

  }
  const collectMessageToRecievers = async () => {
    setCollect({ collectMails: true, haveToFindRecievers: !collect.haveToFindRecievers, collected: true, sent: false});
  }
  if (send) return <CircularProgress/>
  return (

    <div>
      <Box>
        { send &&
          <div>
            <Snackbar
              open={send}
              autoHideDuration={10000}
              onClose={handleClose}
              message= {'Lähetetäänsähköpostia'}
              />
          </div>
        }
      </Box>
      <GmailFilter1/>

      <Box>
        {filters.taskArray.selections.findIndex(((fi: any ) => fi !== '') ) !== -1 &&
          <Box>
          {
          !collect.collected ?
            <Button sx= {{p: 2, m:2} } variant="contained" onClick={() => {collectMessageToRecievers()}} >Collect </Button>
            :
            <Button sx= {{p: 2, m:2} } variant="contained" disabled onClick={() => {collectMessageToRecievers()}} >Collect Mails </Button>
          }
          {
          collect.collected && !collect.sent ?
            <Button sx= {{p: 2, m:2} } variant="contained" onClick={() => {sendMessageToRecievers()}} >Send Mails</Button>
            :
            <Button sx= {{p: 2, m:2} } variant="contained" disabled onClick={() => {sendMessageToRecievers()}} >Send </Button>
          }
          </Box>
        }
        {filters.taskArray.selections.filter ((sel1: any) => sel1 !== '')
          .map((sel2: any, i: any) => {
            return  <Typography key= {i} sx={ subHeaderStyles }>{`Muistutussähköpostit: ${filters.taskArray.tasks[i]}`}</Typography>
          })
        }
        <Table sx={{ display: { xs: 'none', md: 'inline-table' }, width: '100%' }}>
          <TableHead>
            <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
              <TableCell>{'Header'}</TableCell>
              <TableCell>{'Nimi'}</TableCell>
              <TableCell>{'Sähköposti'}</TableCell>
              <TableCell>{'Vuorot'}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              lines.map((line: any, i: any) => {
                const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
                return (
                  <TableRow sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${i}_row11`}>
                    <TableCell sx={{ backgroundColor }}>{ line.header } </TableCell>
                    <TableCell sx={{ backgroundColor }}>{ line.name } </TableCell>
                    <TableCell sx={{ backgroundColor }}>{ line.mail } </TableCell>
                    <TableCell sx={{ backgroundColor }}>{ line.content.map((con: any, i: any)=> {
                      return (
                        // <td key = {i}>
                          <Table key = {i}>
                            <TableBody>
                              <TableRow>
                                <TableCell  sx={{ olor: 'success.dark', display: 'inline', fontWeight: 'bold', mx: 0.5, fontSize: 14, }}
                                          key= {i}> {con.subject + ' ' + con.day + ' ' + con.time }</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        // </td>
                        )
                        }) }
                      </TableCell>
                  </TableRow>
                )
              })
            }

          </TableBody>
        </Table>
      </Box>
    </div>
  )
}

  export default GmailStart;