import { Container, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Outlet } from 'react-router-dom';
import { headerStyles, simpleContainerPaperStyle, subHeaderStyles } from '../../styles/themeStylesSx';
//import useLocales from '../hooks/useLocales';

export default function ProfileContainer() {
  //const { t } = useLocales();
  return (
    <Container>
      <Box sx = {{pt: 10}}>
        <Typography sx={ headerStyles }>{'My Profile'}</Typography>
      </Box>
      <Paper sx={ simpleContainerPaperStyle }>
          <Outlet />
      </Paper>
    </Container>
  );
}