
import './App.css';
import AppMenuBar from './components/layout/AppMenuBar';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {CircularProgress} from '@material-ui/core'
import SideNavigation from './components/layout/SideNavigation';
import { Box, Snackbar } from '@mui/material';
import {   BrowserRouter, Route, Routes } from 'react-router-dom';
import InfoPage from './components/admin/InfoPage';
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setTextFilter , editFilterData} from './actions/filters'
import { firebase } from './firebase/firebase'
import 'firebase/auth'
import { login, logout, updateIdToken } from './actions/auth';
import { colors } from './styles/themeStylesSx';
import {credentialsOnly} from './services/authdecodedtoken';
import { decodedToken } from './services/authdecodedtoken';
import SpeakersContainer from './components/containers/SpeakersContainer';
import ListSpeakersPage from './components/speakers/ListSpeakersPage';
import ServicesContainer from './components/containers/ServicesContainer';
import ReportsContainer from './components/containers/ReportsContainer';
import  ListServiceProgramPage from './components/service/ListServiceProgramPage';
import AddServiceLine from './components/service/AddServiceLine';
import ServiceAnalyze from './components/service/analyzes/ServiceAnalyze';
import  CheckCals from './components/calendars/CheckCals';
import  UpdateCals from './components/calendars/UpdateCals';
import CalendarContainer from './components/containers/CalendarContainer';
import AuthenticationContainer from './components/containers/AuthenticationContainer';
import InfoContainer from './components/containers/InfoContainer';
import AddSpeakerToLineContainer from './components/containers/AddSpeakerToLineContainer';
import SetSpeakerToLine from './components/loadSpeakers/SetSpeakerToLine';
import ServiceReports from './components/reports/ServiceReports';
import DriveContainer from './components/containers/DriveContainer';
import DriveListing from './components/drives/DriveListing';
import DriveStart from './components/drives/DriveStart';
import DriveGroups from './components/drives/groups/DriveGroups';
import DriveUsers from './components/drives/DriveUsers';
import LonelyUsers from './components/drives/LonelyUsers';
import GmailContainer from './components/containers/GmailContainer';
import SandBox from './components/containers/SandBox';
import SandBox1 from './components/containers/SandBox1';
import GmailStart from './components/gmail/GmailStart';
import UserListing from './components/users/UserListing';
import UserComponent from './components/users/UserComponent';
import UserContainer from './components/containers/UserContainer';
import AclListCalendar from './components/calendars/AclListCalendar';
import CalendarStart from './components/calendars/CalendarStart';
import ProfileContainer from './components/containers/ProfileContainer';
import ProfilePage from './components/profile/ProfilePage';
import AdminContainer from './components/containers/AdminContainer';
import ChangeYear from './components/admin/ChangeYear';
import CheckServiceProgram from './components/admin/CheckServiceProgram';
import LoginContainer from './components/containers/LoginContainer';
import {RootState} from './store/ConfigureStore';
import moment from 'moment';

const color1 = '#96CBA0';
const color2 = '#00687D';

const mdTheme = createTheme({
  shape: {
    borderRadius: 20,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: colors.backgroundGreen,
          boxShadow: 'none',
          borderBottom: 'thin solid gray',
        }
      }
    },
  },
  palette: {
    primary: {
      main: color1,
    },
    secondary: {
      main: color2,
    },
    warning: {
      main: '#EF5F5F',
    }
  },
  typography: {
    fontFamily: [
      'Lexend', 'sans-serif'
    ].join(','),
    button: {
      fontWeight: 600,
    },
    h1: {
      fontWeight: 600
    },
    h2: {
      fontWeight: 600
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600
    },
    h5: {
      fontWeight: 600
    },
    h6: {
      fontWeight: 600
    },
  },
});
let laskuri: any =0;

function App() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState (false);
  const filters: any = useSelector((state: RootState)=> state.filters);
  const auth: any = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  let nameF = []
  let nameY: any = [];
  const [refresh, setRefresh] =useState(false);
  const [refreshTime, setRefreshTime] = useState(0);
  let workIdToken = '';
 
  if (auth.userProfile) {
    setTimeout(() => {   
      // console.log("this is the first message");
      // console.log('auth_time', auth.userProfile.credentials.auth_time, 'iat', auth.userProfile.credentials.iat, 'exp', auth.userProfile.credentials.exp , 'now', moment().unix());
      // console.log('elapsed', auth.userProfile.credentials.exp - moment().unix());
      
    
      laskuri = auth.userProfile.credentials.exp - moment().unix();
      if ( laskuri < 500) {
        //if (!refresh) {
          setRefresh(true);      
          console.log('time to refresh?',  auth.userProfile.credentials.exp - moment().unix() );
          setRefreshTime(Math.random())
       // }      
      } 
    }, 500);
  }
  useEffect(() => {
    if (refresh) {

      const refreshToken = async(idToken: any) => {
        const credentials = await credentialsOnly(idToken);
        let userProfile = auth.userProfile;
        userProfile.credentials = credentials;
        dispatch(updateIdToken(idToken, userProfile ));      
      }

      const fireRefresh = async() => {
        try{
          const idToken = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true) 
        
          await refreshToken(idToken)
          
        }catch(error) {
          console.log('Virhe idtokenin päivityksessä - Ota yhteyttä järjestelmänvalvojaan',error)
          dispatch(editFilterData({error: true, errorMessage: 'Virhe käyttäjän tunnistamisessa Ota yhteyttä järjestelmänvalvojaanyyyyy'}))
        }     
        dispatch(editFilterData({ loading: false }));
        setLoading(false);
      }

      dispatch(editFilterData({ loading: true }));
      setLoading(true);
      fireRefresh();
    }
  }, [refreshTime])
 
     
  useEffect(()=> {
    const fetchtoken = async (user: any) => {
      try {
        await user.providerData.forEach(function (profile: any, authorities:any, decodedToken: any) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          nameF = profile.displayName.split(' ')
          // eslint-disable-next-line react-hooks/exhaustive-deps
          nameY = nameF[nameF.length-1] + ' ' + nameF[0]
          dispatch(setTextFilter(nameY))
        });
        await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true)
          .then(async function(idToken: any) {
            const userProfile: any = await decodedToken ( idToken, dispatch );
            dispatch(login(user.uid,idToken, user.email, nameY, userProfile));
          }).catch(function(error: string) {
            console.log('Virhe käyttäjän tunnistamisessa Ota yhteyttä järjestelmänvalvojaanxxx',error)
            dispatch(editFilterData({error: true, errorMessage: 'Virhe käyttäjän tunnistamisessa Ota yhteyttä järjestelmänvalvojaanxxx'}))
            // setError(true);
            // setErrorMessage(`errori decodedTokenin käsittelyssä ${error}`)
          });
      }
      catch (e:any){
        console.log('errori in app lastone',e)
      }
    }
    const fire = async () => {
      try {
        await firebase.auth().onAuthStateChanged(async (user: any) => {
          if (user) {
            await fetchtoken(user);
          }
          else {
            dispatch(logout());
          }
        })
      }
      catch (e: any){
        console.log('errori in app',e)
        dispatch(editFilterData({error: true, errorMessage: 'Virhe firebaseAuth onAuthStageChanged procedure kontaktoi järjestelmänvalvojaan ' + e }))
      }
      dispatch(editFilterData({ loading: false }));
      setLoading(false);
    }
    dispatch(editFilterData({ loading: true }));
    setLoading(true)
    fire()
  },[]);

  if (loading ) return <div> loading in app <CircularProgress/></div>
  
  if (loading) {
    return (<div><CircularProgress/>..firebase loading</div>) };

  const handleClose: any = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    dispatch(editFilterData({error: false, errorMessage:''}));
  }

  if (loading ) return <div><CircularProgress /> <br />...header is  loading</div>
  // if (!auth.idToken ) return <div><CircularProgress /> <br />...idtoken loading</div>

  return (
    <Box>
      {
      (filters.error) &&
      <div>
        <Snackbar
          open={filters.error}
          autoHideDuration={10000}
          onClose={handleClose}
          message= {filters.errorMessage}
          
          />
      </div>
     }

    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex', border:'thin solid' }}>
        <BrowserRouter>
          <Box sx={{ display: 'flex' }}>
            <AppMenuBar />;
            <SideNavigation />;
            <Box
              component="main"
              sx= {{ flexGrow: 1, p: { xs: 0, md: 7 }, mt: { xs: 4, md: 0 } }}
            >
              <Routes>
                <Route path="/" element= { < AuthenticationContainer />} />
                <Route path="/kirjaudu" element= { < LoginContainer />} />
                <Route path="/info" element= { <InfoContainer/> }  >
                  <Route index element={ <InfoPage />} />
                </Route>
                <Route path="/puhujat" element= { <SpeakersContainer/> } >
                  <Route index element={ <ListSpeakersPage />} />
                </Route>
                <Route path="/seuraohjelma" element= { <ServicesContainer/> } >
                  <Route path  = "/seuraohjelma" element={ <ListServiceProgramPage />} />
                  <Route path  = "/seuraohjelma/seuraohjelma" element={ <ListServiceProgramPage />} />
                  <Route path  = "/seuraohjelma/lisaarivi" element={ <AddServiceLine />} />
                  <Route path  = "/seuraohjelma/analyysit" element={ <ServiceAnalyze />} />
              
                </Route>
                <Route path="/calendar" element= { <CalendarContainer/> } >             
                  <Route path="/calendar/CheckCals" element= {  <CheckCals />} />     
                  <Route path="/calendar/calendar" element= {  <CalendarStart />} />    
                  <Route path="/calendar/Users" element= {  <AclListCalendar />} />          
                  <Route path="/calendar/UpdateCals" element= { <UpdateCals />} />
                </Route>
                <Route path="/servcrea" element= { <AddSpeakerToLineContainer/> } >
                  <Route index element={ <SetSpeakerToLine />} />
                </Route>
                <Route path="/reports" element= { <ReportsContainer/> } >
                  <Route index element={ <ServiceReports />} />
                </Route>
               
                <Route path="/drive" element= { <DriveContainer/> } >
                  <Route path = "/drive/drivelist" element = { <DriveListing/>} />
                  <Route path = "/drive" element={ <DriveStart />} />
                  <Route path = "/drive/drive" element={ <DriveStart />} />
                  <Route path = "/drive/groups" element={ <DriveGroups />} />
                  <Route path = "/drive/users" element={ <DriveUsers />} />
                  <Route path = "/drive/lonely" element={ <LonelyUsers />} />
                </Route>
                <Route path="/user" element= { <UserContainer/> } >
                  <Route path = "/user/userlist" element = { <UserListing/>} />
                  <Route path = "/user" element={ <UserComponent />} />
                  <Route path = "/user/user" element={ <UserComponent />} />
                </Route>
                <Route path="/gmail" element= { <GmailContainer/> } >
                  <Route path = "/gmail/gmail" element = { <GmailStart/>} />
                </Route>
                <Route path="/profile" element= { <ProfileContainer/> } >
                  <Route path = "/profile/profile" element = { <ProfileContainer/>} />
                  <Route index element={ <ProfilePage />} />
                </Route>
                <Route path="/admin" element= { <AdminContainer/> } >
                  {/* <Route path  = "/admin/admin" element={ <AdminContainer />} /> */}
                  <Route path  = "/admin/year" element={ <ChangeYear />} />
                  <Route path  = "/admin/checkProgram" element={ <CheckServiceProgram />} />
                  {/* <Route path  = "/seuraohjelma/lisaarivi" element={ <AddServiceLine />} />
                  <Route path  = "/seuraohjelma/analyysit" element={ <ServiceAnalyze />} /> */}
              
                </Route>
               
              </Routes>
            </Box>
          </Box>
        </BrowserRouter>
      </Box>
    </ThemeProvider>
    </Box>
  )
}
export default App;