import { Container, Paper } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { simpleContainerPaperStyle } from '../../styles/themeStylesSx';
//import useLocales from '../hooks/useLocales';

export default function AddSpeakerToLineContainer() {

  //const { t } = useLocales();
  return (
    <Container>
      {/* <Box>
        <Typography sx={ headerStyles }>{'Puhujien lataus seuraohjelmaan'}</Typography>
      </Box> */}
      <Paper sx={ simpleContainerPaperStyle }>
          <Outlet />
      </Paper>
    </Container>
  );
}