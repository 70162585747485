/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
import React from 'react';
import moment from 'moment';

import  {useDispatch} from 'react-redux';
import { setDaysToState } from '../../actions/filters';
import { IconButton, TableCell } from '@mui/material';
import { DeleteRounded } from '@mui/icons-material';
import { colors } from '../../styles/themeStylesSx';
type SpeakerDaysType= {
  id: any,
  array:any [],
  startDate:string,
  endDate:string,
  idx: number;
}

const SpeakerDays = ({ id,array,startDate,endDate, idx} : SpeakerDaysType) => {
  const dispatch = useDispatch();
  const backgroundColor = idx % 2 ? colors.backgroundGreen : 'transparent';


  const deleteLine = (idToDelete: any) => {
    array.forEach((ar: any) => {
      if (ar.id === idToDelete) {
        ar.deleted=true
      }
    })
    dispatch(setDaysToState(array))
  }
  return (
    <>
      <TableCell sx={{backgroundColor: backgroundColor }}>{moment(startDate,'D.M.YYYY').format('D.M.YYYY') }</TableCell>
      <TableCell sx={{backgroundColor: backgroundColor }}>{moment(startDate,'D.M.YYYY').format('ww') }</TableCell>
      <TableCell sx={{backgroundColor: backgroundColor }}>{moment(endDate,'D.M.YYYY').format('D.M.YYYY')} </TableCell>
      <TableCell sx={{backgroundColor: backgroundColor }}>{moment(endDate,'D.M.YYYY').format('ww') }</TableCell>
      <TableCell>  <IconButton  onClick={() => deleteLine(id)}>  <DeleteRounded /></IconButton></TableCell>
    </>
  )
}

export default SpeakerDays;