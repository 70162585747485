/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */

import React, { useState, useEffect }  from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import { CircularProgress, Box, IconButton, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { colors } from '../../styles/themeStylesSx';
import { setLoading} from '../../actions/filters';
import submitCreateSheets from './submitCreateSheets';
import submitSheetsToGsheet from './submitSheetsToGsheet';
import submitAddDataToSheet from './submitAddDataToSheet';
import {RootState} from '../../store/ConfigureStore';
import {subHeaderStyles} from '../../styles/themeStylesSx'
import { VisibilityRounded } from '@mui/icons-material';
import SidePanelLarge from '../sidepanel/SidePanelLarge';
import ListPermissions from './ListPermissions';
import {useNavigate} from 'react-router-dom';
type DriveStateType = {
  driveId?: string;
  open?: boolean;
};

const DriveListing = () => {
  const dispatch = useDispatch()
  const authorit: any = useSelector((state: RootState) => state.auth);
  const drivesx = useSelector((state: RootState) => state.drives);
  const filters = useSelector((state: RootState) => state.filters);
  const [loading,toggleLoading] = useState (true);
  const  token: any = `bearer ${authorit.idToken}`;
  const [sheetChecked,setSeetChecked] = useState(false);
  const [sheetId, setSheetId] = useState(' ');
  const navigate = useNavigate();
  // eslint-disable-next-line no-undef
  const [state, setState] = useState<DriveStateType>({ open: false, driveId : '' });
  useEffect( () => {
    if (drivesx.length === 0) {
      navigate("/drive");
    }
    setState({ open: false, driveId : '' });
    dispatch(setLoading(false));
    toggleLoading(false);
  }, []);

  if (loading ) return <div><CircularProgress /> <br />...latailee vielä</div>
  if (!filters.drive) return (<div> <br />. Ei vaadittuja oikeuksia drive managing</div>)
  let permArray2 = [];
  // for (const drive of drivesx) {
  //   for (const perm of drive.drivesPermissions) {
  //     permArray2.push(perm)
  //   }
  // }
  const createSheetProc = async (token: any, uid: any, dispatch: any ) => {
    const sheetResponse = await submitCreateSheets(token, uid, dispatch);
    const sheetIdArray = await submitSheetsToGsheet(token, authorit.uid, sheetResponse.spreadsheetId, drivesx, dispatch );
    submitAddDataToSheet(token, authorit.uid, sheetResponse.spreadsheetId, drivesx, sheetIdArray);
    const linksu1 = 'https://docs.google.com/spreadsheets/d/'
    const linksu2 = '/edit#gid=1083853510'
    const linksu3 = `${linksu1}${sheetResponse.spreadsheetId}${linksu2}`
    setSheetId(linksu3)
  }
  if (sheetChecked) { 
    createSheetProc(token,authorit.uid, dispatch);
    setSeetChecked( false );    
  }

  return (
    <Box>
      <SidePanelLarge
       open={ state.open === true }
       close={ () => setState({ open: false })}
      >
        <Box>
          <ListPermissions driveId= {state.driveId }  close ={ () => setState({ open: false })} />
        </Box>
      </SidePanelLarge>
      <Box>
        <Box>
          <Typography sx={ subHeaderStyles }>{'Hry:n jaetut drive-hakemistot' }</Typography>
          <Table sx={{ display: { xs: 'inline-table', md: 'inline-table' }, width: '100%' }}>
            <TableHead>
              <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
                <TableCell></TableCell>
                <TableCell>{'Drive-hakemiston nimi'} </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {
            drivesx.map((drive: any, i: any) => {
            const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
              return (
                <TableRow sx={{backgroundColor, height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key = {drive.driveId}>
                  <TableCell sx={{
                    backgroundColor,
                    borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>
                    <IconButton  onClick={() => setState({ open: true, driveId : drive.driveId })}><VisibilityRounded /></IconButton>
                  </TableCell>
                  <TableCell>{drive.driveName}</TableCell>
                </TableRow>
                )
              })
            }
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Box>
  )
}
export default DriveListing