/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */

import { v4 as uuidv4 } from 'uuid';
import updateSpeakerCalendar from '../speakers/updateSpeakerCalendar';
export const submitSpeakersToCalendar = async (serviceArrayParm: any, speakerArrayParm: any,
  startEditServDates: any, addSpeaker: any, updateCalendars: any, token: any, filters: any) => {
  const returnable: any = {};
  let speakerCounter = speakerArrayParm.length
  const speakerList = serviceArrayParm.filter((filtered: any) => (['1','2','3','4','5','6','7','8','9','0','10','11'].indexOf(filtered.type) !== -1))
    .sort(( a: any,b: any ) => {
      var x = a.sspeaker
      var y = b.sspeaker
      if (x < y) {return -1}
      if (x > y) {return 1}
      else return 0
    })
    .reduce ((speakerLines: any, line: any) => {
      speakerLines[line.sspeaker] = speakerLines[line.sspeaker] || [];
      const speakerId = speakerArrayParm.find((sp: any) => line.sspeaker === sp.speaker);
      let workId = 0;
      if (speakerId) {
        workId = speakerId.linenumber;
      } else workId = 99
      speakerLines[line.sspeaker].push({
        id: workId,
        Speaker:line.sspeaker,
        Subject: line.subject,
        Lisätieto: line.Selite,
        Viikonpäivä: line.Vkonpäivä,
        startdate: line.StartDate,
        starttime: line.StartTime,
        enddate: line.EndDate,
        endtime: line.EndTime,
        Paikka: line.Location,
        Type: line.type
      })
      return speakerLines
    }, {});
  const speakerArray = Object.keys(speakerList);
  for (const spe of speakerArray) {
    const servdates=[];
    const Day1 = [];
    const Day2 = [];
    const Day3 = [];
    const Day4 = [];
    const Day5 = [];
    const Day6 = [];
    const Day7 = [];
    const Day8 = [];
    const Day9 = [];
    const Day0 = [];
    const Day10 = [];
    const Day11 = [];
    for (const service1 of speakerList[spe]) {
      servdates.push(service1.startdate)
      switch(service1.Type) {
      case '1':
        Day1.push(service1.startdate)
        break
      case '2':
        Day2.push(service1.startdate)
        break
      case '3':
        Day3.push(service1.startdate)
        break
      case '4':
        Day4.push(service1.startdate)
        break
      case '5':
        Day5.push(service1.startdate)
        break
      case '6':
        Day6.push(service1.startdate)
        break
      case '7':
        Day7.push(service1.startdate)
        break
      case '8':
        Day8.push(service1.startdate)
        break
      case '9':
        Day9.push(service1.startdate)
        break
      case '0':
        Day0.push(service1.startdate)
        break
      case '10':
        Day10.push(service1.startdate)
        break
      case '11':
        Day11.push(service1.startdate)
        break
      }
    // eslint-disable-next-line no-extra-semi
    }
    // eslint-disable-next-line array-callback-return
    const speakerId: any = speakerArrayParm.filter((spea: any) => {
      if (spea.speaker === spe) return spea
    });
    if (  speakerId.length === 0) {

      speakerCounter = speakerCounter + 1;
      speakerId.push ({
        id: uuidv4(),
        speaker: spe,
        linenumber : speakerCounter
      })
      addSpeaker({  servid : speakerId.id, speaker: spe, mail: '', phone: '', types: '', dates: '',random:'' ,linenumber: 999, servDatesx: [],
        servDate1x:[] ,servDate2x:[] , servDate3x:[] , servDate4x:[] , servDate5x:[], servDate6x:[], servDate7x:[], servDate8x:[], servDate9x:  [] ,
        servDate0x:  [] , servDate10x:  [], servDate11x: [] })
    };
    const Day1x=Day1.join();
    const Day2x=Day2.join();
    const Day3x=Day3.join();
    const Day4x=Day4.join();
    const Day5x=Day5.join();
    const Day6x=Day6.join();
    const Day7x=Day7.join();
    const Day8x=Day8.join();
    const Day9x=Day9.join();
    const Day0x=Day0.join();
    const Day10x=Day10.join();
    const Day11x = Day11.join();
    const Datesx=servdates.join();
    // if (speakerId.length > 0) {
    const servDatesArray = ({
      servid : speakerId[0].id,
      servspeaker :speakerId[0].speaker,
      id:speakerId[0].linenumber,
      servDates: servdates,
      workDays: {
        servDate1: Day1x,
        servDate2: Day2x,
        servDate3: Day3x,
        servDate4: Day4x,
        servDate5: Day5x,
        servDate6: Day6x,
        servDate7: Day7x,
        servDate8: Day8x,
        servDate9: Day9x,
        servDate0: Day0x,
        servDate10: Day10x,
        servDate11: Day11x,
        servDate1x: Day1,
        servDate2x: Day2,
        servDate3x: Day3,
        servDate4x: Day4,
        servDate5x: Day5,
        servDate6x: Day6,
        servDate7x: Day7,
        servDate8x: Day8,
        servDate9x: Day9,
        servDate0x: Day0,
        servDate10x: Day10,
        servDatesx: Datesx,
        servDate11x: Day11,
      }
      
    });
    if (updateCalendars) {
      await speakerList[spe].reduce( async (earlier: any, current: any) => {
        await earlier;

        const deleteonly = false;
        await updateSpeakerCalendar(token, filters, spe, current.startdate, current.starttime,current.enddate,current.endtime,
          current.Subject,current.Lisätieto,spe, current.Paikka,speakerId[0].mail,current, deleteonly)
        //Wait 500ms after each request
        //}
        await new Promise( resolve => setTimeout(resolve, 5000));
      }, Promise.resolve([]));
    }
    await startEditServDates(servDatesArray.id, servDatesArray)
    returnable[speakerId[0].id] = speakerList;
    // }
  }
  return speakerList;
};