import programService from "../../services/programService";
import { editFilterData } from "../../actions/filters";
export const GenGetSubjects = async (serviceArray: any) => {
  const allSubjects = serviceArray.reduce ((results: any, data: any) => {
    const result = results;
  //   if (['',' ','Kahvitus', 'Ruokailu', 'Siivousvuoro', 'Subject', 'Talviloma', 'Työvaliokunta', 'Johtokunnan kokous', 'Vuosikokous', 'Pääsiäinen','Joulu',
  // 'Helatorstai','Helluntai', 'Itsenäisyyspäivä','Juhannus', 'Vappu', 'Jouluaatto'].indexOf(data.subject) === -1 && data.strike !== 'y'  && typeof(data.subject) !== 'undefined' )
  if (data.Toimikunta === 'Seuratoimikunta') {
    let work: any = data.subject;
    if (work.includes('Alueseurat' )) {
      work = 'Alueseurat'
    };
    result[work] = result[work] || [];
    if (result[work].findIndex((res: any) => data.StartDate === res.StartDate )  === -1 || work === 'Alueseurat') {
      
      result[work].push ({
        subject: work,
        StartDate: data.StartDate,
        Selite: data.Selite,
        sspeaker : data.sspeaker    
      });
    }
     
    }        
  return result;
},{});
return allSubjects;
}; 

export  const GenGetAllSubSheets = async (dispatch: any, filters: any, token: any, year: any, callTable: any ) => {
 
  let subSheetLines : any = [];
  await callTable.forEach((curr: any) => {
    subSheetLines.push(filters.sheetYears[filters.sheetYears.findIndex((fi: any) => {return fi[0] === curr + year})]  )
  })
  const param = subSheetLines.map((cols: any) => {
    return ({sheetid: cols[1], rangeid:cols[2]})
  });      
  let initialLines: any = []
  const initialRows = await programService
    .getAllSubSheets(token, param);
    if (Array.isArray(initialRows)) {
      initialLines = initialRows;
    }
    else {
      initialLines = [];
      dispatch(editFilterData({error: true, errorMessage: 'UpdateServiceLine failed, check sheetId params '}));
    };
    return initialLines;
}
