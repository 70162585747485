/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable linebreak-style */
//Make speakers visible
import moment from 'moment'
export default async (services: any, { gmailStartDate, gmailEndDate}: any) => {
  return services.filter((service: any) => {
    const startWork: any = moment(gmailStartDate,'DD.MM.YYYY').format('D.M.YYYY')
    const endWork: any = moment(gmailEndDate,'DD.MM.YYYY').format('D.M.YYYY')
    const startDateMatch = moment( service.StartDate,'D.M.YYYY').isSameOrAfter(moment(startWork,'DD.MM.YYYY'))
    const endDateMatch = moment( service.EndDate,'D.M.YYYY').isSameOrBefore(moment(endWork,'DD.MM.YYYY'))
    return startDateMatch  && endDateMatch
  })
}