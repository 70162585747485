/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
import React, { useEffect, useRef} from 'react'
import { connect } from 'react-redux';
//import moment from 'moment'
import SpeakerListLoadFilters from './SpeakerListLoadFilters';
import { setCalendarIds }  from  '../../actions/filters'
import { useDispatch, useSelector  } from 'react-redux';
import submitGetCalendarIdArr from '../calendars/submitGetCalendarIdArr'
import { CircularProgress, Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { subHeaderStyles } from '../../styles/themeStylesSx';
import submitGetSheetId from '../service/submitGetSheetId';
import { setSheetData } from '../../actions/filters'
import { Navigate } from 'react-router-dom';
import {RootState} from '../../store/ConfigureStore';
import { colors } from '../../styles/themeStylesSx';
import moment from 'moment';
import MouseOverPopOver from '../../utilities/MouseOverPopover'
//import filters from '../../reducers/filters';
const popOverText = 'Luo Seuraohjelmaan puhujatiedot seuraavalle vuodelle'

const SetSpeakerToLine = (props: any) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const filtterit: any = useSelector((state: RootState) => state.filters);
  const auth : any = useSelector((state: RootState) => state.auth)
  const dispatch = useDispatch();
  const isMounted: any = useRef(null);
  const open = Boolean(anchorEl);
  const token = `bearer ${props.auth.idToken}`

  useEffect(() => {
  if (filtterit.loading)  <CircularProgress/>
    const retr1 = async () => {
      const doDispatch = false;
      const sheetData: any = await submitGetSheetId(token, filtterit.serviceYear, dispatch, doDispatch, filtterit );
      dispatch(setSheetData(sheetData));
      const calendarDataArray1 = await submitGetCalendarIdArr(token);
      dispatch(setCalendarIds(calendarDataArray1));
    }
    if (auth.idToken)
        retr1();

  }, [])
 
  if (filtterit.loading) return <CircularProgress/>;
  if (filtterit.speakerSubDayCol1 === 'loaded') return (<div><br />Seuraohjelman puhujat on jo merkitty ladatuksi. Ota yhteys järjestelmävalvojaan</div>)
 
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
 
 
 
  if (props.services.length === 0 || moment(filtterit.serviceYear, 'YYYY').isSame(moment(),'year' ) ){
    if(moment(filtterit.serviceYear, 'YYYY').isSame(moment(),'year' ) ) window.alert('Valitse seuraohjelman vuodeksi tuleva vuosi')
    else
    if (props.services.length === 0) window.alert('Valitse tuleva vuosi seuraohjelman latausta varten');
    return <Navigate to="/" replace={true} />
  }

  if (!filtterit.admin) return (<div> <br />. Ei vaadittuja oikeuksia setSpeakerToLine</div>)

  const deleteFirst = (dateArray: any) => {
    if (dateArray[0] === '1.12.2020') dateArray.shift()
    return dateArray
  }
  return(
    <Box>
       <Typography sx={ subHeaderStyles }
        aria-owns = { open ? 'mouse-over-popover1' : undefined}
        aria-haspopup = "true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
       > Serviceprogram - Puhujien lataus ohjelmaan   {props.filters.serviceYear}
       </Typography>
       <MouseOverPopOver popOverText = { popOverText } anchor = { anchorEl }/>
      <SpeakerListLoadFilters/>
        <Table sx={{ display: { xs: 'inline-table', md: 'inline-table', color: 'secondary.main' }}}>
          <TableHead>
            <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
              <TableCell>{'Puhuja'}</TableCell>
              <TableCell>{'Loma-ajat'}</TableCell>
              <TableCell>{'Tyypit'}</TableCell>
              <TableCell>{'Lkm'}</TableCell>
              <TableCell>{'Kaikki'}</TableCell>
              <TableCell>{'Su_16'}</TableCell>
              <TableCell>{'Su_18'}</TableCell>
              <TableCell>{'La_18'}</TableCell>
              <TableCell>{'Ke_19'}</TableCell>
              <TableCell>{'Alues'}</TableCell>
              <TableCell>{'Laitosh'}</TableCell>
              <TableCell>{'Kirkkop'}</TableCell>
              <TableCell>{'Lastens'}</TableCell>
              <TableCell>{'Tallinna'}</TableCell>
              <TableCell>{'Ahvenanmaa'}</TableCell>
              <TableCell>{'Keskilta'}</TableCell>
              <TableCell>{'Raamtumti'}</TableCell>
            </TableRow>
          </TableHead>
        <TableBody>
        {
          props.tspeakers.filter((filt: any) => filt.mail !== '' && filt.mail !== ' ' && typeof(filt.mail !== 'undefined'))
          .map((speaker: any, i: any) => {
            const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
            return (
            <TableRow sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${speaker.linenumber}_row13`}>
              <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15 }}>
              { speaker.speaker }
              </TableCell>
              { speaker.datesArrayFinal[0].apvm &&
              <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15 }}>
                { moment(speaker.datesArrayFinal[0].apvm,'D.M.YYYY').isBefore('2040-01-01') &&
                speaker.datesArrayFinal.map((date: any) => moment(date.apvm).format('D.M.YYYY') + '-' + moment(date.ppvm).format('D.M.YYYY') + ' ') }
              </TableCell>
              }
              <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15 }}>
                { speaker.typesArray.map((type: any) => type + ' ') }
              </TableCell>
              <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15 }}>
              { speaker.servDates.length } vuoroa
              </TableCell>
              <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15 }}>
                {  deleteFirst(speaker.servDates).map((fi: any) => fi + ' ') }
              </TableCell>
              <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15 }}>
                {  deleteFirst(speaker.servDate1x).map((serv: any) => serv + ' ') }
              </TableCell>
              <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15 }}>
                {  deleteFirst(speaker.servDate2x).map((serv: any) => serv + ' ') }
              </TableCell>
              <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15 }}>
                {  deleteFirst(speaker.servDate3x).map((serv: any) => serv + ' ') }
              </TableCell>
              <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15 }}>
                {  deleteFirst(speaker.servDate4x).map((serv: any) => serv + ' ') }
              </TableCell>
              <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15 }}>
                {  deleteFirst(speaker.servDate5x).map((serv: any) => serv + ' ') }
              </TableCell>
              <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15 }}>
                {  deleteFirst(speaker.servDate6x).map((serv: any) => serv + ' ') }
              </TableCell>
              <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15 }}>
                {  deleteFirst(speaker.servDate7x).map((serv: any) => serv + ' ') }
              </TableCell>
              <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15 }}>
                {  deleteFirst(speaker.servDate8x).map((serv: any) => serv + ' ') }
              </TableCell>
              <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15 }}>
                {  deleteFirst(speaker.servDate9x).map((serv: any) => serv + ' ') }
              </TableCell>
              <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15 }}>
                {  deleteFirst(speaker.servDate11x).map((serv: any) => serv + ' ') }
              </TableCell>
              <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15 }}>
                {  deleteFirst(speaker.servDate0x).map((serv: any) => serv + ' ') }
              </TableCell>
              <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15 }}>
              {  deleteFirst(speaker.servDate10x).map((serv: any) => serv + ' ') }
              </TableCell>
            
            </TableRow>
            )
          })
        }
        </TableBody>
      </Table>
      <Table sx={{ display: { xs: 'inline-table', md: 'inline-table', color: 'secondary.main' }}}>
          <TableHead>
            <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
              <TableCell>{'Puhuja'}</TableCell>
              <TableCell>{'Alk'}</TableCell>
              <TableCell>{'Tilaisuus'}</TableCell>
              <TableCell>{'Selite'}</TableCell>
            </TableRow>
          </TableHead>
        <TableBody>
        {/* <Typography sx={ subHeaderStyles }>{'Puhujavuorot'}</Typography> */}
          {props.services &&
            props.services
            .filter((service: any) => ['1','2','3','4','5','6','7','8','9','0','10','11'].indexOf(service.type) !== -1)
            .map((service: any, i: any) => {
            const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
              return (
                <TableRow sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${service.serviceId}_row14`}>
                  {service.strike !== 'y' &&
                    <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>{ service.sspeaker } </TableCell>
                  }
                  {service.strike === 'y' &&
                    <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15, textDecoration:"line-through"}}>{ service.sspeaker } </TableCell>
                  }
                  {service.strike !== 'y' &&
                    <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>{ service.Vkonpäivä + ' ' + service.StartDate + ' ' + service.StartTime}</TableCell>
                  }
                  {service.strike === 'y' &&
                    <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15, textDecoration:"line-through"}}>{ service.Vkonpäivä + ' ' + service.StartDate + ' ' + service.StartTime}</TableCell>
                  }
                  {service.strike !== 'y' &&
                    <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>{ service.subject }</TableCell>
                  }
                  {service.strike === 'y' &&
                    <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15,textDecoration:"line-through" }}>{ service.subject }</TableCell>
                  }
                  {service.strike === 'y' &&
                    <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>{ service.Selite}</TableCell>
                  }
                  {service.strike !== 'y' &&
                    <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>{ service.Selite}</TableCell>
                  }
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>

    </Box>
  )
}



const mapStateToProps = (state: any, props: any) => {
  return {
    services: state.services,
    tspeakers: state.speakers,
    auth:state.auth,
    filters: state.filters
  }
}

export default connect(mapStateToProps)(SetSpeakerToLine);
