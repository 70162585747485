/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
import programService from '../../services/programService'
import moment from 'moment';
const submitLeaveTimes = async (token:  any, speaker: any, filters: any ) => {
  const leaveTimes= filters.daysToState.filter((da: any) =>
    !da.deleted).map((da: any) => `${moment(da.startDate).format('D.M.YYYY')} -  ${moment(da.endDate).format('D.M.YYYY')}` )
  const stringData = leaveTimes.toString();
  await programService
    .updatespleaves(token,speaker.linenumber,stringData,filters.serviceYearSpeakers, filters.sheetId, filters.columnAddInfo, filters.subSheet)
    .catch(error => {
      console.log('error updating speakers leavetimes',error)
    })
  return 'kukkuutikukkuu'
}

export default submitLeaveTimes;