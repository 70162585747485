/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable no-undef */
/* eslint-disable linebreak-style */
import React, { useState, useEffect } from 'react' ;
import {  useSelector , useDispatch} from 'react-redux';
import {  Box, Checkbox, Grid, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { editFilterData } from '../../actions/filters';
import {DateRangePicker} from 'react-dates';
import {RootState} from '../../store/ConfigureStore';
type StateType = {
  calendarFocused?: any ,
  saveSpeaker?: string,
  gmailStartDate?: string,
  gmailEndDate?:string,
  ButtonStyle?: 'secondary',
  gmailBoss?: boolean,
  gmailSoundObserver? : boolean,
  gmailAccompanist?: boolean,
  gmailLeadSinger? : boolean,
  gmailSupervisor? : boolean,
  gmailCasher? : boolean,

}



const GmailFilter = () => {
  const filters: any = useSelector((state: RootState)=> state.filters);
  const dispatch = useDispatch();


  const [state, setState] = useState<StateType>({
    calendarFocused: null,
    saveSpeaker: filters.text,
    gmailStartDate: filters.gmailStartDate,
    gmailEndDate:filters.gmailEndDate,
    ButtonStyle: 'secondary',
    gmailBoss: filters.gmailBoss,
    gmailSoundObserver : filters.gmailSoundObserver,
    gmailAccompanist: filters.gmailAccompanist,
    gmailLeadSinger : filters.gmailLeadSinger,
    gmailSupervisor : filters.gmailSupervisor,
    gmailCasher : filters.gmailCasher,
  });

  const onDatesChange = ({startDate,endDate}: any) => {
    const taskArray = filters.taskArray
    dispatch(editFilterData({gmailStartDate: startDate, gmailEndDate: endDate, taskArray}))

  }
  const onFocusChange = (calendarFocused: any) => {
    setState({...state, calendarFocused: calendarFocused});
  }

  const handleToggle = (value: string) => () => {
    const taskArray = filters.taskArray;
    if (taskArray.selections[filters.taskArray.tasks.indexOf(value)] === value) {
        taskArray.selections[filters.taskArray.tasks.indexOf(value)] = '';
        const gmailName = filters.taskArray.stateKeys[filters.taskArray.tasks.findIndex((fi: any) => fi === value)];
        dispatch(editFilterData({ [gmailName]: false, taskArray}));
    }
    else {
      taskArray.selections[filters.taskArray.tasks.indexOf(value)] = value;
      const gmailName = filters.taskArray.stateKeys[filters.taskArray.tasks.findIndex((fi: any) => fi === value)];
      dispatch(editFilterData({ [gmailName]: true, taskArray}));
    }

  };

  const customList = ( tasks: any) => (
    <Box>
      <List
        sx={{
          width: 200,
          height: 230,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {filters.taskArray.tasks.map((value: any, i: number) => {
          const labelId = `transfer-list-all-item-${value}-label`;
          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={filters.taskArray.selections.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={` ${value }`} />
            </ListItem>
          );
        })
        }
      </List>
    </Box>
  )
    return (
      <Box sx={{borderBottom:'thin solid', pb:2}}>
        <Grid item>{customList( filters.taskArray.tasks)}</Grid>
        <Box sx={{ mt: 1 }}>
        <DateRangePicker
            startDate = {filters.gmailStartDate}
            endDate = {filters.gmailEndDate}
            startDateId="MyDatePicker"
            endDateId="your_unique_end_date_id"
            onDatesChange={onDatesChange}
            focusedInput={state.calendarFocused}
            showClearDates ={true}
            onFocusChange={onFocusChange}
            numberOfMonths ={1}
            isOutsideRange = {() => false}
            firstDayOfWeek={1}
            minimumNights={0}
            displayFormat = "DD.MM.YYYY"
          />
        </Box >
      </Box>
    )
  }


export default GmailFilter