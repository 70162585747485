/* eslint-disable semi */

import submitGetCalendarIdArr from './submitGetCalendarIdArr'
import database from '../../firebase/firebase';
import submitGenCalRead from './submitGenCalRead';
//import driveServices from '../services/driveServices'
import checkChangesOfService from '../checkChangesOfServices';

const calendarList = ['services', 'Hry tilat', 'Autotalli', 'Juhlapyhät', 'Kahvio', 'Keittiö', 'Yläkerran kokoushuone', 'Kerhohuone', 'Arkisto', 'Leivintupa', 'Sali', 'Sauna', 'Toimisto']

const submitReadHryCals = async ( uid: any, dispatch: any, setCalendarIds: any, token: any, filters: any, auth: any) => {
  const servYear=filters.serviceYear;
  const returnable: any = ({
    'services':[],
    'Hrytilat':[],
    'Autotalli': [],
    'Juhlapyhät':[],
    'Kahvio':[],
    'Keittiö':[],
    'Yläkerrankokoushuone': [],
    'Kerhohuone' :[],
    'Arkisto': [],
    'Leivintupa':[],
    'Sali':[],
    'Sauna':[],
    'Toimisto':[]

  });

  const calCall = async (id: any, calendar: any) => {
    const translatedCalendars = ['services', 'Hrytilat', 'Autotalli', 'Juhlapyhät', 'Kahvio', 'Keittiö', 'Yläkerrankokoushuone', 'Kerhohuone', 'Arkisto', 'Leivintupa', 'Sali', 'Sauna', 'Toimisto'];
    const searchCalendar = translatedCalendars[calendarList.indexOf(calendar)];
    const calendarRows = await submitGenCalRead(id, token, filters.startDate,filters.endDate, dispatch);
    returnable[searchCalendar] = calendarRows;
    return
  }
  const retr1 = async (calendarDataArray: any) => {
    for ( const row of calendarDataArray)  {
      await calCall(row.calendarId, row.calendar);
    }
  }
  const retr = async () => {
    const calendarDataArray1 = await submitGetCalendarIdArr(token);
    dispatch( setCalendarIds(calendarDataArray1))
    return calendarDataArray1
  }
  const checkVersion = async () => {
    if (filters.sheetYears.length > 0)
      await checkChangesOfService(token,filters,auth)
  }
  const getSheet = async () => {
    await checkVersion();
    const returnable = await retr ();
    await retr1(returnable);
    return;
  }


  await getSheet()

  let serviceList: any = []
  await database.ref(`users/${uid}/serviceYear/${servYear}/serviceProgram`)
    .once('value')
    .then((snapshot: any) => {
      snapshot.forEach((childSnapshot: any) => {
        serviceList.push({
          serviceId:childSnapshot.key,
          ...childSnapshot.val()
        })
      })
      returnable.services = serviceList.filter((serv: any) => {
        return serv.strike.toLowerCase() === 'n';
      });
    })
  return returnable
}
export default submitReadHryCals