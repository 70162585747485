/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
import React, { useState, useEffect} from 'react';
import {useSelector, useDispatch } from 'react-redux';
import {RootState} from '../../store/ConfigureStore';
import { subHeaderStyles } from '../../styles/themeStylesSx';
import { Box, IconButton, Table, TableRow, TableCell, TableHead, TableBody, Typography} from '@mui/material';
import { colors } from '../../styles/themeStylesSx';
import { VisibilityRounded } from '@mui/icons-material';
import SidePanel from '../sidepanel/SidePanel';
import {editFilterData} from '../../actions/filters';
import UpdateServicePage from './../service/UpdateServicePage'
const nameArray: any = ['kesk','su16','su18', 'la18', 'ke19', 'alue', 'lait', 'kirk', 'last', 'tall', 'raam'];

type ListSpeakerDaysType= {
  speaker: any;
  close: () => void;
}

type ListTurnsStateType = {
  openStrike?: boolean;
  openSpeaker?: boolean;
  serviceId?: number;
  add?: boolean;
  linenumber?: number;
  id?: any;
};

const ListSpeakerDays = (props : ListSpeakerDaysType) => {
  const speakers: any = useSelector((state: RootState) => state.speakers);
  const [state, setState] = useState<ListTurnsStateType>({ openStrike: false, openSpeaker: false });
  const serviceArray: any = useSelector((state: RootState) => state.services);
  const filters: any = useSelector((state: RootState) => state.filters)
  const speArr = speakers.map((spe:any)=> {return spe.speaker});
  const dispatch = useDispatch();
  const speakerList: any = serviceArray
    .filter((filtered: any) => (['1','2','3','4','5','6','7','8','9','0','10','11'].indexOf(filtered.type) !== -1))
    .filter((filtered: any) => (speArr.indexOf(filtered.sspeaker) !== -1))
    .sort(( a: any,b: any ) => {
      var x = a.sspeaker
      var y = b.sspeaker
      if (x < y) {return -1}
      if (x > y) {return 1}
      else return 0
    })
    .reduce ((results: any, data: any) => {
      const result = results;
      result[data.sspeaker] = result[data.sspeaker] || []
      result[data.sspeaker].push ({
        sspeaker : data.sspeaker,
        vko: data.Vko,
        StartDAte: data.StartDate,
        type: data.type,
        time: data.StartTime,
        Vkonpäivä: data.Vkonpäivä,
        id: data.serviceId,
    })
    return result
  }, {});
  useEffect(() => {
    dispatch(editFilterData({somethchanged:  Math.random()}))
  },[props.speaker, filters.serviceEdited])


  return (
    <Box>
      <SidePanel
      open={ state.openSpeaker === true }
      close={ () => setState({ openSpeaker: false })}
      >
        {state.openSpeaker &&
          <UpdateServicePage serviceId= {state.id } close ={ () => setState({ openSpeaker: false, openStrike: false })} />
        }
       

      </SidePanel>
        <Typography sx={ subHeaderStyles }> {props.speaker} {'Puhujavuorot'}</Typography>
      <Table>

        <TableHead>
          <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
            <TableCell></TableCell>
            <TableCell>{'Viikko'}</TableCell>
            <TableCell>{'Tilaisuus'}</TableCell>
            <TableCell>{'Vkonpäivä'}</TableCell>
            <TableCell>{'Päivämäärä'}</TableCell>
            <TableCell>{'Kello'}</TableCell>
          </TableRow>
        </TableHead>


        <TableBody>
          {speakerList && props.speaker !== '' && 
           speakerList[props.speaker].map((service: any, i: any) => {
              const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
              return (
                <TableRow sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${service.id}_row12`}>
                  <TableCell sx={{
                    backgroundColor,
                    borderTopRightRadius: 15, borderBottomRightRadius: 15}}>
                    <IconButton sx={{p: 0}} onClick={() => setState({ id:service.id , openSpeaker: true})}><VisibilityRounded /></IconButton>
                  </TableCell>
                  <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>{ service.vko } </TableCell>
                  <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>
                    {  nameArray[['0','1','2','3','4','5','6','7','8','9','10','11'].indexOf(service.type)] } </TableCell>
                  <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>{ service.Vkonpäivä } </TableCell>
                  <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>{ service.StartDAte } </TableCell>
                  <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>{ service.time } </TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    </Box>
  )
}

export default ListSpeakerDays;