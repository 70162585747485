/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
//import React, { useState, useEffect ,useReducer, useContext } from 'react'
import calendarServices from '../../services/calendarServices'
import Moment from 'moment';
import 'moment-timezone';

const submitGenCalUpd = async (calendarIds: any, token: any, service: any, deleteOnly: string, dispatch: any, editFilterData: any ) => {
  const startdatea =  Moment(service.StartDate, 'D-M-YYYY').format('YYYY-MM-DD') + 'T' + Moment(service.StartTime,'HH:mm' ).format('HH:mm');
  const enddatea =   Moment(service.EndDate, 'D-M-YYYY').format('YYYY-MM-DD') + 'T' + Moment(service.EndTime,'HH:mm' ).format('HH:mm');
  let worksummary = '';
  let workSelite = service.Selite;
  if (!workSelite) {
    workSelite = ' ';
  }
  worksummary =  service.Location === 'Keittiö' ? service.Toimikunta + ' / ' + service.subject + ' / ' + workSelite : service.subject;
  let sendUpdates = 'all'
  if (process.env.REACT_APP_MAIL==='testi') {
    sendUpdates = 'none';
  }
  const newObject = ({
    calendarId:calendarIds.calendarId,
    attendee: 'matti.friman@helsinginrauhanyhdistys.fi',
    start: {
      'dateTime' : Moment.tz(startdatea, 'Europe/Helsinki').format(), 
      'timeZone': 'Europe/Helsinki'
    },
    end: {
      'dateTime' : Moment.tz(enddatea, 'Europe/Helsinki').format(),
      'timeZone': 'Europe/Helsinki'
    },
    'summary': worksummary,  
    'location': calendarIds.calendar,
    'creator': 'seurat@helsinginrauhanyhdistys.fi',
    selite: service.Selite,
    sendUpdates: sendUpdates,
    q: service.subject
  })
  try {
    const initialLines = await calendarServices
    .genericGetAll( token, newObject );
    initialLines.forEach((id: any) => {
      try {
        calendarServices
        .removeEventGen(id.id, newObject, token)
      }catch(error: any) {
        console.log('pxxxxx kalenteririvin delaus errori',error);
        
        dispatch(editFilterData({error: true, errorMessage: `error when removing calendarlines in submitGenCalUpd errrorCode: error ${error.response.status}`, somethchanged: Math.random()}));
        ;
      }
    
    })
  }catch(error: any) {
    console.log('erreodddddd',error);
    dispatch(editFilterData({error: true, errorMessage: `error when reading calendarlines in submitGenCalUpd errrorCode: error ${error.response.status}`, somethchanged: Math.random()}));

  }
 
  if (deleteOnly !== 'deleteOnly') {  
    try {
      await calendarServices
      .insertEventGen(newObject, token)
    }catch(error: any) {
      console.log('Puhujat kalenteritapahtuman luonti errori',error);
      dispatch(editFilterData({error: true, errorMessage: `error when inserting servicelines in submitGenCalUpd errrorCode: error ${error.response.status}`, somethchanged: Math.random()}));

    }
  }
  return 'kukkuutikukkuu'
}

export default submitGenCalUpd;