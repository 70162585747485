/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */

import React, { useState, useEffect } from 'react' ;
import { useSelector , useDispatch} from 'react-redux'
import { useNavigate } from "react-router-dom";
import submitGenCalUpd from '../calendars/submitGenCalUpd';
import programService from '../../services/programService';
import { subHeaderStyles } from '../../styles/themeStylesSx';
import moment from 'moment'
import { editFilterData } from '../../actions/filters';
import {RootState} from '../../store/ConfigureStore';
import { Box, Button, FormControl, FormControlLabel, Switch, InputLabel, MenuItem, Select, TextField, Typography, CircularProgress } from '@mui/material';
import ResponsiveDateTimePickers from './DateTimepicker';
import {ServiceLineType} from './../../types/serviceLine';
import { setCalendarIds } from '../../actions/filters';
import submitGetCalendarIdArr from '../calendars/submitGetCalendarIdArr';
import ReadAndStoreServiceProgram from '../genUtilities/ReadAndStoreServiceProgram';
import submitGetSheetId from './submitGetSheetId';
import submitSheetRetr from './submitSheetRetr';
import AddFireToService from '../../firebase/AddFireToService';
import { Capitalize } from '../genUtilities/Capitalize';

let subjects: any = [];
let sortedSubject: any = [];
let subSheetLines: any = [];
const AddServiceLine = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const filters: any = useSelector((state: RootState)=> state.filters);
  const services: any = useSelector((state: RootState)=> state.services);
  const auth: any = useSelector((state: RootState) => state.auth);
  const token = `bearer ${auth.idToken}`;
  const [arrays, setArrays] = useState<any>([]);
  
  const [state, setState] = useState<ServiceLineType>({
     serviceId: '',
    type: '',
    excluded: '',
    Vko: 0,
    Vkonpäivä: '',
    StartDate: moment(),
    StartTime: '',
    EndDate: moment(),
    EndTime: '',
    Pm: '',
    subject: '',
    Selite: '',
    Location: '',
    Toimikunta: '',
    Private: '',
    sspeaker: '',
    Seuraisäntä: '',
    Säestäjä: '',
    Esilaulaja: '',
    Valvontaryhmänvetäjä: '',
    Englannintulkkaus: '',
    Eestintulkkaus: '',   
    Viittomakielentulkkaus: '',
    Äänentoisto: '',       
    Siivousryhmä: '',
    Kassaryhmä: '', 
    linenumber: 0,
    strike: '',   
    email:'',
    PmTrueFalse: false,
    originalLocation: '',
    originalStartDate: '',
    originalStartTime: '',
    originalEndDate: '',
    originalEndTime :'',
    originalSubject : '',
    originalSelite: '',
  })
 
  let strikeArray: any [] = [];

  useEffect (() => {
    if (services.length === 0) {
      navigate("/seuraohjelma");
    }
     
    else {

      const getAllSubSheetsP = async (year: any, callTable: any) => {
        await callTable.forEach((curr: any) => {
          subSheetLines.push(filters.sheetYears[filters.sheetYears.findIndex((fi: any) => {return fi[0] === curr + year})]  )
        });
        const param = subSheetLines.map((cols: any) => {
          if (!cols) {
            dispatch(editFilterData({error: true, errorMessage: 'Subdsheet-tunnukset puutteelliset hakuvuodelle '}))
          }else {
          return ({sheetid: cols[1], rangeid:cols[2]})
          }
        });      
        let initialLines: any = []
        const initialRows = await programService
          .getAllSubSheets(token, param);
          if (Array.isArray(initialRows)) {
            initialLines = initialRows;
          }
          else {
            dispatch(editFilterData({ error: true, errorMessage: initialRows }));
            initialLines = [];
          };
          return initialLines;
        }

      const getSubjects = async () => {
        const allSubjects = services.reduce ((results: any, data: any) => {
          const result = results;
          result[data.subject] = result[data.subject] || []
          result[data.subject].push ({
            sspeaker : data.sspeaker    
          });
          return result;
        // }
      },{});
      return allSubjects;
      }

      const callStates = async () => {
        const callTable = ['types','TMK-nimet', 'location', 'seuraIsannat', 'saestajat', 'esilaulajat', 'valvojat','aanentoisto','tulkit'];
        const sheetArray = await getAllSubSheetsP(filters.serviceYear, callTable);
        subjects = await getSubjects();
        sortedSubject = Object.keys(subjects)
        .sort(( a: any,b: any ) => {
          var x: any= a
          var y: any = b
          if (x < y) {return -1}
          if (x > y) {return 1}
          else return 0
        })
        //.filter((fil) =>{ return !fil.toLowerCase().includes('seurat')})
        setArrays({typesValues: sheetArray[0], TMKValues: sheetArray[1], locationValues: sheetArray[2], 
          seuraIsannat: sheetArray[3], saestajat: sheetArray[4], esilaulajat: sheetArray[5],
          valvojat: sheetArray[6], aanitarkkailijat: sheetArray[7], tulkit: sheetArray[8], subjectValues : sortedSubject  });
      };    
      const retr = async () => {
        const calendarDataArray1 = await submitGetCalendarIdArr(token);
        dispatch( setCalendarIds(calendarDataArray1))
        return calendarDataArray1
      }
      const  callBody = async () => {      
        await callStates();
        await retr();
        dispatch(editFilterData({loading: false}));
      }  
      dispatch(editFilterData({loading: true}));
      callBody()        
    }   
  
  },[filters.serviceYear]);

  if ( filters.loading ) return <div> Latailee vielä parametreja AddServiceLine  <CircularProgress /></div>

  const handleClose: any = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(editFilterData({error: false, errorMessage:''}));
  }
  const  handleChange1 = async (e: any) => {
    const x: any = e.target.checked;
    if (x) {
      dispatch(editFilterData({ newLinePmx : x ,newLinePm : 'k'}))
    } else {
      dispatch(editFilterData({newLinePmx : x, newLinePm : 'e'}))
    }    
  }
  const handleChange = (name: any, value: number | string | Date | null) => {
    let workValue: any = [];
  
    setState({ ...state, [name]: value })

    if (name === 'newLineType') {
      workValue = arrays.typesValues.find( (type: any, i: number) => {
        return type[0] == value
      });
      
      dispatch(editFilterData({newLineSubject: workValue[2], newLineType: value, newLineLocation: workValue[3] , newLineToimikunta: workValue[4]}));     
    }
    if (name === 'newLineSubject') {
      dispatch(editFilterData({newLineType : ''}));
    }      
    dispatch(editFilterData({[name] : value}))
  };
  const getServiceProgram = async (sheetid: any, range: any, year: any, strikeArray: any) => {
    await ReadAndStoreServiceProgram(sheetid, range, year, strikeArray, dispatch, filters, auth);  
  }
  const retr1 = async (token: any, sheetid: any, range: any, year: any, strikeArray: any) => {
    const doDispatch = true;
    const sheetsDataStriked: any = await submitGetSheetId(token,'striked'+year, dispatch, doDispatch, filters );
    const strikeArray1 = await submitSheetRetr(token,sheetsDataStriked.sheetId,sheetsDataStriked.range,strikeArray, dispatch);
    strikeArray = [...strikeArray1];
    const services = await getServiceProgram(sheetid,range,year,strikeArray);
    await AddFireToService(year, auth.uid, services, dispatch);         
  }

  const retr = async (token: any, year: any) => {
    const doDispatch = false;
    const serviceIdData: any = await submitGetSheetId(token,filters.serviceYear, dispatch, doDispatch, filters );
    await retr1( token, serviceIdData.sheetId, serviceIdData.range, filters.serviceYear, strikeArray );                 
  }
  
  

  const paluu = async () => {
    navigate("/seuraohjelma");
  }

  const submit = async () => {
    const param = [[ filters.newLineType,
    filters.newLineExcluded, 
    filters.newLineVko, 
    Capitalize(filters.newLineVkonpäivä),
    filters.newLineStartDate,
    filters.newLineStartTime,
    filters.newLineEndDate,
    filters.newLineEndTime,
    filters.newLinePm, 
    filters.newLineSubject,
    filters.newLineSelite,
    filters.newLineLocation,
    filters.newLineToimikunta,
    ' ',
    filters.newLinePuhuja,
    filters.newLineSeuraisäntä,
    filters.newLineSäestäjä,
    filters.newLineEsilaulaja,
    filters.newLineValvoja,
    filters.newLineTulkki,
    ' ',
    ' ',
    filters.newLineÄänentoisto,    
    ' ',
    ' ',
    ' ',
    ' ',
    auth.email
    ]];
    const startMoment = filters.newLineStartDate+'T' + filters.newLineStartTime;
    const endMoment = filters.newLineEndDate + 't' + filters.newLineEndTime;
    const unix1 = moment(startMoment, 'DD.MM.YYYYTHH.mm').unix();
    const unix2 = moment(endMoment, 'DD.MM.YYYYTHH.mm').unix();
    let ok = 'ok';
    if (filters.newLineSubject === '') {
      dispatch(editFilterData({ error: true, errorMessage: 'Anna tilaisuuden nimi' }));
      ok = 'no';
    }
    else {
      if (filters.serviceYear  != moment(filters.newLineStartDate,'D.M.YYYY').year()) {
        dispatch(editFilterData({ error: true, errorMessage: 'Valitse päivä seuraohjelman vuodelta' }));
        ok = 'no';
      }
      else {
        if (moment(unix2).isSameOrBefore(moment(unix1))) {
          dispatch(editFilterData({ error: true, errorMessage: 'Rivin alkuhetki po pienempi kuin loppuhetki' }));
          ok = 'no';
        }
      }  
    };   
    if (ok === 'ok') {
      if (!filters.newLineToimikunta || filters.newLineToimikunta === ' ' || filters.newLineToimikunta === 'Toimikunta') {
        dispatch(editFilterData({ error: true, errorMessage: `Anna myös vastuutoimikunta` }));
        ok = 'no'
      }
    
    }
    if (ok === 'ok') {    
      const lineNumber = services.findIndex((se: any) => {
        const workDate = moment(se.StartDate,'D.M.YYYY').unix();
        return moment(workDate).isSame(moment(filters.newLineStartDate,'DD.MM.YYYY').unix());
      })
  
      if (lineNumber === -1){
         dispatch(editFilterData({ error: true, errorMessage: `Päivämäärä puuttuu${filters.newLineStartDate}` }));
      } 
      else{
        if ( filters.newLineSubject !== '')  {
          dispatch(editFilterData({ haveToRead: true }));
          const range = `${filters.subSheet}!${'A'}${lineNumber+1}:${'AB'}${lineNumber+1}`
          const reply = await programService
          .insertNewLine(token, filters.sheetId, 'xxxx', lineNumber, lineNumber+1, param, range);

          reply.split(' ').map((text:any) => {
            return text === 'Error' && dispatch(editFilterData({error: true, errorMessage: 'Seuraohjelman luvussa joku virhe' }))
          });
          const calendarId = filters.calendarDataArray.find((cal: any) => cal.calendar === filters.newLineLocation)
          const service = ({
            StartDate: moment(filters.newLineStartDate,'D.M.YYYY').format('D-M-YYYY'),
            StartTime: filters.newLineStartTime,
            EndDate: moment(filters.newLineEndDate,'D.M.YYYY').format('D-M-YYYY'),
            EndTime : filters.newLineEndTime,
            selite: filters.newLineSelite,
            subject: filters.newLineSubject,
            Location: filters.newLineLocation,
            Toimikunta: filters.newLineToimikunta,

          })
          if (calendarId) {
            const deleteOnly: any = 'no';
              submitGenCalUpd( calendarId, token, service, deleteOnly,  dispatch, editFilterData);
          }
          retr(token, filters.serviceYear)
        
          if (reply === '') {
            navigate("/seuraohjelma");
          }
        }  
      }
    }
  
  };
  if (!arrays.typesValues) return  <div> Waiting for types <CircularProgress/></div>;
  return(
 
  <Box>
    <Box>
    <Typography sx={ subHeaderStyles }>{`Lisää rivi ${filters.serviceProgramName}`}</Typography>
    </Box>        
      <Box>
        <FormControl   sx={{ p:1}}>
          <InputLabel> Seuratilaisuus</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="selectType"
            value = {filters.newLineType}
            name="type"
            onChange={(e: any) => {
              handleChange('newLineType', e.target.value)
            }}
          >            
            {
            arrays.typesValues.map( (type: any, i: number) =>
              <MenuItem
                key={i}
                value={type[0]}
              >
                { type[1] }
              </MenuItem>
            )}
          </Select>
        </FormControl>    
        <FormControl sx={{ pt:1}}>
          <InputLabel> Muu tilaisuus</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="selectSubject"
            value = {filters.newLineSubject}
            name="subject"
            onChange={(e: any) => {
              handleChange('newLineSubject', e.target.value)
            }}
          >            
            {
            arrays.subjectValues.map( (subj: any, i: number) =>
              <MenuItem
                key={i}
                value={ subj }
              >
                { subj }
              </MenuItem>
            )}
          </Select>
        </FormControl>       
        <FormControl sx={{ p:1}}>
          <InputLabel> Toimikunta</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="selectToimikunta"
            value = { filters.newLineToimikunta}
            name="toimikunta"
            onChange={(e: any) => {
              handleChange('newLineToimikunta', e.target.value)
            }}
            >      
            {arrays.TMKValues.length > 0 &&
            arrays.TMKValues.map( (tmk: any, i: number) =>
              <MenuItem
                key={i}
                value={tmk[0]}
              >
                { tmk[0] }
              </MenuItem>
            )}          
          </Select>
        </FormControl>
        <FormControl sx={{ pt:1, pb:1 }}>
          <InputLabel> Location</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="selectLocation"
            value = { filters.newLineLocation }
            name="location"
            onChange={(e: any) => {
              handleChange('newLineLocation', e.target.value)
            }}
           >      
            {
            arrays.locationValues.map( (loc: any, i: number) =>
              <MenuItem
                key={i}
                value={loc[0]}
                >
                { loc[0] }
            </MenuItem>
            )
          }
        </Select>
      </FormControl>
    
        <FormControl sx={{ pt:1, pb:1 }}>
          <InputLabel> Seuraisäntä</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="selectSeuraisäntä"
            value = { filters.newLineSeuraisäntä }
            name="Seuraisäntä"
            onChange={(e: any) => {
              handleChange('newLineSeuraisäntä',e.target.value )
            }}
           >      
            {
            arrays.seuraIsannat.map( (loc: any, i: number) =>
              <MenuItem
                key={i}
                value={loc[0]}
                >
                { loc[0] }
            </MenuItem>
            )
          }
        </Select>
      </FormControl>
      
      <FormControl sx={{ pt:1, pb:1 }}>
          <InputLabel> Säestäjä</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="selectSaestaja"
            value = { filters.newLineSäestäjä }
            name="Säestäjä"
            onChange={(e: any) => {
              handleChange('newLineSäestäjä', e.target.value)
            }}
           >      
            {
            arrays.saestajat.map( (loc: any, i: number) =>
              <MenuItem
                key={i}
                value={loc[0]}
                >
                { loc[0] }
            </MenuItem>
            )
          }
        </Select>
      </FormControl>
      <FormControl sx={{ pt:1, pb:1 }}>
          <InputLabel> Esilaulaja</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="selectEsilaulaja"
            value = { filters.newLineEsilaulaja }
            name="Esilaulaja"
            onChange={(e: any) => {
              handleChange('newLineEsilaulaja', e.target.value)
            }}
           >      
            {
            arrays.esilaulajat.map( (loc: any, i: number) =>
              <MenuItem
                key={i}
                value={loc[0]}
                >
                { loc[0] }
            </MenuItem>
            )
          }
        </Select>
      </FormControl>
      <FormControl sx={{ pt:1, pb:1 }}>
          <InputLabel> Valvoja</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="selectValvoja"
            value = { filters.newLineValvoja }
            name="Valvoja"
            onChange={(e: any) => {
              handleChange('newLineValvoja', e.target.value)
            }}
           >      
            {
            arrays.valvojat.map( (loc: any, i: number) =>
              <MenuItem
                key={i}
                value={loc[0]}
                >
                { loc[0] }
            </MenuItem>
            )
          }
        </Select>
      </FormControl>
     
      <FormControl sx={{ pt:1, pb:1 }}>
          <InputLabel> Äänentoisto</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="selectÄänentoisto"
            value = { filters.newLineÄänentoisto}
            name= "Äänentoisto"
            onChange={(e: any) => {
              handleChange('newLineÄänentoisto', e.target.value)
            }}
           >      
            {
            arrays.aanitarkkailijat.map( (loc: any, i: number) =>
              <MenuItem
                key={i}
                value={loc[0]}
                >
                { loc[0] }
            </MenuItem>
            )
          }
        </Select>
      </FormControl>
      <FormControl sx={{ pt:1, pb:1 }}>
          <InputLabel> Tulkki</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="selectTulkki"
            value = { filters.newLineTulkki}
            name="Tulkki"
            onChange={(e: any) => {
              handleChange('newLineTulkki', e.target.value)
            }}
           >      
            {
            arrays.tulkit.map( (loc: any, i: number) =>
              <MenuItem
                key={i}
                value={loc[0]}
                >
                { loc[0] }
            </MenuItem>
            )
          }
        </Select>
      </FormControl>
      <Box sx={{ mt: 1, p: 1 }}>
        <ResponsiveDateTimePickers Alkupäivä = 'Alkupäivä' />
        {filters.newLineType === '' && filters.newLineStartDate !== '' &&
          <Box sx={{ mt:1 }}>
            <ResponsiveDateTimePickers Päättymispäivä = 'Päättymispäivä' />
          </Box>
        }
      </Box >
      <FormControlLabel
        control={<Switch checked={filters.newLinePmx} onChange={handleChange1} name="newLinePm" />}
        label="Päivämieheen"
      />
     
      <TextField
        sx={{ mt: 1, pl:1 }}
        label={'Päivämieheen'}
      
        rows={1}
        variant="outlined"
        fullWidth
        value={ filters.newLinePm }
        onChange={(e: any) => handleChange('newLinePm', e.target.value)}
      />
     
      <TextField
        sx={{ mt: 1, pl:1 }}
        label={'Selite'}
      
        rows={1}
        variant="outlined"
        fullWidth
        value={ filters.newLineSelite}
        onChange={(e: any) => handleChange('newLineSelite', e.target.value)}
      />
            <TextField
        sx={{ mt: 1, pl:1 }}
        label={'Subject'}
      
        rows={1}
        variant="outlined"
        fullWidth
        value={ filters.newLineSubject}
        onChange={(e: any) => handleChange('newLineSubject', e.target.value)}
      />
      <Box sx = {{fontSize: 20 }}> 
        {filters.newLineType !== '' && filters.newLineType !== ' ' ?
          <Box sx={{ p: 1 }}>         
            {'Seuratyyppi'}: {            
            arrays.typesValues[ arrays.typesValues.findIndex((ar:any)=> ar[0] === filters.newLineType)][2]}
          </Box>
          :
          <Box sx={{ p: 1 }}> 
          {'Seuratyyppi'} : 'Muu tilaisuus';
          </Box>
          }
        <Box sx={{ p: 1 }}>
          {'Päivämieheen'}: {filters.newLinePm}
        
        </Box>
        <Box sx={{ p: 1 }}>
          {'Location'}: { filters.newLineLocation === ' ' ? ' ' : filters.newLineLocation}
        </Box>
        <Box sx={{ p: 1 }}>
          {'Vko'}: { filters.newLineVko}
        </Box>
        <Box sx={{ p: 1 }}>
          {'VkonPäivä'}: { Capitalize(filters.newLineVkonpäivä)}
        </Box>
        <Box sx={{ p: 1 }}>
          {'Alkupvm'}: { filters.newLineStartDate}
        </Box>
        <Box sx={{ p: 1 }}>
          {'Klo'}: { filters.newLineStartTime}
        </Box>
        <Box sx={{ p: 1 }}>
          {'Päättymispvm'}: { filters.newLineEndDate}
        </Box>
        <Box sx={{ p: 1 }}>
          {'Klo'}: { filters.newLineEndTime}
        </Box>
        <Box sx={{ p: 1 }}>
          {'Subject'}: { filters.newLineSubject}
        </Box>
        <Box sx={{ p: 1 }}>
          {'Selite'}: { filters.newLineSelite}
        </Box>
        <Box sx={{ p: 1 }}>
          {'Location'}: { filters.newLineLocation}
        </Box>
      </Box>
    
      <Box>
        <Box sx={{ mt: 1 }}>         
          <Box>
            <Button color="primary" variant="contained" sx={{ ml: 1 }}
              onClick= {submit}>
              {('Submit')}
            </Button>   
            <Button color="primary" variant="contained" sx={{ ml: 1 }}
              onClick= {paluu}>
              {('Paluu')}
            </Button>       
          </Box>          
        </Box>
      </Box>
    </Box>
  </Box>
  )
}

export default AddServiceLine;