import { SxProps } from '@mui/material';

export const containerPaperStyle: SxProps = {
  p: 1,
  pt: {
    xs: 1,
    md: 5,
  },
  ml: 0,
  zIndex: 9,
  pb: 5,
};

export const headerStyles:SxProps = {
  color: 'secondary.main', fontSize: { md: 40, xs: 25 }, fontWeight: 600
};
export const subHeaderStyles:SxProps = {
  color: 'secondary.main', fontSize: { md: 30, xs: 20 }, fontWeight: 600
};
export const lineStyles:SxProps = {
  color: 'secondary.main', fontSize: { md: 20, xs:15 }, fontWeight: 600
};

export const simpleContainerPaperStyle: SxProps = {
  p: 3,
  pt: 1,
  ml: 0,
  mt: 0,
  zIndex: 9,
  pb: 5,
  width: "140%"
};

export const colors = {
  backgroundGreen: '#E9F7EF',
  darkerGreen: '#3AB293',
  highlightItem: '#3AB293',
  navigationSelectedList: '#D0EBE2',
  red: '#CD5C5C'
};

export const addButtonStyle = {
  color: 'secondary.main',
  border: '1px solid',
  textTransform: 'none',
  fontWeight: 'bold'
};

export const backButtonStyle = {
  color: 'mainsecondary.',
  mb: 10,
  mt: 1
};

export const tabsStyle = {
  ml: 1.5,
  mt: 2,
  textTransform: 'none',
  minWidth: 0,
  marginRight: 1,
  backgroundColor: 'transparent',
  color: '#fff',
};

export const tabStyle: SxProps = {
  zIndex: 0,
  textTransform: 'none',
  borderRadius: '10px 10px 0 0',
  '&.Mui-selected': {
    zIndex: 1,
    color: '#fff',
    backgroundColor: colors.highlightItem,
  },
  marginRight: 1,
};

export const sidePanelEditButtonBox = {
  borderTop: 'thin solid gray',
  mt: 4,
};