/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */

import React, { useState, useEffect } from 'react' ;
import { useSelector , useDispatch} from 'react-redux';
import userServices from '../../services/userServices';
import { subHeaderStyles } from '../../styles/themeStylesSx';
import { setLoading, editFilterData } from '../../actions/filters';
import {RootState} from '../../store/ConfigureStore';
import { Box,  Snackbar, Table, TableBody, TableCell, TableRow, TableHead,  Typography, CircularProgress } from '@mui/material';
import submitDrivesToState from '../drives/submitDrivesToState';
import driveServices from '../../services/driveServices';
import { colors } from '../../styles/themeStylesSx';
type QueryDrivesType = {
  close: () => void,
  primaryEmail: string;
  users: string[]
};

const QueryDrives = (props: QueryDrivesType) => {
  const dispatch = useDispatch();
  const filters: any = useSelector((state: RootState)=> state.filters);
  const users: any = useSelector((state: RootState)=> state.users);
  const auth: any = useSelector((state: RootState) => state.auth);
  const [ loading, toggleLoading ] = useState(false);
  const token = `bearer ${auth.idToken}`;
  const [allLines, setAllLines] = useState<any[]>([]);
  const [switches, setSwitches ] = useState({readingDrives: false, readingGroups: false})
  let result: any = [];
  let resultGroup: any = [];

  useEffect( () => {
    if (filters.drive)  {

      const readGroups = async (primaryEmail: any) => {      
        try {
          const groups: any = await userServices
          .inWhichGroups(token, primaryEmail)
          if (Array.isArray(groups.data)) {
            return groups.data
          } else return[];
          
         
        }catch(error) {
          console.error('Error fetching readGroups in QueryDrives', error);
          return[];
        }

      }  

      const areYouReally = async( drive: any,  permissionc: any, emailAddress: any, groupsReturned: any) => {       
        const foundIndex = groupsReturned.findIndex((group: any) => group.email === permissionc.emailAddress);
        let isInGroup = false;
        if (foundIndex !== -1 ) {
          isInGroup = true;
        }
        if(isInGroup)  {
          const translated = TranslatePermission(permissionc.permissionRole);
          resultGroup.push({
            displayName: permissionc.displayName,
            translatedPermissionValues: translated,
            permissionType: permissionc.permissionType,
            kind: drive.kind ,
            driveId : drive.id,
            driveName: drive.driveName,
          });
        }
      }
 
      const createResults = async( drives: any, emailAddress: any, groupsReturned: any) => {
        for (const drive of drives) {
          let permissionList = [];
          try {
            permissionList = await driveServices
            .listPermissions(token,drive.driveId);
            if (!Array.isArray(permissionList)) {
              dispatch(editFilterData({error: true, errorMessage: ' Virhe ListPermissions, createResults, .listPermissions'}));
              permissionList = [];
            }
          }catch(error) {     
            console.error('Error fetching permissions', error);           
          };
          await permissionList.reduce(async(results: any, data: any )=> {
            await results;
            const work: any = data;
           
            let permissionTxt = work.emailAddress;
            permissionTxt= permissionTxt.replace('@hry.fi','@helsinginrauhanyhdistys.fi')
            if (work.permissionType === 'group' ) {
              await areYouReally (drive, work, emailAddress, groupsReturned );
            }
            else {
              if ((emailAddress === permissionTxt) )  {
                const translated = TranslatePermission(work.permissionRole);
                result.push({
                  displayName: work.displayName,
                  translatedPermissionValues:translated,
                  permissionType: work.permissionType,
                  kind: data.kind ,
                  driveId : drive.driveId,
                  driveName: drive.driveName,
                  // youAreOrganizer : youAreOrganizer,
                  // drivesPermissions: drivePerm,
                })
              }
              work.organizer = true;
            }; 
            return result         
          }, Promise.resolve([]));
        }
         
        toggleLoading(false);
        const work = [...result, ...resultGroup];
        work.sort(( a: any, b: any ) => {
          var x= a.driveName
          var y = b.driveName
          if(x < y) {return -1}
          else
          if (x > y) {return 1}
          else return 0
        });
        setAllLines(work);
      }
    
      const listDrives = async () => {
        setSwitches({readingGroups : false, readingDrives: true});
        const allDrives: any = await submitDrivesToState(token, dispatch, auth.uid, 'GoogleDrive', auth, users);
        setSwitches({readingGroups : true, readingDrives: false})
        const groupsReturned = await readGroups(props.primaryEmail);
       await createResults(allDrives, props.primaryEmail, groupsReturned);
      }
      toggleLoading(true);
      listDrives ();
    }
    else {
      dispatch(setLoading(false));
      toggleLoading(false);
    }
  }, []);

  if (loading && switches.readingDrives) return <div> <CircularProgress/> Waiting drivefolders</div> 
  if (loading && switches.readingGroups) return <div> <CircularProgress/> Waiting Google Groups</div> 
  if (loading) return <div> <CircularProgress/> Waiting waiting, be patient</div> 
  if (!filters.drive) return <div> Ei tarvittavia oikeuksia</div> 
  if (loading ) return   <CircularProgress />
  const handleClose: any = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(editFilterData({error: false, errorMessage:''}));
  } 

  const TranslatePermission = (permissionRole: any) => {
    const translatedPermissionValues = ['Sisällönhallinta','Ylläpitäjä','Katsoja', 'Tekijä','Kommentoija','Omistaja'  ]
    const translatedPermissionValue = translatedPermissionValues[['fileOrganizer','organizer','reader','writer','commenter','owner'].indexOf(permissionRole)]
    return translatedPermissionValue;
  }
  return( 
    <Box>
      { filters.error &&
        <div>
          <Snackbar
            open={filters.error}
            autoHideDuration={10000}
            onClose={handleClose}
            message= {filters.errorMessage}
          />
        </div>
      }

      <Box>
        <Typography sx={ subHeaderStyles }>{`${props.primaryEmail} :lla oikeudet seuraaviin kansioihin`}</Typography>
      </Box>  
      <Box>
        <Table sx={{ display: { xs: 'inline-table', md: 'inline-table' }, width: '100%' }}>
          <TableHead>
            <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
              {/* <TableCell sx={{width:5}}></TableCell>  */}
              <TableCell sx = {{width:10}}>{'Drive'}</TableCell>    
              <TableCell sx={{width:20}}>{'Nimi'}</TableCell>    
              <TableCell sx={{width:20}}>{'Oikeus'}</TableCell>          
              <TableCell sx={{width:20}}>{'Type'}</TableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
            {allLines.length > 0 &&
               allLines.map((user: any, i: number) => {
                const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
                return (
                  <TableRow  sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${i}_row119`}>
                    {/* <TableCell>
                      <IconButton  onClick={() => setState({openGroup: true, deleteUser: false, })}><VisibilityRounded /></IconButton>                    
                    </TableCell>  */}
                    <TableCell sx={{backgroundColor }}>{ user.driveName }</TableCell>               
                    <TableCell sx={{ width:20, backgroundColor }}>{ user.displayName }</TableCell>                    
                    <TableCell sx={{backgroundColor }}>{ user.translatedPermissionValues }</TableCell>
                    <TableCell sx={{backgroundColor }}>{ user.permissionType }</TableCell>
                  </TableRow>
                ) 
              })
              }
            
          </TableBody>  
        </Table>     
      </Box>
    </Box>
  )
}

export default QueryDrives;