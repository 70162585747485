/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import { Box, CircularProgress, Button,  Tooltip, Typography, Table, TableBody, TableHead, TableRow, TableCell, TextField } from '@mui/material';

import { RootState } from '../../../store/ConfigureStore';
import { lineStyles } from '../../../styles/themeStylesSx';
import {colors} from '../../../styles/themeStylesSx';
import submitGetSheetId from '../../service/submitGetSheetId';
import { editFilterData } from '../../../actions/filters';
import programService from '../../../services/programService';
import CompSheetToGroup from './CompSheetToGroup';

import SidePanel from '../../sidepanel/SidePanel';

type readPersons = {
  groupName: string;
  groupEmail: string;
  groupId: any;
  ownerName: string;
  close: () => void;
  token: string;
  listAll: boolean;
 
};
type GroupStateType = {
  open?: any;
  groupId?: any;
  close?: boolean;
  memberId?: string;
  memberName?: any;
  groupName?: any;
  group?: any;
  lineStart?: any
  mouseMove? : any;
  startLine?: any;
  endLine?: any;
  criterias: any,
  serviceProgramSheetId: any;
  sheetToAnalyze: any;
  compareGroup: boolean;
  groupEmail: string;
};

  const ReadPersonsFromSubSheets =  (props: readPersons ) => {
  const [loading, toggleLoading] = useState(false);
  const dispatch = useDispatch();
  const filters: any = useSelector((state: RootState) => state.filters);
  const auth: any = useSelector((state: RootState) => state.auth);
  const groups: any = useSelector((state: RootState) => state.groups);
  const [state, setState] = useState<GroupStateType>({  groupId: '', close: false, mouseMove: false,  
  criterias: {
    startColumn: '',
    endColumn: '',
    startLine: '',
    endLine: '',
    subSheet: '',
    firstLine:'',
  },
  serviceProgramSheetId: '',
  sheetToAnalyze: '', 
  groupEmail:'',
  compareGroup: false });
  const token = `bearer ${auth.idToken}`;
  const [sheetNames, setSheetNames ] = useState<any>([]);
  const [actuelGroup, setActuelGroup] = useState<any>();  
  const [clickMove, setClickMove] =useState<any>({count: 0}); 

  const readSubSheets = async( token: any, serviceYear: any, serviceProgramSheetId: any, criterias: any) => {
    const sheetWanted0 = await programService
    .getAll(token, serviceYear, serviceProgramSheetId, criterias.subSheet + '!' + criterias.startColumn+ criterias.startLine+ ':' + criterias.endColumn + criterias.endLine);
    if (sheetWanted0.length === 0) {
      console.log('No awailable data from sheets', serviceYear, serviceProgramSheetId, criterias.subSheet + '!' + criterias.startColumn+ criterias.startLine+ ':' + criterias.endColumn + criterias.endLine);
      setSheetNames([]);
    }
    else {
      const work = sheetWanted0.map((sh: any, i: number) => ({
      //lineColumn : workR[0].substr(0,1),
      lineNumber: Number(i) + Number(criterias.startLine),
      name: sh.length > 0 ? sh[0] : '',
      toimikunta: sh.length > 1 ? sh[1] : '',
      telNumber: sh.length > 2 ? sh[2] : '',
      servEmail: sh.length > 3 ? sh[3] : '',
      email: sh.length > 3 ? sh[3] : 'no email',
    }));
  setSheetNames(work);
    }
    
  }

  useEffect(() => {

    const getSubSheets = async (token: any, sheet: string, range: string , serviceProgramSheetId: any, groupId: any) => {
      let sheetsArray: any = [];
      // reads array of array matching google drive folder and keys to service program (sunsheet + range)
      const initialLines = await programService
        .getAll(token, filters.serviceYear, sheet,range)
        if (Array.isArray(initialLines)) {
          sheetsArray = initialLines;
        }
        else {
          sheetsArray = [];
          dispatch(editFilterData({error: true, errorMessage: initialLines}));
        }
      //reads fron read array line, which matches with groups email
      let sheetToAnalyze = 0;
      sheetToAnalyze = sheetsArray.findIndex((sh: any) =>  sh[0] === actuel.email );
      if (sheetToAnalyze === -1) {
        sheetToAnalyze = sheetsArray.findIndex((sh: any) =>  sh[0] === 'general@helsinginrauhanyhdistys.fi' );
      }
      if (sheetToAnalyze !== -1) {
        const criterias = ({
          startColumn: sheetsArray[sheetToAnalyze][2].substr(0,1),
          endColumn: sheetsArray[sheetToAnalyze][2].substr(sheetsArray[sheetToAnalyze][2].indexOf(':') +1, 1),
          startLine: sheetsArray[sheetToAnalyze][2].substr(1, sheetsArray[sheetToAnalyze][2].indexOf(':')-1),
          endLine: sheetsArray[sheetToAnalyze][2].substr(sheetsArray[sheetToAnalyze][2].indexOf(':') +2),
          subSheet: sheetsArray[sheetToAnalyze][1],
          firstLine: sheetsArray[sheetToAnalyze][2].substr(1, sheetsArray[sheetToAnalyze][2].indexOf(':')-1),
        }) ;
        setState({ ...state, criterias: criterias, sheetToAnalyze: sheetsArray[sheetToAnalyze][1], serviceProgramSheetId: serviceProgramSheetId,});
        //reads service program subsheet depending of pref findIndex (Found range, sheetId = serviceProgram sheetId)
        readSubSheets (token, filters.serviceYear, serviceProgramSheetId, criterias);       
      } else {
       dispatch(editFilterData({error: true, errorMessage: `Kysyttyä ryhmää ${actuel.email} ei määritelty parametreissa`})) 
      }
    }

    const readProgramSheet= async (groupId: any)  => {
      const doDispatch = false;
      const searchCriteria = `ryhmätarkistus${filters.serviceYear}`
      const sheetData = await submitGetSheetId(token, searchCriteria, dispatch, doDispatch, filters);
      const sheetId = sheetData.sheetId;
      const range = sheetData.range;    
      const serviceProgramSheetId = await submitGetSheetId(token, filters.serviceYear, dispatch, doDispatch, filters);
      await getSubSheets(token, sheetId, range, serviceProgramSheetId.sheetId, groupId );     
      dispatch(editFilterData({loading : false}));
      toggleLoading(false);
      return sheetData
    }

    const actuel = groups.find((gro: any) => {
      return gro.id === props.groupId;    
    });
    setActuelGroup(actuel);    
    toggleLoading(true);    
    readProgramSheet(actuel.id);
  },[props.groupId, filters.somethchanged ]);
  
  if (loading) return ( <div> lataa groupsmembereitä <CircularProgress/> </div>)  
  if (!filters.drive) return (<div> <br />. Ei vaadittuja oikeuksiaa</div>);

  const LineChangeStart = ( index: any ) => {
    const workCriterias = state.criterias;   
    clickMove.count = 0; 
    setClickMove({count: clickMove.count+1});
    setState({...state, criterias:{ ...workCriterias, startLine: index}});
    const work = Number(index);
    if (work < 1 || work > 999 || index === '') {
      dispatch(editFilterData({error: true, errorMessage: 'Anna arvo 1-999'}));
    }  
    setState({...state, criterias:{ ...workCriterias, startLine: index}});    
  }
  const LineChangeEnd = ( index: any ) => { 
    if (index === '' ) {
      dispatch(editFilterData({error: true, errorMessage: 'Anna kentttään arvo'})); 
      const workCriterias = state.criterias;   
      setState({...state, criterias: {...workCriterias, endLine: index}});
    
    } else 
    {
      const work = Number(index);
      if (work < 2 || work > 999) {
        dispatch(editFilterData({error: true, errorMessage: `Anna arvo 1-999 nyt: ${work}`}))
      }
      else {   
        const workCriterias = state.criterias;   
        setState({...state, criterias: {...workCriterias, endLine: index}});
        if (Number(workCriterias.startLine) < 1  ) {
          dispatch(editFilterData({error: true, errorMessage:'anna StartLinelle arvo'}));
        }
        else {
          workCriterias.endLine = index;
          //workCriterias.firstLine = i + index;
          //readSubSheets (token, filters.serviceYear, state.serviceProgramSheetId, workCriterias );
        } 
      }
    }
         
  }
  const LineClick = ( index: any ) => {
    const workCriterias = state.criterias;   
    if (clickMove.count === 0 ){
      setClickMove({count: clickMove.count+1});
      setState({...state, criterias:{ ...workCriterias, startLine: index}});    
    } else 
      if (clickMove.count === 1 ) {
        setClickMove({count:3 });
        setState({...state, criterias: {...workCriterias, endLine: index}});
        
        workCriterias.endLine = index;
        //workCriterias.firstLine = i + index;
        //readSubSheets (token, filters.serviceYear, state.serviceProgramSheetId, workCriterias );      
      }
      else{
        setClickMove({count:0 });
        //dispatch(editFilterData({somethchanged:  Math.random()}))
      }
    }
    const checkGroup = (sheetNames: any) => {
      setState({...state, groupEmail: props.groupEmail, compareGroup: true, groupId: props.groupId});
    }

  return (
    <Box>
      <Box>
      <SidePanel
        open={ state.compareGroup === true }
        close={ () => setState({ ...state, compareGroup: false })}
        >
        {state.compareGroup &&
          <CompSheetToGroup 
          groupId = {state.groupId } 
          groupEmail= {state.groupEmail} 
         
          sheetNames= {sheetNames} 
          close ={ () => setState({ ...state, compareGroup: false })}
          token= {token}
          ownerName= {props.ownerName}
          />
        }
        </SidePanel>
        </Box>
      {actuelGroup && 
        <Typography sx={ lineStyles }>{`Ryhmä ${actuelGroup.name} Seuraohjelma: ${filters.serviceYear},  alasivu ja  sarake ${state.sheetToAnalyze} ${state.criterias.startColumn }${state.criterias.startLine } - ${state.criterias.endColumn }${state.criterias.endLine } ` }</Typography>
      }
       <TextField
          sx={{ pt:1 }}
          label={'Startline'}
          type = "number"
          rows={1}
          variant="outlined"
          fullWidth
          value={ state.criterias.startLine  }   
          onChange={(e: any) => {
            LineChangeStart( e.target.value)}}     
        />
          <TextField
          sx={{ pt:1 }}
          label={'Endtline'}
          type= "number"
          rows={1}
          variant="outlined"
          fullWidth
          value={ state.criterias.endLine  }    
          onChange={(e: any) => {
            LineChangeEnd( e.target.value)}}     
        />
        <Tooltip title="Hae rivit seuraohjelman alasivulta" arrow>
          <Button variant="contained" color="primary" onClick = {(e) => { readSubSheets (token, filters.serviceYear, state.serviceProgramSheetId, state.criterias )} }>Hae rivt </Button>
        </Tooltip>
       
      <Box>
        {sheetNames.length> 0 &&
          <Table>      
            <TableHead>
              <TableRow>             
                <Tooltip title="Rivinumero seuraohjelmassa, klikkaa" arrow>
                <TableCell sx = {{ md: 30, xs: 20 , fontWeight:800}}>
                 rivinumero seuraohjelmassa
                </TableCell>
                </Tooltip>
                <Tooltip title="Nimi seuraohjelmassa" arrow>
                <TableCell sx = {{ md: 30, xs: 20 , fontWeight:800}}>
                  Nimi Seuraohjelmassa
                </TableCell>
                </Tooltip>
                <Tooltip title="Jäsenen tiedot seuraohjelman alasivulla" arrow>
                <TableCell sx = {{ md: 30, xs: 20 , fontWeight:800}}>
                Jäsenen tiedot seuraohjelman alasivulla
                </TableCell>
                </Tooltip>                
              </TableRow>
            </TableHead>
         
            <TableBody>
              {sheetNames &&
                // groups.find((group: any) => group.id === props.groupId)
                sheetNames.map((member: any, i: number) => {
                  const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
                  return (
                    <TableRow
                      key={i}>                     
                      <TableCell  sx={{
                          backgroundColor,
                          borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>                       
                        {/* {Number(i) + Number(state.criterias.firstLine) === Number(state.criterias.startLine) && clickMove.count === 1 ?
                          <Button variant = 'text' size='small' sx={{ p: 0, color: 'red', width:'5px', textTransform:'lowercase', flexFlow: 'wrap row'}} onClick={() => LineClick(Number(member.lineNumber)) }  >
                            { Number(member.lineNumber)}
                           </Button>
                           : */}
                        <Button variant = 'text' size='small' sx={{ p: 0, color: 'black', width:'5px', textTransform:'lowercase', flexFlow: 'wrap row'}} onClick={() => LineClick( Number(member.lineNumber)) }  >
                          {Number(member.lineNumber)}
                        </Button>
                        
                      </TableCell>
                      {member.name ? 
                        <TableCell  sx={{
                          backgroundColor,
                          borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>
                          {member.name}
                        </TableCell>
                        :
                        <TableCell sx = { member.nameSheet && member.nameSheet.includes('!!!') ? {fontWeight:800,  color:'red', backgroundColor }: {fontWeight: 100, backgroundColor} }>
                          {member.name}
                        </TableCell>                   
                      }
                      <TableCell  sx={{
                          backgroundColor,
                          borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>
                        {member.toimikunta}
                      </TableCell> 
                      <TableCell  sx={{
                          backgroundColor,
                          borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>
                        {member.telNumber}
                      </TableCell>      
                      <TableCell  sx={{
                          backgroundColor,
                          borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>
                        {member.servEmail ? member.servEmail : '' }
                      </TableCell>       
                    </TableRow>
                  )
                })
              }
                  
          </TableBody>
        </Table>
        
      }      
      </Box>
      <Tooltip title="Vertaa ylläolevaa listaa Analysoitavaan Google-group - ryhmään. Merkitsee yo listalta puuttuvat ja ehdottaa niiden poistamista Google-groupista" arrow>
        <Button variant="contained" color="primary" onClick = {(e) => {checkGroup(sheetNames)}} >CompareGroup </Button>
      </Tooltip>
                           
    </Box>
  )
}
export default ReadPersonsFromSubSheets