import { Container, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Outlet } from 'react-router-dom';
import { headerStyles, simpleContainerPaperStyle } from '../../styles/themeStylesSx';
//import useLocales from '../hooks/useLocales';

export default function SpeakersContainer() {
  //const { t } = useLocales();
  return (
    <Container>
      <Box>
        <Typography sx={ headerStyles }>{'Puhujien vapaat'}</Typography>
      </Box>
      <Paper sx={ simpleContainerPaperStyle }>
          <Outlet />
      </Paper>
    </Container>
  );
}