/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react'
import { useSelector, useDispatch  } from 'react-redux';
import { editFilterData } from '../../actions/filters';
import {RootState} from '../../store/ConfigureStore';
import userServices from '../../services/userServices';
import { addAllUsers } from '../../actions/users';
type DeleteUserType = {
  member?: any,
  group?: any,
  open?: boolean,
  openDelete: any,
  members?: any,
  close: () => void,
  primaryEmail: string,
  users: any,
};
const DeleteUser = (props: DeleteUserType) => {
  const dispatch = useDispatch();
  const close = props.close;
  const authh: any = useSelector((state: RootState) => state.auth);
  const filters: any = useSelector((state: RootState) => state.filters);
  const token: any = `bearer ${authh.idToken}`;

  useEffect( () => {        
      close();    
  }, [filters.somethchanged]);

  useEffect(() => {
    const deleteLine = async(email: string, token: string ) => {
      try {
        const response =  await userServices.deleteUserLine(token, email);
        if (response !== 'ok' ){
          dispatch(editFilterData({ error: true, errorMessage : `Poisto epäonnistui virhekoodi:  ${response}`}))
        }else {
          dispatch(editFilterData({somethchanged : Math.random(), error: true, errorMessage:  `Käyttäjä ${email} poistettu`}));
        }
      } catch( error: any) {
        dispatch(editFilterData({ erroro: true, errorMessage : 'something went wrong in userDeletion (DeleteUSerr '}))
      }
    }
    const email = props.primaryEmail;
    // eslint-disable-next-line no-undef
    if (window.confirm('Oletko varma: Poistat käyttäjän ' + email )) {
      const newUsers = props.users.filter((use: any) => {
        return use.primaryEmail !== email
      });   
      dispatch(addAllUsers(newUsers));
      deleteLine(email, token);   
    }
    else {
      dispatch(editFilterData({somethchanged : Math.random()}));
    }
  }, [])

  return (
    <div></div>
  )
}
export default DeleteUser
