/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */

import React, { useState, useEffect }  from 'react';
import { useSelector  } from 'react-redux';
import { CircularProgress, Box, Button, IconButton, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useNavigate } from "react-router-dom";

import ListGroupMembersSimple from './ListGroupMembersSimple';
import {RootState} from '../../../store/ConfigureStore';
import {subHeaderStyles} from '../../../styles/themeStylesSx';
import { colors } from '../../../styles/themeStylesSx';
import SidePanelLarge from '../../sidepanel/SidePanelLarge';
import ListGroupOwners from './ListGroupOwners';
import { VisibilityRounded } from '@mui/icons-material';

const DriveGroups = () => {
  const navigate = useNavigate();
  const filters = useSelector((state: RootState) => state.filters);
  const groups: any = useSelector((state: RootState)=> state.groups);
  const authorit: any = useSelector((state: RootState) => state.auth);
  const token: any = `bearer ${authorit.idToken}`;  

  type GroupStateType = {
    open?: boolean;
    group?: any;
    listOwners?: boolean;
    ownerWaiting?: boolean;

  };

  const [state, setState] = useState<GroupStateType>({ open: false, group: '', listOwners: false });

  useEffect( () => {
    if (groups.length === 0 ) {  
      navigate("/drive");
    }
  },[])

  if (filters.loading) return <div><CircularProgress /> <br />...latailee filtterssii  vieläkinnnn</div>

  if (groups.length !== 0) {
  if (!filters.write) return (<div> <br />. Ei vaadittuja oikeuksia drive managing</div>)
  }
  // const listOwners = () => {
  //   setState({...state, listOwners: true, ownerWaiting : true})
  // }
 
  return (
    <Box>
      {groups.length > 0 &&  (typeof(groups[0].members) !== undefined ) &&
        <Box>
          <SidePanelLarge
            open={ state.open === true }
            close={ () => setState({ open: false })}
          >  
            <Box>
              {state.open &&           
                <ListGroupMembersSimple group= {state.group }  close ={ () => setState({ open: false })} />
              }
            </Box>
          </SidePanelLarge>
          <SidePanelLarge
            open={ state.listOwners === true }
            close={ () => setState({ listOwners: false })}
          >
            <Box>
              {state.listOwners &&              
                <ListGroupOwners group= {state.group }  token= {token}
                close ={ () => setState({ listOwners: false, ownerWaiting: false })} />
              }
            </Box>
          </SidePanelLarge>
          <Box>
            <Typography sx={ subHeaderStyles }>{`Helsingin Rauhanyhdistyksen  Google-Ryhmät ${groups.length} kpl` }</Typography>
            <Box>
              <Button variant="contained" color="primary"
                onClick ={() => setState({ listOwners: true })}>Listaa omistajat</Button>
            </Box>
            <Table sx={{ display: { xs: 'inline-table', md: 'inline-table' }, width: '100%' }}>
              <TableHead>
                <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
                  <TableCell>{}</TableCell>
                  <TableCell>{'Group-email'} </TableCell>
                  <TableCell>{'Grop-name'} </TableCell>
                  {/* <TableCell>{'Grop-owner'} </TableCell> */}
                  <TableCell>{'Grop-description '} </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {groups.map((group: any, i: any) => {
                const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
                return  (
                  <TableRow sx={{backgroundColor, height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key = {`${i}_row7`}>
                    <TableCell sx={{
                      backgroundColor,
                      borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>
                      <IconButton  onClick={() => setState({ open: true, group: group  })}><VisibilityRounded /></IconButton>    
                    </TableCell>
                    <TableCell>{group.email}</TableCell>
                    <TableCell>{group.name}</TableCell>
                    <TableCell>{group.description}</TableCell>
                  </TableRow>
                )
              })
              }
            </TableBody>
          </Table>
        </Box>
      </Box>
      }
     </Box>
  )
}
export default DriveGroups;