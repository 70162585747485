/* eslint-disable semi */
/* eslint-disable linebreak-style */
import axios from 'axios'
import programService from './programService'
import submitGetSheetId from '../components/service/submitGetSheetId';
import{editFilterData} from '../actions/filters';

const baseUrl   = `${process.env.REACT_APP_API_URL}/api/decodedtoken`;

export const credentialsOnly = async (idToken: any) => {
  const token =  `bearer ${idToken}`
  const config: any = {
    headers: { Authorization: token }
  }
  const response = await axios.get(baseUrl, config)
  return response.data
}


export const decodedToken = async (credentials: any, dispatch: any) => {
  const token =  `bearer ${ credentials}`
  const config: any = {
    headers: { Authorization: `bearer ${ credentials}` }
  }
  const response = await axios.get(baseUrl, config)

  const retr = async (token: any, dispatch: any) => {
    let initialProgram : any = []
    const doDispatch = false;
    const sheetData: any = await submitGetSheetId(token,'authority', dispatch, doDispatch, 'auth');

    const initialLines = await programService
      .getAuth( token, sheetData.sheetId, sheetData.range)
    if (!Array.isArray(initialLines)) {
      initialProgram = []
      dispatch(editFilterData({error: true, errorMessage: initialLines}))
    } else {
      initialProgram = initialLines;
      };
    const returnable: any = ({
      credentials: response.data,
      authorities: initialProgram
    })
    return returnable

  }
  const returnable = retr(token, dispatch)
  return returnable
}

// eslint-disable-next-line import/no-anonymous-default-export
//export default { decodedToken} ;