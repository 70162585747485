/* eslint-disable semi */
/* eslint-disable linebreak-style */
//import React, { useState, useEffect }  from 'react'
import driveServices from '../../services/driveServices'
import {removeLines} from '../../actions/drives'
import database from '../../firebase/firebase';
import { editFilterData } from '../../actions/filters';
let drivesPermissions: any = [];
const submitPermissionDeleteOfDrive = async(token: any, driveId: any,
  permission: any,
  uid: any,
  role: any,
  dispatch: any) => {

  const replyOfDelete = await driveServices
    .permissiondelete(token,driveId, permission.permissionId);
    if (replyOfDelete === 403) {
      dispatch(editFilterData({error: true, errorMessage: 'Ei tarvittavia oikeuksia toimenpiteeseen!'}))

    }else {
      if (replyOfDelete !== 'ok') {
         dispatch(editFilterData({error: true, errorMessage: 'Jäsenen poisto ei onnistunut!!!!!'}))
      } else {
        dispatch(editFilterData({error: true, errorMessage: 'Jäsenen poisto onnistui'}))
      }
    } 
  
  
  await database.ref(`users/${uid}/driveList/${permission.drivesId}/permissions/${permission.id}`).remove()
    .then(() => {
      const driveId = permission.driveId
      dispatch(removeLines({driveId, permission }))
    })
    .catch((e: any) => {
      console.log('Did not remove data submitPermissionDeleteOfDrive',e)
    })
  return drivesPermissions;
}
export default submitPermissionDeleteOfDrive;