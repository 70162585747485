/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react'
import 'core-js/stable';
import {   useDispatch, useSelector  } from 'react-redux'
// eslint-disable-next-line no-unused-vars
import ServiceFormLine from './ServiceFormLine';
import {  setCalendarIds , haveToRead, serviceEdited } from '../../actions/filters';
import submitGetCalendarIdArr from '../calendars/submitGetCalendarIdArr'
import { CircularProgress } from '@mui/material';
import {RootState} from '../../store/ConfigureStore';
type UpdateServiceStrikeComponentType = {
  serviceId?: any,
  linenumber?: any,
  filters?: any[],
  close: () => void,
};

const UpdateServiceStrike = (props: UpdateServiceStrikeComponentType) => {
  // const [ speakers, setSpeakers ] = useState([]);
  const close = props.close;
  const [ loading, toggleLoading ] = useState(false);
  const [service, setService] = useState('');
  //const service: any = props.service;
  const auth: any = useSelector((state: RootState) => state.auth);
  const token = `bearer ${auth.idToken}`;
  const filters: any = useSelector((state: RootState) => state.filters);
  const tspeakers: any = useSelector((state: RootState) => state.speakers);
  const services: any = useSelector((state: RootState) => state.services);
  const dispatch = useDispatch();

  useEffect(() => {
    const findService = async (id: any) => {
    const service = await services.find((ser: any) => {
      return ser.serviceId === id 
    });
    setService(service);
    toggleLoading(false)
    return service;
    }
    toggleLoading(true);
    findService(props.serviceId)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.serviceId])

  useEffect(() => {
    if (filters.change) {
      dispatch(haveToRead(false))
      toggleLoading(true);

      const retr = async () => {
        const calendarDataArray1 = await submitGetCalendarIdArr(token)
        console.log('qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqAddServiceStrikeeee', calendarDataArray1)
        dispatch( setCalendarIds(calendarDataArray1))
        toggleLoading(false);
        return calendarDataArray1
      }
      retr ();
    }
    else toggleLoading(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {

    if (filters.serviceEdited) {
      close()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filters.serviceEdited])

  useEffect(() => {

    const disp = async (dispatch: any) => {
        await dispatch(serviceEdited(false))
      }

      disp(dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filters.serviceEdited])


  if (loading) return  <div><CircularProgress /> <br />...tarkistetaan oikeuksia</div>
  if (!filters.change) return (<div> <br />. Ei vaadittuja oikeuksia</div>)


  return (
    <div>
        <ServiceFormLine
          service={service}
          speakers={tspeakers}
          auth=   {auth}
          filters= {filters}
        />

    </div>


  )
}

export default  UpdateServiceStrike;
