/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
// import { v4 as uuidv4 } from 'uuid';
import database from '../firebase/firebase';

/* eslint-disable linebreak-style */
// SET_START_DATE
export const setStartDate = (startDate: any) => ({
  type: 'SET_START_DATE',
  startDate
});
export const setEndDate = (startDate: any) => ({
  type: 'SET_END_DATE',
  startDate
});

export const editService = (id: any, updates: any) => ({
  type: 'EDIT_SERVICE',
  id,
  updates
});

export const editStrike = (linenumber: any, updates: any) => ({
  type: 'EDIT_STRIKE',
  linenumber,
  updates
});
export const emptyService = () => ({
  type: 'EMPTY_SERVICE',

});

export const  startRemoveDatabase = (servYear: any) => {
  return (dispatch: any, getState: any ) => {
    const uid = getState().auth.uid;
    return database.ref(`users/${uid}/serviceYear/${servYear}/serviceProgram`).remove()
      .catch((e: any) => {
        console.log('Did not remove data services.ts')
      })
  }
}

export const startEditServiceProgram = (id: any, updates: any,servYear: any) => {
  const updates_save = updates;
  return ( dispatch: any, getState: any ) => {
    const uid = getState().auth.uid;
    return database.ref(`users/${uid}/serviceYear/${servYear}/serviceProgram/${id}`).update(updates_save).then(() => {
      dispatch(editService(id, updates_save));
    })
  }
}
export const addService= (service: any) => ({
  type: 'ADD_SERVICE_PROGRAM',
  service
});
export const loadOnce= (serviceList: any) => ({
  type: 'ADD_SERVICE_PROGRAM_ONCE',
  serviceList
});

export const onceUpdateServicesToRedux =  (servYear: any) => {
  return (dispatch: any, getState: any ) => {
    const uid =  getState().auth.uid;
    database.ref(`users/${uid}/serviceYear/${servYear}/serviceProgram`).remove()
      .then(() => {
        if (getState().services.length > 0) {
          // const empty = []
          dispatch(emptyService())

        }
      }).catch((e: any) => {
        console.log('Did not remove data servicesxxx.ts')
      })
      //startRemoveDatabase(servYear);
  const serviceList: any = []
  database.ref(`users/${uid}/serviceYear/${servYear}/serviceProgram`)
    .once('value')
    .then((snapshot: any) => {
      snapshot.forEach((childSnapshot: any) => {
        serviceList.push({
          serviceId:childSnapshot.key,
          ...childSnapshot.val()
        })
      })
      dispatch(loadOnce(serviceList));
    })


  }

}

export const startAddServiceProgram = (serviceProgramData = {},servYear= {} ) => {
  return (dispatch: any, getState: any ) => {
    const uid = getState().auth.uid;
    const {
      type ='',
      excluded='',
      Vko='',
      Vkonpäivä='',
      StartDate='' ,
      StartTime='',
      EndDate='',
      EndTime='',
      Pm='',
      subject='',
      Selite='',
      Location='',
      Toimikunta='',
      Private='',
      sspeaker='',
      Seuraisäntä='',
      Säestäjä='',
      Esilaulaja='',
      Valvontaryhmänvetäjä='',
      Englannintulkkaus='',
      Eestintulkkaus='',
      Viittomakielentulkkaus='',
      Äänentoisto='',
      Siivousryhmä='',
      Kassaryhmä='',
      linenumber='',
      strike='n',
      lastUpdater = '',
    }: any = serviceProgramData


    const service = { type, excluded, Vko, Vkonpäivä,  StartDate, StartTime,  EndDate,  EndTime, Pm, subject, Selite, Location, Toimikunta, Private, sspeaker, Seuraisäntä, Säestäjä,
      Esilaulaja, Valvontaryhmänvetäjä, Englannintulkkaus,  Eestintulkkaus,  Viittomakielentulkkaus, Äänentoisto, Siivousryhmä, Kassaryhmä, linenumber, strike, lastUpdater }
    return database.ref(`users/${uid}/serviceYear/${servYear}/serviceProgram`).push(service).then((ref: any)  => {
      //return database.ref('serviceProgram').push(service).then((ref: any)  => {
        dispatch(addService({
          id: ref.key,
          ...service
        }))
    })
  }

};

// REMOVE
export const removeSpeaker = ({ id }: any = {}) => ({
  type: 'REMOVE_Speaker',
  id
});
export const startEditStreak = (linenumber: any) => {
  return ( dispatch: any ) => {
    dispatch(editStrike(linenumber, {strike:true}));
  }
}