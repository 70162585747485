import submitGetSheetId from "../service/submitGetSheetId";
import submitSheetRetr from "../service/submitSheetRetr";
import ReadAndStoreServiceProgram from "./ReadAndStoreServiceProgram";
import AddFireToService from "../../firebase/AddFireToService";

export const ReadServiceProgram = async (dispatch: any, filters: any, token: any, auth: any, year: string) => {
  let strikeArray: any = [];
  const getServiceProgram = async (sheetid: any, range: any, year: any, strikeArray: any) => {
    await ReadAndStoreServiceProgram(sheetid, range, year, strikeArray, dispatch, filters, auth);  
  }

  const retr1 = async (token: any, sheetid: any, range: any, year: any, strikeArray: any) => {
    const doDispatch = true;
    const sheetsDataStriked: any = await submitGetSheetId(token,'striked'+year, dispatch, doDispatch, filters );
    const strikeArray1 = await submitSheetRetr(token,sheetsDataStriked.sheetId,sheetsDataStriked.range,strikeArray, dispatch);
    strikeArray = [...strikeArray1];
    const services = await getServiceProgram(sheetid,range,year,strikeArray);
    await AddFireToService(year, auth.uid, services, dispatch);         
  }

  const retr = async (token: any, year: any) => {
    const doDispatch = false;
    const serviceIdData: any = await submitGetSheetId(token,filters.serviceYear, dispatch, doDispatch, filters );
    await retr1( token, serviceIdData.sheetId, serviceIdData.range, filters.serviceYear, strikeArray );                 
  }
retr(token, year)
}