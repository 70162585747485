/* eslint-disable linebreak-style */
/* eslint-disable no-extra-semi */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
const usersInitialState = []


// eslint-disable-next-line import/no-anonymous-default-export
export default (state = usersInitialState, action) => {
  switch (action.type) {

  case 'ADD_USERS':
    return [
      ...state,
      action.usersData
    ];
  case 'ADD_ALL_USERS':
    state = [];
    return action.allUsers.map(user => {
      return user;
    })

  case 'EMPTY_USERS':
    return  usersInitialState;

  case 'EDIT_USER_GROUPS':
    return  state.map((user) => {
      if (user.primaryEmail === action.primaryEmail) {
        return {
          ...user,
          groups:action.groups
        };
      } else {
        return user;
      }
    });

    case 'EDIT_USER_DRIVES':
      return  state.map((user) => {
        if (user.primaryEmail === action.primaryEmail) {
          return {
            ...user,
            drives:action.drives

          };
        } else {
          return user;
        }
      });
  default:
    return state
  }
}


