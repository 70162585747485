import { useEffect}  from 'react';
import CheckAuthority from './CheckAuthority'

import { editFilterData} from '../../actions/filters';
import { useSelector, useDispatch } from 'react-redux'
import {RootState} from '../../store/ConfigureStore';

export default function CheckAuthorities() {
  const authorit: any = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    const checkWrite = async (authorit: any, act: string) => {
      const reply = await CheckAuthority(authorit, act)
        return reply
    }
    const checkAdmin = async (authorit: any, act: string) => {
      const reply = await CheckAuthority(authorit,act)
      return reply;
    }
    const checkRead = async (authorit: any, act: string) => {
      const reply = await CheckAuthority(authorit,act);
      return reply;
    }
    const checkDrive = async (authorit: any, act: string) => {
      const reply = await CheckAuthority(authorit,act)
        return reply;
    }
    const checkFree = async (authorit: any,act: string) => {
      const reply = await CheckAuthority(authorit,act);
      return reply;
    }
    const checkChange = async (authorit: any,act: string) => {
      const reply = await CheckAuthority(authorit,act)
      return reply;
    }
    const checkListCal = async (authorit: any,act: string) => {
      const reply = await CheckAuthority(authorit,act)
      return reply;
    }
    const checks = async () => {
      dispatch(editFilterData({admin: await checkAdmin (authorit,'admin'),
                            write: await checkWrite (authorit,'write'),
                            read: await checkRead (authorit,'read'),
                            drive: await checkDrive (authorit,'drive'),
                            freetime:  await checkFree (authorit,'freetime'),
                            change: await checkChange (authorit,'change'),
                            listCal: await checkListCal (authorit,'listcal')
      }));
    }

    if (authorit.email) {
      checks();
    }
  }, [dispatch, authorit]);

};
