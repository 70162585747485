import submitGetSheetId from "../service/submitGetSheetId";
import { editFilterData } from "../../actions/filters";

import moment from "moment";



const ChangeServiceYear = async (year: string, filters: any, authorit: any, dispatch: any) => {
  // const filters: any = useSelector((state: RootState) => state.filters);
  // const auth: any = useSelector((state: RootState) => state.auth);
  // const dispatch = useDispatch();
  
const token = `bearer ${authorit.idToken}`;

  const retr = async (authorit: any,year: string) => {
    const doDispatch = true;
    const sheetData = await submitGetSheetId(token,year,dispatch, doDispatch, filters );
    //if (filters.startDate.isBefore (moment(year,'year'))) {
    dispatch(editFilterData({
      sheetId: sheetData?.sheetId,
      range: sheetData?.range,
      columnSpeaker: sheetData?.columnSpeaker,
      columnAddInfo: sheetData?.columnAddInfo,
      subSheet: sheetData?.subSheet,
      speakerSubColumn: sheetData?.speakerSubColumn,
      speakerSubDayCol1: sheetData?.speakerSubDayCol1,
      speakerSubDayCol2: sheetData?.speakerSubDayCol1,
      subjectColumn:sheetData?.subjectColumn,
      haveToRead: true,
      serviceYear: year,
      startDate: filters.startDate.isBefore (moment(year,'year')) ? moment([year]) : moment().startOf('week'),
      endDate: filters.startDate.isBefore (moment(year,'year')) ? moment([year]).add(374,'day') :  moment().endOf('year').add(10,'days')
    }));
    return sheetData
  }
  retr (authorit, year);
}
export default ChangeServiceYear;


