
import React, { useEffect } from 'react';
import { useSelector , useDispatch} from 'react-redux';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import {Button, Snackbar} from '@mui/material';
import Paper from '@mui/material/Paper';
import {Box} from '@mui/material';
import {addMembersToGroup} from '../../actions/groups';
import {editFilterData, haveToReadDrive} from '../../actions/filters';

import groupServices from './../../services/groupServices';
import { RootState } from '../../store/ConfigureStore';

function not(a: readonly number[], b: readonly number[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly number[], b: readonly number[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function TransferList(arrays: any, setMembers: any, members: any, groupId: any, selectedGroup: any , close: any) {
  const [checked, setChecked] = React.useState<readonly any[]>([]);
  const array = arrays.map((ar: any) => ar.primaryEmail)
  const [left, setLeft] = React.useState<readonly any[]>([]);
  const [right, setRight] = React.useState<readonly any[]>([]);
  const auth: any = useSelector((state: RootState)  => state.auth);
  const filters: any = useSelector((state: RootState) => state.filters)
  const dispatch = useDispatch();
  const selected = selectedGroup;

  const token = `bearer ${auth.idToken}`;
    useEffect( () => {  
      setLeft(array)
      // setRight(members); 
  }, [arrays]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const submit = async () => {
    const callInsert = async (member: any) => {  
      try{                
        const response = await groupServices      
        .insertMemberToGroup(token, groupId, member, dispatch, editFilterData);

        if (response.status) {
          if (response.status === 200) {
            selected.push(response.data);      
            dispatch(addMembersToGroup(groupId, selected));
            dispatch(haveToReadDrive(true))   
            dispatch(editFilterData({error : true, errorMessage : `Lisäys/Lisäykset tehty` }))        
          }
        }    
      }catch (error) {
        console.error('errorr in indertmembertogroup :', error)
        return;
      }       
    }
    right.forEach ((member: any) => {
      callInsert(member);
    }) ;
    setRight([]);
  };
  const paluu = () => {
    close();
  }
  const handleClose: any = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(editFilterData({error: false, errorMessaage: ''}));
  }

  const customList = (items: readonly string[], right: any) => (
    <Box>   
      <Paper sx={{ width: 300, height: 430, overflow: 'auto' }}>
        <List dense component="div" role="list">
          {items.map((value: any, ii: any) => {
            const labelId = `transfer-list-item-${value}-label`;
            return (           
              <ListItem
                key={ii}
                role="listitem"
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${value }`} />
              </ListItem>
            );
          })}
        </List>
      </Paper>
    </Box>
  );

  

  return (
    <Box>    
      { filters.error &&
        <div>
          <Snackbar
            open={filters.error}
            autoHideDuration={6000}
            onClose={handleClose}
            message= {filters.errorMessage}
            />
        </div>
      }
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item>{customList(left, 'left')}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleAllRight}
              disabled={left.length === 0}
              aria-label="move all right"
            >
              ≫
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleAllLeft}
              disabled={right.length === 0}
              aria-label="move all left"
            >
              ≪
            </Button>
          </Grid>
        </Grid>
        <Grid item>{customList(right, 'right')}
        
        </Grid>
      </Grid>
      <Box>
        { right.length > 0 &&
          <Button color="primary" variant="contained" sx={{ ml: 1 }}
            onClick= {submit}>
            {('Submit')}
          </Button>   
        }
      <Button color="primary" variant="contained" sx={{ ml: 1 }}
        onClick= {paluu}>
        {('Paluu')}
      </Button>       
    </Box>          
  </Box>
  );
}
