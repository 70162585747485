/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
import axios from 'axios'

//const sendMessageUrl = 'http://localhost:3001/api/sendmessage';
const sendMessageUrl = `${process.env.REACT_APP_API_URL}/api/sendmessage`;

const sendMessage = async(token: string, from: string, to: string, subject: string, content: string ) => {
  const config = {
    headers: { Authorization: token },
  }
  const request =  axios.post( `${sendMessageUrl}/${from}`, {to: to, subject: subject, content: content}, config)
  return request.then(response => {
    return response.data
  })
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  sendMessage : sendMessage,

}