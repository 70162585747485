/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch  } from 'react-redux';
import { CircularProgress, Box, Button, Typography } from '@mui/material';
import { RootState } from '../../store/ConfigureStore';
import { subHeaderStyles } from '../../styles/themeStylesSx';
import calendarServices from '../../services/calendarServices';
import {editFilterData} from '../../actions/filters';


type aclDeleteMembersType = {
  calendarId: any,
  id: any;
  open?: any,
  close: () => void,
};
type GroupStateType = {
  open?: any;
  member?: any;
  group?: any;
  openDelete? : any;
  members? : any,
  addUser? : any;
  close: boolean;
  updateOk?: boolean;
};

const AclDeleteCalendarMember =  (props: aclDeleteMembersType ) => {

  const close = props.close
   const authorit: any = useSelector((state: RootState) => state.auth);
  const filters: any = useSelector((state: RootState) => state.filters);
  const [state, setState] = useState<GroupStateType>({ open: false, openDelete: false, addUser: false, members: [], member: '', group: '', close: false, updateOk: false});
  const dispatch = useDispatch();

  const token: any = `bearer ${authorit.idToken}`;
  useEffect( () => {
    if (state.close){
      setState({...state, close: false})
      close()
    };      
  }, []);

  useEffect( () => {
    if (state.updateOk){
      setState({...state, close: false})
      close()
    };      
  }, [state.updateOk]);
  
  if (filters.loading) return (<div><CircularProgress/>..members loading</div>);
  if (!filters.drive) return (<div> <br />. Ei vaadittuja oikeuksia</div>);
  // if (groups.length === 0)
  //   close()
  const submit = async () => {
    if (window.confirm('Oletko varma: Poistat ' + props.id  )) {
      try{
          await calendarServices
      .aclDeleteCalendarUser(  props.calendarId, props.id, token );

      setState({...state, close: true});
      setState({ ...state, updateOk: true});
      dispatch(editFilterData({somethchanged : Math.random()}));

      }catch(error) {
        console.log('errori');
        setState({ ...state, updateOk: false});
        dispatch(editFilterData({error: true, errorMessage: ' Roolin päivitysvihr AclDeleteFilterData'}));
      };    
    }
  };  

  return (
    <Box>
      {props.calendarId && props.id &&
       <Typography sx={ subHeaderStyles }>{`Poistetaan Kalenterin permission ${props.id} kalenterista ${props.calendarId}`}</Typography>
      }
     
      <Box>
        <Button sx= {{p: 2 }  } variant='contained' color="primary"
          onClick={() => {submit()}}>Poista permission</Button>
                 
      </Box>
    </Box>
  )
}
export default AclDeleteCalendarMember