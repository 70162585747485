import React, { useState, useEffect } from 'react';
import {editFilterData, setServiceYearListSpeakers, setLoading} from '../../actions/filters';

import moment from 'moment'
import {   useDispatch, useSelector  } from 'react-redux';
import { Box, Typography, CircularProgress, Button, Tooltip,  FormControlLabel, Checkbox  } from '@mui/material';
import {RootState} from '../../store/ConfigureStore';
import CreateGraphicsOfTurns from './CreateGraphicsOfTurns';
import CreateGraphicsOfTimes from './CreateGraphicsOfTimes';
import { headerStyles } from '../../styles/themeStylesSx';
import MouseOverPopOver from '../../utilities/MouseOverPopover';
import { ReadServiceAndSpeakersToRedux } from '../genUtilities/ReadServiceAndSpeakersToRedux';

type SpeakerStateType = {
  open?: boolean;
  speakerId?: number;
  add?: boolean;
  showLeaves: boolean;
};


const ServiceReports: any = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, setState] = useState<SpeakerStateType>({ open: false, showLeaves: false });
  const [createGraph, setCreateGraph] = useState( false)
  const [createTimeGraph, setCreateTimeGraph] = useState( false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ speakers, setSpeakers ] = useState([]);
  //const [ loading, toggleLoading ] = useState(false);
  const auth: any = useSelector((state: RootState) => state.auth);
  const filters: any = useSelector((state: RootState) => state.filters)
  const token = `bearer ${auth.idToken }`
  const dispatch = useDispatch()
  
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const popOverText = 'VUOROLKM: Graafi puhujien vuoroista vuorotyypeittäin, TIMELINE: Puhujien vuorot viikkojanalla, Näytä vapaat: Puhujien toivomat vapaa-ajat viikkokalenterissa'

  useEffect(() => {
  if (filters.loading)  <CircularProgress />

 })
  useEffect(() => {
    if (auth.idToken ) {
      setState({ ...state, open: false }); 
      const getSheetId = async() => {
        await ReadServiceAndSpeakersToRedux(dispatch, token, filters, auth);
        dispatch(setLoading(false));
      }
      
      dispatch( setServiceYearListSpeakers(moment().year() + 1));
      dispatch(setLoading(true));
      getSheetId()
    }
    else {
      dispatch(editFilterData({loading: false}))
    }
  }, [auth.idToken])
  if (filters.loading) return <div> <CircularProgress/> Loading serviceprogramstaff. Be patient.</div>
  if (!auth.idToken) return <div> <CircularProgress/> Kirjaudu järjestelmään (Reports)</div>
  if (!filters.admin) return <div> Eiriittäviä oikeuksia</div>
  if (!auth.idToken) return <div> Kirjaudu järjestelmään <CircularProgress /></div>; 
  let variantx:  "text" | "outlined" | "contained" | undefined = 'outlined';
  if (!createTimeGraph)
    { variantx = "outlined"}
  else
    {variantx = "contained"}
  let variantx1: "text" | "outlined" | "contained" | undefined = 'outlined';
  if (!createGraph)
    { variantx1 = "outlined"}
  else
    {variantx1 = "contained"}

  const createGr = (truefalse: boolean) => {
    truefalse = !truefalse;
    setCreateGraph(truefalse)
  }
  const createTimeGr = (gtruefalse: boolean) => {
    gtruefalse= !gtruefalse;
    setCreateTimeGraph(gtruefalse)    
  }
  const changeValue = (e: any) => {
    
    if (e.target.checked ) {
        setState(() => ({...state, showLeaves: e.target.checked}))
      } else
      setState(() => ({...state, showLeaves: e.target.checked}))
    } 
  return (
    <Box>
   
      <Typography sx={ headerStyles }

      >{filters.serviceYear}
      </Typography>
      <MouseOverPopOver popOverText = { popOverText } anchor = { anchorEl }/>
      <Box sx={{ height: '20px',  width: '200px', borderColor: 'secondary.main', pb:2 }}>
        <Box sx={{ display: 'flex', height: '45px', width: '200px', gap:2}}>
          <Tooltip title= "Valitse raportti puhujien vuorojen lukumääristä" arrow>
          <Button variant = {variantx1} color = "primary" sx={{width: '1500px',  fontSize: '10px'}}
            onClick = {(e: any) => { createGr(createGraph)}}>Vuorolkm</Button>
          </Tooltip>
          <Tooltip title= "Valitse raportti vuoroist aikjanalla puhujittain" arrow>
          <Button variant = {variantx }color = "primary" sx={{width: '1500px', fontSize: '10px'}}
            onClick = {(e: any) => { createTimeGr(createTimeGraph)}}>Aikajana</Button>
          </Tooltip>
             <FormControlLabel
           control={
            <Checkbox
              checked= {state.showLeaves}
              onChange= {(e) => {changeValue(e)}}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          label="Näytä vapaat"
          />
        </Box>
      </Box>
      <Box  >     
        {createGraph &&
          <CreateGraphicsOfTurns />
        }
        {createTimeGraph &&
          <CreateGraphicsOfTimes leaves = {state.showLeaves}/>
        }         
      </Box>
  </Box>
  )
}
export default ServiceReports;
