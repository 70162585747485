/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
//import React, { useState, useEffect ,useReducer, useContext } from 'react'
import calendarServices from '../../services/calendarServices'
import Moment from 'moment';
import {editFilterData } from '../../actions/filters';
import 'moment-timezone'
let calendarRows: any = []

const submitGenCalRead = async (calendarId: any, token: any,startdate: any, enddate: any, dispatch: any) => {
  const startdatea =  Moment(startdate, 'D-M-YYYY').format('YYYY-MM-DDTHH:mm' )
  const enddatea =   Moment(enddate, 'D-M-YYYY').format('YYYY-MM-DDTHH:mm' )
  //if (attendee === ' ') attendee = 'matti.friman@helsinginrauhanyhdistys.fi'
  const newObject = ({
    calendarId:calendarId,
    start: {
      'dateTime' : Moment.tz(startdatea, 'Europe/Helsinki').format(),
      'timeZone': 'Europe/Helsinki'
    },
    end: {
      'dateTime' : Moment.tz(enddatea, 'Europe/Helsinki').format(),
      'timeZone': 'Europe/Helsinki'
    },
  })
  let rows: any = [];
  await calendarServices
    .genericGetAll( token, newObject )
    .then(initialLines => {

      if (Array.isArray(initialLines)) {
        rows = initialLines
      }
      else {
        rows = [];

        dispatch(editFilterData({error: true, errorMessage: initialLines}))

      }
      calendarRows = rows

        // eslint-disable-next-line array-callback-return
        .sort((a: any, b: any) => {
          var x= Moment(a.start.dateTime).unix()
          var y = Moment(b.start.dateTime).unix()
          if (x < y) {return -1}
          if (x > y) {return 1}
          // eslint-disable-next-line eqeqeq
          if (x==y) {return 1}
        })
    })
    .catch(error => {console.log('erreoddd',error)
    })

  return calendarRows
}

export default submitGenCalRead;