/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import submitPermissionDeleteOfDrive from '../submitPermissionDeleteOfDrive';
import { haveToReadDrive, editFilterData } from '../../../actions/filters'
import { Box, Button, Typography } from '@mui/material';
import { CircularProgress } from '@material-ui/core';
import { RootState } from '../../../store/ConfigureStore';
import { subHeaderStyles } from '../../../styles/themeStylesSx';
import groupServices from '../../../services/groupServices';

type DeletePermPropsType = {
  driveId?: any;
  permission?: any;
  openDelete?: boolean;
  close:() => void;
}
type StateType = {
  driveId?: any;
  permission?: any;
}

const DeletePermissions =  (props: DeletePermPropsType) => {
  const close = props.close;
  const drives = useSelector((state: RootState) => state.drives);
  const authorit: any = useSelector((state: RootState) => state.auth);
  const filters = useSelector((state: RootState) => state.filters);
  const dispatch = useDispatch();
  const users: any = useSelector((state: RootState) => state.users);
  const authUserId = users.find((usr: any) => {
    return authorit.email === usr.primaryEmail
  });
  const [ loadingPerm, toggleLoadingPerm ] = useState(false);
  const [role,setRole] = useState(' ');
  const token = `bearer ${authorit.idToken}`;
  const [errorState, setErrorState] = useState(false);
  const [, setErrorMessage] = useState ('');
  const [closeOk, setCloseOk] = useState(false);

  let workPermission: any = '';
  const [state, setState] = useState<StateType>({permission: props.permission, driveId: props.driveId});
  if (props.permission ) workPermission = props.permission
  else workPermission = state.permission

  useEffect (()=> {
    if (!errorState && closeOk)
    close();
  },[errorState, closeOk] )

  useEffect(() => {
    if (!workPermission) {
      close();
    }
    else
    if (drives.length === 0 ) {
      close();
    };
    if (workPermission) {
      setRole(workPermission.permissionRole);
      setState({permission: workPermission, driveId: props.driveId});
    }
  },  [state.driveId, filters.driveLineEdited, workPermission, props.permission, loadingPerm])

  if (loadingPerm) return <CircularProgress/>

  if (!filters.drive) return (<div> <br />. Ei vaadittuja oikeuksia</div>);
  if (filters.driveLineEdited) close();

    const deletePermissionLine = async (role: any) => {

      const createResults = async (permission: any) => {
        try{
          const isInGroup = await groupServices
          .membersHasMember(token, permission!.emailAddress, authUserId.id);
          if (isInGroup) {
            dispatch(editFilterData({error: true, 
              errorMessage:'Et voi poistaa ryhmää jos se sisältää organizer-roolin sinulle'}));
          return isInGroup
            }
        }catch( error) {
          console.error('Error fetching drivelist', error)
          setErrorState(true);
          setErrorMessage('Error in membersHasMember proc')
          return error
        };
      }


      const deletePerm = async (permission: any) => {
       await  submitPermissionDeleteOfDrive(  token, state.driveId,
        permission,
        authorit.uid,
        role,
        dispatch);
        toggleLoadingPerm(false);
        setCloseOk(true);
      };

      toggleLoadingPerm(true);
      let isInGroup = false;
      if (state.permission.type === 'group') {
        isInGroup = await createResults(state.permission)
      }
      if (!isInGroup ) {
         await deletePerm(state.permission);
      } else {
        toggleLoadingPerm(false)
      }

      if (loadingPerm) return (<div><CircularProgress/>..permission loading</div>)

      dispatch(haveToReadDrive(true))
      setCloseOk(false);
    }
    const handleClose: any = (event: any, reason: any) => {
      if (reason === 'clickaway') {
        return;
      }
      setErrorState(false);
    }

  return (
    <Box>
  
      <Box>
        <Typography sx={ subHeaderStyles }>{`Poistetaan rooli: ${role} henkilöltä/ryhmältä`}</Typography>
        <Typography sx={ subHeaderStyles }>{` ${state.permission?.displayName}`}</Typography>

          <Box>
            <Button variant="contained" onClick={() => {setRole(role); deletePermissionLine(role)}} > {state.permission?.displayName} </Button>
          </Box>
      </Box>
    </Box>
  )
}

export default (DeletePermissions)
