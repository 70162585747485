/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
import groupServices from '../../../services/groupServices';
import {addGroups, emptyGroups} from  '../../../actions/groups';

const submitGroupsToState = async (token,dispatch) => {

  const readGr = async () => {
    const groupList = await groupServices
      .groupList(token)
      .catch( error => {
        console.error('Error fetching drivelist', error)
      })

    await setGroupList(groupList);
    return groupList;
  }

  const setGroupList = async (groupList) => {
    dispatch (emptyGroups({}))
    let allGroups = [];
    await groupList.forEach(  group => {
      dispatch(addGroups({
        kind : group.kind,
        id : group.id,
        etag: group.etag,
        email : group.email,
        name : group.name,
        directMembersCount : group.directMembersCount,
        description: group.description,
        adminCreated : group.adminCreated,
        nonEditableAliases : group.nonEditableAliases

      }))
      allGroups.push ({
        kind : group.kind,
        id : group.id,
        etag: group.etag,
        email : group.email,
        name : group.name,
        directMembersCount : group.directMembersCount,
        description: group.description,
        adminCreated : group.adminCreated,
        nonEditableAliases : group.nonEditableAliases
      })
    })
    return allGroups;
  }
  const allGroups = await readGr();
  return allGroups
}
export default submitGroupsToState