/* eslint-disable linebreak-style */
//import React, { useState, useEffect }  from 'react'
import programServices from './../../services/programService'
//import {startAddSheet} from '../actions/filters'
// import database from '../firebase/firebase';
const submitAddDataToSheet = (token, uid, sheetId, drivesx , sheetIdArray) => {
  let w_sheetId = ' '

  const updateSheets = async (token,sheetId,driveId, driveName, columnValues,  rowValues) => {
    w_sheetId = sheetIdArray.filter(wd => {
      if (wd[0] === driveId)
        return wd[0][1]
    })
    const resp =  await programServices
      .updateDataToSheets(token,sheetId,driveName, columnValues, rowValues, w_sheetId[0][1])
      .catch( error => {
        console.error('Error fetching permissions', error)
      })
    return resp
  }
  const columnValues =[ ['Nimi','Sähköposti','Rooli','Tyyppi']]
  // eslint-disable-next-line no-unused-vars
  let rowValues = drivesx.map (drive => {
    let rowValue = []
    drive.drivesPermissions.map(permsh => {
      const rowValueObj = ({
        displayName : permsh.displayName,
        emailAddress : permsh.emailAddress,
        translatedPermissionValues :permsh.translatedPermissionValues,
        type : permsh.type
      })
      rowValue.push(rowValueObj)
      return rowValue
    })
    const rowValuesArray = [...rowValue]
    const sortedRowValues = rowValuesArray.sort(( a,b ) => {
      var x = a.displayName
      var y = b.displayName
      if (x < y) {return -1}
      if (x > y) {return 1}
      else return 0
    })
    let lineArrayx = []
    sortedRowValues.forEach(row => {
      const lineArray =
        [
          row.displayName,
          row.emailAddress,
          row.translatedPermissionValues,
          row.type
        ]
      lineArrayx.push(lineArray)
    })
    updateSheets(token,sheetId,drive.driveId, drive.driveName, columnValues, lineArrayx)
  })
}
export default submitAddDataToSheet