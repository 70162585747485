/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
import React, { useState, useEffect } from 'react'
import { colors } from '../../styles/themeStylesSx';
import {   useDispatch, useSelector  } from 'react-redux';
import { Box, Button, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Typography, CircularProgress  } from '@mui/material';
import {RootState} from '../../store/ConfigureStore';
import UpdateSpeakerToProgram from '../service/UpdateSpeakerToProgram';
import { editFilterData } from '../../actions/filters';
import moment from 'moment';

type SpeakerStateType = {
  open?: boolean;
  speakerId?: number;
  add?: boolean;
  speaker?: string;
  close: () => void,
  moves: any,
};

const nameArray: any = ['kesk','su16','su18', 'la18', 'ke19', 'alue', 'lait', 'kirk', 'last', 'tall', 'raam', 'ahve', 'useita'];
const MoveTurns = (props: any) => {
  const clickMove = props.moves;
 const  close = props.close;
  const auth: any = useSelector((state: RootState) => state.auth);
  const filters: any = useSelector((state: RootState) => state.filters);
  const services: any = useSelector((state: RootState) => state.services);
  const speakers = useSelector((state: RootState)=> state.speakers);
  const [state, setState] = useState<any>([false,false,false,false,false]);

  const token = `bearer ${auth.idToken }`
  const dispatch = useDispatch();
  if (!filters.freetime || !auth.speakerName){
    close()
    return (
      <Box>
        <div> <br />. Eiole vaadittuja oikeuksia / Tai et ole kirjautunut </div>
      </Box>
      );   
   
  }  
  let acceptedServiceLine: any = [];
  const checkTypes = (x: any, typeService: any) => {
    if (typeService === x) return true
  }
  const handleClick = (i: any, e: any) => {
    e.preventDefault();
   const array: any = [false,false,false,false,false];

   array[i] = !array[i];
  setState(array);
  }
  let serviceLine = [];
  const serviceLine0 = services.filter((line: any) =>
    line.sspeaker === clickMove.from[0] && Number(line.Vko) === Number(clickMove.from[1])) ;
    serviceLine = serviceLine0;
    if (serviceLine.length === 0 && clickMove.from[1] > 52 ) {
      const yy: any = Number(moment(filters.serviceYear,'YYYY').format('YY')) + 1;
      const yyx: string = `1/${yy}`
      const yyy: string = yyx;
      const serviceLineNextYear = services.filter((line: any) => line.sspeaker === clickMove.from[0] && line.Vko === yyy);
      serviceLine = serviceLineNextYear;
    }
    if (serviceLine.length === 0 && clickMove.from[1] === 0 ) {
      const yy: any = '52';
      const yy1: any = '53'
      
      const serviceLinePrevYear = services.filter((line: any) => line.sspeaker === clickMove.from[0] && (line.Vko === yy || line.Vko === yy1 ));
      serviceLine = serviceLinePrevYear;     
    }
  const speakerLine = speakers.find((spe: any) => spe.speaker === clickMove.to[0] );
  if (!speakerLine) {
  }
  else {
    const serviceLineScanned = serviceLine.filter((line: any) => {
     return  !speakerLine.typesArray.some((type: any) => checkTypes(type, line.type));
    });

    const checkDates = (speakkeri: any, dateService: any) => {
      if (moment(dateService,'D.M.YYYY').isSameOrAfter(moment(speakkeri.apvm,'D.M.YYYY')) &&
        moment(dateService,'D.M.YYYY').isSameOrBefore(moment(speakkeri.ppvm,'D.M.YYYY')) ) {
        return true
      }
    };
    acceptedServiceLine = serviceLineScanned.filter((line: any) => {
      return !speakerLine.datesArrayFinal.some((dates: any) => checkDates(dates,moment(line.StartDate,'D.M.YYYY')))
    });
  }
  const submit = (from: any, to: any,  line: any) => {
    let workLine = line; 
    if (workLine.length > 1) {
      const ind = state.findIndex((ind: any) => ind === true)
      if (ind !== -1) {
        workLine = line[ind]
      }
    else {
      dispatch(editFilterData({error : true, errorMessage: 'jotai mätää Raportoinnissa: MoveTurns.tsx'}))
    } 

    } 
    else {
        workLine = line[0];
      }
    UpdateSpeakerToProgram(from, to, workLine, auth, filters, speakers, dispatch, )
  
    close()
  }

  const paluu = () => {
    close()

  }
  return (
    <Box>
      {clickMove.count} 
     { clickMove.from[0]}
     { clickMove.from[1]}
     { clickMove.to[0]}
     { clickMove.to[1]}
     <Box>
      <Box>
        <Table>   
          <TableHead>
              <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>          
                <TableCell>{'Sel'}</TableCell>
                <TableCell sx={{width:10}}>{'Siirretään vuoro'}</TableCell>
                <TableCell sx={{width: 10 }}>{'Päivämäärältä'}</TableCell>
                <TableCell sx={{width: 10 }}>{'Kello'}</TableCell>
                <TableCell sx={{ width: 10 }} >{'Tilaisuus'}</TableCell>
                <TableCell sx={({ width: 10 }) } >{'Puhujalle'}</TableCell>
                <TableCell sx={{ width: 10 }}>{'Rivino'}</TableCell>
              
              </TableRow>
            </TableHead>
          <TableBody>
            {
            acceptedServiceLine.map((rivi: any, i: any) => {
                const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
                return (
                  <TableRow key={`${i}${clickMove.from[0]}_rowx11`}>
                      <TableCell padding="checkbox"                       
                        >  
                        {  acceptedServiceLine.length > 1 &&
                          <Checkbox
                            //  aria-owns = { open ? 'mouse-over-popover' : undefined}
                            // aria-haspopup = "true"
                            // onMouseEnter={handlePopoverOpen6}
                            // onMouseLeave={handlePopoverClose6}
                            onClick={event => handleClick(i, event)}
                            className="selectCheckbox"
                            checked={state[i]} 
                            key={`${i}${clickMove.from[0]}_rowx103`}
                          />
                        }
                        </TableCell>
                    <TableCell  sx={{ backgroundColor: backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15 , borderBottomRightRadius: 15, borderTopRightRadius: 15 ,
                      fontWeight: 'light' }} key={`${i}${clickMove.from[0]}_rowx12`}>
                      {clickMove.from[0]}
                    </TableCell>
                    <TableCell  sx={{ backgroundColor: backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15 , borderBottomRightRadius: 15, borderTopRightRadius: 15 ,
                      fontWeight: 'light' }} key={`${i}${clickMove.from[0]}_rowx13`}>
                      {rivi.StartDate}
                    </TableCell>
                    <TableCell  sx={{ backgroundColor: backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15 , borderBottomRightRadius: 15, borderTopRightRadius: 15 ,
                      fontWeight: 'light' }} key={`${i}${clickMove.from[0]}_rowx131`}>
                      {rivi.StartTime}
                    </TableCell>
                    <TableCell  sx={{ backgroundColor: backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15 , borderBottomRightRadius: 15, borderTopRightRadius: 15 ,
                      fontWeight: 'light' }} key={`${i}${clickMove.from[0]}_rowx14`}>
                      {nameArray[rivi.type]}
                    </TableCell>
                    <TableCell  sx={{ backgroundColor: backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15 , borderBottomRightRadius: 15, borderTopRightRadius: 15 ,
                      fontWeight: 'light' }} key={`${i}${clickMove.from[0]}_rowx15`}>
                    {clickMove.to[0]}
                    </TableCell>
                    <TableCell  sx={{ backgroundColor: backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15 , borderBottomRightRadius: 15, borderTopRightRadius: 15 ,
                      fontWeight: 'light' }} key={`${i}${clickMove.from[0]}_rowx17`}>
                    { rivi.linenumber}
                    </TableCell>
                  </TableRow> 
                )
              })  
            }     
          </TableBody>   
        </Table>
      </Box>
      <Box>
        <Box sx={{ mt: 1 }}>         
          <Box>
            {acceptedServiceLine.length === 0 ?  
              <Button color="primary" variant="contained" disabled sx={{ ml: 1 }}
                onClick= {() => submit( clickMove.from[0],  clickMove.to[0], acceptedServiceLine)}>
                {('Tee siirto')}
              </Button>
            :
              <Button color="primary" variant="contained" sx={{ ml: 1 }}
               onClick= {() => submit( clickMove.from[0],  clickMove.to[0], acceptedServiceLine)}>
               {('Tee siirto')}
              </Button>            
            }
             
            <Button color="primary" variant="contained" sx={{ ml: 1 }}
              onClick= {paluu}>
              {('Paluu')}
            </Button>       
          </Box>          
        </Box>
      </Box>
     </Box>


    </Box>

  )
}
export default MoveTurns;
