/* eslint-disable array-callback-return */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */

import driveServices from '../../services/driveServices';
import { editDrive, editYouAreOrganizer} from  '../../actions/drives'
import { v4 as uuidv4 } from 'uuid';
import database from '../../firebase/firebase';
import moment from 'moment';

const SubmitPermissionsOfDrive = async (token: any, dispatch: any, auth: any, driveId: any, driveName: any, inputDataBase: any, users: any) =>  {

  let drivelist: any = []
  let drivesPermissions: any = [];

  if (inputDataBase === 'Firebase') {
    await database.ref(`users/${auth.uid}/driveList`)
      .once('value')
      .then((snapshot: any) => {
        snapshot.forEach((childSnapshot: any) => {
          drivelist.push({
            driveId:childSnapshot.key,
            ...childSnapshot.val()
          })
        })
        const activeDrive = drivelist.filter((drive: any) => {
          return driveId === drive.driveId
        })
        const permissionlist = activeDrive[0].permissions
        if (!permissionlist) console.log('drive redux on rikki' );
        const objKeys = Object.keys(permissionlist)
        let youAreOrganizer = false;
        objKeys.forEach(obj =>   {
          const permission = permissionlist[obj]
          let permissionTxt = permission.emailAddress;
          permissionTxt= permissionTxt.replace('@hry.fi','@helsinginrauhanyhdistys.fi')
          if (permissionTxt === auth.email) {
            if (permission.role === 'organizer') {
              youAreOrganizer = true;
            }
          }

          permissionTxt = permission.emailAddress;
          permissionTxt= permissionTxt.replace('@hry.fi','@helsinginrauhanyhdistys.fi')
          let creationDate = users.find((user: any) => {
            if (user.primaryEmail === permissionTxt) return user.creationDate
          } )
          let organizer = false;
          if (permissionTxt === auth.email) organizer = true;
          let create = '';
          let lastlog='';
          if (permission.permissionType !== 'group'){
            if (creationDate){
              create = creationDate.creationDate;
              lastlog = creationDate.lastLoginDate;
            }
            if (!creationDate) {
              create = moment('1970-01-01').format('D.M.YYYY')
              lastlog= moment('1970-01-01').format('D.M.YYYY')
            }
          }
          const pArray = ({
            id: obj,
            driveId : driveId,
            driveName:  driveName,
            drivesId : permission.drivesId,
            permissionsId : permission.permissionsId,
            permissionId: permission.permissionId,
            permissionType: permission.type,
            permissionRole: permission.role,
            translatedPermissionValues: permission.translatedPermissionValues,
            type: permission.type,
            emailAddress:permission.emailAddress,
            role: permission.role,
            displayName: permission.displayName,
            creationDate:create,
            lastLoginDate : lastlog,
            organizer : organizer
          })
          drivesPermissions.push(pArray)
        })
        dispatch(editDrive({driveId, drivesPermissions }))
        dispatch(editYouAreOrganizer({driveId,youAreOrganizer}))
      })
  } else{
    const permissionlist = await driveServices
      .listPermissions(token,driveId)
      .catch( error => {
        console.error('Error fetching permissions', error)
      })
    let youAreOrganizer = false;
    await permissionlist.reduce(async(earlier: any, permission: any) => {
      await earlier;
      if (!permission.deleted) {
        let permissionTxt = permission.emailAddress;
        permissionTxt= permissionTxt.replace('@hry.fi','@helsinginrauhanyhdistys.fi')
        if (permissionTxt === auth.email) {
          if (permissionTxt === 'organizer') {
            youAreOrganizer = true;
          }
        }

        const translatedPermissionValues = ['Sisällönhallinta','Ylläpitäjä','Katsoja', 'Tekijä','Kommentoija','Omistaja'  ]
        const translatedPermissionValue = translatedPermissionValues[['fileOrganizer','organizer','reader','writer','commenter','owner'].indexOf(permission.permissionRole)]
        permissionTxt = permission.emailAddress;
        permissionTxt= permissionTxt.replace('@hry.fi','@helsinginrauhanyhdistys.fi')
        let creationDate = users.find((user: any) => { if (user.primaryEmail === permissionTxt) return user.creationDate} )
        let organizer = false;
        if (permissionTxt === auth.email) organizer = true;
        let create = '';
        let lastlog='';
        if (permission.permissionType !== 'group'){
          if (creationDate){
            create = creationDate.creationDate;
            lastlog = creationDate.lastLoginDate;
          }
          if (!creationDate) {
            create = moment('1970-01-01').format('D.M.YYYY')
            lastlog= moment('1970-01-01').format('D.M.YYYY')

          }}
        const pArray = ({
          id:uuidv4(),
          driveId : driveId,
          driveName:  driveName,
          permissionId: permission.permissionId,
          permissionType: permission.permissionType,
          permissionRole: permission.permissionRole,
          translatedPermissionValues: translatedPermissionValue,
          type: permission.permissionType,
          emailAddress:permission.emailAddress,
          role: permission.permissionRole,
          displayName: permission.displayName,
          creationDate:create,
          lastLoginDate : lastlog,
          organizer : organizer
        })
        drivesPermissions.push(pArray)
      }
    },{});
    // dispatch(startAddPermissions(driveId, drivesPermissions ))
    // dispatch(editYouAreOrganizer({driveId,youAreOrganizer}))
  }
  return drivesPermissions
}
export default SubmitPermissionsOfDrive