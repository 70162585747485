/* eslint-disable no-unused-vars */
/* eslint-disable semi */
// eslint-disable-next-line no-unused-vars
import React, {useState, useEffect }  from 'react';
//import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import {setTextFilter, startAddUserName,setSheetData, setLoading } from '../../actions/filters';
import CalendarListFilters from '../calendars/CalendarListFilters'
import {submitHryCals} from '../loadSpeakers/submitHryCals'
// eslint-disable-next-line no-unused-vars
// import ListCalDif from './ListCalDif'
import { Button, CircularProgress, FormLabel, Switch, Tooltip, Box,FormControl, FormGroup, FormControlLabel } from '@mui/material';
import checkChangesOfService from '../checkChangesOfServices';
import {RootState} from '../../store/ConfigureStore';
import submitGetSheetId from '../service/submitGetSheetId';

const UpdateCals =  () => {
  const [HryTilat,setHryTilat] = useState(false);
  const [Kahvio, setKahvio ] = useState(false);
  const [YläkerranKokousHuone,setYläkerranKokousHuone] = useState(false);
  const [Keittiö,setKeittiö]= useState(false);
  const [AutoTalli, setAutotalli]= useState(false);
  const [KerhoHuone, setKerhohuone] = useState(false);
  const [Arkisto, setArkisto]= useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [Juhlapyhät, setJuhlapyhät]= useState(false);
  const auth: any = useSelector((state: RootState) => state.auth)
  const filters: any = useSelector((state: RootState)=> state.filters)
  //const services = useSelector(state => state.services)
  const dispatch = useDispatch();
  const [loading1, setLoading1] = useState(false);
  const[restart,setRestart] = useState(false)
  const token = `bearer ${auth.idToken}`

  useEffect(() => {
    if (auth.idToken) {
      const readSheetData = async() => {
        const doDispatch = true;
        const sheetData =  await submitGetSheetId(token,filters.serviceYear,dispatch, doDispatch, filters );
        dispatch(setSheetData(sheetData));
        dispatch(setTextFilter(''))
        setRestart(true);
        await checkVersion();
        setLoading1(false);
      };

      const checkVersion = async () => {
        await checkChangesOfService(token,filters,auth)
        dispatch(startAddUserName())
      }
      setLoading1(true);

      readSheetData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[restart, filters.startDate,filters.endDate])

  if (!auth.idToken) return <div>You must login</div>
  if (loading1)  return <div><CircularProgress /> <br />...loading in updatecals</div>
  if (!filters.admin) return (<div> <br />. Ei vaadittuja oikeuksia CheckCAls</div>)


  const  handleCalChange = async (e: any) => {
    const name = e.target.name;
    let value = e.target.checked;
    switch (name) {
    case 'hryTilat':{
      // await loadMissingLine('hryTilat')
      setHryTilat(value)
      break
    }
    case 'keittiö':  {
      // await loadMissingLine('keittiö')
      setKeittiö(value)
      break
    }
    case 'kahvio':{
      // loadMissingLine('kahvio')
      setKahvio(value)
      break
    }
    case 'yläkerranKokousHuone' : {
      // loadMissingLine('yläkerranKokousHuone')
      setYläkerranKokousHuone(value)
      break
    }
    case 'arkisto' : {
      // loadMissingLine('arkisto')
      setArkisto(value)
      break
    }
    case 'autoTalli': {
      // loadMissingLine('autoTalli')
      setAutotalli(value)
      break
    }
    case 'kerhoHuone': {
      // loadMissingLine('kerhoHuone')
      setKerhohuone(value)
      break
    }
    case 'juhlapyhät': {
      // loadMissingLine('juhlapyhät')
      setJuhlapyhät(value)
      break
    }
    default:
      console.log('error CheckCals')
    }
  }
  const  UpdCalendars = async (e: any) => {
    setLoading1(true)
    dispatch(setLoading(true))
    // const name = e.target.name;
    // let value = e.target.checked;
    setRestart(!restart)
    await submitHryCals ( auth.uid, HryTilat,
      Kahvio, YläkerranKokousHuone,Keittiö,
      Arkisto, AutoTalli, KerhoHuone,
      dispatch, token, filters.serviceYear, filters);
    setLoading1(false)
    dispatch(setLoading(false))
  }

  return (

    <Box>
      <Box>
        <CalendarListFilters/>
      </Box>

      <Box>
        <Box>
          { filters.admin ?
            <Box>
              <FormControl component="fieldset"> </FormControl>
              <FormLabel component="legend">  Kalenterien päivitys seuraohjelmasta tiloittain</FormLabel>
              <Tooltip title="Tilakalenterien päivitys tilakalentereittain - varalla, jos Listauksen alla ei toimisikkaan, älä siis käytä!" arrow>

            
              <FormGroup row>
                <FormControlLabel
                  control={<Switch checked={HryTilat} onChange={ handleCalChange} name="hryTilat" />}
                  label="Hry tilat"
                />
                <FormControlLabel
                  control={<Switch checked={Keittiö} onChange={ handleCalChange} name="keittiö" />}
                  label="Keittiö"
                />
                <FormControlLabel
                  control={<Switch checked={Kahvio} onChange={ handleCalChange} name="kahvio" />}
                  label="Kahvio"
                />
                <FormControlLabel
                  control={<Switch checked={YläkerranKokousHuone} onChange={ handleCalChange} name="yläkerranKokousHuone" />}
                  label="Yläkerran kokoushuone"
                />
                <FormControlLabel
                  control={<Switch checked={Arkisto} onChange={ handleCalChange} name="arkisto" />}
                  label="Arkisto"
                />
                <FormControlLabel
                  control={<Switch checked={AutoTalli} onChange={ handleCalChange} name="autoTalli" />}
                  label="Autotalli"
                />
                <FormControlLabel
                  control={<Switch checked={KerhoHuone} onChange={ handleCalChange} name="kerhoHuone" />}
                  label="Kerhohuone"
                />
                {/* <FormControlLabel
                  control={<Switch checked={Juhlapyhät} onChange={ handleCalChange} name="juhlapyhät" />}
                  label="Juhlapyhät"
                /> */}
              </FormGroup>
              </Tooltip>
              { loading1 ? <CircularProgress/>
                :
                <Button variant="contained" color="primary"
                  onClick = {(e) => { UpdCalendars(e)}}>Päivitä kalenterit</Button>
              }
            </Box>
            :
            <div>
            </div>}

        </Box>
      </Box>
    </Box>
  )
}
export default UpdateCals ;