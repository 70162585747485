import { SxProps } from '@mui/material/styles';

export const layoutWidths: SxProps = {
  width: {
    md: '90%',
    xs: '90%',
  },
  textAlign: {
    md: 'left',
    xs: 'center',
  }
};

export const layoutFlexRow: SxProps = {
  display: 'flex',
  flexWrap: 'wrap',
};

export const mobileWidth50: SxProps = {
  width: {
    md: '90%',
    xs: '90%',
  },
};