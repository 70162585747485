/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */

import React, { useState, useEffect } from 'react' ;
import { useSelector , useDispatch} from 'react-redux';
import { lineStyles } from '../../styles/themeStylesSx';
import moment from 'moment';
import userServices from '../../services/userServices'
import { VisibilityRounded } from '@mui/icons-material';
import { editFilterData } from '../../actions/filters';
import {RootState} from '../../store/ConfigureStore';
import { Box, IconButton, Snackbar, Table, TableBody, TableCell, TableRow, TableHead, Typography, CircularProgress } from '@mui/material';
import { colors } from '../../styles/themeStylesSx';
import {AccountLineType} from '../../types/accountLine';
import SidePanel from '../sidepanel/SidePanel';
import ListGroupMembersLight from '../drives/groups/ListGroupMembers';


type QueryGroupsType = {
  close: () => void,
  primaryEmail: string;
};

const QueryGroups = (props: QueryGroupsType) => {
  const dispatch = useDispatch();
  const filters: any = useSelector((state: RootState)=> state.filters);
  const auth: any = useSelector((state: RootState) => state.auth);
  const groups: any = useSelector((state: RootState) => state.groups);
  const [ loading, toggleLoading ] = useState(false);
  const token = `bearer ${auth.idToken}`;
  const [inclGroups, setInclGroups] = useState<any[]>([])
  const [state, setState] = useState<AccountLineType>({
    creationDate: moment().format('D.M.YYYY'),
    lastLoginDate:'',
    id:'',
    primaryEmail: '',
    addUser: false,
    deleteUser: false,
    openDrive: false,
    openGroup: false,
    familyName: '',
    givenName: '',
    passWord: '',
    customerId: '',
  });

  useEffect (() => {  

    const readGroups = async (primaryEmail: string) => {  
      try {
        const groups: any = await userServices
        .inWhichGroups(token, primaryEmail)
        setInclGroups(groups.data); 
        toggleLoading(false);
      }catch(error) {
        console.error('Error fetching drivelist', error)
      }
    }
  toggleLoading(true);
  readGroups(props.primaryEmail);   
  },[]);  
    
  if (loading ) return <div><CircularProgress /> <br />loading...</div>
  const handleClose: any = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(editFilterData({error: false, errorMessage:''}));
  };

  if (inclGroups) <CircularProgress/>
  return( 
    <Box>
      { filters.error &&
        <div>
          <Snackbar
            open={filters.error}
            autoHideDuration={10000}
            onClose={handleClose}
            message= {filters.errorMessage}
          />
        </div>
      }
        <SidePanel
          open={ state.openGroup === true }
          close={ () => setState({ openGroup: false })}
        >
        <Box>
          {state.openGroup &&    
            <ListGroupMembersLight group= {groups.find((gro: any) => state.group?.email === gro.email )} close ={ () => setState({ openGroup: false })} />
          }
        </Box>
      </SidePanel>

      <Box>
        <Typography sx={ lineStyles }>{`${props.primaryEmail} on seuraavissa ryhmissä jäsenenä:`}</Typography>
      </Box>  
      <Box>
        <Table sx={{ display: { xs: 'inline-table', md: 'inline-table' }, width: '100%' }}>
          <TableHead>
            <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
              <TableCell></TableCell> 
              <TableCell>{'Email'}</TableCell>      
              <TableCell>{'Nimi'}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inclGroups.length > 0 &&           
              inclGroups.map((user: any, i: number) => {
                const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
                return (
                  <TableRow  sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${user.id}_row20`}>
                    <TableCell>
                      <IconButton  onClick={() => setState({openGroup: true, group: user })}><VisibilityRounded /></IconButton>                    
                    </TableCell> 
                    <TableCell sx={{backgroundColor }}>{ user.email }</TableCell>                  
                    <TableCell sx={{backgroundColor }}>{ user.name }</TableCell>
                  </TableRow>
                ) 
              })
              }
            
          </TableBody>  
        </Table>     
      </Box>
    </Box>
  )
}

export default QueryGroups;