import { CircularProgress , Container, Paper, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { Outlet } from 'react-router-dom';
import { headerStyles, simpleContainerPaperStyle, subHeaderStyles } from '../../styles/themeStylesSx';
import { RootState } from '../../store/ConfigureStore';
//import useLocales from '../hooks/useLocales';

export default function InfoContainer() {
  const auth: any = useSelector((state: RootState) => state.auth)
  //const { t } = useLocales();
  return (
    <Container>
      <Box sx = {{pt: 10}}>
        {auth.uid &&
          <Box>
            {/* <Typography sx={ headerStyles }>{'Siirrytään palveluun'}</Typography> */}
            <Typography sx={ subHeaderStyles }>{'siirrytään palveluun ....'}</Typography>
            <CircularProgress color="secondary" />
            <CircularProgress color="success" />
            <CircularProgress color="inherit" />
          </Box>
}
      </Box>
      {/* <Paper sx={ simpleContainerPaperStyle }> */}
          <Outlet />
      {/* </Paper> */}
    </Container>
  );
}