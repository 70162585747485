/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress, Box, IconButton, Tooltip,  Typography, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import { RootState } from '../../../store/ConfigureStore';
import { subHeaderStyles } from '../../../styles/themeStylesSx';
import { colors } from '../../../styles/themeStylesSx';
import SidePanelMedium from '../../sidepanel/SidePanelMedium';
import SidePanelLarge from '../../sidepanel/SidePanelLarge';
import EditIcon from '@mui/icons-material/Edit';
import UpdateGroupMemberLight from './UpdateGroupMemberLight';
import groupServices from '../../../services/groupServices';
import { ViewCompact, ViewCompactAlt } from '@mui/icons-material';
import programService from '../../../services/programService';
import submitGetSheetId from '../../service/submitGetSheetId';
import { editFilterData } from '../../../actions/filters';
import ReadPersonsFromSubSheets from './ReadPersonsFromSubSheets';

type ListOwnersType = {
  group: any,
  open?: any,
  close: () => void,
  token: string;
};
type GroupStateType = {
  groupName: string;
  groupEmail: string;
  changeRole: boolean;
  groupId: string;
  ownerName: string;
  token: string;
  compareServiceProgram: boolean;
  listAll: boolean;
};


const ListGroupOwners =  (props: ListOwnersType ) => {
  const groups: any = useSelector((state:RootState) => state.groups);
  const filters: any = useSelector((state: RootState) => state.filters);
  const authorit: any = useSelector((state: RootState) => state.auth);
  const [ loading, toggleLoading ] = useState(false);
  const [state, setState] = useState<GroupStateType>({  changeRole: false, compareServiceProgram: false,  groupEmail: ' ', groupName: ' ', groupId: ' ', token:' ', ownerName: ' ', listAll: false});
  const [ownerArray, setOwnerArray] = useState([]);
  const  token: any = `bearer ${authorit.idToken}`;
  const [groupCompareNames , setGroupCompareNames] = useState<any>([]);
  const [ sheetsToCompare, setSheetsToCompare] = useState<any> ([]);
  const dispatch= useDispatch();

  useEffect( () => { 
    const array : any = [];

    const doReducee = async () => {
      await groups.reduce(async(earlier: any, current: any) => {
        let groupOwners = [];
        try {
          groupOwners = await groupServices
            .groupRoleList(token,current.id, 'OWNER');
        } catch( error) {
          console.error('Error fetching drivelist', error)
        } 
        let workOwner = '';
        if (current.directMembersCount === '0') {
          workOwner = 'No owner'
        }
        else {         
          if (groupOwners.length === 0 ) {
            workOwner = 'Only Members'
          }
        };    
        if (groupOwners.length > 0) {
          workOwner = groupOwners[0].email
        };
    
        const result = ({
          id: current.id,
          email: current.email,
          name: current.name,
          owner: workOwner,
          ownerId: groupOwners.length === 0 ? 0: groupOwners[0].id,
          type: current.type,
          value: current.email,
        });
        array.push(result);
        return array;
      }, ([]));
      array.sort((a: any, b: any) => {
        var x = a.owner;
        var y = b.owner;
        if (x < y) {return -1};
        if (x > y) {return 1}
        else return 0;

      })
      setOwnerArray(array);
      toggleLoading(false)   
    }
      const getSubSheet = async (token: any, sheet: any, range: any, serviceProgramsheetId: any ) => {
        let sheetsArray: any = [];
        // reads array of array matching google drive folder and keys to service program (sunsheet + range)
        const initialLines = await programService
          .getAll(token, filters.serviceYear, sheet, range)
          if (Array.isArray(initialLines)) {
            sheetsArray = initialLines;
          }
          else {
            sheetsArray = [];
            dispatch(editFilterData({error: true, errorMessage: initialLines}));
          }
        //reads fron read array line, which matches with groups email
        return( sheetsArray);      
      }  
      const readSheetId = async() => {
        const doDispatch = false;
        const searchCriteria = `ryhmätarkistus${filters.serviceYear}`
        const sheetData = await submitGetSheetId(token, searchCriteria, dispatch, doDispatch, filters);
        const sheetId = sheetData.sheetId;
        const range = sheetData.range;
        const serviceProgramSheetId = await submitGetSheetId(token, filters.serviceYear, dispatch, doDispatch, filters);
        const sheetCompareArray = await getSubSheet(token, sheetId, range, serviceProgramSheetId.sheetId )
      
        dispatch(editFilterData({loading : false}))
        //dispatch(setSheetData(sheetData)) /* siirrä tää editfilterdata-kogtaan kutsujaaan*/
        toggleLoading(false);
        setSheetsToCompare(sheetCompareArray);
      }
    toggleLoading(true);
    doReducee();
    const returnable = readSheetId();    
  }, []);

  if (loading) return <div><CircularProgress/>Group owners loading</div>
  //if (state.waitOwners) return <div><CircularProgress/>.Finding owners Be patient</div>
  if (!filters.drive) return (<div> <br />. Ei vaadittuja oikeuksia</div>)

  return (
    <Box>
      <Box>  
        <SidePanelMedium
        open = { state.changeRole === true }
        close={ () => setState({ ...state, changeRole: false })}
        >
          <Box>
            {state.changeRole &&
              <UpdateGroupMemberLight groupId = {state.groupId } 
              groupEmail= {state.groupEmail}  
              groupName= {state.groupName} 
              close ={ () => setState({ ...state, changeRole: false })}
              token= {token}
              ownerName= {state.ownerName}
              />
            }
            </Box>
        </SidePanelMedium>
        <SidePanelLarge
        open = { state.compareServiceProgram === true }
        close={ () => setState({ ...state, compareServiceProgram: false })}
        >
          <Box>
            {state.compareServiceProgram &&
              <ReadPersonsFromSubSheets groupId = {state.groupId } 
              groupEmail= {state.groupEmail}  
              groupName= {state.groupName} 
              close ={ () => setState({ ...state, compareServiceProgram: false })}
              token= {token}
              ownerName= {state.ownerName}
              listAll = {state.listAll}
              />
            }
            </Box>
        </SidePanelLarge>
      </Box> 
      <Typography sx={ subHeaderStyles }>{`Omistajat Seuraohjelma:  ${filters.serviceYear}`}</Typography>
      
      <Table sx={{ display: { xs: 'inline-table', md: 'inline-table' }, width: '100%' }}>
        <TableHead>
          <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
          <Tooltip title="Keitä ryhmässäjäseninä" arrow>
          <TableCell>{'List'}</TableCell>
          </Tooltip>
          <Tooltip title="Tarkista, onko ryhmä ajan tasalla" arrow>
          <TableCell>{'Tark'}</TableCell>
          </Tooltip>
            <TableCell>{'Omistaja'}</TableCell>
            <TableCell>{'Nimi'}</TableCell>
            <TableCell>{'Email'}</TableCell>
          </TableRow>
        </TableHead>  
      
        <TableBody>
          {ownerArray.map((member: any , i: any)=> {         
            const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
            return (               
              <TableRow sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${member.id}_row9`}>               
                <TableCell sx={{
                  backgroundColor,
                  borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>                  
                  <IconButton  onClick={() => setState({ changeRole: true, compareServiceProgram: false, groupEmail: member.email, groupId: member.id, ownerName: member.owner, groupName: member.name, token: token , listAll: false  })}><EditIcon /></IconButton>
                </TableCell>
                <TableCell sx={{
                  backgroundColor,
                  borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>  
                  { (sheetsToCompare.findIndex((email: any) => email[0]  === member.email ) !== -1) ?                
                  <IconButton  onClick={() => setState({ changeRole: false, compareServiceProgram: true, groupEmail: member.email, groupId: member.id, ownerName: member.owner, groupName: member.name, token: token, listAll: false })}><ViewCompact /></IconButton>
                  :
                  <IconButton  onClick={() => setState({ changeRole: false, compareServiceProgram: true, groupEmail: member.email, groupId: member.id, ownerName: member.owner, groupName: member.name, token: token, listAll: true })}><ViewCompactAlt /></IconButton>
                  }

                </TableCell>
                <TableCell sx={{backgroundColor }}>{ member.owner }</TableCell>                 
                <TableCell sx={{backgroundColor }}>{ member.name }</TableCell>
                <TableCell sx={{backgroundColor }}>{ member.email }</TableCell>
              </TableRow>         
            )
          })
          }
        </TableBody>
      </Table>     
    </Box>
  )
}
export default ListGroupOwners;
