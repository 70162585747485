/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable quotes */
/* eslint-disable linebreak-style */
import * as firebase from 'firebase/firebase';

import  'firebase/auth'
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBpC0j3KMVxUfezCxxJBGFPn07WZS4O_Ho",
  authDomain: "speakersturns.firebaseapp.com",
  databaseURL: "https://speakersturns.firebaseio.com",
  projectId: "speakersturns",
  storageBucket: "speakersturns.appspot.com",
  messagingSenderId: "418877141769",
  appId: "1:418877141769:web:81ea8808000919b20ad9d8",
  measurementId: "G-4GEMJ51JG2"
};
firebase.initializeApp(firebaseConfig);

const database = firebase.database()

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export { firebase, googleAuthProvider, database as default };
