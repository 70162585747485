/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
import axios from 'axios'

//const directoryListUrl = 'http://localhost:3001/api/directoryList'



const directoryListUrl = `${process.env.REACT_APP_API_URL}/api/directoryList`;
const addAccountUrl = `${process.env.REACT_APP_API_URL}/api/insertUser`;
const groupQueryUrl = `${process.env.REACT_APP_API_URL}/api/inWhichGroups`;
const deleteUSerLine = `${process.env.REACT_APP_API_URL}/api/deleteUserLine`;


const userList = async (token) => {
  const config = {
    headers: { Authorization: token },
  }
  const request =  axios.get( directoryListUrl, config)
  .catch(response => {return `Error in getSheet ${directoryListUrl}`})
  return request.then(response => {
    return response.data
  })
};

const deleteUserLine = async (token, primaryEmail) => {
  const config = {
    headers: { Authorization: token },
  }
  try{
     const response = await axios.delete( `${ deleteUSerLine }/${ primaryEmail }`, config);
     return ('ok');
  }catch(error)  {
    const response = error.response.status;
    console.log('Error in deleteUserLine Errorrcode = ', error.response.status);
    return response; 
  }
};

const inWhichGroups = async (token, primaryEmail) => {
  const config = {
    headers: { Authorization: token },
  }
  try {
    const response =  await axios.post( groupQueryUrl,{query:`memberKey=${primaryEmail}` }, config);
    return response;
  }catch(error) {
    console.log('Error In inwhichgroupresponseis', error);
    return `Error in inwhichgroups ${groupQueryUrl}`
  };  
};

const insertAccount = async (token, accountData ) => {
  const config = {
    headers: { Authorization: token },
  }
  try{
     const response = await axios.post( addAccountUrl, 
    {creationDate: accountData.creationDate, 
      lastLoginDate: accountData.lastLoginDate, 
      primaryEmail:accountData.primaryEmail, 
      familyName: accountData.familyName,
      givenName: accountData.givenName,
      customerId: accountData.customerId,
      passWord: accountData.passWord,
      changePasswordAtNextLogin: true
    
    },
     config);
     return response
  }catch(error) {
    console.error(`Error in insertAccount ${error.response.status}`)
    const response = ({Error:  `Error ${error.response.status}`});
    return response;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  userList : userList,
  insertAccount: insertAccount,
  inWhichGroups: inWhichGroups,
  deleteUserLine : deleteUserLine,
}