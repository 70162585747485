
import moment from 'moment';
let speakerArray: any = [];
speakerArray = [];
const createSpeakersState = async (initialSpeakers: any) => {
  speakerArray = [];
  let workLineNumber: number = 0;
  
  initialSpeakers.forEach((sp: any) => {
    workLineNumber = workLineNumber + 1;
    if (typeof(sp[4]) === 'undefined' ) {
      sp[4] = ' ';
    }
    const datearray1 = sp[4].split(',')
    const datesarray2 = datearray1.map((da: any) => {
      if (da.indexOf('-') === -1 && sp[4] !== '' ) {
        const apvm = moment(da,'D.M.YYYY')
        const ppvm = apvm
        const daarr = ({
          apvm: apvm,
          ppvm:ppvm,
          duration: moment(ppvm,'D.M.YYYY').diff(moment(apvm,'D.M.YYYY'),'days'),
        })
        return daarr
      }
      else {
        if (sp[4] === '' ) {
          const daar = ({
            apvm: moment('31.12.2040','DD.MM.YYYY'),
            ppvm : moment('31.12.2040','DD.MM.YYYY'),
            duration : 0,
          })
          return daar
        } else
        {
          const apvm = da.slice (0,da.indexOf('-'))
          const ppvm= da.slice (da.indexOf('-')+1)
          const daarr= ({
            apvm: moment(apvm,'D.M.YYYY'),
            ppvm: moment(ppvm,'D.M.YYYY'),
            duration: moment(ppvm,'D.M.YYYY').diff(moment(apvm,'D.M.YYYY'),'days'),
          })
          return daarr
        }
      }
    })
    if (!sp[3]) sp[3] = ' ' ;
    if (!sp[4]) sp[4] = ' ' ;
    if (!sp[6]) sp[6] = ' ' ;
    if (!sp[8]) sp[8] = ' ' ;
    if (!sp[9]) sp[9] = ' ' ;
    if (!sp[10]) sp[10] = ' ' ;
    if (!sp[11]) sp[11] = ' ' ;
    if (!sp[12]) sp[12] = ' ' ;
    if (!sp[13]) sp[13] = ' ' ;
    if (!sp[14]) sp[14] = ' ' ;
    if (!sp[15]) sp[15] = ' ' ;
    if (!sp[16]) sp[16] = ' ' ;
    if (!sp[17]) sp[17] = ' ' ;
    if (!sp[18]) sp[18] = ' ' ;
    if (!sp[19]) sp[19] = ' ' ;
    speakerArray.push ({
      speaker: sp[0],
      mail: sp[2],
      phone: sp[1],
      types: sp[3],
      typesArray :  sp[3]=== '' ? [] :  sp[3].split(','),
      dates: sp[4],
      datesArray: sp[4] === '' ? ['1.12.2020'] : sp[4].split(','),
      datesArrayFinal : datesarray2,
      random:sp[5] ,
      linenumber: workLineNumber,
      servDatesx: sp[6] === '' ? ['1.12.2020'] : sp[6],
      servDates: sp[6].split(','),
      servDate1x:sp[8]  === '' ? ['1.12.2020'] : sp[8].split(','),
      servDate2x:sp[9]  === '' ? ['1.12.2020'] : sp[9].split(','),
      servDate3x:sp[10]  === '' ? ['1.12.2020'] : sp[10].split(','),
      servDate4x:sp[11]  === '' ? ['1.12.2020'] : sp[11].split(','),
      servDate5x:sp[12]  === '' ? ['1.12.2020'] : sp[12].split(','),
      servDate6x:sp[13]  === '' ? ['1.12.2020'] : sp[13].split(','),
      servDate7x:sp[14]  === '' ? ['1.12.2020'] : sp[14].split(','),
      servDate8x:sp[15] === '' ? ['1.12.2020'] : sp[15].split(','),
      servDate9x:sp[16]  === '' ? ['1.12.2020'] : sp[16].split(','),
      servDate0x:sp[17] === '' ? ['1.12.2020'] : sp[17].split(','),
      servDate10x:sp[18] === '' ? ['1.12.2020'] : sp[18].split(','),
      servDate11x:sp[19] === '' ? ['1.12.2020'] : sp[19].split(','),
      sortSDate: '',
      servDate1 : ['1.12.2020'],
      servDate2 : ['1.12.2020'],
      servDate3 : ['1.12.2020'],
      servDate4 : ['1.12.2020'],
      servDate5 : ['1.12.2020'],
      servDate6 : ['1.12.2020'],
      servDate7 : ['1.12.2020'],
      servDate8 : ['1.12.2020'],
      servDate9 : ['1.12.2020'],
      servDate10 : ['1.12.2020'],
      servDate11 : ['1.12.2020'],
      servDate0 : ['1.12.2020'],
      subjectArray : [],
      speakersTypesArray:[],
    servDatesLength : sp[6].split(',').length,
    servDate1Length : '' ? 0 : sp[8].split(',').length,
    servDate2Length : '' ? 0 : sp[9].split(',').length,
    servDate3Length : '' ? 0 : sp[10].split(',').length,
    servDate4Length : '' ? 0 : sp[11].split(',').length,
    servDate5Length : '' ? 0 : sp[12].split(',').length,
    servDate6Length : '' ? 0 : sp[13].split(',').length,
    servDate7Length : '' ? 0 : sp[14].split(',').length,
    servDate8Length : '' ? 0 : sp[15].split(',').length,
    servDate9Length : '' ? 0 : sp[16].split(',').length,
    servDate0Length : '' ? 0 : sp[17].split(',').length,
    servDate10Length : '' ? 0 : sp[18].split(',').length,
    servDate11Length : '' ? 0 : sp[19].split(',').length,
    })

  })
  return speakerArray;
}
      export default createSpeakersState;