import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {  Box, Button, FormControl, InputLabel, MenuItem, Select, Typography, TextField, } from '@mui/material';
import {subHeaderStyles} from '../../styles/themeStylesSx';
import groupServices from '../../services/groupServices';
import { RootState } from '../../store/ConfigureStore';
import {updateMember} from '../../actions/groups';
import {editFilterData} from '../../actions/filters';

type UpdatePropsType = {
  id: string,
  groupId: string;
  member: string;
  memberId: any;
  members: [];
  close(): void;
  name: string;
  memberName: any
  groupName: string;

}


const UpdateRole  = (props: UpdatePropsType) => {
  const roleWork: any = props.members.find((mem: any) =>  mem.email === props.memberName);
  const typeWork: any = props.members.find((mem: any) =>  mem.email === props.memberName)
  const [state, setState] = useState<any>({newRole: roleWork.role, updateOk: false});
  const auth : any = useSelector((state: RootState ) => state.auth);
  const token = `bearer ${auth.idToken}`;
  const close = props.close;
  const dispatch = useDispatch();

  useEffect(() => {
    if(state.updateOk ){
      close()
    }
    setState({newRole: roleWork.role})
  },[state.updateOk])

  const handleChange = (e: any) => {
    setState({...state, newRole: e.target.value})
  }

  const Update = async() => {
    const member = ({
      kind: roleWork.kind,
      etag: roleWork.etag ,
      id: roleWork.id,
      email: roleWork.email ,
      role: state.newRole,
      type: roleWork.type,
      status: roleWork.status
    })

    try{
      const result = await groupServices
      .updateGroupMember(token,props.groupId, props.memberId, { role: state.newRole } );
      dispatch(updateMember(props.groupId,props.memberId,member));
      dispatch(editFilterData({somethchanged : Math.random(), error: true, errorMessage: 'Rooli Päivitetty'}));
      close();
   
    }catch(error) {
      console.log('error update in role in UpdateRole' , error);      
      dispatch(editFilterData({error: true, erroroMessage: 'virhe roolin päovityksessä'}))
      setState({ ...state, updateOk: false});
    }   
  }

  return (
    <Box>
      <Box>
        <Typography sx= {subHeaderStyles}> {`Ryhmän ${props.name} henkilön: ${props.memberName}  roolin päivittäminen` }</Typography>
      </Box>

      <Box>
        <TextField
          sx={{ mt: 1, pl:1 }}
          label={'email'}
        
          rows={1}
          variant="outlined"
          fullWidth
          value={ props.memberName}
        />
        <TextField
          sx={{ mt: 1, pl:1 }}
          label={'GroupName'}
        
          rows={1}
          variant="outlined"
          fullWidth
          value={ props.name}
          //onChange={(e: any) => handleChange('newLinePm', e.target.value)}
        />
        <TextField
          sx={{ mt: 1, pl:1 }}
          label={'Rooli'}
        
          rows={1}
          variant="outlined"
          fullWidth
          value={ state.newRole}
          //onChange={(e: any) => handleChange('newLinePm', e.target.value)}
        />
        <TextField
          sx={{ mt: 1, pl:1 }}
          label={'Type'}
        
          rows={1}
          variant="outlined"
          fullWidth
          value={ typeWork!.type}
          //onChange={(e: any) => handleChange('newLinePm', e.target.value)}
        />
        <Box>
          <Typography sx= {subHeaderStyles}> {`Henkilön: ${props.memberName}  uusi rooli` }</Typography>
        </Box>
        <Box>
          <FormControl  fullWidth = {true}>
            <InputLabel>Valitse Rooli</InputLabel>
            <Select
              labelId="selectRole"
              value={state.newRole}
              name="rolechange"
              onChange={handleChange}
            >
              <MenuItem value="OWNER">   Omistaja    </MenuItem>
              <MenuItem value="MANAGER">   Manageri </MenuItem>
              <MenuItem value="MEMBER"> Jäsen</MenuItem>
              <MenuItem value = " "> </MenuItem>
            </Select>

          </FormControl>

        </Box>
        <Box>
          <Button variant= "contained" color="primary" 
          onClick = {(e) => {Update()}}> Päivitä rooli</Button>

        </Box>

      </Box>
    </Box>
  )
} 
export default UpdateRole;