/* eslint-disable linebreak-style */
const CheckAuthority = async (authorit: any, level: any) => {
  const ind = await authorit.userProfile.authorities.filter((line: any) =>  line[0] === authorit.userProfile.credentials.email)
  if (ind.length > 0) {
    const arr = ind[0][1].split(',')

    if (arr.some((au: any) => au === level)) {
      return true
    }
    else return false
  }
  else return false
}
export default CheckAuthority