/* eslint-disable linebreak-style */
/* eslint-disable no-extra-semi */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
const groupsInitialState = []


// eslint-disable-next-line import/no-anonymous-default-export
export default (state = groupsInitialState, action) => {

switch (action.type) {
  case 'UPDATE_MEMBER_OF_GROUP':
    return state.map(group => {
      if (group.id === action.groupid ) {
        const members = group.members.map(mem => {
          if (mem.id === action.memberid) {
            return action.member
          }
          else {
            return mem
          }       
        });
        return {...group, members}
      }
      else {
        return group
      }
    });
  case 'ADD_MEMBERS_TO_GROUP':
    return  state.map((group) => {

      if (group.id === action.id) {
        return {
          ...group,
          members: action.members
        };
      } else {
        return group;
      }
    });

  case 'ADD_GROUPS':
    return [
      ...state,
      action.groupsData
    ];

  case 'ADD_OWNER':
    return  state.map((group) => {
      if (group.id === action.owner.id) {
        return {
          ...group,
          owner: action.owner
        };
      } else {
        return group;
      }
    });

  case 'EMPTY_GROUPS':
    return  groupsInitialState;
  default:
    return state
  }
}