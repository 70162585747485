/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable linebreak-style */

/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
import moment from 'moment'
const filtersReducerDefaultState = {
  text: '',
  sortBy: ' ',
  startDate: moment().startOf('week'),
  endDate: moment(moment().endOf('year')).add(10,'days'),
  startDateRed: moment().startOf('week'),
  endDateRed: moment().add(15, 'M'),
  serviceYear: moment().year(),
  serviceYearSpeakers: moment().year(),
  sheetId:'',
  range:'',
  columnSpeaker: '',
  columnAddInfo:'',
  subSheet:'',
  speakerSubColumn:'',
  speakerSubDayCol1:'',
  speakerSubDayCol2:'',
  subjectColumn:'',
  loadSheet:false,
  //serviceYearChanged :true,
  programLoading :true,
  listSpeakers :false,
  spDays:15,
  speakerList: [],
  fromDataBase : 'GoogleDrive', 
  driveSheetId : ' ',
  sheetYears:[],
  calendarDataArray:[],
  admin:false,
  read:false,
  write:false,
  drive:false,
  freetime:false,
  change:false,
  listCal:false,
  haveToRead:true,
  haveToReadDrive:false,
  daysToState:[],
  typesToState:[],
  typesArray: [],
  loading:false,
  headerLoading:false,
  listAll:false,
  lastChangeTimestamp : ' ',
  turnsToFile:false,
  speakerEdited: false,
  serviceEdited: false,
  mustRender: false,
  logOff: false,
  driveLineDeleted: false,
  gmailBoss: false,
  gmailSoundObserver: false,
  gmailAccompanist: false,
  gmailLeadSinger: false,
  gmailSupervisor: false,
  gmailCasher: false,
  gmailStartDate:  moment().startOf('week').add(1, 'week'),
  gmailEndDate: moment(moment().startOf('week')).add(2,'weeks'),
  taskArray:{tasks: ['Isäntä', 'Äänitarkkailija', 'Säestäjä', 'Esilaulaja','Valvoja','Kassa'],
            selections: ['','','','','','',],
            stateKeys:['gmailBoss','gmailSoundObserver', 'gmailAccompanist', 'gmailLeadSinger','gmailSupervisor', 'gmailCasher'],
            columnNames:['Seuraisäntä','Äänentoisto','Säestäjä','Esilaulaja','Valvontaryhmänvetäjä','Englannintulkkaus','Kassaryhmä'],
          },
  error: false,
  errorMessage: '',
  newLineServiceId: '',
  newLineType: '',
  newLineExcluded: '',
  newLineVko: 0,
  newLineVkonpäivä: '',
  newLineStartDate:'',
  newLineStartTime: '',
  newLineEndDate: '',
  newLineEndTime: '',
  newLinePm: '',
  newLineSubject: '',
  newLineSelite: '',
  newLineLocation: '',
  newLineToimikunta: '',
  newLinePrivate: '',
  newLineSspeaker: '',
  newLineSeuraisäntä: '',
  newLineSäestäjä: '',
  newLineEsilaulaja: '',
  newLineValvoja: '',
  newLineTulkki: '',
  newLineEestintulkkaus: '',   
  newLineViittomakielentulkkaus: '',
  newLineÄänentoisto: '',       
  newLineSiivousryhmä: '',
  newLineKassaryhmä: '', 
  newLineLlinenumber: 0,
  newLineStrike: '', 
  newLinePmx: false,
  käyttäjä:'',
  somethchanged:1,
  userEmails:[],
  searchText: '',
  iniTypes:[],
  lineId: 0,
  // haveToReadProg:false

}
export default  (state = filtersReducerDefaultState, action) => {
  switch (action.type) {
  case 'SET_TEXT_FILTER':
    return { ...state,
      text: action.text
    };
  case 'SET_LIST_ALL':
    return { ...state,
      listAll: action.listAll
    };
  case 'SORT_BY_SPEAKER':
    return {
      ...state,
      sortBy: 'speaker'
    };
    case 'SORT_BY_CREATED':
      return {
        ...state,
        sortBy: 'created'
      };
  case 'SORT_BY_LINENUMBER':
    return {
      ...state,
      sortBy: ' '
    };
  case 'SORT_BY_TYPE':
    return {
      ...state,
      sortBy: 'type'
    };
  case 'SORT_BY_NAME':
    return {
      ...state,
      sortBy: 'name'
    };
  case 'SORT_BY_DRIVE':
    return {
      ...state,
      sortBy: 'drive'
    };
  case 'SORT_BY_ROLE':
    return {
      ...state,
      sortBy: 'role'
    };
  case 'SORT_BY_LAST_LOGIN':
    return {
      ...state,
      sortBy: 'lastLogin'
    };
  case 'SORT_BY_DATE':
    return {
      ...state,
      sortBy: 'date'
    };

  case 'SET_START_DATE':
    return {
      ...state,
      startDate: action.startDate
    };
  case 'SET_START_DATE_RED':
    return {
      ...state,
      startDateRed: action.startDate
    };
  case 'SET_END_DATE':
    return {
      ...state,
      endDate: action.endDate
    };
  case 'SET_END_DATE_RED':
    return {
      ...state,
      endDateRed: action.endDate
    };
  case 'SET_FROM_DATABASE':
    return {
      ...state,
      fromDataBase: action.fromDataBase
    };
  case 'HAVE_TO_READ':
    return {
      ...state,
      haveToRead: action.haveToRead
    };
  case 'SET_TURNSTOFILE':
    return {
      ...state,
      turnsToFile: action.turnsToFile
    };
  case 'HAVE_TO_READ_DRIVE':
    return {
      ...state,
      haveToReadDrive: action.haveToReadDrive
    };


  case 'SET_SERVICE_YEAR':
    return {
      ...state,
      serviceYear: action.serviceYear
    };
  case 'SET_SERVICE_YEAR_SPEAKERS':
    return {
      ...state,
      serviceYearSpeakers: action.serviceYearSpeakers
    };
  case 'SET_SHEET_DATA':
    return {
      ...state,
      sheetId : action.sheetData.sheetId,
      range:action.sheetData.range,
      columnSpeaker:action.sheetData.columnSpeaker,
      columnAddInfo: action.sheetData.columnAddInfo,
      subSheet: action.sheetData.subSheet,
      speakerSubColumn:action.sheetData.speakerSubColumn,
      speakerSubDayCol1:action.sheetData.speakerSubDayCol1,
      speakerSubDayCol2:action.sheetData.speakerSubDayCol1,
      subjectColumn:action.sheetData.subjectColumn,
      loadSheet:true
    };
  case 'SET_SHEET_YEARS':
    return {
      ...state,
      sheetYears : action.sheetYears
    };
  case 'SET_CALENDAR_IDS':
    return {
      ...state,
      calendarDataArray : action.calendarDataArray
    };
  case 'SET_SP_DAYS':
    return {
      ...state,
      spDays: action.spDays
    };

  case 'EDIT_FILTER_DATA':
    return {
      ...state,
      ...action.filterData
    };
  case 'SET_LOADING':
    return {
      ...state,
      loading: action.loading
    };
  case 'SET_HEADER_LOADING':
    return {
      ...state,
      headerLoading: action.headerLoading
    };
  case 'SET_ADMIN':
    return {
      ...state,
      admin: action.admin
    };
  case 'SET_READ':
    return {
      ...state,
      read: action.read
    };
  case 'SET_WRITE':
    return {
      ...state,
      write: action.write
    };
  case 'SET_DRIVE':
    return {
      ...state,
      drive: action.drive
    };
  case 'SET_FREETIME':
    return {
      ...state,
      freetime: action.freetime
    };
  case 'SET_CHANGE':
    return {
      ...state,
      change: action.change
    };
  case 'SET_LISTCAL':
    return {
      ...state,
      listCal: action.listCal
    };

  case 'ADD_DRIVE_SHEET':
    return {
      ...state,
      spreadSheetId : action.spreadSheetId
    };
  case 'EMPTY_DRIVE_SHEET':
    return {
      ...state,
      spreadSheetId : ''
    };
  case 'SET_DAYS_TO_STATE':
    return {
      ...state,
      daysToState:action.daysToState
    };
  case 'SET_TYPES_TO_STATE':
    return {
      ...state,
      typesToState:action.typesToState
    };
  case 'LIST_SPEAKERS':
    return {
      ...state,
      listSpeakers : action.listSpeakers
    };
  case 'ADD_SPEAKERLIST':
    return {
      ...state,
      speakerList : action.speakerList
    };
  case 'EDIT_TIMESTAMP':
    return {
      ...state,
      lastChangeTimestamp : action.lastChangeTimestamp
    };
  case 'SPEAKER_EDITED':
    return {
      ...state,
      speakerEdited : action.truefalse
    };
  case 'SERVICE_EDITED':
    return {
      ...state,
      serviceEdited : action.truefalse
    };
  case 'MUST_RENDER':
    return {
      ...state,
      mustRender : action.truefalse
      };

  default:
    return state
  }
}