/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable linebreak-style */
//Make speakers visible
import moment from 'moment'
export default (services: any, {text, sortBy, startDate, endDate}: any) => {
  return services.filter((service: any) => {
    const startWork: any = moment(startDate,'DD.MM.YYYY').format('D.M.YYYY')
    const endWork: any = moment(endDate,'DD.MM.YYYY').format('D.M.YYYY')
    const startDateMatch = moment( service.StartDate,'D.M.YYYY').isSameOrAfter(moment(startWork,'DD.MM.YYYY'))
    const endDateMatch = moment( service.EndDate,'D.M.YYYY').isSameOrBefore(moment(endWork,'DD.MM.YYYY'))
    const textMatch = service.sspeaker.toLowerCase().includes(text.toLowerCase())
    return startDateMatch  && endDateMatch && textMatch
  }).sort((a: any, b: any) => {
    if (sortBy === 'date') {
      return moment(a.StartDate,'D.M.YYYY').isBefore(moment( b.StartDate,'D.M.YYYY')) ? -1: 1
    }
    else {
      if (sortBy === 'speaker') {
        return a.sspeaker < b.sspeaker ? -1 : 1
      }
      else {
        if (sortBy === 'type') {
          return a.type < b.type ? -1 : 1
        }
        else {
          if (sortBy === ' ') {
            return a.linenumber < b.linenumber ? -1 : 1
          }
          else
            // eslint-disable-next-line array-callback-return
            return
        }
      }
    }
  })
}