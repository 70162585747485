//Read CalIds from sheeets
//Read calendars by sheetId
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress, Box,  IconButton, Snackbar, Typography, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import { VisibilityRounded, DeleteRounded } from '@mui/icons-material';
import ListIcon from '@mui/icons-material/List';
import EditIcon from '@mui/icons-material/Edit';
import { RootState } from '../../store/ConfigureStore';
import { subHeaderStyles } from '../../styles/themeStylesSx';
import SidePanel from '../sidepanel/SidePanel';
import { colors } from '../../styles/themeStylesSx';
import QueryGroups from '../users/QueryGroups';
import calendarServices from './../../services/calendarServices';
import ListGroupMembers from '../drives/groups/ListGroupMembers';
import AclDeleteCalendarMember from './AclDeleteCalendarMember';
import AclEditCalendarMember from './AclEditCalendarMember';
import {addMembersToGroup } from '../../actions/groups';
import submitListMembersOfGroup from '../drives/submitListMembersOfGroup';

type ListCalendarPermissionsType = {
  calendarId?: string,
  close: () => void,
  calendarName: string;
};

type PermissionsType = {
  items: itemsType[];
}
type itemsType={
  items: []
}
;

type CalendarStateType = {
  openPerm?: boolean;
  open?: boolean;
  openDrive?: boolean;
  calendarId?: string;
  add?: boolean;
  linenumber?: number;
  id?: any;
  permission?: any;
  openGroup?:boolean;
  group?: any;
  email?: any;
  deletePerm?: boolean;
  editRole?: boolean;
  permissison?: any;
  calendarName?: any;
};

const ListCalendarPermissions =  (props: ListCalendarPermissionsType) => {
  const dispatch = useDispatch();
  const filters = useSelector((state: RootState)  => state.filters);
  const groups = useSelector((state: RootState) => state.groups);
  const authorit: any = useSelector((state: RootState)  => state.auth);
  const token = `bearer ${authorit.idToken}`;
  const [permissions, setPermissions] = useState<PermissionsType[]>([])
  const [state, setState] = useState<CalendarStateType>({ openPerm: false, openDrive: false, openGroup: false, deletePerm: false, email:'', group: '' });
  const [ loading, toggleLoading ] = useState(true);
  const [errorState, setErrorState] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const calendarId = props.calendarId;  

  useEffect(() => {
    const membersFunc = async (id: any ) =>  {
      await submitListMembersOfGroup(token, id)
        .then((memberList) => {
        dispatch(addMembersToGroup(id,memberList))
      })
    }
      const readMembers =  async (allGroups: any) => {
      allGroups.forEach((group: any) =>{      
        membersFunc( group.id );
      });
      toggleLoading(false);
    };

    const retrieveCalendarUsers = async(calendarId: any) => {
      try {
        const userList = await calendarServices
          .aclListCalendar( calendarId, token);  

          const work = userList.sort((a: any, b: any) => {
            var x = a.scope.value
            var y = b.scope.value
            if (x<y) {return -1}
            if (x > y) {return +1}
            if (x === y) {return 1}
          })
          const work1 = work.map((aa: any ) => {              
            const translatedPermissionValues = ['Tee muutoksia','Näytä tiedot','Tee muutoksia ja hallitse', 'Näytä vain vapaat/varatut','Ei ivaltuutta' ]
            const workRole = translatedPermissionValues[['writer','reader','owner','freeBusyReader','none'].indexOf(aa.role)];
            return ({
              kind: aa.kind,
              etag: aa.etag,
              id: aa.id,
              scope: aa.scope,
              role: workRole,
              initialRole: aa.role,
            })
           
          })

          setPermissions(work1);
          toggleLoading(false);
        }catch(error) {
          console.error('Error fetching calendarlist', error)
          setErrorState(true);
          setErrorMessage('Error in membersHasMember proc')
          toggleLoading(false);
          return error
        }
        readMembers(groups);

        toggleLoading(false);
    }
    toggleLoading(true);
    retrieveCalendarUsers(calendarId);

  }, [ props.calendarId, filters.somethchanged ])

  if (loading ) return <div><CircularProgress /> <br />venaa älähosu</div>
  if (!filters.admin) return (<div> <br />. Ei vaadittuja oikeuksia</div>)

  const handleClose: any = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorState(false);
  }


  return (
    <Box>
      <SidePanel
       open={ state.openPerm === true }
       close={ () => setState({ ...state, openPerm: false })}
      >
        <Box>
          {state.openPerm &&
            <QueryGroups primaryEmail = {state.email } close ={ () => setState({ openPerm: false })} />
          } 
        </Box>
      </SidePanel>
      <SidePanel
       open={ state.deletePerm === true }
       close={ () => setState({ ...state, deletePerm: false })}
      >
        <Box>
          {state.deletePerm &&
            <AclDeleteCalendarMember calendarId= {calendarId} id= {state.id} close ={ () => setState({ deletePerm: false })} />
          } 
        </Box>
      </SidePanel>
      <SidePanel
        open={ state.openDrive === true }
        close={ () => setState({ ...state, openDrive: false })}
      >
        {/* <Box>
          {state.openDrive &&
            <QueryDrives primaryEmail = {state.email } users={users} close ={ () => setState({ ...state, openDrive: false })} />  
          }    
        </Box> */}
      </SidePanel>
      <SidePanel
        open={ state.openGroup === true }
        close={ () => setState({ ...state,  openGroup: false  })}
        >
          <Box>
            {state.openGroup &&            
              <ListGroupMembers group= {groups.find((gro: any ) => gro.email === state?.email) }  close ={ () => setState({ ...state,  openGroup: false  })} />  
            }
          </Box>
        </SidePanel>
      <SidePanel
        open={ state.editRole === true }
        close={ () => setState({  editRole: false  })}
      >
        <Box>
          {state.editRole &&            
            <AclEditCalendarMember calendarId= {calendarId} id= {state.id} permission= {state.permission} 
            calendarName= {state.calendarName} close ={ () => setState({ editRole: false  })} />  
          }
        </Box>
      </SidePanel>
      { errorState &&
        <div>
          <Snackbar
            open={errorState}
            autoHideDuration={6000}
            onClose={handleClose}
            message= {errorMessage}
            />
        </div>
      }
      <Typography sx={ subHeaderStyles }>{`${props.calendarName} : Henkilöt / Ryhmät`}</Typography>

      <Table sx={{ display: { xs: 'inline-table', md: 'inline-table' }, width: '100%' }}>
        <TableHead>
          <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
           
            <TableCell>Missä ryhmissä</TableCell>
            {/* <TableCell>Mihin driveihin</TableCell> */}
            <TableCell>Ryhmä</TableCell>
            <TableCell>Poista</TableCell>
            <TableCell>Role edit</TableCell>


            <TableCell>{'Email'}</TableCell>
            <TableCell>{'Role'}</TableCell>
            <TableCell>{'Type'}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

        { permissions.map((permissionc: any, i: number) => {

          const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
          return (            
            <TableRow sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${permissionc.id}_row2`}>              
              <TableCell sx={{
                backgroundColor,
                borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>
                  {permissionc.scope.type !== 'group' && permissionc.scope.type !== 'domain' &&
              <IconButton  onClick={() => setState({ openPerm: true, openDrive: false, open: false, deletePerm: false, email: permissionc.scope.value  })}><VisibilityRounded /></IconButton>
                  }
              </TableCell>              
              {/* <TableCell sx={{
                backgroundColor,
                borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>
                {permissionc.scope.type !== 'group' && permissionc.scope.type !== 'domain' &&
                  <IconButton  onClick={() => setState({ openDrive: true, open: false, openPerm: false, deletePerm: false, email: permissionc.scope.value })}><FolderRoundedIcon /></IconButton>
                }
              </TableCell> */}
              <TableCell sx={{
                backgroundColor,
                borderTopRightRadius: 15, borderBottomRightRadius: 15 }} >
                {permissionc.scope.type === 'group' &&
                  <IconButton onClick={() => setState({ openGroup: true, openDrive: false, openPerm: false, deletePerm: false, email: permissionc.scope.value })}><ListIcon/></IconButton>
                }
              </TableCell>    
              <TableCell sx={{
                backgroundColor,
                borderTopRightRadius: 15, borderBottomRightRadius: 15 }} >                    
                <IconButton onClick={() => setState({ openGroup: false, openDrive: false, openPerm: false, deletePerm: true, id: permissionc.id })}><DeleteRounded/></IconButton>
                </TableCell>    
              <TableCell sx={{
              backgroundColor,
              borderTopRightRadius: 15, borderBottomRightRadius: 15 }} >                    
                <IconButton onClick={() => setState({ openGroup: false, 
                  openDrive: false, 
                  openPerm: false, 
                  deletePerm: false, 
                  editRole: true, 
                  permission: permissionc,
                  calendarName: props.calendarName,
                  id: permissionc.id })}><EditIcon/></IconButton>
              </TableCell>         
              <TableCell sx={{backgroundColor }}>{ permissionc.scope.value }</TableCell>
              <TableCell sx={{backgroundColor }}>{ permissionc.role}</TableCell>
              <TableCell sx={{backgroundColor}}>{ permissionc.scope.type }</TableCell>                
            </TableRow>       
          )
        })}
      </TableBody>
    </Table>
  </Box>
  )
}
export default (ListCalendarPermissions)

