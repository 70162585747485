/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
//import React, { useState, useEffect ,useReducer, useContext } from 'react'
import programService from '../../services/programService'
import 'core-js/stable'

const updateSpeaker = async (token: any, id: any, servYear: any, sheetId: any, range: any, subSheet: any, newValue: any, columnStart: any, columnEnd: any, email: any) => {
  await programService
    .update(token,id, servYear,sheetId, range, subSheet, newValue, columnStart, columnEnd, email)
    .then(initialLines => {
    })
    .catch(error => {
      console.log('erreor in UpdateSpeaker',error)
    })
  return
}

export default updateSpeaker;