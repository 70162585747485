/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
import { v4 as uuidv4 } from 'uuid';
import database from '../firebase/firebase';


// export const editDrive1 = (driveId,permissionsData) => ({
//   type: 'EDIT_DRIVE1',
//   driveId,
//   permissionsData
// });
export const editDrive = (permissionsData) => ({
  type: 'EDIT_DRIVE',
  permissionsData
});
export const removeLines = (permissionsData) => ({
  type: 'REMOVE_LINES',
  permissionsData
});
export const editPermission= (driveId, permission) => ({
  type: 'EDIT_PERMISSION',
  driveId,
  permission
});
export const editYouAreOrganizer= (driveId, youAreOrganizer) => ({
  type: 'EDIT_YOU_ARE_ORGANIZER',
  driveId,
  youAreOrganizer
});
export const editDriveNames = (driveId,permissionsNames) => ({
  type: 'EDIT_DRIVE_NAMES',
  driveId,
  permissionsNames
});
export const editDrivePerm = (permissions) => ({
  type: 'EDIT_DRIVE_PERM',
  permissions
});
export const editDrivePermissionName = (driveId, permissionsname) => ({
  type:'EDIT_DRIVE_PERMISSION_NAME',
  driveId,
  permissionsname
});
export const editDrivePermId = (permissionsdata) => ({
  type:'EDIT_DRIVE_PERM_ID',
  permissionsdata
});
export const editDriveSheetId = (driveSheetId) => ({
  type:'EDIT_DRIVE_SHEETID',
  driveSheetId
});
export const addDrives= (drivesData) => ({
  type: 'ADD_DRIVES',
  drivesData
});
export const addDrivesOnce= (driveArray) => ({
  type: 'ADD_DRIVES_ONCE',
  driveArray
});


export const emptyDrives= () => ({
  type: 'EMPTY_DRIVES',

});

export const startAddDrives = (drivesData = {}) => {
  return (dispatch, getState ) => {
    const uid = getState().auth.uid;
    return database.ref(`users/${uid}/driveList`).push(drivesData)
      .then((ref)  => {
        const {
          drivesId = ref.key,
          kind = '' ,
          driveId ='',
          driveName = '',
          permissions = []	,

        } = drivesData
        dispatch(addDrives({
          drivesId : ref.key,
          ...drivesData
        }))
      })
  }
};

export const startAddDrivesOnce = (drivesDataArray = {}) => {
  return (dispatch, getState ) => {
    const uid = getState().auth.uid;
    drivesDataArray.driveArray.forEach(drivesData => {
      return database.ref(`users/${uid}/driveList`).push(drivesData)
        .then((ref)  => {
        const {
          drivesId = ref.key,
          kind = '' ,
          driveId ='',
          driveName = '',
          permissions = []	,

        } = drivesData
        dispatch(addDrives({
          drivesId : ref.key,
          ...drivesData
        }))
      })   
    })
  }
};

export const startAddPermissions = (driveId,pArrayArray = {}) => {
  const xdriveId = driveId
  const drivesPermissions = [];
  return (dispatch, getState ) => {
    const uid = getState().auth.uid;
    const drivesArr = getState().drives
    const permissionsDrive = drivesArr.filter(drivef => {
      return drivef.driveId === driveId
    })
    pArrayArray.forEach(perm => {
      const dataToFireBase = ({
        id: uuidv4(),
        driveId : perm.driveId,
        drivesId: permissionsDrive[0].drivesId,
        driveName:  perm.driveName,
        permissionId: perm.permissionId,
        permissionType: perm.permissionType,
        permissionRole: perm.permissionRole,
        translatedPermissionValues: perm.translatedPermissionValues,
        type: perm.type,
        emailAddress:perm.emailAddress,
        role: perm.role,
        displayName: perm.displayName,
        lastLoginDate : perm.lastLoginDate,
        creationDate: perm.creationDate,
        organizer : perm.organizer
      })
      database.ref(`users/${uid}/driveList/${permissionsDrive[0].drivesId}/permissions`).push(dataToFireBase)
        .then((ref)  => {
          const dataToState = ({
            id: ref.key,
            driveId : perm.driveId,
            drivesId: permissionsDrive[0].drivesId,
            driveName:  perm.driveName,
            permissionsId : ref.key,
            permissionId: perm.permissionId,
            permissionType: perm.permissionType,
            permissionRole: perm.permissionRole,
            translatedPermissionValues: perm.translatedPermissionValues,
            type: perm.type,
            emailAddress:perm.emailAddress,
            role: perm.role,
            displayName: perm.displayName,
            lastLoginDate : perm.lastLoginDate,
            creationDate: perm.creationDate,
            organizer : perm.organizer
          })
          drivesPermissions.push(dataToState)
        })
      dispatch(editDrive({
        driveId: xdriveId,
        drivesPermissions
      }))
    })
  }
};
export const  startRemoveDatabase = () => {
  return (dispatch,getState ) => {
    const uid = getState().auth.uid;
    database.ref(`users/${uid}/driveList`).remove()
      .then(() => {
        if (getState().drives.length > 0) {
          const empty = []
          dispatch(emptyDrives( {services:empty}))
        }
      }).catch((e) => {
        console.log('Did not remove data drives.js,',e)
      })
  }
}