import { Box } from "@mui/material";
import LoginPage from "../LoginPage";
import { Navigate } from 'react-router-dom';
import { layoutWidths } from "../../layout";
import { useSelector } from 'react-redux';
import {RootState} from '../../store/ConfigureStore';
import { startEditTypesArray } from "../../actions/speakers";

// type Props = {
//   children: JSX.Element,
// }

// Logic to check if authenticated and base details loaded
export default function LoginContainer() {
 
   const authorit: any = useSelector((state: RootState) => state.auth);
  // const filters: any = useSelector((state: RootState) => state.filters)
  //   let isAuthenticated: boolean = false;
  //   // if (filters.logOff ) {
  //   //   isAuthenticated = false;
    // }
    return (
      <div>
        {/* { !isAuthenticated  ? */}
          <Box sx={{
             ...layoutWidths, ml: 'auto', mr: 'auto', mt: 'auto'
            }}>
            <LoginPage />
          </Box>
          {/* :

          <Navigate to="/info" replace={true} />
        } */}
    </div>
    )
}