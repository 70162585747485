/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable no-undef */
/* eslint-disable linebreak-style */
import React from 'react'
import { connect } from 'react-redux'
import {  Box } from '@mui/material';
import { setTextFilter, setListAll, setStartDate, setEndDate } from '../../actions/filters';
import {DateRangePicker} from 'react-dates'

class CalendarListFilters extends React.Component <any, any>{
  state = {
    calendarFocused:null,
    selspeaker : '',
    speaker:this.props.filters.text,
    saveSpeaker: this.props.filters.text,
    checked: false,
    checkedQuest: false,
    checkedAll:false,
    workYear : this.props.filters.serviceYear,
    token : `bearer ${this.props.auth.idToken}`,
    hryTilat:false,
    keittiö:false,
    startDate:this.props.filters.startDate,
    endDate:this.props.filters.endDate,
    checkedToFile : false
  }


handleCheckboxChange = (event: any) => {
  if (this.state.checked === true) this.setState(() => ({checked:false}))
  else {
    this.setState(() => ({ checked: true }));
  }

  if (this.state.checked === false){
    this.props.dispatch(setTextFilter(''))
    this.setState(() => ({speaker:''})) }
  else {
    this.props.dispatch(setTextFilter(this.state.saveSpeaker))
    this.setState(() => ({speaker:this.state.saveSpeaker}))
  }
};
handleCheckboxChangeQuest = (event: any) => {
  if (this.state.checkedQuest === false) this.setState(() => ({checkedQuest:true}))
  else {
    this.setState(() => ({ checkedQuest: false }));
  }

  if (this.state.checkedQuest === true){
    this.props.dispatch(setTextFilter(''))
    this.setState(() => ({speaker:''})) }
  else {
    this.props.dispatch(setTextFilter('Vieras'))
    this.setState(() => ({speaker: 'Vieras'}))
  }
};
handleCheckboxChangeAll = (event: any) => {
  this.props.dispatch(setListAll(!this.state.checkedAll))
  if (this.state.checkedAll === true) {
    this.setState(() => ({checkedAll:false}))
  }
  else {
    this.setState(() => ({ checkedAll: true }));
  }

  if (this.state.checkedAll === false){
    this.props.dispatch(setTextFilter(''))
    //
    this.setState(() => ({speaker:''})) }
  else {
    this.props.dispatch(setTextFilter(this.state.saveSpeaker))
    this.setState(() => ({speaker:this.state.saveSpeaker}))
  }
};
  onDatesChange = ({startDate,endDate}: any) => {
    this.props.dispatch(setStartDate(startDate));
    this.props.dispatch(setEndDate(endDate));
  }
  onFocusChange = (calendarFocused: any) => {
    this.setState(( )=> ({calendarFocused}))
  }
  onChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(() => ({ [name]: value }));
  }
  handleCalChange = (e: any) => {
    const name = e.target.name;
    let value = e.target.checked;
    this.setState(() => ({ [name]: value }));
  }

  render () {
    return (

      <Box sx={{ m: 2 }}>
        <Box sx={{ mt: 2 }}>
          <DateRangePicker
            startDate = {this.props.filters.startDate}
            endDate = {this.props.filters.endDate}
            startDateId="MyDatePicker"
            endDateId="your_unique_end_date_id"
            onDatesChange={this.onDatesChange}
            focusedInput={this.state.calendarFocused}
            showClearDates ={true}
            onFocusChange={this.onFocusChange}
            numberOfMonths ={1}
            isOutsideRange = {() => false}
            firstDayOfWeek={1}
            minimumNights={0}
            displayFormat = "DD.MM.YYYY"
          />
        </Box >
        <Box sx = {{mt: 2, fontWeight:700}}>
          Ladattu Seuraohjelma: {this.props.filters.serviceProgramName}
        </Box>
        
      </Box>
    )
  }
}
const mapStateToProps = (state: any) => {
  return {
    filters: state.filters,
    speakers: state.speakers,
    auth:state.auth
  }
}
export default connect(mapStateToProps) (CalendarListFilters)