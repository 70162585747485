/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
/* eslint-disable no-undef */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux';
import updateSpeakerColor from './updateSpeakerColor';
import updateSpeakerCalendar from '../speakers/updateSpeakerCalendar';
import { Box, Button, TextField, Typography } from '@mui/material';
import 'react-dates/lib/css/_datepicker.css';
import { serviceEdited, haveToRead, setTextFilter} from '../../actions/filters';
import { subHeaderStyles } from '../../styles/themeStylesSx';
import { startEditServiceProgram} from '../../actions/services';
import { editFilterData } from '../../actions/filters';


class ServiceFormLine extends React.Component< any, any> {
  constructor(props: any){
    super(props)
    this.state = {
      speaker: props.service ? props.service.sspeaker : '',
      id: props.service ? props.service.serviceId : '',
      service: props.service ? props.service : ' ',
      prevspeaker: props.service ? props.service.sspeaker : '',
      startdate: props.service ? moment(props.service.StartDate,'D.M.YYYY').format('D.M.YYYY') : moment(),
      starttime: props.service ? moment(props.service.StartTime,'HH.mm').format('HH.mm') : ('00.00'),
      enddate: props.service ? moment(props.service.EndDate,'D.M.YYYY').format('D.M.YYYY') : moment(),
      endtime: props.service ? moment(props.service.EndTime,'HH.mm').format('HH.mm') : ('24.00'),
      subject: props.service ? props.service.subject : '',
      selite:props.service ? props.service.Selite : '',
      strike: props.service ? props.service.strike: 'n',
      modSubject:props.service ? props.service.subject : '',
      linenumber: props.service ? props.service.linenumber : '',
      dayArray1:props.dayArray1,
      questspeaker: '',
      mail: ' ',
      location:props.service ? props.service.Location : '',
      serviceFocused : false,
      error: '',
      speakers: props.speakers,
      filters:props.filters,
      token : `bearer ${props.auth.idToken}`,
      servYear :props.filters.serviceYear,
      fromwhere : 'oma',
      selspeaker : '',
      selselite: '',
      finalSelite:'',
      font:{color:'rgb(255,0,0)'}
    }
  }
  onPaluu = (event : any) => {
    this.props.dispatch(serviceEdited(true));
  };
  handleChange = (event: any) => {
    const apu = event.target.value;
    this.setState(() => ({ fromwhere: apu }));
    this.setState(() => ({selspeaker : ''}))
    this.setState(() => ({questspeaker : ''}))
    this.setState(() => ({selselite : ''}))
    // eslint-disable-next-line no-lone-blocks
    {this.setState(() => ({selspeaker: this.state.speaker}))}
  };
  onChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(() => ({ [name]: value }));
  }

  onSaveChange = (e: any) => {
    e.preventDefault();
    this.setState(() => ({error: ''}))
    const attendeear = this.state.speakers.filter((speaker: any ) => speaker.speaker === this.state.speaker)
    let speakerMail: any = {}
    if (attendeear.length === 0) {
      this.setState(() => ({mail: 'matti.friman@helsinginrauhanyhdistys.fi'}))
      speakerMail = {mail: 'matti.friman@helsinginrauhanyhdistys.fi'}
    } else {
      this.setState(() => ({mail:  attendeear[0].mail}))
      speakerMail = {mail:   attendeear[0].mail}
    }
    let startColumn = 'A';
    let endColumn = 'AB';
    startColumn = 'A';
    endColumn = 'AB';
    const tostrike = this.state.strike === 'y' ? 'n' : 'y';
    if (this.state.strike === 'y')
      this.setState(() => ({strike: 'n'}))
    else
      this.setState(() => ({strike: 'y'}));

    let modSubject = this.state.modSubject;

    updateSpeakerColor(this.state.token,this.state.linenumber,this.props.filters.sheetId, this.props.filters.subSheet,tostrike,startColumn,endColumn);
    let deleteonly = 'xxxxx';
    if (tostrike === 'y')
      deleteonly = 'deleteonly';
    updateSpeakerCalendar(this.state.token,this.state.filters,this.state.speaker,this.state.startdate, this.state.starttime,this.state.enddate,this.state.endtime,
      this.state.subject,this.state.selite,this.state.prevspeaker, this.state.location,speakerMail.mail, this.props.service,deleteonly);
    this.props.dispatch(serviceEdited(true));
    this.props.dispatch(haveToRead(false))
    this.props.dispatch(setTextFilter(''))
    this.props.dispatch(startEditServiceProgram(this.state.id, {strike: tostrike, subject: modSubject, type: this.state.service.type }, this.props.filters.serviceYear));
    this.props.dispatch(editFilterData({somethChanged: Math.random()}))
  }

  render () {
    // if (this.state.speakers.length === 0)
    //   return <Navigate to ='/listaaseuraohjelma'/>
    return (
      <Box sx= {{mt: 2}}>
        <Typography sx={ subHeaderStyles }>{'Yliviivaa rivi'}</Typography>
        {this.state.error && <p> {this.state.error}</p>}
        <Box>
          <Box>
            {/* <form onSubmit = {this.onSubmit}> */}
            <TextField
              sx={{ mt: 1 , width: 150 }}
              label="Speaker"
              autoFocus
              name="speaker"
              value={this.state.speaker}
              //onChange={this.onChange}
            />
            <TextField
              sx={{ mt: 1, width: 110 }}
              label="Pvm"
              name="startdate"
              value={this.state.startdate}
              //onChange={this.onChange}
            />
            <TextField
               sx={{ mt: 1, width: 80 }}
              label="Aika"
              name="starttime"
              value={this.state.starttime}
              // onChange={this.onChange}
            />

            <TextField
              sx={{ mt: 1, width: 150 }}
              label="Subject"
              name="subject"
              value={this.state.subject}
              //onChange={this.onChange}
            />
            <TextField
              sx={{ mt: 1 }}
              label="Selite"
              name="selite"
              value={this.state.selite}
              //onChange={this.onChange}
            />
            <Box>
              <Box>
                <Box sx= {{ mt: 1 }} >
                  {!this.state.filters.admin &&
                    <Button  sx= {{mt: 2}} color="primary" variant="contained" disabled>Disabled</Button>
                  }
                  {this.state.strike === 'n' && this.state.filters.admin &&
                    <Button color="primary" variant="outlined" onClick={this.onSaveChange}>Yliviivaa rivi</Button>
                  }
                  {this.state.strike === 'y' && this.state.filters.admin &&
                    <Button color="primary" variant="outlined" onClick={this.onSaveChange}>Palauta rivi</Button>
                  }
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }
}

  const mapStateToProps = (state: any) => {
    return {
      authz:state.auth
    }
  }
   export default connect(mapStateToProps) (ServiceFormLine)