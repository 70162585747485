/* eslint-disable semi */
/* eslint-disable linebreak-style */
//import React, { useState, useEffect }  from 'react'
import groupServices from '../../../services/groupServices'

const deleteGroupMemberLine = async( token: any, groupId: any, memberId: any) => {

  await groupServices
    .deleteGroupMember(token,groupId,memberId)
    .catch( error => {
      console.error('Error fetching permissions', error)
    })
}
export default deleteGroupMemberLine