/* eslint-disable linebreak-style */
/* eslint-disable no-undef */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
import {createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';
import speakersReducer from '../reducers/speakers'
import filtersReducer from '../reducers/filters'
import servProgReducer from '../reducers/servProg'
import drivesReducer from '../reducers/drivesReducer'
import authReducer from '../reducers/auth';
import usersReducer from '../reducers/users'
import groupsReducer from '../reducers/groups';
import { composeWithDevTools } from 'redux-devtools-extension';

export const rootReducer = combineReducers({
  filters: filtersReducer,
  speakers: speakersReducer,
  services : servProgReducer,
  auth: authReducer,
  drives: drivesReducer,
  users: usersReducer,
  groups : groupsReducer,
})

//const composeEnhancers =  window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose || compose;
export type RootState = ReturnType<typeof rootReducer>
// eslint-disable-next-line import/no-anonymous-default-export
export default  () => {


  const store = createStore(
    combineReducers({
      filters: filtersReducer,
      speakers: speakersReducer,
      services : servProgReducer,
      auth: authReducer,
      drives: drivesReducer,
      users: usersReducer,
      groups : groupsReducer,
    }),
    //composeEnhancers(applyMiddleware(thunk))
    composeWithDevTools(applyMiddleware(thunk))
    );
    //composeWithDevTools());
  return store;
}
