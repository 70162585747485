import React, { useEffect, useState } from 'react';
import {  useSelector  } from 'react-redux';
import { ArrowBackRounded } from '@mui/icons-material';
import { Button, Container, Link, Paper, Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { backButtonStyle, containerPaperStyle, headerStyles, tabsStyle, tabStyle } from '../../styles/themeStylesSx';
import { Outlet, Link as RouterLink, useNavigate, useLocation, Location } from 'react-router-dom';
import { RootState} from '../../store/ConfigureStore';


export default function CalendarContainer() {
  // const { t } = useLocales();
  const location: Location = useLocation();
  //const calendars: any  = useSelector((state: RootState) => state.calendars);
  //const params = useParams();
  const navigate = useNavigate();
  const [value, setValue] = React.useState('calendar');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(`/calendar/${newValue}`);
    setValue(newValue);
  };

  useEffect(() => {
    const parts = location.pathname.split('/');
    const last = parts[parts.length - 1];
    if (last !== value) {
      setValue(last);
    };
  }, [location, value]);

  const windowWidth = window ? window.innerWidth : 1000;
  const [width, setWidth] = useState(windowWidth);

  useEffect(() => {
    setWidth(windowWidth);
  }, [windowWidth]);

  return (
    <Container>
      <Link sx={ backButtonStyle } component={RouterLink} to='/'>
        <Button sx={{ color: 'secondary.main', fontWeight: 600 }} startIcon={<ArrowBackRounded />}>{'Back'}</Button>
      </Link>
      <Box>
        <Typography sx={ headerStyles }>{'Tilakalenterien hallinta'}</Typography>
      </Box>
      <Tabs
        orientation={ width < 1000 ? 'vertical' : 'horizontal' }
        value={value === '' ? 'calendar' : value}
        onChange={handleChange}
        TabIndicatorProps={{
          style: { display: 'none' }
        }}
        sx={tabsStyle}
        aria-label="calendar tabs"
      >
        <Tab
          disableRipple
          disableFocusRipple
          sx={ tabStyle }
          value="calendar"
          label={'Calendar'}
        />
        <Tab
          value="CheckCals"
          label={'Listaus'}
          sx={ tabStyle }
        />
        <Tab
          value="UpdateCals"
          label={'Päivitys'}
          sx={ tabStyle }
        />
        <Tab
          value="Users"
          label={'Käyttäjät'}
          sx={ tabStyle }
        />
      </Tabs>
      <Paper sx={containerPaperStyle}>
        <Outlet />
      </Paper>

    </Container>
  );
}