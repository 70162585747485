import React,{useEffect }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeFromDataBase, setLoading, haveToReadDrive } from '../../actions/filters';
import submitUsersToState from './submitUsersToState';
import submitDrivesToState from './submitDrivesToState';
import submitGroupsToState from './groups/submitGroupsToState';
import { Box, Button, CircularProgress,  FormControl,RadioGroup, FormControlLabel, Radio, Typography } from '@mui/material';
import {RootState} from '../../store/ConfigureStore';
import {subHeaderStyles} from '../../styles/themeStylesSx';

export const DriveStart = () => {
  const dispatch = useDispatch()
  const authorit: any = useSelector((state: RootState) => state.auth);
  const drivesx = useSelector((state: RootState) => state.drives);
  const filters = useSelector((state: RootState) => state.filters);
  const groups: any = useSelector((state: RootState)=> state.groups);
  const users = useSelector((state: RootState)=> state.users);
  const inputDataBase = useSelector((state: RootState) => state.filters.fromDataBase);

  const  token: any = `bearer ${authorit.idToken}`

  let componentMounted = true;
  
  useEffect( () => {
    if (filters.drive) {      

      const listDrives = async () => {     
        await submitUsersToState(token, dispatch);
        await submitDrivesToState(token,dispatch, authorit.uid, inputDataBase, authorit, users );
        await submitGroupsToState(token,dispatch);
        dispatch(setLoading(false));
        if (componentMounted) {
       
        }
        return () => { // This code runs when component is unmounted
          // eslint-disable-next-line react-hooks/exhaustive-deps
         console.log('unmounteeed')
          componentMounted = false; // (4) set it to false if we leave the page
        }
      }
       dispatch(setLoading(true));
      listDrives ();
      
    }
      
    else {
      dispatch(setLoading(false));
    }
  }, [filters.fromDataBase, filters.haveToReadDrive]);
  if (!authorit.idToken) return <div> Kirjaudu järjestelmään</div>
  if (filters.loading) return <div> Lataa Ryhmien ja kansioiden tietoja<CircularProgress/></div>
  if (!filters.drive) return <div> Ei tarvittavia oikeuksia</div>
  
  const handleChange = (event: any) => {
    const apu = event.target.value;
    dispatch(changeFromDataBase(apu));
  };

  return (
    <Box>
    
      <Box>
        <Button variant="contained" color="primary"
          onClick = {(e) => { dispatch(haveToReadDrive(true))}}>Lataa </Button>
      </Box>
      <Box>
        <Typography sx={ subHeaderStyles }>{'Ladattu ' + drivesx.length + ' Drive-kansiota' }</Typography>
        <Typography sx={ subHeaderStyles }>{'Ladattu ' + users.length + ' Drive-käyttäjää' }</Typography>
        <Typography sx={ subHeaderStyles }>{'Ladattu ' + groups.length + ' Drive-ryhmää' }</Typography>
      </Box>
    </Box>
  )
}
  export default DriveStart;