import updateSpeakerColor from "./updateSpeakerColor";
import { startEditServiceProgram } from "../../actions/services";
export const StrikeSingleLine =async (line: any, token: string, sheetId: any, subSheet: any, dispatch: any, serviceYear: string) => {
  const toStrike = 'y';
  let startColumn = 'A';
  let endColumn = 'AB';
  startColumn = 'A';
  endColumn = 'AB';

  updateSpeakerColor(token, line.linenumber, sheetId, subSheet, toStrike, startColumn, endColumn);
  dispatch(startEditServiceProgram(line.serviceId, {strike: toStrike, subject: line.Subject, type: line.type }, serviceYear));
}