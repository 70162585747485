/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import { Box, Button, CircularProgress, IconButton, Typography, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import { RootState } from '../../../store/ConfigureStore';
import { subHeaderStyles } from '../../../styles/themeStylesSx';
import { colors } from '../../../styles/themeStylesSx';
import SidePanelMedium from '../../sidepanel/SidePanelMedium';
import EditIcon from '@mui/icons-material/Edit';
import DeleteGroupMember from './DeleteGroupMember';
import { DeleteRounded } from '@mui/icons-material';
import UpdateRole from '../UpdateRole';
import AddUserToGroup from './AddUserToGroup';
import submitListMembersOfGroup from '../submitListMembersOfGroup';
import { addMembersToGroup } from '../../../actions/groups';

type aclUpdateMembersType = {
  groupName: string;
  groupEmail: string;
  groupId: any;
  ownerName: string;
  close: () => void;
  token: string;
 
};
type GroupStateType = {
  open?: any;
  member?: any;
  groupId?: any;
  id?: string;
  openDelete? : any;
  members? : any,
  addUser? : any;
  close?: boolean;
  change?: boolean;
  memberId?: string;
  memberName?: any;
  groupName?: any;
  group?: any;
};

const UpdateGroupMember =  (props: aclUpdateMembersType ) => {
  const [members, setMembers] = useState<[]>([]);
  const [loading, toggleLoading] = useState(false);
  const dispatch = useDispatch();
  const close = props.close;
  const filters: any = useSelector((state: RootState) => state.filters);
  const groups: any = useSelector((state: RootState) => state.groups);
  const selectedGroup = useSelector((state: RootState)=> state.groups.filter((group: any) => group.id === props.groupId));
  const [state, setState] = useState<GroupStateType>({ members: [], groupId: '', id: ' ', close: false, change: false });
  const users: any = useSelector((state: RootState)=> state.users)

  useEffect( () => {
    if (state.close){
      setState({...state, close: false})
      close()
    }    
  }, [close]);

  useEffect(() => {
    
    const membersFunc = async (id: any ) =>  {
      try{
        const memberList = await submitListMembersOfGroup(props.token, id);
        let userName: any = '';
        let nameArray: any = [];
      
        for (const line of memberList) {
          userName = users.find((user: any) => {
          return user.id === line.id})
          if (userName) {
            userName = userName.name
          }else 
          {
            userName = ({ 
               familyName: '', givenName:'', fullName: ''
            })
          }
          const str: any = ({
            ...line,
            ...userName
          })
          nameArray.push(str);
        }          
         
        const work = await nameArray!.sort((a: any, b: any) => {
          var x = a.email
          var y = b.email
          if (x < y) {return -1}
          if (x > y) {return +1}
          if (x === y) {return 1}
        });
        setMembers(work);
        dispatch(addMembersToGroup(id,work));  
      } catch(error) {
        console.log('erroro from membersfunc in listgroupmembersimple', error);
      }  
    toggleLoading(false);       
    }

    const actuel = groups.find((gro: any) => {
      return gro.id === props.groupId;    
    });
    
    toggleLoading(true);
    membersFunc(actuel.id);
  },[props.groupId]);
  
  if (loading) <div> lataa groupsmembereitä <CircularProgress/> </div>  
  if (!filters.drive) return (<div> <br />. Ei vaadittuja oikeuksiaa</div>);

  return (
    <Box>
      <Box>
        <SidePanelMedium
          open = { state.change === true }
          close={ () => setState({ ...state, change: false })}
          >
          <Box>
            {state.change &&
              <UpdateRole id = {props.groupId } member= {state.memberName} groupId= {props.groupId}
              memberId= {state.memberId} members= {members} name= {props.groupName} 
              memberName= {state.memberName}
              groupName= {props.groupName}
              close ={ () => setState({ ...state,  change: false })} />
            }
          </Box>
        </SidePanelMedium>
        <SidePanelMedium
        open = { state.addUser === true }
        close={ () => setState({ ...state, addUser: false })}
        >
          <Box>
            {state.addUser &&
               <AddUserToGroup group = {state.group } openDelete= {false}  close ={ () => setState({ addUser: false })} />         
            }
            </Box>
        </SidePanelMedium>

        <SidePanelMedium
          open = { state.openDelete === true }
          close={ () => setState({ ...state, openDelete: false })}
          >
          <Box>
            {state.openDelete &&
              <DeleteGroupMember member = {state.member} group = {state.group }  members= {members}  open = {state.open} openDelete = {true} close ={ () => setState({ openDelete: false })} />  
            }
          </Box>
        </SidePanelMedium>       
      </Box>
     
      {props.groupId && selectedGroup[0].members &&
        <Typography sx={ subHeaderStyles }>{`Henkilön roolin päivitys ryhmässä:  ${props.groupName} `}</Typography>
      }      
      {props.groupId && selectedGroup[0].members &&
     <Table sx={{ display: { xs: 'inline-table', md: 'inline-table' }, width: '100%' }}>
        <TableHead>      
          <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
            <TableCell></TableCell>
            <TableCell>{'Email'}</TableCell>
            <TableCell>{'Nimi'}</TableCell>
            <TableCell>{'Rooli'}</TableCell>
            <TableCell>{'Tyyppi'}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groups.find((group: any) => group.id === props.groupId)
          .members.map((member: any , ii: any)=> {           
            const backgroundColor = ii % 2 ? colors.backgroundGreen : 'transparent';
            return (              
              <TableRow sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${member.id}_row10`}>  
                {member.type !== 'GROUP' ?           
                  <TableCell sx={{
                    backgroundColor,
                    borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>
                    <IconButton  onClick={() => setState({ ...state, change: true, openDelete: false, memberId: member.id, groupId: props.groupId, memberName: member.email })}><EditIcon /></IconButton>
                    <IconButton  onClick={() => setState({ ...state, openDelete: true, change: false, group: selectedGroup[0], member: member })}><DeleteRounded /></IconButton>
                  </TableCell>  
                  :
                  <TableCell>               
                  </TableCell>
                }
                <TableCell sx={{backgroundColor }}>{ member.email }</TableCell>
               
                <TableCell sx={{backgroundColor }}>{ member.fullName}</TableCell>
                <TableCell sx={{backgroundColor }}>{ member.role }</TableCell>
                <TableCell sx={{backgroundColor }}>{ member.type }</TableCell> 
              </TableRow>
            )
             })
            }
        </TableBody>
      </Table>
}
       <Box>
        <Button sx= {{p: 2 }  } variant='contained' color="primary"
          onClick={() => setState({ ...state,   addUser: true, group: groups.find((gr: any) => gr.id === props.groupId )})}>Lisää käyttäjä ryhmään</Button>
                 
      </Box>
    </Box>
  )
}
export default UpdateGroupMember