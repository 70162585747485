//Read CalIds from sheeets
//Read calendars by sheetId
import React, { useEffect, useState } from 'react';
import {  useDispatch, useSelector  } from 'react-redux';
import submitGetCalendarIdArr from './submitGetCalendarIdArr';
import { CircularProgress, Box,  IconButton, Typography, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import { VisibilityRounded } from '@mui/icons-material';
import {RootState} from '../../store/ConfigureStore';
import { setLoading, setCalendarIds} from '../../actions/filters'
import ListCalendarPermissions from './ListCalendarPermissions';
import {subHeaderStyles} from '../../styles/themeStylesSx';
import SidePanelLarge from '../sidepanel/SidePanelLarge';
import submitGroupsToState from '../drives/groups/submitGroupsToState';
import { colors } from '../../styles/themeStylesSx';

export default function AclListCalendar(){
  const auth : any = useSelector((state: RootState) => state.auth);
  const filters: any = useSelector((state: RootState) => state.filters);
  const dispatch = useDispatch();
  const [loading, toggleLoading] = useState(false);
  const token = `bearer ${auth.idToken}`
 
  
  type CalendarStateType = {
  calendarId?: string;
  open?: boolean;
  calendarName?: any;
};
 const [state, setState] = useState<CalendarStateType>({ open: false, calendarId : '' , calendarName: ' '});


  useEffect(() => {
    
    if (auth.idToken) {
     //if (!auth.idToken) return <div><CircularProgress /> <br />waiting for idToken</div>
      const retr = async () => {
        await submitGroupsToState(token,dispatch);
        const calendarDataArray1 = await submitGetCalendarIdArr(token);
        dispatch( setCalendarIds(calendarDataArray1));
        dispatch(setLoading(false));
        toggleLoading(false);
        return calendarDataArray1
      }

      toggleLoading(true);
      dispatch(setLoading(true));
      retr()
  }
    
  }, [])

  //if (!auth.idToken) navigate("/calendar/calendar")
  if (!filters.admin) return <div> Ei oikeuksia</div>
  if (loading ) return <div><CircularProgress /> <br />...latailee vielä</div>
 
  return (
    <Box>
      <SidePanelLarge
       open={ state.open === true }
       close={ () => setState({ open: false })}
      >
        {state.open &&
        <Box>          
            <ListCalendarPermissions calendarId= {state.calendarId } calendarName= {state.calendarName} close ={ () => setState({ open: false })} />
        </Box>
}
      </SidePanelLarge>
      <Box>
        <Box>
          <Typography sx={ subHeaderStyles }>{'Hry:n tilakalenterit' }</Typography>
          <Table sx={{ display: { xs: 'inline-table', md: 'inline-table' }, width: '100%' }}>
            <TableHead>
              <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
                <TableCell></TableCell>
                <TableCell>{'Tilakalenterin nimi'} </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {
            filters.calendarDataArray.map((cale: any, i: any) => {
            const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
              return (
                <TableRow sx={{backgroundColor, height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key = {`${cale.calendarId}${i}_row`}>
                  <TableCell sx={{
                    backgroundColor,
                    borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>
                    <IconButton  onClick={() => setState({ open: true, calendarId : cale.calendarId, calendarName: cale.calendar })}><VisibilityRounded /></IconButton>
                  </TableCell>
                  <TableCell>{cale.calendar}</TableCell>
                </TableRow>
                )
              })
            }
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Box>
  )

}
