/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
import { v4 as uuidv4 } from 'uuid';


export const addSpeaker = (
  {
    id ='',
    speaker = '',
    phone = '',
    mail = '',
    createdAt = '',
    types= [] ,
    typesArray =[],
    dates= '',
    datesArray =['1.12.2020'],
    datesArrayFinal =[{apvm: '1.1.2040' , ppvm : '1.1.2040'}],
    random= '',
    subjectArray = [],
    speakersTypesArray = [],
    servDatesx = '1.12.2020',
    servDates=[],
    sortSDate ='',
    linenumber = '',
    servDate1 = ['1.12.2020'],
    servDate1Length = 0,
    servDate2 = ['1.12.2020'],
    servDate2Length = 0,
    servDate3 = ['1.12.2020'],
    servDate3Length = 0,
    servDate4 = ['1.12.2020'],
    servDate4Length = 0,
    servDate5 = ['1.12.2020'],
    servDate5Length = 0,
    servDate6 = ['1.12.2020'],
    servDate6Length = 0,
    servDate7 = ['1.12.2020'],
    servDate7Length = 0,
    servDate8 = ['1.12.2020'],
    servDate8Length = 0,
    servDate9 = ['1.12.2020'],
    servDate9Length = 0,
    servDate0 = ['1.12.2020'],
    servDate0Length = 0,
    servDate10 = ['1.12.2020'],
    servDate10Length = 0,
    servDate11 = ['1.12.2020'],
    servDate11Length = 0,
    servDate1x = ['1.12.2020'],
    servDate2x = ['1.12.2020'],
    servDate3x = ['1.12.2020'],
    servDate4x = ['1.12.2020'],
    servDate5x = ['1.12.2020'],
    servDate6x = ['1.12.2020'],
    servDate7x = ['1.12.2020'],
    servDate8x = ['1.12.2020'],
    servDate9x = ['1.12.2020'],
    servDate0x = ['1.12.2020'],
    servDate10x = ['1.12.2020'],
    servDate11x = ['1.12.2020']

  } = {}
) => ({
  type: 'ADD_SPEAKER',
  speaker: {
    id: uuidv4(),
    speaker,
    phone,
    mail,
    createdAt,
    types,
    typesArray,
    dates,
    datesArray,
    datesArrayFinal,
    subjectArray,
    speakersTypesArray,
    servDates,
    servDatesx,
    sortSDate,
    linenumber,
    servDate1,
    servDate1Length,
    servDate2,
    servDate2Length,
    servDate3,
    servDate3Length,
    servDate4,
    servDate4Length,
    servDate5,
    servDate5Length,
    servDate6,
    servDate6Length,
    servDate7,
    servDate7Length,
    servDate8,
    servDate8Length,
    servDate9,
    servDate9Length,
    servDate0,
    servDate0Length,
    servDate10,
    servDate10Length,
    servDate11,
    servDate11Length,
    servDate1x,
    servDate2x,
    servDate3x,
    servDate4x,
    servDate5x,
    servDate6x,
    servDate7x,
    servDate8x,
    servDate9x,
    servDate0x,
    servDate10x,
    servDate11x,

  }
});

export const speakersToStateOnce = (speakerArray: any) => ({
  type: 'SPEAKERS_TO_ARRAY_ONCE',
  speakerArray
})

export const emptySpeaker = () => ({
  type: 'EMPTY_SPEAKER',

});
export const editSpeaker = (id: any, updates: any) => ({
  type: 'EDIT_SPEAKER',
  id,
  updates
});
export const startEditServDates = (id: any, updates: any) => ({
  type: 'EDIT_SERV_DATES',
  id,
  updates
});
export const startEditServSubjects = (id: any, updates: any) => ({
  type: 'EDIT_SERV_SUBJECTS',
  id,
  'subjectArray':updates
});

export const startEditServDatesTypesArray = (id: any, updates: any) => ({
  type: 'EDIT_SERV_DATES_TYPES',
  id,
  updates
});

export const startEditDatesArray = (id: any, updates: any) => ({
  type: 'EDIT_DATES_ARRAY',
  id,
  updates
});

export const startEditTypesArray = (id: any, updates: any) => ({
  type: 'EDIT_TYPES_ARRAY',
  id,
  'typesArray' : updates
});
export const startEditSpeakerProgram = (id: any, updates: any) => {
  const updates_save = updates
  return ( dispatch: any ) => {
    dispatch(editSpeaker(id, updates_save));
  }
}

