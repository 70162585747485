/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect}  from 'react';
// eslint-disable-next-line quotes
//import AppRouter, { history } from "../routes/AppRouter";
import { FormControl,  Select, MenuItem,  Box, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {RootState} from '../../store/ConfigureStore';
import { useNavigate } from "react-router-dom";
import ChangeServiceYear from '../genUtilities/ChangeServiceYear';
import { ReadServiceAndSpeakersToRedux } from '../genUtilities/ReadServiceAndSpeakersToRedux';
import { editFilterData } from '../../actions/filters';

const ChangeYear = () => {
  const dispatch = useDispatch();
  const authorit: any = useSelector((state: RootState) => state.auth)
  const filters: any = useSelector((state: RootState) => state.filters);
 
  const token = `bearer ${authorit.idToken}`;
  const currentYear = moment().year();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [buttonStyle, setButtonStyle] = useState<"inherit" | "primary" | "secondary" | "default" | undefined>('primary');

  let navigate = useNavigate();
 
  useEffect(() => {
    if (filters.sheetYears.length === 0) {
      navigate("/seuraohjelma");
    }
  })

  if (!authorit.idToken) return <div> Kirjaudu järjestelmään</div>

  if (!filters.read) {
    return (<div> <br />. Ei vaadittuja oikeuksia ListServiceProgramPage</div>)
  }
  if (filters.loading) <CircularProgress/>
  const ReadIns = async(dispatch: any, token: any, workFilters: any, authorit: any ) => {
    
    await ReadServiceAndSpeakersToRedux (dispatch, token, workFilters, authorit);

    dispatch(editFilterData({loading: false, error: true , errorMessage: 'Seuraohjelman vuosi muutettu'}));

  }
  
  const onServiceYearChange = async (e: any) => {  
    ChangeServiceYear (e.target.value, filters, authorit, dispatch);
    const workFilters = filters;
    workFilters.serviceYear = e.target.value;
   dispatch(editFilterData({loading: true}))
   ReadIns(dispatch, token, workFilters, authorit);
  }
  return  ( 
    <Box>
      Valitse seuraohjelman vuosi
      <Box>
        {filters.sheetYears.length > 0 &&
          <FormControl>
            <Select
              style={{ minWidth: '50px', margin:'1em'}}
              labelId="selectSort"
              value = { filters.serviceYear }
              onChange={(e) => {
                onServiceYearChange(e)
              }}
            >
              <MenuItem value= { filters.sheetYears[filters.sheetYears.findIndex((line: any) => {
                return  line[0] == currentYear;
                })][0]
                }
                >
                { filters.sheetYears[filters.sheetYears.findIndex((line: any) => {
                return  line[0] == currentYear })][0] }
              </MenuItem>
              <MenuItem value= { filters.sheetYears[filters.sheetYears.findIndex((line: any) => {
                return  line[0] == currentYear }) + 1][0] }
              >
                {filters.sheetYears[filters.sheetYears.findIndex((line: any) => {
                  return  line[0] == currentYear })+1][0] }
              </MenuItem>
            </Select>
          </FormControl>
        }
      </Box>
    </Box>
  )
}
export default ChangeYear