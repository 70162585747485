/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable linebreak-style */
/* eslint-disable no-extra-semi */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
const speakersReducersInitialState = []

export default (state = speakersReducersInitialState, action) => {
  switch (action.type) {
    case 'SPEAKERS_TO_ARRAY_ONCE':
      state = [];
      return action.speakerArray.map(spe => {
        return spe;
      });
  case 'SET_SPEAKER':
    return [
      ...state,
      action.speaker
    ];
  case 'ADD_SPEAKER':
    return [
      ...state,
      action.speaker
    ];
  case 'EDIT_SPEAKER':
    return state.map((speaker) => {
      if (speaker.linenumber === action.id) {
        return {
          ...speaker,
          ...action.updates
        };
      } else {
        return speaker;
      }
    });
  case 'EDIT_SERV_DATES':
    return state.map((speaker) => {
      if (speaker.linenumber === action.updates.id) {
        
        return {
          ...speaker,
          ...action.updates.workDays,
          ...action.updates,
        };
      } else {
        return speaker;
      }
    });
  case 'EDIT_SERV_SUBJECTS':
    return state.map((speaker) => {
      if (speaker.id === action.id) {
        return {
          ...speaker,
          ...action.updates
        };
      } else {
        return speaker;
      }
    });
  case 'EDIT_SERV_DATES_ARRAY':
    return state.map((speaker) => {
      if (speaker.linenumber === action.linenumber) {
        return {
          ...speaker,
          ...action.updates
        };
      } else {
        return speaker;
      }
    });
  case 'EDIT_DATES_ARRAY':
    return state.map((speaker) => {
      if (speaker.id === action.id) {
        return {
          //...action.datesArray,
          ...speaker,
          ...action.updates
        };
      } else {
        return speaker;
      }
    });
  case 'EDIT_DATES_ARRAY_FINAL':
    return state.map((speaker) => {
      if (speaker.id === action.id) {
        return {
          //...action.datesArray,
          ...speaker,
          ...action.datesArray
        };
      } else {
        return speaker;
      }
    });
  case 'EDIT_TYPES_ARRAY':
    return state.map((speaker) => {
      if (speaker.id === action.id) {
        return {
          //...action.datesArray,
          ...speaker,
          ...action.typesArray
        };
      } else {
        return speaker;
      }
    });
  case 'REMOVE_SPEAKER':
    return state.filter(({ id }) => id !== action.id);
  case 'EMPTY_SPEAKER':
    return  speakersReducersInitialState;
  default:
    return state
  }
}
const filtersReducerDefaultState = {
  text: '',
  sortBy: 'date',
  startDate: undefined
}

