/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
//import React, { useState, useEffect ,useReducer, useContext } from 'react'
import programService from '../../services/programService'
import 'core-js/stable'
const updateSpeakerSubSheet = async (token: any, speaker: any, id: any, servdates: any, servYear: any, sd1: any,
  sd2: any, sd3: any, sd4: any, sd5: any, sd6: any, sd7: any, sd8: any, sd9: any, sd0: any, sd10: any,sd11: any,
  subSheet: any, sheetId: any, speakerSubColumn: any, speakerSubDayCol1: any, speakerSubDayCol2: any,datesArrayFinal: any) => {
  const stringData = servdates.toString();
  const st1 = sd1.toString();
  const st2 = sd2.toString();
  const st3 = sd3.toString();
  const st4 = sd4.toString();
  const st5 = sd5.toString();
  const st6 = sd6.toString();
  const st7 = sd7.toString();
  const st8 = sd8.toString();
  const st9 = sd9.toString();
  const st0 = sd0.toString();
  const st10 = sd10.toString();
  const st11 = sd11.toString();

  await programService
    .updatespsheet(token,speaker,id,stringData,st1, st2,st3,st4,st5,st6,st7,st8,st9,st0,st10, st11,
      servYear,subSheet,sheetId, speakerSubColumn, speakerSubDayCol1, speakerSubDayCol2 )
    .catch(error => {
      console.log('erreoddd',error,speaker,id)
    })

    return 'kukkuutikukkuu'
}

export default updateSpeakerSubSheet;