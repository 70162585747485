/* eslint-disable array-callback-return */
import React,{useEffect, useState }  from 'react';
import { useSelector } from 'react-redux';
import { Box, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress, Typography } from '@mui/material';
import {RootState} from '../../store/ConfigureStore';
import {subHeaderStyles} from '../../styles/themeStylesSx';
import {useNavigate} from 'react-router-dom';

import groupServices from '../../services/groupServices'
import { colors } from '../../styles/themeStylesSx';

type aloneType = {
  creationDate: string;
  id: string;
  lastLoginDAte: string;
  primaryEmail: string;
}
type notAloneType = {
  user: string;
  groups: [] ;
}


export const LonelyUsers = () => {
  const authorit: any = useSelector((state: RootState) => state.auth);
  const drives = useSelector((state: RootState) => state.drives);
  const users = useSelector((state: RootState)=> state.users);
  const [loading,toggleLoading] = useState (true);
  const  token: any = `bearer ${authorit.idToken}`

  const [aloneUser, setAlone] = useState<aloneType[]>([]);
  const [notAloneUser, setNotAloneUser] = useState<notAloneType[]>([]);
  const [notAloneDrive, setNotAloneDrive] = useState<notAloneType[]>([]);

  let pArrayArray: any = [];
  let reallyAlone: any = [];
  let navigate = useNavigate();
  let usersAllGroupsArray: any = [];

  useEffect (() => {
    let usersAllDrivesArray: any = [];
    let isDriveAuthority: any = [];
    if (drives.length === 0) {
      navigate("/drive");
    } else {
      drives.forEach((drive: any) => {
        pArrayArray.push(...drive.drivesPermissions);
      });

    const runSingleUser = async(token: any, userLine: any) => {
      const isInGroups =await groupServices
      .usersGroupList(token,userLine.id)
      .catch( error => {
        console.error('Error fetching permissions', error)
      })

      isDriveAuthority = pArrayArray.filter((use: any) => {
        const permissionTxt= use.emailAddress.replace('@hry.fi','@helsinginrauhanyhdistys.fi')
        return permissionTxt === userLine.primaryEmail
      })

      if (isInGroups.length === 0) {

        if (isDriveAuthority.length === 0) {
           reallyAlone.push(userLine);
        }
      }
      else {
        const usersAllGroups = ({
          user: userLine.primaryEmail,
          groups: isInGroups
        })

        usersAllGroupsArray.push(usersAllGroups);
      }
      if (isDriveAuthority.length > 0) {
        const usersAllDrives =({
          user: userLine.primaryEmail,
          drives: isDriveAuthority,
        });
        usersAllDrivesArray.push(usersAllDrives);
      }

      await new Promise( resolve => setTimeout(resolve, 10));
    }

    const findUsersInGroups = async() => {
      await users.reduce( async (earlier: any, current: any) => {
        await earlier;
        return  await runSingleUser(token, current );
      });
      toggleLoading(false);
    }

    setAlone(reallyAlone);
    setNotAloneUser(usersAllGroupsArray);
    setNotAloneDrive(usersAllDrivesArray);
    toggleLoading(true);
    findUsersInGroups();
    }

}, []);

if (loading) return <CircularProgress/>

return (
  <Box>
    <Box>
      <Typography sx={ subHeaderStyles }>{'Käyttäjät, joilla ei ryhmää eikä drive permissionia' }</Typography>
        <Table sx={{ display: { xs: 'inline-table', md: 'inline-table' }, width: '100%' }}>
          <TableHead>
            <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
              <TableCell>{'Email'}</TableCell>
              <TableCell>{'Creation Date'} </TableCell>
              <TableCell>{'LastLogin Date'} </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {
          aloneUser.map((lon: any, i: any) => {
          const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
            return (
              <TableRow sx={{backgroundColor, height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key = {i}>
                <TableCell>{lon.primaryEmail}</TableCell>
                <TableCell>{lon.creationDate}</TableCell>
                <TableCell>{lon.lastLoginDate}</TableCell>
              </TableRow>
              )
            })
          }
          </TableBody>
        </Table>
    </Box>
      <Box>
      <Typography sx={ subHeaderStyles }>{'Käyttäjän ryhmät' }</Typography>
        <Table sx={{ display: { xs: 'inline-table', md: 'inline-table' }, width: '100%' }}>
          <TableHead>
            <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
              <TableCell>{'User'}</TableCell>
              <TableCell>{'groups'} </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {
          notAloneUser.map((lon: any, i: any) => {
            const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
              return (
                <TableRow sx={{backgroundColor, height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key = {i}>
                  <TableCell>{lon.user}</TableCell>
                  {lon.groups.map((lon1: any, ii: any) => {
                    return(
                      <TableCell key = {ii}>{lon1.name}</TableCell>
                    )
                  })
                  }
              </TableRow>
            )
          })
        }
        </TableBody>
      </Table>
    </Box>
    <Box>
      <Typography sx={ subHeaderStyles }>{'Käyttäjän driveRyhmät' }</Typography>
        <Table sx={{ display: { xs: 'inline-table', md: 'inline-table' }, width: '100%' }}>
          <TableHead>
            <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
              <TableCell>{'User'}</TableCell>
              <TableCell>{'drives'} </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {
          notAloneDrive.map((lon: any, i: any) => {
            const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
              return (
                <TableRow sx={{backgroundColor, height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key = {i}>
                  <TableCell>{lon.user}</TableCell>
                  {lon.drives.map((lon1: any, ii: any) => {
                    return(
                      <TableCell key = {ii}>{lon1.driveName}</TableCell>
                    )
                  })
                  }
              </TableRow>
            )
          })
        }
        </TableBody>
      </Table>
    </Box>
  </Box>
)
  }
  export default LonelyUsers;