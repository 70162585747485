
/* eslint-disable quotes */
/* eslint-disable linebreak-style */
/* eslint-disable no-mixed-operators */
/* eslint-disable eqeqeq */

/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
/* eslint-disable no-undef */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
import React from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';
import {DateRangePicker} from 'react-dates';
//import 'react-dates/lib/css/_datepicker.css';
import FormGroup from '@material-ui/core/FormGroup';
import { setStartDateRed, setEndDateRed , setTypesToState, setDaysToState, speakerEdited} from '../../actions/filters';
import {editSpeaker} from '../../actions/speakers';
import SpeakerDays from './SpeakerDays';
import submitLeaveTimes from './submitLeaveTimes';
import { Box, TextField,  Button, Table, TableBody, TableCell, TableHead, TableRow, FormControl,
  Snackbar, Switch,FormLabel, FormControlLabel } from '@mui/material';
import submitLeaveTypes from './submitLeaveTypes';
import { addButtonStyle} from '../../styles/themeStylesSx';
import {makeStyles} from '@mui/styles';


// eslint-disable-next-line @typescript-eslint/no-unused-vars
const path = window.location.pathname;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: any) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

// const inputStyle = {
//   marginRight: '8px',
//   marginTop: '8px',
//   marginBottom: '8px'
// }
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const padStyle = {
  style:{
    padding:0,
    margin:0
  }
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface SpeakerDatesFormType {
  startDate: any;
      endDate: any;
      startDatePicked: any;
      endDatePicked: any;
      calendarFocused: any;
      speaker: any;
      serviceFocused : boolean;
      error: string;
      speakers: string;
      token : string;
      dayRangeArray: any [];
      errormessage : string;
      open : boolean;
      sunAt16: any;
      sunAt18: any;
      satAt18:any;
      wedAt19: any;
      regional: any;
      careof: any;
      church: any;
      tallinn: any;
      ahvenanmaa: any;
      child: any;
      chairman: any;
      bible: any;

}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface SpeakerDatesInterfaceType {
  speaker: any;
  filters: any;
  startDate: any;
  endDate: any;
  startDatePicked: any;
  endDatePicked: any;
  calendarFocused: any;
  serviceFocused : boolean;
  error: string;
  speakers: string;
  token : string;
  dayRangeArray: any [];
  errormessage : string;
  open : boolean;
  sunAt16: any;
  sunAt18: any;
  satAt18:any;
  wedAt19: any;
  regional: any;
  careof: any;
  church: any;
  tallinn: any;
  ahvenanmaa: any;
  child: any;
  chairman: any;
  bible: any;
  close: void;
}

class SpeakerDatesForm extends React.Component< any, any>  {
  constructor(props: any){
    super(props)
    this.state = {
      startDate: moment(moment().startOf('year')).add(365,'days'),
      endDate: null,
      startDatePicked:moment(moment().startOf('year')).add(365,'days'),
      endDatePicked:moment(moment().startOf('year')).add(25,'month'),
      calendarFocused:null,
      speaker:props.speaker,
      speakersLeaveTimes : props.speaker.datesArrayFinal,
      serviceFocused : false,
      error: '',
      speakers: props.speakers,
      token : `bearer ${props.auth.idToken}`,
      dayRangeArray:props.filters.daysToState,
      errormessage : '',
      open : false,
      filters: props.filters,
      sunAt16: props.filters.typesToState.findIndex((fi: any) => fi == '1')  === -1 ? false :true,
      sunAt18:props.filters.typesToState.findIndex((fi: any) => fi == '2')  === -1 ? false :true,
      satAt18:props.filters.typesToState.findIndex((fi: any) => fi == '3')  === -1 ? false :true,
      wedAt19:props.filters.typesToState.findIndex((fi: any) => fi == '4')  === -1 ? false :true,
      regional:props.filters.typesToState.findIndex((fi: any) => fi == '5')  === -1 ? false :true,
      careof:props.filters.typesToState.findIndex((fi: any) => fi == '6')  === -1 ? false :true,
      church: props.filters.typesToState.findIndex((fi: any) => fi == 7) === -1 ? false :true,
      tallinn:props.filters.typesToState.findIndex((fi: any) => fi == '9')  === -1 ? false :true,
      ahvenanmaa:props.filters.typesToState.findIndex((fi: any) => fi == '11')  === -1 ? false :true,
      child:props.filters.typesToState.findIndex((fi: any) => fi == '8')  === -1 ? false :true,
      chairman:props.filters.typesToState.findIndex((fi: any) => fi == '0')  === -1 ? false :true,
      bible:props.filters.typesToState.findIndex((fi: any) => fi == '10')  === -1 ? false :true,
      close: props.close

    }
  }


   handleClose: any = (event: any, reason: any) => {
     if (reason === 'clickaway') {
       return;
     }

     this.setState(() => ({open:false}))
   };
  onPaluu = () => {
    this.props.dispatch(speakerEdited(true));
  };
  onDatesChange = ({ startDate,endDate }: any) => {
    let endStatus = endDate === null;
    if (endStatus === true) endDate = startDate;

    if ( this.state.calendarFocused === 'endDate' && !this.props.filters.startDate  ){

      this.setState(() => ({ errormessage: 'anna alkupäivä ekaksi ' }));
      this.setState(() => ({open:true}));
      this.props.dispatch(setStartDateRed(endDate));
    }
    else  {
      if ( moment(endDate).isBefore(moment(this.state.startDate)) || moment(startDate).isAfter(moment(this.state.endDate))) {
        this.setState(() => ({ errormessage: 'valitse kyselyvuoden päivät ' }));
        this.setState(() => ({open:true}));

      } else {
        if (moment(startDate).isBefore(this.state.startDate) || moment(endDate).isAfter(this.state.endDate)) {
          this.setState(() => ({ errormessage: 'valitse kyselyvuoden päivät' }));
          this.setState(() => ({open:true}))

        } else
        {
          var found = this.props.filters.daysToState.findIndex((da: any) => {
            return moment(startDate).isBetween(da.startDate,da.endDate,'day') && !da.deleted ||
            moment(endDate).isBetween(da.startDate,da.endDate,'day') && !da.deleted ||
            moment(startDate).isSame(da.startDate,'day') && !da.deleted  || moment(moment(startDate,'D.M.YYYY')).isSame(moment(da.endDate,'D.M.YYYY'),'day') && !da.deleted})
          if (found === -1){
            if (this.state.calendarFocused==='endDate') {

              found = this.props.filters.daysToState.findIndex((da:any) => {
                return moment(endDate).isBetween(da.startDate,da.endDate,'day') && !da.deleted ||
              moment(endDate).isSame(da.startDate,'day') && !da.deleted || moment(moment(endDate,'D.M.YYYY')).isSame(moment(da.endDate,'D.M.YYYY'),'day') && !da.deleted })
            }
          }
          if (found !==-1){

            this.setState(() => ({ errormessage: 'päällekkäinen valinta ' }));
            this.setState(() => ({open:true}))
            this.props.dispatch(setStartDateRed(null))
            this.props.dispatch(setEndDateRed(null))
            this.setState(() => ({calendarFocused:'startDate'}))
          } else{
            if (endDate === null && this.state.calendarFocused==='endDate'){
              this.setState(() => ({ errormessage: 'anna alkupäivä ekaksi nulliapukkaa ' }));
              this.setState(() => ({open:true}))
              this.props.dispatch(setStartDateRed(endDate))
              } else {
                this.setState(() => ({ startDatePicked:startDate }));

                this.setState(() => ({ endDatePicked:endDate }));

                this.props.dispatch(setStartDateRed(startDate))
                this.props.dispatch(setEndDateRed(endDate))
                let array =  this.props.filters.daysToState
                if (this.state.calendarFocused==='endDate') {
                  array.push({id:uuidv4() ,startDate:startDate, endDate:endDate, speakerId:this.state.speaker.id})
                  this.props.dispatch(setDaysToState(array))
                  this.setState(() => ({calendarFocused:'startDate'}))
                  this.setState(() => ({dayRangeArray:array}))
                }
                this.props.filters.daysToState.sort ((a: any, b: any) => {
                  var x = moment(a.startDate,'D.M.YYYY').unix()

                  var y = moment(b.startDate,'D.M.YYYY').unix()
                  if (x<y) {return -1}
                  if (x>y) {return 1}
                  else return 0
                })
              }
            }
          //}
        }
      }
    }
  }

  onFocusChange = (calendarFocused: any) => {
    this.setState(( )=> ({calendarFocused}))
  }

  handleChange = (event: any) =>{
    this.setState({ [event.target.name]: event.target.checked });
    //event.preventDefault()
    let arrayTypes = []
    if (this.state.sunAt16 ) arrayTypes.push('1')
    if (this.state.sunAt18 ) arrayTypes.push('2')
    if (this.state.satAt18 ) arrayTypes.push('3')
    if (this.state.wedAt19 ) arrayTypes.push('4')
    if (this.state.regional ) arrayTypes.push('5')
    if (this.state.careof ) arrayTypes.push('6')
    if (this.state.church ) arrayTypes.push('7')
    if (this.state.child ) arrayTypes.push('8')
    if (this.state.tallinn ) arrayTypes.push('9')
    if (this.state.ahvenanmaa) arrayTypes.push('11')
    if (this.state.chairman ) arrayTypes.push('0')
    if (this.state.bible ) arrayTypes.push('10')
    if (event.target.checked) {
      if (event.target.name === 'sunAt16') arrayTypes.push('1')
      if (event.target.name === 'sunAt18') arrayTypes.push('2')
      if (event.target.name === 'satAt18') arrayTypes.push('3')
      if (event.target.name === 'wedAt19') arrayTypes.push('4')
      if (event.target.name === 'regional') arrayTypes.push('5')
      if (event.target.name === 'careof') arrayTypes.push('6')
      if (event.target.name === 'church') arrayTypes.push('7')
      if (event.target.name === 'child') arrayTypes.push('8')
      if (event.target.name === 'tallinn') arrayTypes.push('9')
      if (event.target.name === 'ahvenanmaa') arrayTypes.push('11')
      if (event.target.name === 'chairman') arrayTypes.push('0')
      if (event.target.name === 'bible') arrayTypes.push('10')

      this.props.dispatch(setTypesToState(arrayTypes))
    } else{
      let number =''
      if (event.target.name === 'sunAt16') number = '1';
      if (event.target.name === 'sunAt18') number = '2';
      if (event.target.name === 'satAt18') number = '3';
      if (event.target.name === 'wedAt19') number = '4';
      if (event.target.name === 'regional') number = '5';
      if (event.target.name === 'careof') number = '6';
      if (event.target.name === 'church') number = '7';
      if (event.target.name === 'child') number = '8';
      if (event.target.name === 'tallinn') number = '9';
      if (event.target.name === 'ahvenanmaa') number = '11';
      if (event.target.name === 'chairman') number = '0';
      if (event.target.name === 'bible') number = '10';
      arrayTypes = arrayTypes.filter(ar => {
        return ar != number
      })
      this.props.dispatch(setTypesToState(arrayTypes))

    }
  }
  onSubmit = (e: any) => {
    e.preventDefault()
    if (this.props.filters.daysToState.length !== 0) {
      this.setState(() => ({error: 'Please select leave days!'}))
    } else  {
      this.setState(() => ({error: ''}))
      this.props.onSubmit({

      })
    }

  }
  saveDays = () => {
    submitLeaveTimes(this.state.token,this.props.speaker,this.props.filters)
    const leaveTimes= this.props.filters.daysToState.filter((da: any) =>
      !da.deleted).map((da:any) => ({
      apvm: moment(da.startDate),
      ppvm:moment(da.endDate)
    }) );
    this.props.dispatch(editSpeaker(this.state.speaker.linenumber,{datesArrayFinal:leaveTimes}));
    submitLeaveTypes(this.state.token,this.props.speaker,this.props.filters);
    this.props.dispatch(editSpeaker(this.state.speaker.linenumber,{typesArray:this.props.filters.typesToState}));
    this.props.dispatch(speakerEdited(true));
  }

  render () {
    if (this.state.speakers.length === 0)
      this.onPaluu()
    return (
      <Box sx={{ mt: 2 }}>

        { this.state.open &&
          <div>
            <Snackbar
              open={this.state.open}
              autoHideDuration={6000}
              onClose={this.handleClose}
              message= {this.state.errormessage}
              />
          </div>
        }
        <Box>
          {this.state.error && <p> {this.state.error}</p>}
          <TextField
            sx={{ mt: 1 }}
            label="Speaker"
            autoFocus
            name="speaker"
            value={this.state.speaker.speaker}
            //onChange={this.onChange}
          />
          <TextField
            sx={{ mt: 1 }}
            label="Startdate"
            name="startdate"
            value={moment(this.state.startDate).format('D.M.YYYY')}
            //onChange={this.onChange}
          />
          <Box sx={{ mt: 2 }}>
            <FormControl component="fieldset">
              <FormLabel component="legend">  Mihin tilaisuuksiin ET halua vuoroja?</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={<Switch checked={this.state.sunAt16} onChange={this.handleChange} name="sunAt16" />}
                  label="Su klo 16"
                />
                <FormControlLabel
                  control={<Switch checked={this.state.sunAt18} onChange={this.handleChange} name="sunAt18" />}
                  label="Su klo 18"
                />
                <FormControlLabel
                  control={<Switch checked={this.state.satAt18} onChange={this.handleChange} name="satAt18" />}
                  label="La klo 18"
                />
                <FormControlLabel
                  control={<Switch checked={this.state.wedAt19} onChange={this.handleChange} name="wedAt19" />}
                  label="Ke klo 19"
                />
                <FormControlLabel
                  control={<Switch checked={this.state.regional} onChange={this.handleChange} name="regional" />}
                  label="Alueseurat"
                />
                <FormControlLabel
                  control={<Switch checked={this.state.careof} onChange={this.handleChange} name="careof" />}
                  label="Kuntoutussairaala"
                />
                <FormControlLabel
                  control={<Switch checked={this.state.church} onChange={this.handleChange} name="church" />}
                  label="Kirkkopyhät"
                />
                <FormControlLabel
                  control={<Switch checked={this.state.tallinn} onChange={this.handleChange} name="tallinn" />}
                  label="Tallinna"
                />
                <FormControlLabel
                  control={<Switch checked={this.state.ahvenanmaa} onChange={this.handleChange} name="ahvenanmaa" />}
                  label="Ahvenanmaa"
                />
                <FormControlLabel
                  control={<Switch checked={this.state.child} onChange={this.handleChange} name="child" />}
                  label="Lastenseurat"
                />
                <FormControlLabel
                  control={<Switch checked={this.state.chairman} onChange={this.handleChange} name="chairman" />}
                  label="Keskilta pj"
                />
                <FormControlLabel
                  control={<Switch checked={this.state.bible} onChange={this.handleChange} name="bible" />}
                  label="Raamattutunti"
                />
      
              </FormGroup>
            </FormControl>
          </Box>

          Mihin ajanjaksoihin/päiviin ET halua vuoroja? Klikkaa ja valitse
          <Box sx={{ mt: 1, mb : 0.5 }}>
            <DateRangePicker
              keepOpenOnDateSelect
              hideKeyboardShortcutsPanel
              minimumNights={0}
              openDirection = "up"
              startDateId="MyDatePicker"
              endDateId="your_unique_end_date_id"
              startDate = {this.state.startDatePicked}
              endDate = {this.state.endDatePicked}
              onDatesChange={this.onDatesChange}
              focusedInput={this.state.calendarFocused}
              showClearDates ={true}
              onFocusChange={this.onFocusChange}
              numberOfMonths ={1}
              isOutsideRange = {() => false}
              firstDayOfWeek={1}
              displayFormat = "DD.MM.YYYY"
            />
            </Box>

          <Table sx={{ display: { xs: 'inline-table', md: 'inline-table' }, width: '50%' }}>
            <TableHead>
              <TableRow>
                <TableCell>{'Alkamispv'}</TableCell>
                <TableCell>{'Viikko'}</TableCell>
                <TableCell>{'Päättymispv'}</TableCell>
                <TableCell>{'Viikko'}</TableCell>
                <TableCell>{'Poista'}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
              this.props.filters.daysToState.filter((da: any) => {
                return !da.deleted }).map((days:any, idx: any )=> {

                  return (
                    <TableRow key= {`typeidx_${idx}`} sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}}>
                      <SpeakerDays key= {`typeidxr_${idx}`}  array={this.props.filters.daysToState} idx = {idx} {...days}></SpeakerDays>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>


        </Box>
        <Box>
          <Button sx={ addButtonStyle } variant="contained"  onClick={() => this.saveDays()}>  Talleta  </Button>
          <Button sx={ addButtonStyle } variant="contained"  onClick= {() => this.onPaluu()}>  Paluu</Button>
        </Box>


      </Box>


    )
  }
}
const mapStateToProps = (state: any) => {
  return {

    filters: state.filters,
    speakers: state.speakers,
    auth:state.auth
  }
}
export default connect(mapStateToProps) (SpeakerDatesForm);