/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react';
import 'core-js/stable';
import {   useDispatch, useSelector  } from 'react-redux'
// eslint-disable-next-line no-unused-vars
import ServiceForm from './ServiceForm';
import { setCalendarIds , haveToRead, serviceEdited, setSheetData} from '../../actions/filters';
import submitGetCalendarIdArr from '../calendars/submitGetCalendarIdArr'
import { CircularProgress } from '@mui/material';
import {RootState} from '../../store/ConfigureStore';
import submitGetSheetId from './submitGetSheetId'
type UpdateServicePageComponentType = {
  serviceId?: any,
  filters?: any[],
  close: () => void,
};

const UpdateServicePage = (props: UpdateServicePageComponentType) => {
  // const [ speakers, setSpeakers ] = useState([]);
  const close = props.close;
  const [ loading, toggleLoading ] = useState(false);
  const [service, setService] = useState('');
  //const service: any = props.service;
  const auth: any = useSelector((state: RootState) => state.auth);
  const token = `bearer ${auth.idToken}`;
  const filters: any = useSelector((state: RootState) => state.filters);
  const tspeakers: any = useSelector((state: RootState) => state.speakers);
  const services: any = useSelector((state: RootState) => state.services);
  const dispatch = useDispatch()

  useEffect(() => {
    if (services.length === 0) {
      close();
    }
    const findService = async (id: any) => {
      const service = await services.find((ser: any) => ser.serviceId === id)
      setService(service);
      toggleLoading(false)
      return service;
    }
    toggleLoading(true);

    if (services.length !== 0 ) {
    findService(props.serviceId);

    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.serviceId])

  useEffect(() => {
    if (filters.change &&services.length > 0) {
      dispatch(haveToRead(false))
      toggleLoading(true);

      const retr2 = async (auth: any, year: any) => {
        const doDispatch = true;
        const sheetData = await submitGetSheetId(token,year, dispatch, doDispatch, filters );
        dispatch(setSheetData(sheetData));
        return sheetData;
      }

      const retr = async () => {
        const calendarDataArray1 = await submitGetCalendarIdArr(token);
        dispatch( setCalendarIds(calendarDataArray1))

        if (filters.range.indexOf('Seurakalenteri') === -1) {
          await retr2 (auth, filters.serviceYear)
        }
        toggleLoading(false);
        return calendarDataArray1
      }

      if (services.length) {
        retr() ;
      }
      // const calendarData = retr ();
    }
    else toggleLoading(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.somethChanged])

  useEffect(() => {


    if (filters.serviceEdited) {
      close()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filters.somethchanged, filters.serviceEdited])

  useEffect(() => {

    const disp = async (dispatch: any) => {
     
        await dispatch(serviceEdited(false))
      }

      disp(dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filters.serviceEdited])


  if (loading) return  <div><CircularProgress /> <br />...tarkistetaan oikeuksia</div>
  if (!filters.change) return (<div> <br />. Ei vaadittuja oikeuksia</div>)


  return (
    <div>
        <ServiceForm
          service={service}
          services2 = { services}
          speakers={tspeakers}
          auth=   {auth}
          filters= {filters}
        />

    </div>


  )
}

export default  UpdateServicePage;
