import database from './firebase';
import { loadOnce} from '../actions/services';

const AddFireToService =  async (servYear: any, uid: any, services: any, dispatch: any) => {

  const serviceList: any = []
  database.ref(`users/${ uid }/serviceYear/${servYear}/serviceProgram`)
    .once('value')
    .then((snapshot: any) => {
      snapshot.forEach((childSnapshot: any) => {
        serviceList.push({
          serviceId:childSnapshot.key,
          ...childSnapshot.val()
        })
      });
      dispatch(loadOnce(serviceList));
    })

}
export default AddFireToService;