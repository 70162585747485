/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */

import programService from '../../services/programService'
const SubmitGetSheet = async (token: any, sheetId: any, range: any) => {
  try {
    const returnable = await programService
      .getSheet(token,sheetId, range)
    return returnable
  }catch(error) {
    console.log('error getting sheets: SubmitGetSheet',error)
  }

}

export default SubmitGetSheet;