/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
import { v4 as uuidv4 } from 'uuid';
import database from '../firebase/firebase';



export const addGroups= (groupsData) => ({
  type: 'ADD_GROUPS',
  groupsData
});
export const updateMember = (groupid, memberid, member) => ({
  type: 'UPDATE_MEMBER_OF_GROUP',
  groupid, memberid, member
})
export const emptyGroups= () => ({
  type: 'EMPTY_GROUPS',

});
export const addMembersToGroup= (id, members) => ({
  type: 'ADD_MEMBERS_TO_GROUP',
  id, members
});
export const addOwner= (owner) => ({
  type: 'ADD_OWNER',
  owner
});