
import moment from 'moment'
import { colors } from '../../styles/themeStylesSx';
import {Box, Table, TableBody, TableHead,TableRow, TableCell, Typography } from '@mui/material';

const nameArray: any = ['kesk','su16','su18', 'la18', 'ke19', 'alue', 'lait', 'kirk', 'last', 'tall', 'raam'];


  type UpdateServicePageComponentType = {
    leaveArray?: any[],
    typeArray?: any[],
    speaker?: string,
    close: () => void,
  };
const speakersLeaveList = (props: UpdateServicePageComponentType) => {
return (

  <Box sx={{width: '40%'}}>
    <Typography variant="h6">{props.speaker} vapaat </Typography>
    <Table sx={{ display: { xs: 'inline-table', md: 'inline-table', color: 'secondary.main' }}}>
      <TableHead>
        <TableRow sx={{ borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
          <TableCell sx = {{width:'20%'}}> {'Alkamispäivä' }</TableCell>
          <TableCell sx = {{width:'20%'}}> {'Päättymispäivä' }</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>


      {
        props.leaveArray?.map((row:any, i: any) => {
          const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
          return (
            <TableRow sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${i}_row17`}>
              <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>
                { moment(row.apvm).format('D.M.YYYY') }
                </TableCell>
                <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>
                { moment(row.ppvm).format('D.M.YYYY') }
                </TableCell>
            </TableRow>
          )
        })
      }
      </TableBody>
    </Table>
    <Table sx={{ display: { xs: 'inline-table', md: 'inline-table', color: 'secondary.main' }}}>
      <TableHead>
        <TableRow sx={{ borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
          <TableCell sx = {{width:'20%'}}> {'Palvelun tyyppi' }</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>


      {
        props.typeArray?.map((row:any, i: any) => {
          const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
          return (
            <TableRow sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${i}_row18`}>
              <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>
                {  nameArray[row] }
                </TableCell>
            </TableRow>
          )
        } )
      }
      </TableBody>
    </Table>
  </Box>
  )
}
export default speakersLeaveList;