/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Box, Typography, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import {RootState} from '../../../store/ConfigureStore';
import { colors } from '../../../styles/themeStylesSx';
import { subHeaderStyles, headerStyles } from '../../../styles/themeStylesSx';
import userServices from '../../../services/userServices';

type DeleteGroupType = {
  member: any,
  group?: any,
  open?: boolean,
  members?: any,
  close: () => void,
};
const QueryOtherGroups =  (props: DeleteGroupType) => {
  const authh: any = useSelector((state: RootState) => state.auth);
  const groupActuel = props.group;
  const [ loading, toggleLoading ] = useState(false);
  const [inclGroups, setInclGroups]= useState([])
  const token: any = `bearer ${authh.idToken}`;
  let groupId = '';
  if ( groupId )
    groupId = groupActuel.id;
  useEffect( () => {
    const readGroups = async (primaryEmail: string) => {       
      try {
        const groups: any = await userServices
        .inWhichGroups(token, primaryEmail);
        setInclGroups(groups.data); 
        toggleLoading(false);
      }catch(error) {
        console.error('Error fetching drivelist', error)
      }
    }

    toggleLoading(true);
    readGroups(props.member.email);   
  },[]) 
  if (loading) return (<div><CircularProgress/> ..circucircu </div>);

 
  return (
    <Box>
  
      <Box>
        { (groupActuel) &&
        <Box>
          <Typography sx={ headerStyles }>{`${props.member.email} ryhmässä ${groupActuel.name} `}</Typography>
          <Typography sx={ subHeaderStyles }>{`on myös ryhmissä:  `}</Typography>
          <Table>
            <TableHead>
              <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
                <TableCell>{'Ryhmä'}</TableCell>
                <TableCell>{'Emil'}</TableCell>
              </TableRow>
            </TableHead>
      
            <TableBody>
            {
              inclGroups.map((gr: any, i: any) => {
                const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
                return (
                  <TableRow sx={{backgroundColor, height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key = {i}>
                    <TableCell>{gr.name}</TableCell>
                    <TableCell>{gr.email}</TableCell>
                  </TableRow>
                )
              })
            }
            </TableBody>
          </Table>
        </Box>
        }
      </Box>
    </Box>
  )
}

export default QueryOtherGroups
