/* eslint-disable linebreak-style */
//import React, { useState, useEffect }  from 'react'
import programServices from '../../services/programService'
import {editDriveSheetId} from './../../actions/drives'
// import database from '../firebase/firebase';
const submitSheetsToGsheet = async(token, uid, sheetId, drivesx, dispatch) => {
  const sheetIdArray = []
  for (const drive of drivesx ) {
    const sheetOfGsheetResponse = await programServices
      .updateSheetsToGSheet(token,sheetId,drive.driveName, dispatch)
      .catch( error => {
        console.error('Error fetching permissions', error)
      })
    dispatch(editDriveSheetId({
      driveId : drive.driveId,
      driveSheetId : sheetOfGsheetResponse.replies[0].addSheet.properties.sheetId
    }))
    const sheetIdx = [
      drive.driveId,
      sheetOfGsheetResponse.replies[0].addSheet.properties.sheetId
    ]
    sheetIdArray.push(sheetIdx)
  }
  return sheetIdArray
}
export default submitSheetsToGsheet