/* eslint-disable semi */
/* eslint-disable linebreak-style */
import driveServices from '../../services/driveServices';
import {editPermission} from '../../actions/drives'
import database from '../../firebase/firebase';

const SubmitPermissionUpdateOfDrive = async (
      token: any,
      driveId: any,
      permission: any,
      uid: any,
      role: any,
      dispatch: any) => {

  const translatedPermissionValues = ['Sisällönhallinta','Ylläpitäjä','Katsoja', 'Tekijä'];
  const translatedPermissionValue = translatedPermissionValues[['fileOrganizer','organizer','reader','writer','5','6','7','8'].indexOf(role)];
  await driveServices
    .permissionupdate(token, driveId, permission.permissionId,role)
    .catch( (error: any) => {
      console.error('Error fetching permissions', error)
    })
    await database.ref(`users/${uid}/driveList/${permission.drivesId}/permissions/${permission.id}/role`).set(
      role
    )
    await database.ref(`users/${uid}/driveList/${permission.drivesId}/permissions/${permission.id}/permissionRole`).set(
      role
    )
    await database.ref(`users/${uid}/driveList/${permission.drivesId}/permissions/${permission.id}/translatedPermissionValues`).set(
      translatedPermissionValue
    )
    .then(() => {
      permission.role= role
      permission.permissionRole = role
      permission.translatedPermissionValues = translatedPermissionValue
      const driveId = permission.driveId;
      dispatch(editPermission({driveId, permission }))
      //toggleLoading(false);
    })
    .catch((e: any) => {
      console.log('Did not update data,',e)
    })
    //if (loading) return <CircularProgress/>
  return permission
}
export default SubmitPermissionUpdateOfDrive