/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */

import React, { useState, useEffect } from 'react' ;
import { useSelector } from 'react-redux'
import TransferUserToGroups from './TransferUserToGroups';
import {RootState} from '../../store/ConfigureStore';
import { Box, Grid, TextField, Typography, CircularProgress } from '@mui/material';
import { subHeaderStyles } from '../../styles/themeStylesSx';

type AddUserType = {
    member?: any,
    groups?: any,
    open?: boolean,
    openDelete?: any,
    close: () => void,
    userEmail: string,
  };

const AddUserToSeveralGroups = (props: AddUserType) => {
  const filters: any = useSelector((state: RootState)=> state.filters);
  const selectedGroups = props.groups;
  const [ loading, toggleLoading ] = useState(false);
  const [groups, setGroups] = useState<any>([]);
  const close = props.close;
  

  const [state, setState] = useState<any>({
    kind: '',
    etag: '',
    id: 0,
    userEmail:  '',
    role: '',
    type: 'any',
    status: '',
    käyttäjä: '',
    openAdd: false,
    userId: 0,
    groupId: '',

  })
  useEffect (() => {
   
    if (filters.loading) {
     <CircularProgress/>
    }
    
  },[selectedGroups])  

  useEffect (() => {
    if (selectedGroups) {
      toggleLoading(true);
      setGroups( selectedGroups.map((group: any) => {return group.email}));
      toggleLoading(false);
      setState({...state, });
    }
  },[selectedGroups, filters.haveToReadDrive]);

  if (loading ) return   <CircularProgress />
 
  const handleChange = async (name: any, value: string) => { 
    setState({ ...state, [name]: value });
    const groupArray =  selectedGroups.map((group: any) => {return group.email})
      .filter((group: any) => group
      .toLowerCase()
      .includes(value.toLowerCase())   
    )
    setGroups(groupArray);
  }  

  return( 
    <Box>
       <Box>
        <Typography sx={ subHeaderStyles }>{`Valitse  käyttäjälle: ${props.userEmail} vasemmasta sarakkeesta ryhmät, joihin hänet liitetään`}</Typography>
      </Box> 
      <Box>
        <TextField
          sx={{ mt: 1, pl:1 }}
          label={'Kirjoita ryhmän nimeä, johon käyttäjä lisätään'}
        
          rows={1}
          variant="outlined"
          fullWidth
          value={ state.käyttäjä}
          onChange={(e: any) => handleChange('käyttäjä', e.target.value)}
        />
    
      <Box>
        <Grid item xs ={2}>
          { TransferUserToGroups(groups, props.userEmail,  close ) } 
        </Grid>  
      </Box>
    </Box>
  </Box>
  )
}

export default AddUserToSeveralGroups;