/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
import axios from 'axios'

//const groupListUrl = 'http://localhost:3001/api/groupsList';
// const usersGroupListUrl = 'http://localhost:3001/api/usersGroupList';
// const listMembersOfGroup = 'http://localhost:3001/api/listMembersOfGroup';
// const deleteMemeberOfGroup = 'http://localhost:3001/api/deleteMemeberOfGroup';;
//const membersHasMemberUrl = 'http://localhost:3001/api/membersHasMember';


const listGroupRole = `${process.env.REACT_APP_API_URL}/api/listGroupRole`;
const groupListUrl = `${process.env.REACT_APP_API_URL}/api/groupsList`;
const usersGroupListUrl = `${process.env.REACT_APP_API_URL}/api/usersGroupList`;
const listMembersOfGroup = `${process.env.REACT_APP_API_URL}/api/listMembersOfGroup`;
const deleteMemeberOfGroup = `${process.env.REACT_APP_API_URL}/api/deleteMemberOfGroup`;
const updateMemeberOfGroup = `${process.env.REACT_APP_API_URL}/api/updateMemberOfGroup`;
const membersHasMemberUrl = `${process.env.REACT_APP_API_URL}/api/membersHasMember`;
const insertMemberToGroupUrl =  `${process.env.REACT_APP_API_URL}/api/insertMemberToGroup`;

const groupList = async(token: string) => {
  const config = {
    headers: { Authorization: token },
  }
  const request =  axios.get( groupListUrl, config)

  return request.then(response => {
    return response.data
  })
}
const usersGroupList = async(token: string, user: string) => {
  const config = {
    headers: { Authorization: token },
  }
  const request =  axios.get( `${usersGroupListUrl}/memberKey=${user}`, config)
  return request.then(response => {
    return response.data
  })
}
const groupMemberList = async (token: string, id: string) => {
  const config = {
    headers: { Authorization: token },
  }
  try {
    const request =  await axios.get(`${listMembersOfGroup}/${id}`, config)
    return request.data;
  }catch (error) {
    const response =  `Error in getAll ${id} `;
    console.log( `Error in getAll ${id} `);
    return response;
  }
};

const groupRoleList = async (token: string, id: string, role: string) => {
  const config = {
    headers: { Authorization: token },
  }
  try {
    const request =  await axios.get(`${listGroupRole}/${id}/${role}`, config)
    return request.data;
  }catch (error) {
    const response =  `Error in groupRoleList ${id} `;
    console.log( `Error in groupRoleList in groupServices ${id} `);
    return response;
  }
}
const deleteGroupMember = async (token: string, groupId: string, memberId: string) => {
  const config = {
    headers: { Authorization: token },
  }
  try {
    const request = axios.delete(`${deleteMemeberOfGroup}/${groupId}/${memberId}`, config)
    return (await request).data; 
  }catch (error) {
    const response =  `Error in deleteGroupMember ${groupId} ' '  ${memberId}`;
    console.log( `Error in deleteGroupMember ${groupId} ' '  ${memberId}` );
    return response;
  }
};
const getMemberOfGroup = async (token: string, groupId: string, memberId: string) => {
  const config = {
    headers: { Authorization: token },
  }
  try {
    const request = axios.delete(`${getMemberOfGroup}/${groupId}/${memberId}`, config)
    return (await request).data; 
  }catch (error) {
    const response =  `Error in getMemberOfGroup ${groupId} ' '  ${memberId}`;
    console.log( `Error in getMemberOfGroup ${groupId} ' '  ${memberId}` );
    return response;
  }
};


const updateGroupMember = async (token: string, groupId: string, memberId: string, data: any) => {
  const config = {
    headers: { Authorization: token },
  }
  try {
    const request = axios.post(`${updateMemeberOfGroup}/${groupId}/${memberId}`, data, config)
    return (await request).data; 
  }catch (error) {
    const response =  `Error in updateGroupMember ${groupId} ' '  ${memberId}`;
    console.log( `Error in updateGroupMember ${groupId} ' '  ${memberId}` );
    return response;
  }
}

const membersHasMember =  async (token: string, groupId: string, memberId: string) => {
  const config = {
    headers: { Authorization: token }
  }
  const request = await axios.get(`${membersHasMemberUrl}/${groupId}/${memberId}`, config)

  return request.data;
}

const insertMemberToGroup =  async (token: string, groupId: string, memberId: string, dispatch : any, editFilterData: any) => {
  const config = {
    headers: { Authorization: token },
  }
  try {
    const request = await axios.post(`${insertMemberToGroupUrl}/${groupId}/${memberId}` ,{} , config);
    return request.data;
  }catch( error: any ) {
    console.log('Error situation in groupServices: insertMemberToGroup ', error);
    console.log('ererer',error.response)
    if (error.response.status) {
      if (error.response.status === 409) {
        dispatch(editFilterData({error : true, errorMessage : `Duplicate member: ${memberId}` }))
        return error.response;
      } else {
        dispatch(editFilterData({error : true, errorMessage : `Erroro Code ${error.response.status} inserting  member: ${memberId}` }));        
      }
    } else {
      dispatch(editFilterData({error : true, errorMessage : `Something wrong inserting  member: ${memberId}` }));      
    }   
  }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  groupList : groupList,
  groupMemberList:groupMemberList,
  deleteGroupMember:deleteGroupMember,
  membersHasMember: membersHasMember,
  insertMemberToGroup:insertMemberToGroup,
  usersGroupList:usersGroupList,
  updateGroupMember:updateGroupMember,
  groupRoleList: groupRoleList,
  getMemberOfGroup: getMemberOfGroup,
}