import database from './firebase'


const AddServiceToFire: any = async (serviceProgramData: any, servYear: any, uid: any) => {
  const {
    type ='',
    excluded='',
    Vko='',
    Vkonpäivä='',
    StartDate='' ,
    StartTime='',
    EndDate='',
    EndTime='',
    Pm='',
    subject='',
    Selite='',
    Location='',
    Toimikunta='',
    Private='',
    sspeaker='',
    Seuraisäntä='',
    Säestäjä='',
    Esilaulaja='',
    Valvontaryhmänvetäjä='',
    Englannintulkkaus='',
    Eestintulkkaus='',
    Viittomakielentulkkaus='',
    Äänentoisto='',
    Siivousryhmä='',
    Kassaryhmä='',
    linenumber='',
    strike='n',
    lastUpdater='',
  }: any = serviceProgramData

const service = { type, excluded, Vko, Vkonpäivä,  StartDate, StartTime,  EndDate,  EndTime, Pm, subject, Selite, Location, Toimikunta, Private, sspeaker, Seuraisäntä, Säestäjä,
  Esilaulaja, Valvontaryhmänvetäjä, Englannintulkkaus,  Eestintulkkaus,  Viittomakielentulkkaus, Äänentoisto, Siivousryhmä, Kassaryhmä, linenumber, strike, lastUpdater }
await database.ref(`users/${uid}/serviceYear/${servYear}/serviceProgram`).push(service).then((ref: any)  => {})
}
export default AddServiceToFire;