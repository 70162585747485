/* eslint-disable linebreak-style */
//import { connect, useSelector } from 'react-redux';
import submitGetSheet from './SubmitGetSheet'
import {editFilterData} from '../../actions/filters'
const submitSheetRetr = async (token: any, sheetId: any, range: any, strikeArray: any [], dispatch: any) =>{

  const sheetsData = await submitGetSheet(token, sheetId, range);
  if (!sheetsData.sheets) {
    console.log('error sitaution', sheetsData)
    if (sheetsData === 'idtoken expired') {
      dispatch(editFilterData({error: true, errorMessage:sheetsData}));
    }
    else {
      dispatch(editFilterData({error: true, errorMessage:' Parametrivirhe, Sheet-määritykset puuttuvat ko vuodelle tai sitten virhe'}));
    }
  

    return strikeArray
  }
  else {
    sheetsData.sheets[0].data[0].rowData.forEach((rivi: any) => {
      if (rivi.values) {
        if (rivi.values[0].userEnteredFormat) {
          if (rivi.values[0].userEnteredFormat.textFormat) {
            if (rivi.values[0].userEnteredFormat.textFormat.strikethrough) {
              strikeArray.push('y')
            } else
            {
              strikeArray.push('n')
            }
          } else {
            strikeArray.push('n')
          }
        } else strikeArray.push('n')
      } else strikeArray.push('n')
    })
    return strikeArray
  }
  
}
export default submitSheetRetr