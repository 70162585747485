/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import { Box, CircularProgress, IconButton, Tooltip, Typography, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import { RootState } from '../../../store/ConfigureStore';
import { lineStyles } from '../../../styles/themeStylesSx';
import {colors} from '../../../styles/themeStylesSx';
import submitListMembersOfGroup from '../submitListMembersOfGroup';
import submitGetSheetId from '../../service/submitGetSheetId';
import { editFilterData } from '../../../actions/filters';
import programService from '../../../services/programService';
import DeleteGroupMember from './DeleteGroupMember';
import { addMembersToGroup } from '../../../actions/groups';
import SidePanel from '../../sidepanel/SidePanel';

import { DeleteRounded } from '@mui/icons-material';

type sheetGroup = {
  sheetNames: any;
  groupEmail: string;
  groupId: any;
  ownerName: string;
  close: () => void;
  token: string;
 
};
type GroupStateType = {
  open?: any;
  member?: any;
  groupId?: any;
  id?: string;
  openDelete? : any;
  members? : any,
  addUser? : any;
  close?: boolean;
  change?: boolean;
  memberId?: string;
  memberName?: any;
  groupName?: any;
  group?: any;
};

  const CompSheetToGroup =  (props: sheetGroup ) => {
    const workGroupId = props.groupId;
  const [members, setMembers] = useState<any>([]);
  const [loading, toggleLoading] = useState(false);
  const dispatch = useDispatch();
  const close = props.close;
  const filters: any = useSelector((state: RootState) => state.filters);
  const auth: any = useSelector((state: RootState) => state.auth);
  const groups: any = useSelector((state: RootState) => state.groups);
  const [state, setState] = useState<GroupStateType>({ members: [], groupId: '', id: ' ', close: false, change: false });
  const users: any = useSelector((state: RootState)=> state.users);
  const token = `bearer ${auth.idToken}`;
  const [sheetNames, setSheetNames ] = useState<any>([]);
  const [actuelGroup, setActuelGroup] = useState<any>();
  useEffect( () => {
    if (state.close){
      setState({...state, close: false});
      dispatch(editFilterData({somethchanged: Math.random()}))
      close()
    }    
  }, [close]);

  useEffect(() => {
    let returnable: any = [];

    const membersFunc = async (id: any, sheetArray: any ) =>  {
      
      try{
        const memberList = await submitListMembersOfGroup(props.token, id);
        let userNameW: any = ({ name: {familyName: ' ', givenName: ' ', fullName: ' ' }})
        let nameArray: any = [];
        let workGivenName: string = ' ';
        let membersData: any = '';
        let userNameFromUsers: any = '';

        for (const line of memberList) {         
          const userName = users.find((user: any) => { return user.id === line.id });
          if (userName) {
            const wwork = userName.name.familyName + ' ' + userName.name.givenName;
            userNameFromUsers = sheetArray.find((sh: any) => sh.name.includes(wwork));
          
            if (!userNameFromUsers){
                userNameFromUsers = sheetArray.find((sh: any) => sh.email === line.email);
            } 
            if (!userNameFromUsers){
              const work: any = userName.name.givenName + ' ' +userName.name.familyName ;
              userNameFromUsers = sheetArray.find((sh: any) => sh.name.includes(work));
            } ;
            if (!userNameFromUsers) userNameFromUsers = ({ name: '!!!!!!!!!!!!Tarkista, kuuluuko ryhmään!!!!'});
            userNameW = userName.name;
            workGivenName = userName.name.givenName;
            membersData = '';
          } else {
            userNameFromUsers = '';
            userNameW = ({ givenName: ' ', familyName: line.email});
            workGivenName =line.email
            membersData = ({
              membersEmail: line.email,
              userNameFromSheet: sheetArray.find((sh: any) => sh.email.includes(line.email))
          });
          userNameFromUsers = ({ name: '!!!!!!!!!!!!Tarkista, kuuluuko ryhmään-nimeä ei löydy!!!!'});
          }
        
          const nameWork : any = workGivenName.split(' ');
      
          if (nameWork.length > 1) {
             workGivenName =  nameWork[0];
          }
          const str: any = ({
            ...line,
            ...userName,
            compareName: userNameW.familyName + ' ' + userNameW.givenName, 
            nameSheet: userNameFromUsers.name,
            ...membersData,
          });
          nameArray.push(str);
        }          
         
        const work = await nameArray!.sort((a: any, b: any) => {
          var x = a.compareName
          var y = b.compareName
          if (x < y) {return -1}
          if (x > y) {return +1}
          if (x === y) {return 1}
        });
     
    

        dispatch(addMembersToGroup(id,work));  
        setMembers(work);
        returnable = work; 
       // dispatch(addMembersToGroup(id,work));  
      } catch(error) {
        console.log('erroro from membersfunc in listgroupmembersimple', error);
      }  
      // toggleLoading(false);   
      return     returnable;
    }

    const compareGroupName = async (groupId: any, sheetArray: any ) => {
      await membersFunc(groupId, sheetArray);     
      toggleLoading(false)
    }
    const actuel = groups.find((gro: any) => {
      return gro.id === props.groupId;    
    });
    
    setActuelGroup(actuel);
    toggleLoading(true);
    compareGroupName(actuel.id, props.sheetNames);
  },[props.groupId ]);
  
  if (loading) return ( <div> lataa groupsmembereitä <CircularProgress/> </div>)  
  if (!filters.drive) return (<div> <br />. Ei vaadittuja oikeuksiaa</div>);

  return (
    <Box>
      <SidePanel
        open={ state.openDelete === true }
        close={ () => setState({ open: false })}
        >
          <Box>
            { state.openDelete &&
              <DeleteGroupMember member = {state.member} group = {state.group } members = {groups.find((group: any) => group.id === props.groupId)
                .members.map((member: any, i: number) => {return member})} open = {state.openDelete} openDelete = {state.openDelete} close ={ () => setState({ openDelete: false })} />
            }
          </Box>
        </SidePanel>
      {actuelGroup && 
        <Typography sx={ lineStyles }>{`Ryhmä ${actuelGroup.name} Seuraohjelma: ${filters.serviceYear},  puuttuvat nimet` }</Typography>
      }
      <Box>
        {members.length> 0 &&
          <Table>      
            <TableHead>
              <TableRow>
              <Tooltip title="Poista jäsen Google-ryhmästä" arrow>
                <TableCell sx = {{ md: 30, xs: 20 , fontWeight:800}}>
                 X
                </TableCell>
                </Tooltip>
              <Tooltip title="Jäsenen nimi Google-ryhmässä" arrow>
                <TableCell sx = {{ md: 30, xs: 20 , fontWeight:800}}>
                  Nimi Google-ryhmässä
                </TableCell>
                </Tooltip>
                <Tooltip title="Nimi on Google-ryhmässä, mutta ei seuraohjelman ao. luettelosssa. Tsekkaa, voiko poistaa Google-ryhmästä" arrow>
                <TableCell sx = {{ md: 30, xs: 20 , fontWeight:800}}>
                  Nimi Seuraohjelmassa
                </TableCell>
                </Tooltip>
                <Tooltip title="Jäsenen tunnus google-ryhmässä" arrow>
                <TableCell sx = {{ md: 30, xs: 20 , fontWeight:800}}>
                  email Google-ryhmässä
                </TableCell>
                </Tooltip>                
              </TableRow>
            </TableHead>
            <TableBody>
            { groups.find((group: any) => group.id === props.groupId)
             .members.map((member: any, i: number) => {
               const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
               return (
                <TableRow
                  key={i}>
                      <TableCell sx={{
                  backgroundColor,
                  borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>
                  <IconButton  onClick={() => setState({ open: false, openDelete: true, addUser: false, members: members, member: member, group: actuelGroup   })}><DeleteRounded /></IconButton>
                </TableCell>
                  <TableCell  sx={{
                  backgroundColor,
                  borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>
                    {member.compareName}
                  </TableCell>                  
                  {member.userNameFromSheet ? 
                    <TableCell  sx={{
                      backgroundColor,
                      borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>
                     {member.userNameFromSheet.name}
                    </TableCell>
                    :
                    <TableCell sx = { member.nameSheet && member.nameSheet.includes('!!!') ? {fontWeight:800,  color:'red', backgroundColor }: {fontWeight: 100, backgroundColor} }>
                    {member.nameSheet}
                   </TableCell>                   
                  }
                    <TableCell  sx={{
                      backgroundColor,
                      borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>
                    {member.primaryEmail}
                   </TableCell>                 
                </TableRow>
              )
            })
            }           
          </TableBody>
        </Table>
      }      
      </Box>
    </Box>
  )
}
export default CompSheetToGroup