import programService  from "../../services/programService";
import { editFilterData } from "../../actions/filters";
import { startRemoveDatabase } from "../../actions/services";
import AddServiceToFire from "../../firebase/AddServiceToFire";
import driveServices from "../../services/driveServices";
import { updateServiceData } from "../../actions/filters";
import moment from "moment";

const ReadAndStoreServiceProgram = async(sheetid: any, range: any, year: any, strikeArray: any, dispatch: any, filters: any, auth: any) => {

  const getServiceProgram = async (sheetid: any, range: any, year: any, strikeArray: any, dispatch: any, filters: any, auth: any) => {
    const token = `bearer ${auth.idToken}`
    let initialProgram: any = [];  
    let serviceArray: any = [];
    dispatch(startRemoveDatabase(filters.serviceYear));
    try{
      const initialLines = await programService
      .getAll(token,year,sheetid,range)
      initialProgram = initialLines;
    }catch(error) {
      console.error('Error fetching serviceprogram ServiceReports', error);
      dispatch(editFilterData({error: true, errorMessage: 'Seuraohjelman latausvirhe ReadAndStoreServiceProgram'}))
    }      
    
    if (!Array.isArray(initialProgram)) {
      initialProgram = []
      dispatch(editFilterData({error: true, errorMessage: 'error when reading serviceprogram'}));

    } else {
      let riviCount = 0;
      initialProgram.forEach((rivi: any) => {
        riviCount = riviCount + 1;
        rivi[25] = riviCount
      })
    
      initialProgram.forEach((sl: any) => {
        let striked = strikeArray[sl[25]-1];
        const uid = auth.uid;
        const serviceLine =  ({
          type :sl[0],
          excluded :sl[1],
          Vko : sl[2],
          Vkonpäivä : sl[3],
          StartDate : moment(sl[4],'D.M.YYYY').format('D.M.YYYY'),
          StartTime : moment(sl[5], 'HH.mm').format('HH.mm'),
          EndDate : moment(sl[6],'D.M.YYYY').format('D.M.YYYY'),
          EndTime : moment(sl[7], 'HH.mm').format('HH.mm'),
          Pm : sl[8],
          subject : sl[9],
          Selite : sl[10],
          Location : sl[11],
          Toimikunta : sl[12],
          Private : sl[13],
          sspeaker  :sl[14] ? sl[14] : ' ',
          Seuraisäntä : sl[15],
          Säestäjä : sl[16],
          Esilaulaja : sl[17],
          Valvontaryhmänvetäjä : sl[18],
          Englannintulkkaus : sl[19],
          Eestintulkkaus : sl[20],
          Viittomakielentulkkaus : sl[21],
          Äänentoisto : sl[22],
          Siivousryhmä : sl[23],
          Kassaryhmä : sl[24],
          linenumber : sl[25],
          lastUpdater: sl[27],
          strike:striked,
        });
      AddServiceToFire(serviceLine,filters.serviceYear, uid);
      serviceArray.push(serviceLine);
      })
      const fieldstoretr: string = 'modifiedTime,name'
      const file = await driveServices
        .fileget(token,sheetid,fieldstoretr)
        .catch( error => {
          console.error('Error fetching  drivelist', error)
        });
      if (!file.modifiedTime) {
        dispatch(editFilterData({error: true, errorMessage: file}))  
      } 
      else {
        const lastChangeTimestamp = file.modifiedTime;
        dispatch(updateServiceData(
          file.name,
          lastChangeTimestamp,
          filters.serviceYear));
      }        
    }
    return serviceArray;
  }
const serviceArray = await getServiceProgram(sheetid, range, year, strikeArray, dispatch, filters, auth);

return serviceArray;
}
export default ReadAndStoreServiceProgram;