/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable linebreak-style */

import React, { useState, useEffect } from 'react' ;
import { useSelector , useDispatch} from 'react-redux';
import submitGenCalUpd from '../calendars/submitGenCalUpd';
import programService from '../../services/programService';
import { subHeaderStyles } from '../../styles/themeStylesSx';
import moment from 'moment'
import { editFilterData } from '../../actions/filters';
import {RootState} from '../../store/ConfigureStore';
import { Box, Button, FormControl, FormControlLabel, Switch, InputLabel, MenuItem, Select, Snackbar,  TextField, Typography, CircularProgress } from '@mui/material';
import {ServiceLineType} from './../../types/serviceLine';
import submitGetCalendarIdArr from '../calendars/submitGetCalendarIdArr';
import { GenGetAllSubSheets } from './genComponents';
import { startEditServiceProgram } from '../../actions/services';

import { setCalendarIds} from '../../actions/filters';
let subjects: any = [];
let sortedSubject: any = [];
type UpdateServiceLineComponentType = {
  serviceId?: any,
  close: () => void,
};

const UpdateServiceLine = (props: UpdateServiceLineComponentType) => {
  const close = props.close;
  const dispatch = useDispatch();
  const filters: any = useSelector((state: RootState)=> state.filters);
  const services: any = useSelector((state: RootState)=> state.services);
  const auth: any = useSelector((state: RootState) => state.auth);
  const [ loading, toggleLoading ] = useState(false);
  const token = `bearer ${auth.idToken}`;
  const [arrays, setArrays] = useState<any>([]);

  const [state, setState] = useState<ServiceLineType>({
     serviceId: '',
    type: '',
    excluded: '',
    Vko: 0,
    Vkonpäivä: '',
    StartDate: moment(),
    StartTime: '',
    EndDate: moment(),
    EndTime: '',
    Pm: '',
    subject: '',
    Selite: '',
    Location: '',
    Toimikunta: '',
    Private: '',
    sspeaker: '',
    Seuraisäntä: '',
    Säestäjä: '',
    Esilaulaja: '',
    Valvontaryhmänvetäjä: '',
    Englannintulkkaus: '',
    Eestintulkkaus: '',   
    Viittomakielentulkkaus: '',
    Äänentoisto: '',       
    Siivousryhmä: '',
    Kassaryhmä: '', 
    linenumber: 0,
    strike: '', 
    email: auth.email,  
    PmTrueFalse: false,
    originalLocation: '',
    originalStartDate: '',
    originalStartTime: '',
    originalEndDate: '',
    originalEndTime :'',
    originalSubject : '',
    originalSelite: '',
  })

  useEffect (() => {
    if (filters.loading) {
     <CircularProgress/>
    }
    const findService = async (id: any) => {
      const service = await services.find((ser: any) => ser.serviceId === id );
      setState({ ...service, 
        PmTrueFalse: service.Pm === 'k' ? true :false,
        originalLocation: service.Location, 
        originalStartDate: service.StartDate, 
        originalStartTime : service.StartTime,
        originalEndDate: service.EndDate,
        originalEndTime: service.EndTime,    
        originalSubject : service.subject  
      })

      toggleLoading(false)
    };

    toggleLoading(true);
    if (props.serviceId !== undefined) {
      findService(props.serviceId);
    };    
  },[props.serviceId])  
  
  useEffect(() => {
    if (props.serviceId === undefined) {
      close()
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.serviceId])

  useEffect (() => {
    if (services.length === 0) {
      close()
    }
     
    else {
      // const getAllSubSheets = async (year: any, callTable: any) => {
      //   await callTable.forEach((curr: any) => {
      //     subSheetLines.push(filters.sheetYears[filters.sheetYears.findIndex((fi: any) => {return fi[0] === curr + year})]  )
      //   })
      //   const param = subSheetLines.map((cols: any) => {
      //     return ({sheetid: cols[1], rangeid:cols[2]})
      //   });      
      //   let initialLines: any = []
      //   const initialRows = await programService
      //     .getAllSubSheets(token, param);
      //     if (Array.isArray(initialRows)) {
      //       initialLines = initialRows;
      //     }
      //     else {
      //       initialLines = [];
      //       dispatch(editFilterData({error: true, errorMessage: 'UpdateServiceLine failed, check sheetId params '}));
      //     };
      //     return initialLines;
      // }

      const getSubjects = async () => {
        const allSubjects = services.reduce ((results: any, data: any) => {
          const result = results;
          result[data.subject] = result[data.subject] || []
          result[data.subject].push ({
            sspeaker : data.sspeaker    
          })
          return result;
        },{})
        return allSubjects
      }
      const callStates = async () => {
        const callTable = ['types','TMK-nimet', 'location', 'seuraIsannat', 'saestajat', 'esilaulajat', 'valvojat','aanentoisto','tulkit'];
        const sheetArray = await GenGetAllSubSheets(dispatch, filters, token, filters.serviceYear, callTable);
        subjects = await getSubjects();
        sortedSubject = Object.keys(subjects)
        .sort(( a: any,b: any ) => {
          var x: any= a
          var y: any = b
          if (x < y) {return -1}
          if (x > y) {return 1}
          else return 0
        })
        //.filter((fil) =>{ return !fil.toLowerCase().includes('seurat')})
        setArrays({typesValues: sheetArray[0], TMKValues: sheetArray[1], locationValues: sheetArray[2], 
          seuraIsannat: sheetArray[3], saestajat: sheetArray[4], esilaulajat: sheetArray[5],
          valvojat: sheetArray[6], aanitarkkailijat: sheetArray[7], tulkit: sheetArray[8], subjectValues : sortedSubject  });
      };
      const retr = async () => {
        const calendarDataArray1 = await submitGetCalendarIdArr(token);
        dispatch( setCalendarIds(calendarDataArray1))
        return calendarDataArray1
      }
      const  callBody = async () => {
        toggleLoading(true);
        await callStates();
        await retr();
        toggleLoading(false);
      }
      callBody()
    }   
    },[filters.serviceYear, props.serviceId]);
    
  if (loading ) return   <CircularProgress />

  const handleClose: any = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(editFilterData({error: false, errorMessage:''}));
  } 

  const  handleChange1 = async (e: any) => {
    if (e.target.checked) {
      setState({ ...state, Pm : 'k', PmTrueFalse : true});
    } else {
      setState({ ...state, Pm : ' ', PmTrueFalse : false });
    }    
  }
  const handleChange = (name: string, value: number | string | Date | null) => {
    let workValue: any = [];
    const wValue: any = value;
  
    setState({ ...state, [name]: value })

    if (name === 'type') {
      workValue = arrays.typesValues.find( (type: any, i: number) => {
        return type[0] == value
      });
      
      //setState({ ...state, subject: workValue[2], location: workValue[3], toimikunta: workValue[4]});
      setState({...state, subject: workValue[2], type: wValue, Location: workValue[3] , Toimikunta: workValue[4]});     
    }
    if (name === 'subject') {
      setState({...state, type : ''});
    }
      
    setState({...state, [name] : wValue});
  };
  const paluu = async () => {
    //dispatch(serviceEdited(true));
    close()
  }

  const submit = async () => {
    const param = [[ state.type,
    state.excluded, 
    state.Vko, 
    state.Vkonpäivä,
    state.StartDate,
    state.StartTime,
    state.EndDate,
    state.EndTime,
    state.Pm, 
    state.subject,
    state.Selite,
    state.Location,
    state.Toimikunta,
    state.Private,
    state.sspeaker,
    state.Seuraisäntä,
    state.Säestäjä,
    state.Esilaulaja,
    state.Valvontaryhmänvetäjä,
    state.Englannintulkkaus,
    state.Eestintulkkaus,
    state.Viittomakielentulkkaus,
    state.Äänentoisto,
    state.Siivousryhmä,
    state.Kassaryhmä,
    state.linenumber,
    'n',
    auth.email,
    ]];
    if (state.subject === '') {
      dispatch(editFilterData({ error: true, errorMessage: 'Anna tilaisuuden nimi' }));
    };
    if (filters.serviceYear  != moment(state.StartDate,'D.M.YYYY').year()) {
      dispatch(editFilterData({ error: true, errorMessage: 'Valitse päivä seuraohjelman vuodelta' }));
    }
   
    dispatch(editFilterData({ haveToRead: true }));
    const range = `${filters.subSheet}!${'A'}${state.linenumber}:${'AB'}${state.linenumber+1}`
    const reply = await programService
      .updateLine(token, filters.sheetId, 'xxxx', state.linenumber, state.linenumber, param, range);

    reply.split(' ').map((text:any) => {
      return text === 'Error' && dispatch(editFilterData({error: true, errorMessage: 'Seuraohjelman rivin päivityksessä virhe' }))
    });
    dispatch(startEditServiceProgram(props.serviceId, ({ sspeaker : state.sspeaker, StartDate : state.StartDate, StartTime : state.StartTime ,
      EndDate : state.EndDate, EndTime : state.EndTime, subject : state.subject, Location: state.Location, Pm: state.Pm, Seuraisäntä: state.Seuraisäntä, Säestäjä: state.Säestäjä, Esilaulaja: state.Esilaulaja,  Valvontaryhmänvetäjä: state.Valvontaryhmänvetäjä,
      Englannintulkkaus: state.Englannintulkkaus,
      Eestintulkkaus: state.Eestintulkkaus,
      Viittomakielentulkkaus: state.Viittomakielentulkkaus,
      Äänentoisto: state.Äänentoisto,
      Siivousryhmä: state.Siivousryhmä,
      Kassaryhmä: state.Kassaryhmä,
      linenumber : state.linenumber, Selite: state.Selite  }), filters.serviceYear));
    let calendarDataArray1 = filters.calendarDataArray;
    if (filters.calendarDataArray.length === 0 ) {
      calendarDataArray1 = await submitGetCalendarIdArr(token);

      dispatch( setCalendarIds(calendarDataArray1));
    }
    if (state.originalLocation !== '' && state.originalLocation !== ' ' ){
      const calendarId1 = calendarDataArray1.find((cal: any) => cal.calendar === state.originalLocation);
      if (calendarId1) {     
        const service2: any = ({
          StartDate: moment(state.originalStartDate,'D.M.YYYY').format('D-M-YYYY'),
          StartTime: state.originalStartTime,
          EndDate: moment(state.originalEndDate,'D.M.YYYY').format('D-M-YYYY'),
          EndTime : state.originalEndTime,
          Location: state.originalLocation, 
          subject: state.originalSubject, 
        });
        const deleteOnly: string = 'deleteOnly';
        submitGenCalUpd( calendarId1, token, service2, deleteOnly, dispatch, editFilterData);          
      }
    }
    if (state.Location !== '' ) {
      const calendarId = calendarDataArray1.find((cal: any) => cal.calendar === state.Location);
      const service = ({
        StartDate: moment(state.StartDate,'D.M.YYYY').format('D-M-YYYY'),
        StartTime: state.StartTime,
        EndDate: moment(state.EndDate,'D.M.YYYY').format('D-M-YYYY'),
        EndTime : state.EndTime,
        selite: state.Selite,
        subject: state.Location === 'Keittiö' ? state.Toimikunta + ' / ' + state.subject + ' / ' + state.Selite :state.subject,
        Location: state.Location,
      }) 

      if ( calendarId ) {
        const deleteOnly: string = 'no';
          submitGenCalUpd( calendarId, token, service, deleteOnly,  dispatch, editFilterData);
      }
    }
    
    if (reply === '') {
      close();
    }  
  };

  if (!arrays.typesValues) return (<div><CircularProgress/>.... loading</div>);
  if (!state) close();

  return( 
    <Box>
      { filters.error &&
        <div>
          <Snackbar
            open={filters.error}
            autoHideDuration={10000}
            onClose={handleClose}
            message= {filters.errorMessage}
            />
        </div>
      }
   
    <Box>
    <Typography sx={ subHeaderStyles }>{`Päivitä rivi ${filters.serviceProgramName}`}</Typography>
    </Box>        
      <Box  sx={{ p:1}}>
        <FormControl  sx={{ p:1} }>
          <InputLabel> Seuratilaisuus</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="selectType"
            value = { state.type }
            name="type"
            onChange={(e: any) => {
              handleChange('type', e.target.value)
            }}
          >            
            {
            arrays.typesValues.map( (type: any, i: number) =>
              <MenuItem
                key={i}
                value={type[0]}
              >
                { type[1] }
              </MenuItem>
            )}
          </Select>
        </FormControl >    
        <FormControl  sx={{ p:1}}>
          <InputLabel> Muu tilaisuus</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="selectSubject"
            value = {state.subject}
            name="subject"
            onChange={(e: any) => {
              handleChange('subject', e.target.value)
            }}
          >            
            {
            arrays.subjectValues.map( (subj: any, i: number) =>
              <MenuItem
                key={i}
                value={ subj }
              >
                { subj }
              </MenuItem>
            )}
          </Select>
        </FormControl>       
        <FormControl  sx={{ p:1} }>
          <InputLabel> Toimikunta</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="Toimikunta"
            value = { state.Toimikunta}
            name="Toimikunta"
            onChange={(e: any) => {
              handleChange('Toimikunta', e.target.value)
            }}
            >      
            {
            arrays.TMKValues.map( (tmk: any, i: number) =>
              <MenuItem
                key={i}
                value={tmk[0]}
              >
                { tmk[0] }
              </MenuItem>
            )}          
          </Select>
        </FormControl>
        <FormControl  sx={{ pt:1 }}>
          <InputLabel> Location</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="Location"
            value = { state.Location }
            name="location"
            onChange={(e: any) => {
              handleChange('Location', e.target.value)
            }}
           >      
            {
            arrays.locationValues.map( (loc: any, i: number) =>
              <MenuItem
                key={i}
                value={loc[0]}
                >
                { loc[0] }
            </MenuItem>
            )
          }
        </Select>
      </FormControl>
      <FormControl sx={{ pt:1, pb:1 }}>
          <InputLabel> Seuraisäntä</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="selectSeuraisäntä"
            value = { state.Seuraisäntä }
            name="Seuraisäntä"
            onChange={(e: any) => {
              handleChange('Seuraisäntä', e.target.value)
            }}
           >      
            {
            arrays.seuraIsannat.map( (loc: any, i: number) =>
              <MenuItem
                key={i}
                value={loc[0]}
                >
                { loc[0] }
            </MenuItem>
            )
          }
        </Select>
      </FormControl>
      <FormControl sx={{ pt:1, pb:1 }}>
          <InputLabel> Säestäjä</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="selectSaestaja"
            value = { state.Säestäjä }
            name="Säestäjä"
            onChange={(e: any) => {
              handleChange('Säestäjä', e.target.value)
            }}
           >      
            {
            arrays.saestajat.map( (loc: any, i: number) =>
              <MenuItem
                key={i}
                value={loc[0]}
                >
                { loc[0] }
            </MenuItem>
            )
          }
        </Select>
      </FormControl>
      <FormControl sx={{ pt:1, pb:1 }}>
          <InputLabel> Esilaulaja</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="selectEsilaulaja"
            value = { state.Esilaulaja }
            name="Esilaulaja"
            onChange={(e: any) => {
              handleChange('Esilaulaja', e.target.value)
            }}
           >      
            {
            arrays.esilaulajat.map( (loc: any, i: number) =>
              <MenuItem
                key={i}
                value={loc[0]}
                >
                { loc[0] }
            </MenuItem>
            )
          }
        </Select>
      </FormControl>
      <FormControl sx={{ pt:1, pb:1 }}>
          <InputLabel> Valvoja</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="selectValvoja"
            value = { state.Valvontaryhmänvetäjä }
            name="Valvoja"
            onChange={(e: any) => {
              handleChange('Valvontaryhmänvetäjä', e.target.value)
            }}
           >      
            {
            arrays.valvojat.map( (loc: any, i: number) =>
              <MenuItem
                key={i}
                value={loc[0]}
                >
                { loc[0] }
            </MenuItem>
            )
          }
        </Select>
      </FormControl>
     
      <FormControl sx={{ pt:1, pb:1 }}>
      <InputLabel> Äänentoisto</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="selectÄänentoisto"
            value = {state.Äänentoisto}
            name= "Äänentoisto"
            onChange={(e: any) => {
              handleChange('Äänentoisto', e.target.value)
            }}
           >      
            {
            arrays.aanitarkkailijat.map( (loc: any, i: number) =>
              <MenuItem
                key={i}
                value={loc[0]}
                >
                { loc[0] }
            </MenuItem>
            )
          }
        </Select>
      </FormControl>
      <FormControl sx={{ pt:1, pb:1 }}>
          <InputLabel> Tulkki</InputLabel>
          <Select
            sx={{ minWidth: '100px'}}
            labelId="selectTulkki"
            value = { state.Englannintulkkaus}
            name="Tulkki"
            onChange={(e: any) => {
              handleChange('Englannintulkkaus', e.target.value)
            }}
           >      
            {
            arrays.tulkit.map( (loc: any, i: number) =>
              <MenuItem
                key={i}
                value={loc[0]}
                >
                { loc[0] }
            </MenuItem>
            )
          }
        </Select>
      </FormControl>
      <Box sx={{ p:1 }}>
        <FormControlLabel
          control={<Switch checked={state.PmTrueFalse} onChange={handleChange1} name="Pm" />}
          label="Päivämieheen"
        />
      </Box>    
     
      <TextField
        sx={{ pt:1 }}
        label={'Päivämieheen'}
      
        rows={1}
        variant="outlined"
        fullWidth
        value={ state.Pm }
        onChange={(e: any) => handleChange('Pm', e.target.value)}
      />
     
      <TextField
        sx={{ mt: 1 }}
        label={'Selite'}
      
        rows={1}
        variant="outlined"
        fullWidth
        value={ state.Selite}
        onChange={(e: any) => handleChange('Selite', e.target.value)}
      />
      <Box sx = {{fontSize: 20 }}> 
        {state.type !== '' && state.type !== ' ' ?
          <Box sx={{}}>         
            {'Seuratyyppi'}: {            
            arrays.typesValues[ arrays.typesValues.findIndex((ar:any)=> ar[0] === state.type)][2]}
          </Box>
          :
          <Box sx={{ }}> 
          {'Seuratyyppi'} : 'Muu tilaisuus';
          </Box>
          }
        <Box sx={{ pt: 1 }}>
          {'Päivämieheen'}: {state.Pm}
        
        </Box>
        <Box sx={{ pt: 1 }}>
          {'Location'}: { state.Location === 'x' ? ' ' : state.Location}
        </Box>
        <Box sx={{ pt: 1 }}>
          {'Vko'}: { state.Vko}
        </Box>
        <Box sx={{ pt: 1 }}>
          {'VkonPäivä'}: { state.Vkonpäivä}
        </Box>
        <Box sx={{ pt: 1 }}>
          {'Alkupvm'}: { state.StartDate}
        </Box>
        <Box sx={{ pt: 1 }}>
          {'Klo'}: { state.StartTime}
        </Box>
        <Box sx={{ pt: 1 }}>
          {'Päättymispvm'}: { state.EndDate}
        </Box>
        <Box sx={{ pt: 1 }}>
          {'Klo'}: { state.EndTime}
        </Box>
        <Box sx={{ pt: 1 }}>
          {'Subject'}: { state.subject}
        </Box>
        <Box sx={{ pt: 1 }}>
          {'Selite'}: { state.Selite}
        </Box>
        <Box sx={{ pt: 1 }}>
          {'Location'}: { state.Location}
        </Box>
      </Box>
    
      <Box>
        <Box sx={{ mt: 1 }}>         
          <Box>
            <Button color="primary" variant="contained" sx={{ ml: 1 }}
              onClick= {submit}>
              {('Submit')}
            </Button>   
            <Button color="primary" variant="contained" sx={{ ml: 1 }}
              onClick= {paluu}>
              {('Paluu')}
            </Button>       
          </Box>          
        </Box>
      </Box>
    </Box>
  </Box>
  )
}

export default UpdateServiceLine;