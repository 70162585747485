import { Bar } from "@nivo/bar";
import {Box} from '@mui/material';
import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../store/ConfigureStore';
import moment from 'moment';
import { Navigate } from 'react-router-dom';

//import { useAppSelector } from '../../hooks';
// const layers: any = [...svgDefaultProps.layers];
// let results: any = [];

const styles: any = {
  fontFamily: "sans-serif",
  textAlign: "center"
};

const axisBottom: any = {
  tickSize: 5,
  tickPadding: 5,
  tickRotation: -30,
  legend: "Puhuja",
  legendPosition: "middle",
  legendOffset: 32
};

const axisLeft: any = {
  tickSize: 5,
  tickPadding: 5,
  tickRotation: 0,
  legend: "Palveluvuorolkm/types",
  legendPosition: "middle",
  legendOffset: -40
};

const theme: any = {
  background: "#D5D2AF",
  axis: {
    fontSize: "14px",
    tickColor: "#0D1E0A",
    ticks: {
      line: {
        stroke: "#555555"
      },
      text: {
        fill: "#081006"
      }
    },
    legend: {
      text: {
        fill: "#aaaaaa"
      }
    }
  },
  grid: {
    line: {
      stroke: "#555555"
    }
  }
};
const colorCodes: any = [
  '#A52A2A',
  '#7FFD4',
  '#008000',
  '#FFA500',
  '#FFC0CB',
  '#00FF00',
  '#FF0000',
  '#0000FF',
  '#00008B',
  '#800080',
  '#7FFFD4',
  '#00FFFF',
  '#A52A2A',
  '#C0C0C0',
  '#00008B',
  '#FF00FF',
  '#FFC0CB',
  '#808000',
  '#008000',
  '#800000',
  '#000000',
  '#808080',
  '#FFFFFF',

];

let colors:any = {kaikki: colorCodes[1], Su16: colorCodes[2], su18: colorCodes[3],la18: colorCodes[4], ke19: colorCodes[5],
alue: colorCodes[6], kirk: colorCodes[7], kesk: colorCodes[8], tall: colorCodes[9], last: colorCodes[10],
 raam: colorCodes[11], lait: colorCodes[12]};

const colorBy: any = ({id}: {id: string}) => colors[id];

const legends: any = [
  {
    dataFrom: "keys",
    anchor: "bottom-right",
    direction: "column",
    justify: false,
    translateX: 120,
    translateY: 0,
    itemsSpacing: 2,
    itemWidth: 100,
    itemHeight: 20,
    itemDirection: "left-to-right",
    itemOpacity: 0.85,
    itemTextColor: "#081006",
    symbolSize: 20,

  }
];


const CreateGraphicsOfTurns = () => {
const speakers: any = useSelector((state: RootState) => state.speakers);
const serviceArray: any = useSelector((state: RootState) => state.services);
const filters: any = useSelector((state: RootState) => state.filters);

const linex = filters.sheetYears.find((line: any) => 
{
  return  line[0] === 'maxlines'+filters.serviceYear;
});
let lineCount = 0;
if (typeof(linex === 'undefined')) {
  lineCount = 800
}
else {
  lineCount = parseInt(linex[1]);
}

lineCount = lineCount-1;
if  (serviceArray.length === 0 || serviceArray.length < lineCount) {
  window.alert('Ladataan  ensin Seuraohjelma. Tai korjaa sheetaprmin maxlines === seuraklenterin vika rivinro. Palaa sitten Raportteihin:')
    return <Navigate to="/seuraohjelma" replace={true} />
};
const speArr = speakers.map((spe:any)=> {return spe.speaker});
moment.updateLocale('en', {
  week: {
    dow : 1, // Monday is the first day of the week.
  }
});
const nameArray: any = ['kesk','su16','su18', 'la18', 'ke19', 'alue', 'lait', 'kirk', 'last', 'tall', 'raam'];
// const translatedPermissionValues = ['Sisällönhallinta','Ylläpitäjä','Katsoja', 'Tekijä','Kommentoija','Omistaja'  ]
// const translatedPermissionValue = translatedPermissionValues[['fileOrganizer','organizer','reader','writer','commenter','owner'].indexOf(permissionName.role)]
let dataArrayFinal: any = [];
const speakerList: any = serviceArray
.filter((filtered: any) => (['1','2','3','4','5','6','7','8','9','0','10','11'].indexOf(filtered.type) !== -1))
.filter((filtered: any) => filtered.strike === 'n')
.filter((filtered: any) => (speArr.indexOf(filtered.sspeaker) !== -1))
  .sort(( a: any,b: any ) => {
    var x = a.sspeaker
    var y = b.sspeaker
    if (x < y) {return -1}
    if (x > y) {return 1}
    else return 0
  })
  .reduce ((results: any, data: any) => {
    const result = results;
    result[data.sspeaker] = result[data.sspeaker] || []
    result[data.sspeaker].push({
      sspeaker:data.sspeaker,
      type: data.type,
    })
     return result
    }, {});
    const speakerArray = Object.keys(speakerList);
    for (const spe of speakerArray) {
      const servdates=[];
      const Day1 = [];
      const Day2 = [];
      const Day3 = [];
      const Day4 = [];
      const Day5 = [];
      const Day6 = [];
      const Day7 = [];
      const Day8 = [];
      const Day9 = [];
      const Day0 = [];
      const Day10 = [];
      for (const service1 of speakerList[spe]) {
        servdates.push(service1.StartDate)
        switch(service1.type) {
        case '1':
          Day1.push(service1.StartDate)
          break
        case '2':
          Day2.push(service1.StartDate)
          break
        case '3':
          Day3.push(service1.StartDate)
          break
        case '4':
          Day4.push(service1.StartDate)
          break
        case '5':
          Day5.push(service1.StartDate)
          break
        case '6':
          Day6.push(service1.StartDate)
          break
        case '7':
          Day7.push(service1.StartDate)
          break
        case '8':
          Day8.push(service1.StartDate)
          break
        case '9':
          Day9.push(service1.StartDate)
          break
        case '0':
          Day0.push(service1.StartDate)
          break
        case '10':
          Day10.push(service1.StartDate)
          break
        }
      // eslint-disable-next-line no-extra-semi
      }
      dataArrayFinal.push({
        speaker: spe,
        kesk : Day0.length,
        su16: Day1.length,
        su18 : Day2.length,
        la18: Day3.length,
        ke19: Day4.length,
        alue: Day5.length,
        lait: Day6.length,
        kirk: Day7.length,
        last: Day8.length,
        tall: Day9.length,
        raam: Day10.length
      })

    }

  return (
    <Box sx = {{mt:10 }}>
    <div style={styles}>
      <Bar
        width={1800}
        height={900}
        margin={{ top: 70, right: 120, bottom: 60, left: 80 }}
        data={dataArrayFinal}
        keys={nameArray}
        indexBy="speaker"
        labelTextColor="inherit:darker(2.4)"
        labelSkipWidth={12}
        labelSkipHeight={12}
        enableGridX={false}
        axisBottom={axisBottom}
        axisLeft={axisLeft}
        colorBy={colorBy}
        theme={theme}
        legends={legends}

      />

    </div>
    </Box>
  );
};
export default CreateGraphicsOfTurns;
