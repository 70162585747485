/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
import driveServices from '../../services/driveServices'
import {startAddDrivesOnce, addDrivesOnce, startRemoveDatabase, emptyDrives, editYouAreOrganizer} from  '../../actions/drives'
import database from '../../firebase/firebase';

const SubmitDrivesToState = async (token: any,dispatch: any,uid: any, inputDataBase: any, auth: any, users: any) => {

  let drivelist: any = []
  let driveArray: any = []
  if (inputDataBase === 'Firebase') {
    await dispatch(emptyDrives())
    await database.ref(`users/${uid}/driveList`)
      .once('value')
      .then((snapshot: any) => {
        snapshot.forEach((childSnapshot: any) => {
          drivelist.push({
            driveId:childSnapshot.key,
            ...childSnapshot.val()
          })
        })
        drivelist.forEach(  (drive: any) => {
          driveArray.push({
            kind: drive.kind ,
            driveId : drive.driveId,
            driveName: drive.driveName,
          });
        })
        dispatch(addDrivesOnce({
          driveArray
        }))
      })
  } else {
    drivelist = await driveServices
      .driveList(token)
      .catch( error => {
        console.error('Error fetching drivelist', error)
      })
    drivelist.sort(( a: any, b: any ) => {
      var x= a.name
      var y = b.name
      if(x < y) {return -1}
      else
      if (x > y) {return 1}
      else return 0
    });


    await dispatch (startRemoveDatabase())
    //drivelist = drivelist.slice(1,5)
    let youAreOrganizer = false;
    let drivePerm: any[] = [];
    for (const drive of  drivelist) {
      // const permissionlist = await driveServices
      // .listPermissions(token,drive.id)
      // .catch( error => {
      //   console.error('Error fetching permissions', error)
      // });
      // drivePerm = [];
      // for (const perm of permissionlist) {
      //   if (!perm.deleted) {
      //     if (perm.emailAddress === auth.email) {
      //       if (perm.permissionRole === 'organizer') {
      //         youAreOrganizer = true;
      //       }
      //     }
      //     const translatedPermissionValues = ['Sisällönhallinta','Ylläpitäjä','Katsoja', 'Tekijä','Kommentoija','Omistaja'  ]
      //     const translatedPermissionValue = translatedPermissionValues[['fileOrganizer','organizer','reader','writer','commenter','owner'].indexOf(perm.permissionRole)]
      //     let permissionTxt = perm.emailAddress;
      //     permissionTxt= permissionTxt.replace('@hry.fi','@helsinginrauhanyhdistys.fi')
      //     let creationDate = users.find((user: any) => { if (user.primaryEmail === permissionTxt) return user.creationDate} )
      //     let organizer = false;
      //     if (permissionTxt === auth.email) organizer = true;
      //     let create = '';
      //     let lastlog='';
      //     if (perm.permissionType !== 'group'){
      //       if (creationDate){
      //         create = creationDate.creationDate;
      //         lastlog = creationDate.lastLoginDate;
      //       }
      //       if (!creationDate) {
      //         create = moment('1970-01-01').format('D.M.YYYY')
      //         lastlog= moment('1970-01-01').format('D.M.YYYY')
  
      //       }
      //     }
      //     const pArray = ({
      //       id:uuidv4(),
      //       driveId : drive.id,
      //       driveName:  drive.name,
      //       permissionId: perm.permissionId,
      //       permissionType: perm.permissionType,
      //       permissionRole: perm.permissionRole,
      //       translatedPermissionValues: translatedPermissionValue,
      //       type: perm.permissionType,
      //       emailAddress:perm.emailAddress,
      //       role: perm.permissionRole,
      //       displayName: perm.displayName,
      //       creationDate:create,
      //       lastLoginDate : lastlog,
      //       organizer : organizer
      //     })
      //     drivePerm.push(pArray)
      //   }
      // }
     
      driveArray.push({
        kind: drive.kind ,
        driveId : drive.id,
        driveName: drive.name,
        // youAreOrganizer : youAreOrganizer,
        // drivesPermissions: drivePerm,
      })
    }
   
  };
  dispatch(startAddDrivesOnce({
    driveArray
  }));
return driveArray
}
export default SubmitDrivesToState