/* eslint-disable semi */
import driveServices from '../services/driveServices';
import database from '../firebase/firebase';
let versionOk: any = 'ok';

const checkChangesOfService = async(token: any, filters: any, auth: any) => {

  const sheetsId = filters.sheetYears.filter((shyear: any) => {
    // eslint-disable-next-line eqeqeq
    return shyear[0] == filters.serviceYear
  })
  const readGoogle= async (sheetsIdOfYear: any) => {
  const file = await driveServices
    .fileget(token,sheetsIdOfYear, ['modifiedTime'])
    .catch( error => {
      console.error('Error fetching drivelist', error)
    })

    versionOk = await readFire( file.modifiedTime)
   return versionOk;
  }
  const readFire = async (googleTime: any) => {
    let fireModifiedTime = ''
    try {
      await database.ref(`users/${auth.uid}/serviceYear/${filters.serviceYear}/lastChangeTimestamp`)
        .on('value' ,  (snapshot: any) => {
          if (snapshot.exists())
            fireModifiedTime =snapshot.val().lastChangeTimestamp;
          if (filters.admin) {
            if (window.location.pathname === '/calendar/CheckCals' || window.location.pathname === '/calendar/UpdateCals') {
              if (fireModifiedTime !== googleTime  ) {
                // eslint-disable-next-line no-undef
                console.log('change service last update day changed!!!!!!!!!!!!!!!!!',  window.location.pathname)
                // eslint-disable-next-line no-undef
                //window.alert('Lataa seuraohjelmasta uusin versio. Palaa sitten kalenteritoimintoihin  firebase: ' +  fireModifiedTime + ' Google: ' + googleTime +  window.location.pathname)
               versionOk = 'not';
             
              } else {
                versionOk = 'ok';
              }
            }
          }
        })

      }
      catch (e){
        console.log('errori in app checkchanges of service',e)
      }
    return versionOk
  }
 
  if (sheetsId.length > 0) {
    versionOk =  await readGoogle(sheetsId[0][1]);
  }
  return versionOk;
}
export default  checkChangesOfService

