
import React, { useState, useEffect}  from 'react';
import { Box, CircularProgress } from '@mui/material';
import { editFilterData, setLoading } from '../../actions/filters';
import submitGetSheetId from '../service/submitGetSheetId';
import { useDispatch, useSelector } from 'react-redux';
import {RootState} from '../../store/ConfigureStore';
import { useNavigate } from "react-router-dom";
import submitSheetRetr from '../service/submitSheetRetr';
import AddFireToService from '../../firebase/AddFireToService';
import ReadAndStoreServiceProgram from '../genUtilities/ReadAndStoreServiceProgram';
import programService from '../../services/programService';
import createSpeakersState from '../speakers/createSpeakersState';
import { speakersToStateOnce } from '../../actions/speakers';
import { ReadServiceAndSpeakersToRedux } from '../genUtilities/ReadServiceAndSpeakersToRedux';
import { Navigate } from 'react-router-dom';

const InfoPage = () => {
  const dispatch = useDispatch();
  const authorit: any = useSelector((state: RootState) => state.auth)

  const filters: any = useSelector((state: RootState) => state.filters);
  const services: any = useSelector((state: RootState) => state.services);

  const token = `bearer ${authorit.idToken}`;
  const [errorMessage, setErrorMessage] = useState (false);
  let componentMounted = true;
  let strikeArray: any [] = [];  

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [buttonStyle, setButtonStyle] = useState<"inherit" | "primary" | "secondary" | "default" | undefined>('primary');

  let navigate = useNavigate();
  useEffect (() => {
    // if (authorit.idToken) {
      if (filters.error) {
        setErrorMessage(filters.errorMessage);
      }

    if (!authorit.idToken) navigate("/", {replace:true}); 
    const getSheetId = async() => {
      await ReadServiceAndSpeakersToRedux (dispatch, token, filters, authorit);
      dispatch(setLoading(false))
    } 
    dispatch(setLoading(true))
    getSheetId()
   
    //}
    if (componentMounted) {
      dispatch(setLoading(false))
    }
    return () => { // This code runs when component is unmounted
      // eslint-disable-next-line react-hooks/exhaustive-deps
      componentMounted = false; // (4) set it to false if we leave the page
    }
    
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  if (!authorit.idToken) return <div>Kirjaudu järjestelmään</div>
  //if (loading) <CircularProgress/>
  if (filters.loading) return <div> Waiting for services<CircularProgress/></div> 

  return  (
    <Box>
      {services.length > 0 &&
        <Navigate to="/seuraohjelma" replace={true} />
      }       
     </Box>
  )
}
export default InfoPage