/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
import programService from '../../services/programService'

const submitLeaveTypes = async (token: any, speaker: any, filters: any) => {
  const leaveTypes = [...filters.typesToState].toString()
  await programService
    .updatespleaveTypes(token,speaker.linenumber,leaveTypes, filters.serviceYearSpeakers, filters.sheetId, filters.columnSpeaker, filters.subSheet)
    .catch(error => {
      console.log('error updating speakers leavetimes',error)
    })
  return 'kukkuutikukkuu'
}

export default submitLeaveTypes;