/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
import axios from 'axios'
// const filegeturl = 'http://localhost:3001/api/fileget'
// const baseUrl = 'http://localhost:3001/api/drivelist'
// const permissionsUrl = 'http://localhost:3001/api/permissionslist'
// const permissionsgeturl = 'http://localhost:3001/api/permissionsget'
// const permissiondeleteurl = 'http://localhost:3001/api/permissiondelete'
// const permissionupdateurl = 'http://localhost:3001/api/permissionupdate'

const filegeturl          = `${process.env.REACT_APP_API_URL}/api/fileget`;
const baseUrl             =`${process.env.REACT_APP_API_URL}/api/drivelist`;
const permissionsUrl      = `${process.env.REACT_APP_API_URL}/api/permissionslist`;
const permissionsgeturl   =  `${process.env.REACT_APP_API_URL}/api/permissionsget`;
const permissiondeleteurl = `${process.env.REACT_APP_API_URL}/api/permissiondelete`;
const permissionupdateurl = `${process.env.REACT_APP_API_URL}/api/permissionupdate`;

const driveList = async(token: any) => {
  const config = {
    headers: { Authorization: token },
  }
  const request =  axios.get(baseUrl,config)

  return request.then(response => {
    return response.data
  })
}
const listPermissions = async(token: any, driveId: any) => {
  const config = {
    headers: { Authorization: token },
  }
  try {
     const request: any =  await axios.get( `${permissionsUrl}/${driveId}` , config);
     return request.data
  }catch(error) {
    console.log('error in listPermission proc', error);
  }
}
const fileget = async(token: any, fileId: any, fields: any) => {
  try{
    const request =  await axios.get( `${filegeturl}/${fileId}/${fields}` , {headers: { Authorization: token }})
    return request.data
  }catch(error) {
    console.log('ererer',error)
    const response =  `Error in fileget driveServices ${fileId} ${fields}`;
    return response;
  }
}
const permissionsget = async(token: any, driveId: any, permissionid: any) => {
  const config = {
    headers: { Authorization: token },
  }
  const request =  axios.post( `${permissionsgeturl}/${driveId}`,{ permissionid } , config)
  return request.then(response => {
    return response.data
  })
}
const permissionupdate = async(token: any, driveId: any, permissionid: any,role: any) => {
  const config = {
    headers: { Authorization: token },
  }
  const request =  axios.post( `${permissionupdateurl}/${driveId}`,{ permissionid , role}, config)

  return request.then(response => {
    return response.data
  })
}
const permissiondelete = async(token: any, driveId: any, permissionid: any) => {
  const config = {
    headers: { Authorization: token },
  }
  try {
    await axios.post( `${permissiondeleteurl}/${driveId}`,{ permissionid } , config);    
    return 'ok';
  }catch(error: any){
    console.log(error.response.status );
    return error.response.status

  }
}


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  driveList: driveList,
  fileget: fileget,
  listPermissions : listPermissions,
  permissionsget : permissionsget,
  permissiondelete : permissiondelete,
  permissionupdate : permissionupdate,
  // directoryList : directoryList
}