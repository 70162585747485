
import { useSelector } from "react-redux";
import programService from "../../services/programService";
import ReadAndStoreServiceProgram from "./ReadAndStoreServiceProgram";
import createSpeakersState from "../speakers/createSpeakersState";
import submitGetSheetId from "../service/submitGetSheetId";
import { editFilterData, setLoading } from "../../actions/filters";
import { speakersToStateOnce } from "../../actions/speakers";
import submitSheetRetr from "../service/submitSheetRetr";
import AddFireToService from "../../firebase/AddFireToService";
let strikeArray: any = [];

export const ReadServiceAndSpeakersToRedux = async(dispatch: any, token: any, filters: any, authorit: any)=> {
  const getSpeakers = async (year: any) => {
    const search = 'speakers' + year
    const doDispatch = true;
    const speakerData = await submitGetSheetId(token,search, dispatch, doDispatch, filters);
    const sheetId = speakerData?.sheetId;
    const range = speakerData?.range;
    let initialSpeakers: any = []
    const initialRows = await programService
      .getAll(token,year,sheetId,range);
      if (Array.isArray(initialRows)) {
        initialSpeakers = initialRows;
      }
      else {
        initialSpeakers = [];
        dispatch(editFilterData({error: true, errorMessage: initialRows}));
      };
    const speakersArray = await createSpeakersState(initialSpeakers);
    dispatch(speakersToStateOnce(speakersArray));
  }
  const getServiceProgram = async (sheetid: any, range: any, year: any, strikeArray: any) => {
    const service = await ReadAndStoreServiceProgram(sheetid, range, year, strikeArray, dispatch, filters, authorit); 
    // const workService: any = selectServices(service, filters);     
    return service;
  }





  const retr1 = async (token: any, sheetid: any, range: any, year: any) => {
    const doDispatch = false;
    const sheetsDataStriked: any = await submitGetSheetId(token,'striked' + year, dispatch, doDispatch, filters );
    const strikeArray1 = await submitSheetRetr(token,sheetsDataStriked.sheetId,sheetsDataStriked.range,strikeArray, dispatch);
    strikeArray = [...strikeArray1];
    const services1: any = await getServiceProgram(sheetid,range,year,strikeArray);
    getSpeakers(year);
    await AddFireToService(year, authorit.uid, services1, dispatch);
    if (services1.length > 0 ) {
      const servicesWork = [...services1]
      if (servicesWork.filter((work: any) => work.sspeaker.toLowerCase().includes(filters.text.toLowerCase())).length === 0){
        dispatch(editFilterData({text : ''}));
      }  
    }
    return services1;
  }

  const getSheetId = async () => {
    if ( authorit.idToken ) {
      const doDispatch = true;
      const sheetData = await submitGetSheetId(token,filters.serviceYear,dispatch, doDispatch, filters)
      //dispatch(setSheetData(sheetData))

      dispatch(editFilterData({
        sheetId: sheetData?.sheetId,
        range: sheetData?.range,
        columnSpeaker: sheetData?.columnSpeaker,
        columnAddInfo: sheetData?.columnAddInfo,
        subSheet: sheetData?.subSheet,
        speakerSubColumn: sheetData?.speakerSubColumn,
        speakerSubDayCol1: sheetData?.speakerSubDayCol1,
        speakerSubDayCol2: sheetData?.speakerSubDayCol1,
        subjectColumn:sheetData?.subjectColumn,
        haveToRead: true
      }));

      const services2 : any = await retr1(token, sheetData.sheetId, sheetData.range, filters.serviceYear);

      dispatch(setLoading(false))
      //setLoading1(false);
      // if (!filters.admin && !filters.read && !filters.write && !filters.drive && !filters.change && !filters.listCal && filters.freetime )
      // navigate("/puhujat");
  
      //navigate("/seuraohjelma");
    }
  }
  dispatch(setLoading(true));
  await getSheetId()
}