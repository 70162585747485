/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
import React, { useEffect , useRef} from 'react' ;
import 'core-js/stable'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import {editFilterData} from '../../actions/filters';
import ServiceProgramList from './ServiceProgramList'
import ServiceListFilters from './ServiceListFilters'
import {RootState} from '../../store/ConfigureStore';
import { CircularProgress, Box } from '@mui/material';


const ListServiceProgramPage = (props: any) => {
  const navigate = useNavigate();
  const authorit: any = useSelector((state: RootState) => state.auth);
  const filters: any = useSelector((state: RootState) => state.filters);
  const services: any = useSelector((state: RootState) => state.services);
  const isMounted: any = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    //dispatch(editFilterData({error: false, errorMessage: '' }));
    if (!authorit.idToken) {
      navigate('/');    
    }
    if (services.length === 0) 
      navigate ('/');

    // executed when component mounted
    isMounted.current = true;
    return () => {
      // executed when unmount
      isMounted.current = false;
    }
  }, [filters.somethChanged]);
  if (!authorit.idToken) {
    // dispatch(editFilterData({somethchanged: Math.random()}))
    return <div> Kirjaudu järjestelmään</div>
}

  if (filters.error && filters.errorMessage!== 'Seuraohjelman vuosi muutettu'){
    //dispatch(editFilterData({error: false, erroroMessage: ''}))
    return (<div> <br />.` Parametristossa virheitä/Seuraohjelma ei vielä ladattavissa/ . Pyydä järjestelmänvalvojaa korjaamaan sheet_id - kirjasto/ ${filters.errorMessage}`</div>);
  }

  if (!filters.read) {
    return (<div> <br />. Ei vaadittuja oikeuksia ListServiceProgramPage</div>)
}

if (filters.loading) return ( <div> Latailee seuraohjelmaa ListServiceProgramPage  <CircularProgress/> </div>)

  return(
    <Box>

        <ServiceListFilters/>

        <ServiceProgramList/>

   </Box>
  )
}
export default ListServiceProgramPage