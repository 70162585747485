/* eslint-disable linebreak-style */
/* eslint-disable no-extra-semi */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
/* eslint-disable semi */
/* eslint-disable linebreak-style */
const drivesInitialState = []


// eslint-disable-next-line import/no-anonymous-default-export
export default (state = drivesInitialState, action) => {
  switch (action.type) {
  // case 'SET_DRIVE':
  //   return [
  //     ...state,
  //     action.drive
  //   ];
  case 'ADD_DRIVES':
    return [
      ...state,
      action.drivesData
    ];
    case 'ADD_DRIVES_ONCE':
      state = [];
      return action.driveArray.driveArray.map(serv => {
        return serv;
       })

  case 'EMPTY_DRIVE':
    return  drivesInitialState;

  case 'EDIT_DRIVE':
    return  state.map((drive) => {
      if (drive.driveId === action.permissionsData.driveId) {
        return {
          ...drive,
          drivesPermissions: action.permissionsData.drivesPermissions
        };
      } else {
        return drive;
      }
    });

    case 'REMOVE_LINES':
      let permissionsArr = [];
      let permObj = [];
      let drivesArr = [];
      return state.map((drive) => {
        if (drive.driveId === action.permissionsData.permission.driveId) {
        permissionsArr =  drive.drivesPermissions.filter( (perm) => perm.permissionId !== action.permissionsData.permission.permissionId )
        permObj =({drivesPermissions: permissionsArr})
        return {
          ...drive,
          drivesPermissions: [...permissionsArr]
        }
      } else return drive
      })
  case 'EDIT_YOU_ARE_ORGANIZER':
    return  state.map((drive) => {
      if (drive.driveId === action.driveId.driveId) {
        return {
          ...drive,
          youAreOrganizer:action.driveId.youAreOrganizer
        };
      } else {
        return drive;
      }
    });
  case 'EDIT_DRIVE_NAMES':
    return  state.map((drive) => {
      if (drive.driveId === action.driveId) {
        return {
          ...drive,
          permissionsNames:action.permissionsNames
        };
      } else {
        return drive;
      }
    });

  case 'EDIT_DRIVE_SHEETID':
    return  state.map((drive) => {
      if (drive.driveId === action.driveSheetId.driveId) {
        return {
          ...drive,
          drive_sheetId : action.driveSheetId.driveSheetId
        };
      } else {
        return drive;
      }
    });
  case 'EDIT_DRIVE_PERM':
    return  state.map((drive) => {
      if (drive.driveId === action.driveId) {
        return {
          ...drive,
          permission:action.permissions

        };
      } else {
        return drive;
      }
    });
    case 'EDIIT_PERMISSION':
      return  state.map((drive) => {
        if (drive.driveId === action.driveId) {
          return {
            ...drive,
            permission:action.permissions

          };
        } else {
          return drive;
        }
      });

  case 'EMPTY_DRIVES':
    return drivesInitialState
  case 'EDIT_DRIVE_PERM_ID':
    return  state.map((drive) => {
      if (drive.driveId === action.permissionsdata.driveId) {
        return {
          ...drive,
          permission:action.permissionsdata

        };
      } else {
        return drive;
      }
    });
  default:
    return state
  }
}


